import { IObservable } from "../../../utils/eventbase/Observable";
import { Part } from "../Part";

export interface IParamBooleanItemFactoryPart<TViewModel> {
    valueSource?: boolean | null;
    valueSourceSubscription?: IObservable<boolean>;
    itemViewFactoryTrue?: () => Part;
    itemViewFactoryFalse?: () => Part;
}

export class BooleanItemFactoryPart<TViewModel> extends Part {

    constructor(private _par: IParamBooleanItemFactoryPart<TViewModel>) {
        super({});
        if (typeof _par.valueSource == "boolean") {
            this.setValue(_par.valueSource);
        }

        if (_par.valueSourceSubscription != null) {
            _par.valueSourceSubscription.subscribeInitial(this.subscriptionContainer, (v) => this.setValue(v));
        }
    }

    private setValue(value: boolean ) {
        this.removeChildren();
        if (value) {
            if (this._par.itemViewFactoryTrue) {
                let part = this._par.itemViewFactoryTrue();
                this.addChild(part);
            }
        } else {
            if (this._par.itemViewFactoryFalse) {
                this.addChild(this._par.itemViewFactoryFalse());
            }
        }
    }
}