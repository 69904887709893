import { StateTransition } from "./StateTransition";
import { ViewPart } from "../parts/ViewPart";

export class StateTransitionVisibility implements StateTransition<boolean>{

    public static instance = new StateTransitionVisibility();

    public set(p: ViewPart, s: boolean) {
        p.setVisibilityInternal(s);
    }
}