import { TextPopupViewModel } from "./TextPopupViewModel";
import { TitleText } from "../../../../common/ui/parts/text/TitleText";

import { ContainerPart } from "../../../../lib/ui/parts/containers/ContainerPart";
import theme from "../../../../app/style/Theme";

export class TextPopupView extends ContainerPart {
    constructor(viewmodel: TextPopupViewModel) {
        super({
            style: {
                padding: theme.getSpacePx(10),
            },
            children: [
                new TitleText({
                    style: {
                        textAlign: "center"
                    },
                    textSubscription: viewmodel.obsText
                })
            ]
        });
    }
}