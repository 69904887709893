import { ViewModel } from "../../../../../../lib/ui/viewmodels/common/ViewModel";
import { AutomatControlsViewModel } from "../AutomatControlsViewModel";
import { IAutomatProgramStatus } from "../../../../../../server_com/messages/E2C/IAutomatStatusMessageE2C";
import { ObservableDef } from "../../../../../../lib/utils/eventbase/Observable";

export class ProgramStatusViewModel extends ViewModel {



    constructor(private _parent: AutomatControlsViewModel, private data: IAutomatProgramStatus) {
        super(_parent);
        this._automatProgramId.emit(data.automatProgramId);
        this._genreName.emit(data.genreName);
        this._programName.emit(data.programName);
        this._tracklistId.emit(data.tracklistId);
        this._useFactor.emit(data.useFactor);
    }

    public removeProgramClicked() {
        if (this._automatProgramId.value) {
            this._parent.removeAutomatProgram(this._automatProgramId.value);
        }
    }

    public increaseProgramClicked() {
        if (this._automatProgramId.value) {
            this._parent.increaseAutomatProgram(this._automatProgramId.value);
        }
    }

    public decreaseProgramClicked() {
        if (this._automatProgramId.value) {
            this._parent.decreaseAutomatProgram(this._automatProgramId.value);
        }
    }

    private readonly _automatProgramId: ObservableDef<string> = new ObservableDef<string>("");
    public get automatProgramId(): ObservableDef<string> { return this._automatProgramId; };

    private readonly _genreName: ObservableDef<string> = new ObservableDef<string>("");
    public get genreName(): ObservableDef<string> { return this._genreName; };

    private readonly _programName: ObservableDef<string> = new ObservableDef<string>("");
    public get programName(): ObservableDef<string> { return this._programName; };

    private readonly _tracklistId: ObservableDef<string> = new ObservableDef<string>("");
    public get tracklistId(): ObservableDef<string> { return this._tracklistId; };

    private readonly _useFactor: ObservableDef<number> = new ObservableDef<number>(0);
    public get useFactor(): ObservableDef<number> { return this._useFactor; };

}