import texts from '../../../../common/ui/localization/Language';
import theme from '../../../../app/style/Theme';
import { BarLoadingSpinner } from '../../../../common/ui/parts/spinners/BarLoadingSpinner';
import { Button } from '../../../../common/ui/parts/buttons/Button';
import { CardBackgroundColor, CardContainer, CardSizeMode } from '../../../../common/ui/parts/containers/CardContainer';
import { FlexContainerPart } from '../../../../lib/ui/parts/containers/FlexContainerPart';
import { GridContainer } from '../../../../lib/ui/parts/containers/GridContainer';
import { IconPart } from '../../../../lib/ui/parts/icon/IconPart';
import { ImagePart } from '../../../../lib/ui/parts/image/ImagePart';
import { LinkPart } from '../../../../lib/ui/parts/button/LinkPart';
import { LoginViewModel } from '../../../viewmodels/main/login/LoginViewModel';
import { NormalText } from '../../../../common/ui/parts/text/NormalText';
import { StateTransitionVisibilityFade } from '../../../../lib/ui/transitions/StateTransitionVisibilityFade';
import { TitleText } from '../../../../common/ui/parts/text/TitleText';
import { TransitionFadeIn } from '../../../../common/ui/transitions/TransitionFadeIn';
import { UrlRepository } from '../../../../server_com/utils/UrlRepository';

export class LoginView extends GridContainer {

    constructor(viewModel: LoginViewModel) {
        super({
            style: {

                gridTemplateColumns: "1fr",
                gridTemplateRows: "1fr",
            },
            children: [
                new GridContainer({
                    visibleTransition: StateTransitionVisibilityFade,
                    style: {
                        gridColumn: "1",
                        gridRow: "1",
                        gridTemplateRows: "0.4fr auto auto min-content 0.2fr min-content min-content"
                    },
                    children: [
                        new ImagePart({
                            style: {
                                maxWidth: "35%",
                                maxHeight: "65%",
                                margin: "auto"
                            },
                            source: "res/img/logo/speaker2.svg",
                        }),
                        new TitleText({
                            style: {
                                padding: theme.getSpacePx(10),
                                marginLeft: "auto",
                                marginRight: "auto",
                            },
                            text: texts.companyFull,
                        }),
                        new BarLoadingSpinner({
                            style: {
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: theme.scalePx(100),
                                height: theme.scalePx(100)
                            }
                        }),
                        new NormalText({
                            style: {
                                padding: theme.getSpacePx(10),
                                marginLeft: "auto",
                                marginRight: "auto"
                            },
                            textSubscription: viewModel.loginStatusText
                        }),
                        new NormalText({
                            style: {
                                padding: theme.getSpacePx(10),
                                marginLeft: "auto",
                                marginRight: "auto"
                            },
                            textSubscription: viewModel.verificatieStep
                        }),
                        new NormalText({
                            style: {
                                padding: theme.getSpacePx(10),
                                marginLeft: "auto",
                                marginRight: "auto"
                            },
                            textSubscription: viewModel.loginError
                        }),
                    ]
                }),
                //new GridContainer({
                //    visibleSubscription: viewModel.askForCredentials,
                //    visibleTransition: StateTransitionVisibilityFade,
                //    style: {
                //        gridColumn: "1",
                //        gridRow: "1",
                //        gridTemplateRows: "min-content",
                //        gridTemplateColumns: "min-content",
                //        justifyContent: "center",
                //        alignContent: "center"
                //    },
                //    children: [
                //        new GridContainer({
                //            style: {
                //                justifyItems: "center"
                //            },
                //            children: [
                //                new TitleText({
                //                    style: {
                //                        padding: theme.getSpacePx(10),

                //                    },
                //                    text: texts.notLoggedIn
                //                }),
                //                new ImagePart({
                //                    style: {
                //                        maxWidth: "25%",
                //                        marginTop: "10px",
                //                        marginBottom: "10px"
                //                    },
                //                    source: "res/img/logo/speaker2.svg",
                //                }),
                //                new NormalText({
                //                    style: {
                //                        padding: theme.getSpacePx(10),
                //                        marginLeft: "auto",
                //                        marginRight: "auto"
                //                    },
                //                    text: texts.notLoggedInMessage
                //                }),
                //                new LinkPart({
                //                    style: {
                //                        textAlign: "center",
                //                        textDecoration: "none",
                //                    },
                //                    href: UrlRepository.myPbUrl,
                //                    children: [
                //                        new GridContainer({
                //                            style: {
                //                                gridTemplateColumns: "auto auto",
                //                                textDecoration: "none"
                //                            },
                //                            children: [
                //                                new NormalText({
                //                                    style: {
                //                                        display: "inline-block",
                //                                        color: Colors.titleTextColor,
                //                                        textDecoration: "underline"
                //                                    },
                //                                    text: UrlRepository.myPbUrl,
                //                                }),
                //                                new IconPart({
                //                                    style: {
                //                                        display: "inline-block",
                //                                        color: Colors.titleTextColor,
                //                                        verticalAlign: "bottom",
                //                                        fontSize: theme.getSpacePx(25),
                //                                        textDecoration: "none"
                //                                    },
                //                                    icon: "arrow_forward"
                //                                })
                //                            ]
                //                        })
                                        
                //                    ]
                //                })
                //            ]
                //        }),
                //    ]
                //}),
                new NormalText({
                    style: {
                        fontStyle: "italic",
                        alignSelf: "flex-start",
                    },
                    textSubscription: viewModel.versionText
                })
            ]
        });

    }

}