import { ContainerPart, IParamContainerPart } from "../../../../lib/ui/parts/containers/ContainerPart";
import { ImagePart } from "../../../../lib/ui/parts/image/ImagePart";
import { AlbumCoverBase } from "./AlbumCoverBase";
import theme from "../../../../app/style/Theme";

export interface IParamAlbumCover extends IParamContainerPart{

}

export class AlbumCover extends AlbumCoverBase {

    constructor(par: IParamAlbumCover) {
        AlbumCover.setDefaultStyle(par, {
            width: theme.scalePx(242),
            height: theme.scalePx(242),
        });
        super(par);      

    }
}