import { IFromEngineMessageH2C } from "../messages/H2C/IFromEngineMessageH2C";
import { IEventEmitter } from "../../lib/utils/eventbase/EventEmitter";
import { ChannelType } from "../messages/ChannelType";
import { MessageC2E } from "../messages/MessageC2E";
import {ReasonableError} from "../../lib/utils/ReasonableError";
import {IEngineDescription} from "./login/EngineSelectionService";
import {IConnectedEngines} from "../models/engine/IConnectedEngines";
import {ConnectionState} from "./hub/ConnectionState";
import {IObservable} from "../../lib/utils/eventbase/Observable";
import {IMessageToControllerE2DC} from "../messages/E2DC/IMessageToControllerE2DC";

export interface IConnectionService {
    readonly receivedFromEngineEvent: IEventEmitter<IFromEngineMessageH2C | IMessageToControllerE2DC>;
    readonly channelOpened: IEventEmitter<ChannelType>;
    readonly onError: IEventEmitter<ReasonableError<ConnectionErrorReason>>;
    readonly connectedEnginesChangedEvent: IEventEmitter<IConnectedEngines>;
    readonly obsConnectionsState: IObservable<ConnectionState>;
    openChannel(channelId: ChannelType): void;
    closeChannel(channelId: ChannelType): void;
    sendToEngine(message: MessageC2E, channelId: ChannelType): void;
    disconnectFromEngine(): void;
    connectToEngine(engine: IEngineDescription, playerNbr: number): void;
    
}

export enum ConnectionErrorReason {
    Unknow,
    NoPlayer,
    AlreadyConnected,
    NoLogin
}