import { MessageC2E } from "../MessageC2E";

export class PlayAutomatProgramMessageC2E extends MessageC2E {

    constructor(automatProgramId: string) {
        super();
        this.automatProgramId = automatProgramId;
    }

    automatProgramId: string;

}