import texts from "../../../common/ui/localization/Language";
import { GridContainer } from "../../../lib/ui/parts/containers/GridContainer";
import { PlayerViewModel } from "./PlayerViewModel";
import { IconButton, IconButtonSize } from "../../../common/ui/parts/buttons/IconButton";
import { ComponentContainerPart } from "../../../lib/ui/parts/components/ComponentContainerPart";
import { HomeComponentViewModel } from "../../player/components/home/HomeComponentViewModel";
import { TracklistComponentViewModel } from "../../player/components/playlist_details/TracklistComponentViewModel";
import { HomeComponentView } from "../../player/components/home/HomeComponentView";
import { TransitionFadeIn } from "../../../common/ui/transitions/TransitionFadeIn";
import { TransitionFadeOut } from "../../../common/ui/transitions/TransitionFadeOut";
import { TransitionFadeInFromRight } from "../../../common/ui/transitions/TransitionFadeInFromRight";
import { TransitionFadeOutToRight } from "../../../common/ui/transitions/TransitionFadeOutToRight";
import { TracklistComponentView } from "../../player/components/playlist_details/TracklistComponentView";
import { PlaylistsOverviewComponentView } from "../../player/components/playlists_overview/PlaylistsOverviewComponentView";
import { AutomatComponentView } from "../../player/components/automat/AutomatComponentView";
import { SearchComponentView } from "../../player/components/search/SearchComponentView";
import { PlaylistsOverviewComponentViewModel } from "../../player/components/playlists_overview/PlaylistsOverviewComponentViewModel";
import { AutomatComponentViewModel } from "../../player/components/automat/AutomatComponentViewModel";
import { SearchComponentViewModel } from "../../player/components/search/SearchComponentViewModel";
import { TransitionFadeOutToLeft } from "../../../common/ui/transitions/TransitionFadeOutToLeft";
import { TransitionFadeInFromLeft } from "../../../common/ui/transitions/TransitionFadeInFromLeft";
import { PopupComponentContainerPart } from "../../../lib/ui/parts/components/PopupComponentContainerPart";
import { TextPopupViewModel } from "../../player/components/popups/TextPopupViewModel";
import { ViewFactory } from "../../../lib/ui/parts/contentmanagers/ViewFactory";
import { TextPopupView } from "../../player/components/popups/TextPopupView";
import { PlayerBreadCrumText } from "../../../common/ui/parts/breadcrum/PlayerBreadCrumText";
import { PlayerControlBarView } from "./PlayerControlBarView";
import { PlayerSideBarView } from "./PlayerSideBarView";
import { StateTransitionVisibilityFade } from "../../../lib/ui/transitions/StateTransitionVisibilityFade";
import theme from "../../../app/style/Theme";
import { BooleanItemFactoryPart } from "../../../lib/ui/parts/nonvisible/BooleanItemFactoryPart";
import screenPropertiesService from "../../../lib/services/ScreenPropertiesService";
import { StyleBinding, ContainerPart, SelectPart, SwitchableContainerPart } from "../../../lib/index";
import { SettingsViewModel } from "../../player/components/settings/SettingsViewModel";
import { SettingsView } from "../../player/components/settings/SettingsView";
import { AboutView } from "../../player/components/about/AboutComponentView";
import { AboutComponentViewModel } from "../../player/components/about/AboutComponentViewModel";
import { JukeboxControlBarView } from "./JukeboxControlBarView";

export class PlayerView extends GridContainer {
    constructor(viewModel: PlayerViewModel) {
        super({
            style: {
                gridTemplateRows: `minmax(0, 1fr)`,
                gridTemplateColumns: `minmax(0, 1fr)`,

            },
            children: [

                new BooleanItemFactoryPart({
                    valueSourceSubscription: screenPropertiesService.obsIsSmall,
                    itemViewFactoryTrue: () => PlayerView.createSmallView(viewModel),
                    itemViewFactoryFalse: () => PlayerView.createNormalView(viewModel),
                })


            ]
        });
    }

    private static createSmallView(viewModel: PlayerViewModel) {
        return new GridContainer({
            style: {
                gridTemplateRows: `${theme.scalePx(50)} minmax(0, 1fr) auto`,
                gridTemplateColumns: `auto`,
                gridTemplateAreas: `"header" "content" "playerbar"`,
            },
            children: [
                PlayerView.createHeader(viewModel),
                PlayerView.createContent(viewModel),
                PlayerView.createPlayerbar(viewModel),
                PlayerView.createCollapsibleSidemenu(viewModel),
            ]
        })
    }

    private static createNormalView(viewModel: PlayerViewModel) {
        return new GridContainer({
            style: {
                gridTemplateRows: `${theme.scalePx(50)} minmax(0, 1fr) auto`,
                gridTemplateColumns: `auto minmax(min-content, ${theme.scalePx(225)})`,
                gridTemplateAreas: `"header sidemenu" "content sidemenu" "playerbar playerbar"`,
            },
            children: [
                PlayerView.createHeader(viewModel),
                PlayerView.createContent(viewModel),
                PlayerView.createPopupContent(viewModel),
                PlayerView.createPlayerbar(viewModel),
                PlayerView.createSidemenu(viewModel),
            ]
        });


    }

    private static createHeader(viewModel: PlayerViewModel) {
        return new GridContainer({
            style: {
                gridTemplateAreas: `"back_button breadcrumb"`,
                gridTemplateColumns: `${theme.scalePx(60)} 1fr`,
                gridArea: "header"
            },
            children: [
                new IconButton({
                    style: {
                        margin: "auto",
                        gridArea: "back_button"
                    },
                    icon: "keyboard_arrow_left",
                    iconButtonSize: IconButtonSize.Huge,
                    onclick: () => viewModel.backButtonClickAsync(),
                    visibleSubscription: viewModel.obsShowBackButton,
                    visibleTransition: StateTransitionVisibilityFade
                }),
                new GridContainer({
                    style: {
                        gridArea: "breadcrumb",
                        gridTemplateRows: "auto",
                        gridTemplateColumns: "1fr auto"
                    },
                    children: [
                        //new LicenceText({
                        //    textSubscription: _viewModel.obsCustomerName
                        //}),
                        //new LicenceText({
                        //    textSubscription: _viewModel.obsLicenseName
                        //}),
                        new PlayerBreadCrumText({
                            viewModelsSubscription: viewModel.componentContainer.obsViewModels
                        }),
                        new IconButton({
                            icon: "menu",
                            iconButtonSize: IconButtonSize.Huge,
                            visibleSubscription: screenPropertiesService.obsIsSmall,
                            onclick: () => viewModel.toggleMenu()
                        })
                    ]
                }),
            ]
        });
    }

    private static createContent(viewModel: PlayerViewModel) {
        return new ComponentContainerPart({
            style: {
                zIndex: "0",
                gridArea: "content"
            },
            viewFactories: [
                new ViewFactory(HomeComponentViewModel, (vm) => new HomeComponentView(vm), new TransitionFadeIn(), new TransitionFadeOut()),
                new ViewFactory(TracklistComponentViewModel, (vm) => new TracklistComponentView(vm), new TransitionFadeInFromRight(), new TransitionFadeOutToRight()),
                new ViewFactory(PlaylistsOverviewComponentViewModel, (vm) => new PlaylistsOverviewComponentView(vm), new TransitionFadeInFromRight(), new TransitionFadeOutToRight()),
                new ViewFactory(AutomatComponentViewModel, (vm) => new AutomatComponentView(vm), new TransitionFadeInFromRight(), new TransitionFadeOutToRight()),
                new ViewFactory(SearchComponentViewModel, (vm) => new SearchComponentView(vm), new TransitionFadeInFromRight(), new TransitionFadeOutToRight()),
                new ViewFactory(SettingsViewModel, (vm) => new SettingsView(vm), new TransitionFadeInFromRight(), new TransitionFadeOutToRight()),
                new ViewFactory(AboutComponentViewModel, (vm) => new AboutView(vm), new TransitionFadeInFromRight(), new TransitionFadeOutToRight())
            ],
            componentContainer: viewModel.componentContainer,
            transitionActivateFirst: new TransitionFadeIn(),
            transitionDeactivateLast: new TransitionFadeOut(),
            transitionDeactiveteNew: new TransitionFadeOutToLeft(),
            transitionActivateNew: new TransitionFadeInFromRight(),
            transitionDeactiveteExisting: new TransitionFadeOutToRight(),
            transitionActivateExisting: new TransitionFadeInFromLeft(),
        });
    }

    private static createPopupContent(viewModel: PlayerViewModel) {
        return new PopupComponentContainerPart({
            style: {
                gridArea: "content"
            },
            viewFactories: [
                new ViewFactory(TextPopupViewModel, (vm) => new TextPopupView(vm)),
            ],
            componentContainer: viewModel.popupComponentContainer
        });
    }

    private static createPlayerbar(viewModel: PlayerViewModel) {

        return new ContainerPart({
            style: {
                gridArea: "playerbar",
            },
            children: [
                new PlayerControlBarView({
                    style: {
                        height: theme.scalePx(120)
                    },
                    visibleSubscription: viewModel.obsShowControlBar
                }, viewModel.playerBarViewModel),
                new JukeboxControlBarView({
                    style: {
                        height: theme.scalePx(100)
                    },
                    visibleSubscription: viewModel.obsShowJukeboxBar
                }, viewModel.jukeboxBarViewModel)
            ]
        });

        return new PlayerControlBarView({
            style: {
                height: theme.scalePx(120)
            },
            visibleSubscription: viewModel.obsShowControlBar
        }, viewModel.playerBarViewModel);
    }

    private static createCollapsibleSidemenu(viewModel: PlayerViewModel) {
        return new GridContainer({
            style: {
                gridArea: "content",
                gridTemplateAreas: `"overlay sidemenu"`,
                gridTemplateRows: "minmax(0, 1fr)",
                gridTemplateColumns: `minmax(0, 1fr) ${theme.scalePx(225)}`,
                position: "absolute",
                top: "0",
                bottom: "0",
                right: "0",
                overflow: "hidden",
                transition: theme.transition
            },
            bindings: [
                new StyleBinding({
                    subscription: viewModel.obsShowMenu,
                    styleFactory: (v) => {
                        if (v) {
                            return {
                                left: "0%",
                                opacity: "1"
                            };
                        } else {
                            return {
                                left: "100%",
                                opacity: "0"
                            };
                        }
                    }
                }),
            ],
            children: [
                new ContainerPart({
                    style: {
                        gridArea: "overlay",
                        outline: "0",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        webkitTapHighlightColor: "rgba(0, 0, 0, 0)"
                    },
                    onclick: () => viewModel.closeMenuClick()
                }),
                PlayerView.createSidemenu(viewModel)
            ]
        })
    }

    private static createSidemenu(viewModel: PlayerViewModel) {
        return new PlayerSideBarView({
            style: {
                gridArea: "sidemenu",
                zIndex: "1",
            },
        }, viewModel.playerSizeMenuViewModel);
    }
}