import { ViewPart } from "../../../../lib/ui/parts/ViewPart";
import { IconPart } from "../../../../lib/ui/parts/icon/IconPart";
import { ButtonPart, IParamButtonPart } from "../../../../lib/ui/parts/button/ButtonPart";
import { StyleConstants } from "../style/StyleConstants";
import { TextPart } from "../../../../lib/ui/parts/text/TextPart";
import { NormalText } from "../text/NormalText";
import { IObservable } from "../../../../lib/utils/eventbase/Observable";
import { HybridIcon } from "../icon/HybridIcon";
import theme from "../../../../app/style/Theme";


export interface IParamPlayerBarActionButton extends IParamButtonPart {
    iconName: string;
    activatedSubscription?: IObservable<boolean>;
}


export class PlayerBarActionButton extends ButtonPart {

    private _activated: boolean;

    constructor(par: IParamPlayerBarActionButton) {

        ViewPart.setDefaultStyle(par, {
            margin: "0",
            padding: "0",
            border: "none",
            borderRadius: "0",
            outline: "0",
            backgroundColor: "transparent",
            transition: StyleConstants.transition,
            fontSize: theme.fontSizeMenuButton,
        });
        super(par);

        this._activated = false;
        this.addChildren([
            new HybridIcon({
                icon: par.iconName,
                iconSize: theme.scalePx(30),
            })
        ]);
        if (par.activatedSubscription) {
            par.activatedSubscription.subscribeInitial(this.subscriptionContainer, (v) => {
                if (v) {
                    this.activate();
                } else {
                    this.deactivate();
                }
            });
        }
    }

    public activate() {
        this._activated = true;
        this.applyStyle({
            filter: "contrast(100%)"
        });
    }

    public deactivate() {
        this._activated = false;
        this.applyStyle({
            filter: "contrast(20%)"
        });
    }
}