import { FlexContainerPart } from "../../../lib/ui/parts/containers/FlexContainerPart";
import { TitleText } from "../../../common/ui/parts/text/TitleText";
import { ErrorText } from "../../../common/ui/parts/text/ErrorText";
import { GridContainer } from "../../../lib/ui/parts/containers/GridContainer";
import { ImagePart } from "../../../lib/ui/parts/image/ImagePart";
import { BarLoadingSpinner } from "../../../common/ui/parts/spinners/BarLoadingSpinner";
import { IconButton, IconButtonSize } from "../../../common/ui/parts/buttons/IconButton";
import texts from "../../../common/ui/localization/Language";
import { NormalText } from "../../../common/ui/parts/text/NormalText";
import { EngineConnectionMenuViewModel } from "./EngineConnectionMenuViewModel";
import theme from "../../../app/style/Theme";

export class EngineConnectionMenuView extends FlexContainerPart {

    constructor(viewModel: EngineConnectionMenuViewModel) {
        super({
            flexAlignItems: "center",
            children: [
                new GridContainer({
                    style: {
                        gridTemplateRows: "30px 0.4fr auto auto min-content 0.2fr",
                        gridTemplateColumns: "100%"
                    },
                    children: [
                        new IconButton({
                            style: {
                                gridRow: "1"
                            },
                            icon: "menu",
                            iconButtonSize: IconButtonSize.Huge,
                            onclick: () => viewModel.goToMenu()
                        }),
                        new ImagePart({
                            style: {
                                maxWidth: "35%",
                                maxHeight: "65%",
                                margin: "auto",
                                gridRow: "2"
                            },
                            source: "res/img/logo/speaker2.svg",
                        }),
                        new TitleText({
                            style: {
                                padding: theme.getSpacePx( 10),
                                marginLeft: "auto",
                                marginRight: "auto",
                                gridRow: "3",
                                textAlign: "center",
                            },
                            text: texts.companyFull,
                        }),
                        new BarLoadingSpinner({
                            style: {
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: theme.scalePx(100),
                                height: theme.scalePx(100),
                                gridRow: "4"
                            },
                            invisibleSubscription: viewModel.obsRetryButtonVisiblity,
                        }),
                        new IconButton({
                            style: {
                                marginLeft: "auto",
                                marginRight: "auto",
                                gridRow: "4"
                            },
                            icon: "refresh",
                            visibleSubscription: viewModel.obsRetryButtonVisiblity,
                            onclick: () => viewModel.retryConnection()
                        }),
                        new NormalText({
                            style: {
                                padding: theme.getSpacePx(10),
                                marginLeft: "auto",
                                marginRight: "auto",
                                gridRow: "5",
                                textAlign: "center",
                            },
                            text: texts.loadingMin,
                            textSubscription: viewModel.obsStatusText
                        }),
                        new ErrorText({
                            style: {
                                textAlign: "center",
                                gridRow: "6",
                            },
                            textSubscription: viewModel.obsErrorText
                        }),
                    ]
                }),
            ]
        });
    }
}