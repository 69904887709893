import { ServiceChannelBase } from "../../ServiceChannelBase";
import { MessageE2C } from "../../../messages/MessageE2C";
import { SearchTracksRequestC2E } from "../../../messages/C2E/SearchTracksRequestC2E";
import { ITrackSearchResult } from "../../../models/player/search/ITrackSearchResult";
import { AddSearchResultToWaitlistC2E } from "../../../messages/C2E/AddTrackToWaitlistC2E";

export class SearchService extends ServiceChannelBase<"Search">{
    
    private static _singleton: SearchService;
    public static getInstance(): SearchService {
        return this._singleton || (this._singleton = new SearchService());
    }

    constructor() {
        super("Search");
    }

    public async searchTracksAsync(searchText: string) {
        let result = await this.sendRequestAsync(
            new SearchTracksRequestC2E(searchText),
            (request, response) => (response.type == "SearchTracksResponseE2C"
                && response.requestId == request.requestId));

        return this.receiveSearchTracksRequestC2E(result)
    }

    private receiveSearchTracksRequestC2E(result: MessageE2C): ITrackSearchResult[] {
        if (result.type == "SearchTracksResponseE2C") {
            return result.results.map((t) => ({
                album: t.album,
                artist: t.artist,
                duration: t.duration,
                inWaitlist: t.inWaitlist,
                isKaraoke: t.isKaraoke,
                isVideo: t.isVideo,
                title: t.title,
                trackId: t.trackId
            } as ITrackSearchResult))
        }
        throw new Error("Message is not a 'SearchTracksResponseE2C' message");
    }

    protected onMessageOnChannel(message: MessageE2C): void {
        
    }

    public addSearchResultToWaitlist(trackId: string, sourceTracklistId: string | null) {
        this.sendOnChannel(new AddSearchResultToWaitlistC2E(trackId, sourceTracklistId));
    }
}