import { ContainerPart } from "../../../lib/index";
import { EngineListItemViewModel } from "./EngineListItemViewModel";
import { NormalText } from "../../../common/ui/parts/text/NormalText";
import theme from "../../../app/style/Theme";
import { Colors } from "../../../common/ui/parts/colors/Colors";

export class EngineListItemView extends ContainerPart {
    constructor(viewmodel: EngineListItemViewModel) {
        super({
            style: {
                width: "100%",
                height: "auto",
                borderRadius: "100px",
                padding: theme.getSpacePx(5, 25),
                margin: theme.getSpacePx(5,0,0,0),
                backgroundColor: Colors.buttonColor,
                
            },
            children: [
                new NormalText({
                    style: {
                        fontWeight: "bold"
                    },
                    textSubscription: viewmodel.obsEngineName
                }),
                new NormalText({
                    textSubscription: viewmodel.obsCompanyName
                }),
                new NormalText({
                    textSubscription: viewmodel.obsRole
                }),
            ],
            onclick: () => viewmodel.onClick()
        });
    }
}