import { ViewModel } from "../../../../../../lib/ui/viewmodels/common/ViewModel";
import { ObservableOpt, IObservable } from "../../../../../../lib/utils/eventbase/Observable";
import { TracklistComponentViewModel } from "../../../playlist_details/TracklistComponentViewModel";
import { ITracklistHdrSubitem } from "../../../../../../server_com/models/player/tracklist/ITracklistHdrSubitem";

export class PlayListSubItemViewModel extends ViewModel {

    private _obsTitle: ObservableOpt<string> = new ObservableOpt();
    public get obsTitle(): IObservable<string> { return this._obsTitle; }

    private playlistId: string;

    constructor(parent: ViewModel, item: ITracklistHdrSubitem) {
        super(parent);
        this._obsTitle.emit(item.name);
        this.playlistId = item.id;
    }

    public async itemClicked() {
        let playlistViewmodel = new TracklistComponentViewModel(this, this.playlistId);
        await playlistViewmodel.openPlayListAsync();
    }

}