import { ViewPart } from "../ViewPart";
import { SelectBase, SelectOptionBase, IParamSelectBase } from "../bases/SelectBase";
import { IObservableTwoWay } from "../../../utils/eventbase/ObservableTwoWay";
import { IObservable } from "../../../utils/eventbase/Observable";

export interface IParamSelectPart<T> extends IParamSelectBase {
    getValue: (x: T) => any;
    getText: (x: T) => string;
    selectedItemObs?: IObservableTwoWay<T>;
    sourceObs: IObservable<T[]>;
}
// ReSharper disable WrongExpressionStatement
export class SelectPart<T> extends SelectBase {

    private readonly _getTextFunction: (x: T) => string;
    private readonly _getValueFunction: (x: T) => any;

    private _options: SelectOptionPart[] = new Array<SelectOptionPart>();
    private _source: T[] | undefined;
    private _selectedItem: T | undefined;
    private _itemObservableTwoWay: IObservableTwoWay<T> | undefined;
    private _active: boolean = false;
    private _busy: boolean = false;
    private _optionBusy: HTMLOptionElement;


    constructor(param: IParamSelectPart<T>) {
        //const defaultStyle = {
        //    //width: "100%",
        //    //height: "100%",
        //    //overflow: "hidden"
        //};

        //super(parent, ViewPart.mergeStyles(defaultStyle, style));
        super(param);

        this._getValueFunction = param.getValue;
        this._getTextFunction = param.getText;

        this.selectElement.onchange = () => this.onChange();

        if (param.selectedItemObs) {
            this.setSelectedItemObservable(param.selectedItemObs);
        }

        if (param.sourceObs) {
            param.sourceObs.subscribeInitial(this.subscriptionContainer, (s) => {
                this.setSource(s);
            });

        }
        //this.root.onmouseover=() => {
        //    this.applyStyle({
        //        background:"red"
        //    };
        //}

        //this.root.onmouseout = () => {
        //    this.applyStyle({
        //         background: ""
        //    });
        //}


        //this._dropdownContainer = new ContainerPart(this);
        //this._dropdownContainer.applyStyle({
        //    position: "absolute",
        //    minHeight: "10px",
        //    minWidth: "10px",
        //    background: "#000",
        //    borderWidth: "1px",
        //    borderColor: "#FFF",
        //    borderRadius: "4px",
        //    borderStyle: "solid",
        //    width: "",
        //    height: "",
        //});


        //this._textPart = new TextPart(this);


        //this._dropdownContainer.hide();

        //this._textPart.root.onclick = (ef) => { this.openDropdown() };

        this._optionBusy = new Option("select...");
        this._optionBusy.disabled = true;
        this._optionBusy.hidden = true;
        this.selectElement.appendChild(this._optionBusy);

    }


    public setSource(source: T[]) {
        this._source = source;
        for (let item of source) {
            this.addOption(this.getValue(item), this._getTextFunction(item));
        }
    }

    public setText(text: string): void {
        let option = new Option(text, "");
        option.disabled = true;
        option.hidden = true;
        option.selected = true;
        this.selectElement.appendChild(option);
    }

    private async openDropdown(): Promise<void> {


        //new TextPart(this._dropdownContainer, "Hello word");


        //this._dropdownContainer.show();
        //await this.delay(2000);
        //this._dropdownContainer.hide();
        //this._dropdownContainer.removeChildren();
    }

    public removeOptions() {
        this._active = false;
        this.removeChildren();
        this._options = [];
        this._active = true;
    }

    public addOption(value: string, text: string) {
        this._active = false;
        let selected = false;   
        var option = new SelectOptionPart({
            text: text,
            value: value
        });
        this.addChild(option);
        this._options.push(option);
        if (this._selectedItem) {
            this.setSelectedOption(this.getValue(this._selectedItem));
        }        
        this._active = true;
    }

    public setSelectedOption(optionValue: string) {
        this._active = false;
        for (var option of this._options) {
            if (optionValue !== option.value) {
                option.selected = false;
            }            
        }
        for (var option of this._options) {
            if (optionValue === option.value) {
                option.selected = true;
            }
        }
        this._active = true;
    }

    public setSelectedItem(item: T) {
        this._selectedItem = item;
        let value = this.getValue(item);
        this.setSelectedOption(value);
    }

    public setSelectedItemObservable(itemObservable: IObservableTwoWay<T>) {
        this._itemObservableTwoWay = itemObservable;
        this._itemObservableTwoWay.subscribeInitial(this.subscriptionContainer, value => this.setSelectedItem(value));
    }

    private getSelectedItem(): T | undefined {
        let value: string | undefined = undefined;
        for (let option of this._options) {
            if (option.selected) {
                value = option.value.toString();
                break;
            }
        }

        if (this._source) {
            for (let item of this._source) {
                let selValue = this.getValue(item);
                if (selValue === value) {
                    return item;
                }
            }
        }

        return undefined;
    }

    private async onChange(): Promise<void> {
        if (this._itemObservableTwoWay && this._active && !this._busy) {
            this._busy = true;
            let selectedItem = this.getSelectedItem();

            this._optionBusy.text = "busy...";
            this._optionBusy.selected = true;

            if (selectedItem) {
                await this._itemObservableTwoWay.changeValueAsync(selectedItem);
            }

            this._optionBusy.text = "select...";

            if (this._selectedItem) {
                this.setSelectedItem(this._selectedItem);
            }
            this._busy = false;
        }
    }

    private getValue(item: T): string {
        if (item) {
            let x = this._getValueFunction(item);
            if (x) {
                return x.toString();
            }
        }
        return "undefined";
    }
}

export class SelectOptionPart extends SelectOptionBase {
}
