import { ParagraphPart, IParamParagraphPart } from "../../../../lib/ui/parts/text/ParagraphPart";
import { ViewPart } from "../../../../lib/ui/parts/ViewPart";
import { StyleConstants } from "../style/StyleConstants";
import theme from "../../../../app/style/Theme";
import { Colors } from "../colors/Colors";

export interface IParamErrorText extends IParamParagraphPart {
}

export class ErrorText extends ParagraphPart {

    constructor(par: IParamErrorText) {

        ViewPart.setDefaultStyle(par, {
            color: Colors.errorTextColor,
            fontSize: theme.fontSizeError
        });
        super(par);
    }
}