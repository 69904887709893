import { LocalStorage } from "../../../lib/app/local_storeage/LocalStorage";
import { ILocalStrings } from "./ILocalStrings";
import { ILanguageDefenition } from "./ILanguageDefenition";

export class LanguageStorage extends LocalStorage<"controller_texts" | "current_defenition"> {

    private static singleton: LanguageStorage;
    public static getInstance() {
        return this.singleton || (this.singleton = new LanguageStorage());
    }

    private constructor() {
        super("Language");
    }

    public setCurrentLanguageDefenition(langDef: ILanguageDefenition | null) {
        this.setItem("current_defenition", langDef);
    }

    public getCurrentLanguageDefenition(): ILanguageDefenition| null  {
        return this.getItem<ILanguageDefenition>("current_defenition");
    }

    public getLanguage(): ILocalStrings | null {
        return this.getItem<ILocalStrings>("controller_texts");
    }

    public setLanguage(lang: ILocalStrings): void  {
        this.setItem("controller_texts",lang);
    }
}