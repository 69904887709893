import {ViewModel} from "../../viewmodels/common/ViewModel";
import {ViewPart} from "../ViewPart";
import {ITransition} from "../../transitions/ITransition";

export interface IViewFactory {
    readonly contentName: string;
    readonly inTrans: ITransition | undefined;
    readonly outTrans: ITransition | undefined;

    createView(vm: ViewModel): ViewPart;
}