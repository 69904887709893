import { InputBase, IParamInputBase } from "../bases/InputBase";
import { IObservableTwoWay } from "../../../utils/eventbase/ObservableTwoWay";
import { IObservable } from "../../../utils/eventbase/Observable";


export interface IParamInputTextPart extends IParamInputBase {
    text?: string;
    textSubscription?: IObservable<string>;
    textSubscriptionTwoWay?: IObservableTwoWay<string>;
    placeholder?: string;
    placeholderSubscription?: IObservable<string>;
    password?: boolean;
    onKeyPressAsync?: () => Promise<void>;
    onChangedAsync?: () => Promise<void>;
    onEnterAsync?: () => Promise<void>;
}

export class InputTextPart extends InputBase {
    private _lastValue: string;
    private _changedCallback: (() => Promise<void>) | undefined;
    private _enterCallback: (() => Promise<void>) | undefined;
    private _keypressCallback: (() => Promise<void>) | undefined;
    private _textSubscriptionTwoWay?: IObservableTwoWay<string>;

    constructor(par: IParamInputTextPart) {

        let type = par.password ? "password" : "text";

        super(type, par);

        this.inputElement.onchange = () => this.change();
        this.inputElement.onkeydown = (key) => this.keydown(key);
        this.inputElement.onpaste = () => this.onPasteOrCut();
        this.inputElement.oncut = () => this.onPasteOrCut();
        this.inputElement.onblur = () => this.blur();

        if (par.text) {
            this.setInputText(par.text);
            delete par.text;
        }

        if (par.textSubscription) {
            par.textSubscription.subscribeInitial(this.subscriptionContainer, value => {
                this.setInputText(value);
            });
            delete par.textSubscription;
        }

        if (par.textSubscriptionTwoWay) {
            par.textSubscriptionTwoWay.subscribeInitial(this.subscriptionContainer, value => {
                this.setInputText(value);
            });

            this._textSubscriptionTwoWay = par.textSubscriptionTwoWay;
        }

        if (par.placeholder) {
            this.inputElement.placeholder = par.placeholder;
        }
        if (par.placeholderSubscription) {
            par.placeholderSubscription.subscribeInitial(this.subscriptionContainer, (ph) => this.inputElement.placeholder = ph);
        }

        this._lastValue = this.inputElement.value;

        if (par.onChangedAsync) {
            this._changedCallback = par.onChangedAsync;
        }

        if (par.onEnterAsync) {
            this._enterCallback = par.onEnterAsync;
        }

        if (par.onKeyPressAsync) {
            this._keypressCallback = par.onKeyPressAsync;
        }

    }

    public dispose(): void {
        super.dispose();
    }

    private setInputText(text: string): void {
        this._lastValue = text;
        this.inputElement.value = text;
    }

    private async change(): Promise<void> {
        if (this._changedCallback) {
            this._changedCallback();
        }
        this.checkValueChanged();
    }

    private blur() {
        this.checkValueChanged();
    }

    private async keydown(keyEvent: KeyboardEvent): Promise<void> {
        if (keyEvent.keyCode === 13) {
            if (this._enterCallback) {
                this._enterCallback();
            }
        }

        if (this._keypressCallback) {
            this._keypressCallback();
        }

        this.checkValueChangedWithTimeout();
    }

    private onPasteOrCut() {
        this.checkValueChangedWithTimeout();
    }

    private checkValueChanged(): void {
        let value = this.inputElement.value;
        if (value !== this._lastValue) {
            this._lastValue = value;
            if (this._textSubscriptionTwoWay) {
                this._textSubscriptionTwoWay.changeValueAsync(value);
            }
        }
    }

    private checkValueChangedWithTimeout(): void {
        setTimeout(() => {
            let value = this.inputElement.value;
            if (value !== this._lastValue) {
                this._lastValue = value;
                if (this._textSubscriptionTwoWay) {
                    this._textSubscriptionTwoWay.changeValueAsync(value);
                }
            }
        }, 0);
    }

}