import { Part } from "../Part";
import { IObservable } from "../../../utils/eventbase/Observable";

export interface IParamIfPart {
    condition?: boolean;
    conditionSubsription?: IObservable<boolean>;
    falseFactory?: () => Part;
    trueFactory?: () => Part;
}

export class IfPart extends Part {

    constructor(par: IParamIfPart) {

        super({});

        if (par.condition) {
            if (par.trueFactory) {
                this.addChild(par.trueFactory());
            }
        }
        else if (par.condition === false) {
            if (par.falseFactory) {
                this.addChild(par.falseFactory());
            }
        }

        if (par.conditionSubsription) {
            par.conditionSubsription.subscribeInitial(this.subscriptionContainer, condition => {
                this.removeChildren();

                if (condition) {
                    if (par.trueFactory) {
                        this.addChild(par.trueFactory());
                    }
                }
                else {
                    if (par.falseFactory) {
                        this.addChild(par.falseFactory());
                    }
                }
            });
        }
    }

}
