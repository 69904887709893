import { SubscriptionContainer } from "./SubscriptionContainer";
import { SubsribableBase } from "./SubsribableBase";

export interface IObservable<T> {
    subscribe(subscriptionContainer: SubscriptionContainer, callback: (value: T) => void): void;
    subscribeInitial(subscriptionContainer: SubscriptionContainer, callback: (value: T) => void): void;
}

export interface IObservableOpt<T> extends IObservable<T> {
    readonly optValue: T | undefined;
    whenValue(callback: (value: T) => void): void;
}

export interface IObservableDef<T> extends IObservable<T> {
    readonly value: T;
}


export class ObservableOpt<T> extends SubsribableBase<T> implements IObservableOpt<T> {

    private _value: T | undefined;
    private _hasValue: boolean = false;

    constructor() {
        super(true);
    }

    public get optValue(): T | undefined {
        return this._value;
    }

    public whenValue(callback: (value: T) => void): void {
        if (this._hasValue) {
            callback(this._value as T);
        }
    }

    public emit(value: T) {
        this._value = value;
        this._hasValue = true;
        this.emitInternal(value);
    }

    public emitOnChanged(value: T) {

        if (this._hasValue && this._value === value) {
            return;
        }
        this._value = value;
        this._hasValue = true;
        this.emitInternal(value);
    }


    public silentSet(value: T) {
        this._value = value;
        this._hasValue = true;
    }

    public subscribeInitial(subscriptionContainer: SubscriptionContainer, callback: (value: T) => void): void {
        super.subscribe(subscriptionContainer, callback);

        if (this._hasValue) {
            callback(this._value as T);
        }
    }
}

export class ObservableDef<T> extends SubsribableBase<T> implements IObservableDef<T> {

    private _value: T;

    constructor(initvalue: T, async: boolean = true) {
        super(async);
        this._value = initvalue;
    }

    public get value(): T {
        return this._value;
    }

    public emit(value: T) {
        this._value = value;
        this.emitInternal(value);
    }

    public emitOnChanged(value: T) {

        if (this._value === value) {
            return;
        }
        this._value = value;
        this.emitInternal(value);
    }


    public silentSet(value: T) {
        this._value = value;
    }

    public subscribeInitial(subscriptionContainer: SubscriptionContainer, callback: (value: T) => void): void {
        super.subscribe(subscriptionContainer, callback);

        callback(this._value);
    }
}

