import { MessageC2E } from "../MessageC2E";

export class AddTracklistTrackToWaitlistC2E extends MessageC2E {
    constructor(trackId: string, sourceTrackList: string | null) {
        super();
        this.trackId = trackId;
        this.sourceTrackList = sourceTrackList;
    }
    public readonly trackId: string;
    public readonly sourceTrackList: string | null;
}