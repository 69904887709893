import { ObservableOpt, IObservable } from "../../../lib/utils/eventbase/Observable";
import { ILocalStrings } from "./ILocalStrings";
import { DefaultStrings } from "./DefaultStrings";
import { ILanguageDefenition } from "./ILanguageDefenition";
import { LanguageStorage } from "./LanguageStorage";


export class LocalizationService {

    // #region SINGLETON_PATTERN
    private static _singleton: LocalizationService;
    public static getInstance() {
        return this._singleton ? this._singleton : (this._singleton = new LocalizationService());
    }
    // #endregion SINGLETON_PATTERN
    private _languageStore: LanguageStorage;

    private constructor() {
        this._languageStore = LanguageStorage.getInstance();
        let w: any = window;
        w.dumpLang = () => (JSON.stringify(new DefaultStrings()));
        this.checkForUpdateAsync();
    }

    private checkLanguageForMissingKeys(lang: ILocalStrings) {
        let template = new DefaultStrings();
        for (let key in template) {
            if (typeof (lang as any)[key] === "undefined") {
                console.warn("Check language for missing keys misses the key: " + key);
                return false;
            }
        }
        return true;
    }

    public getCurrentLanguageDefenition(): ILanguageDefenition | null {
        return this._languageStore.getCurrentLanguageDefenition();
    }


    public async checkForUpdateAsync() {
        let curLangDef = this._languageStore.getCurrentLanguageDefenition();
        if (curLangDef) {
            let newLang = await fetch(curLangDef.localizationFilePath).then((res) => res.json()) as ILocalStrings;
            let curLang = this._languageStore.getLanguage()
            if (curLang && newLang && (curLang.version != newLang.version || curLang.id != newLang.id)) {
                console.log(`Language update from ${curLang.version} to ${newLang.version}`);
                if (!this.checkLanguageForMissingKeys(newLang)) {
                    console.debug(`Language ${curLangDef.id} incomplete, see above warnings`);
                }
                this._languageStore.setLanguage(newLang);
                location.reload();
            } else if (!curLang && newLang) {
                console.log(`Language set to ${curLangDef.id}`);
                if (!this.checkLanguageForMissingKeys(newLang)) {
                    console.debug(`Language ${curLangDef.id} incomplete, see above warnings`);
                }
                this._languageStore.setLanguage(newLang);
                location.reload();
            }

        }
    }

}