import { ComponentViewModel } from "../../../../lib/ui/viewmodels/components/ComponentViewModel";
import { IViewModel } from "../../../../lib/ui/viewmodels/common/ViewModel";
import { ObservableOpt, IObservable } from "../../../../lib/utils/eventbase/Observable";

export class TextPopupViewModel extends ComponentViewModel {

    private _obsText: ObservableOpt<string> = new ObservableOpt();
    public get obsText(): IObservable<string> { return this._obsText; }

    constructor(parent: IViewModel) {
        super(parent, "Popup");
    }

    public async show(text: string, time: number = 3000) {
        this._obsText.emit(text);
        this.executeComponentAsync();
        await this.delayAsync(time);
        this.tryCloseComponentAsync();
    }
}