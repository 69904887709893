import { ComponentViewModel, IViewModel } from "../../lib/index";
import { EngineConnectionViewModel } from "./EngineConnectionViewModel";
import { IEngineDescription } from "../../server_com/services/login/EngineSelectionService";

export class EngineComponentViewModel extends ComponentViewModel{

    private _engineConnectionViewModel: EngineConnectionViewModel | undefined;
    public get engineConnectionViewModel(): EngineConnectionViewModel {
        if (!this._engineConnectionViewModel)
        {
            throw  new Error();
        }

        return this._engineConnectionViewModel;
    }

    constructor(parent: IViewModel) {
        super(parent, "Player");
    }

    public async show() {
        this._engineConnectionViewModel = new EngineConnectionViewModel(this);
        await this.executeComponentAsync();
        this.dispose()
    }

    public dispose() {
        this._engineConnectionViewModel?.dispose();
        this._engineConnectionViewModel = undefined;
        super.dispose();
    }

}