import { ILocalStrings } from "./ILocalStrings";

export class DefaultStrings implements ILocalStrings {

    readonly [index: string]: string;

    version: string = "1.0.0";
    id: string = "df-df";

    loadingMin: string = "Laden";
    loadingPlayer: string = "Speler wordt geladen";

    //Global
    cancel: string = "Annuleren";
    add: string = "Toevoegen";

    //Connect engine
    connectEngineTitle: string = "Koppel code invoeren";
    connectEngineHelperText: string = "De koppel code vind u op het settings scherm van de speler";
    connectEngineNotFound: string = "Code is niet geldig";
    connectEngineError: string = "Onbekende fout";

    //Main menu
    selectEngine: string = "Selecteer speler";
    engines: string = "Zones";
    about: string = "Informatie";

    //Settings
    settings: string = "Instellingen";
    settingsControllerName: string = "Controller naam";
    settingsSave: string = "Opslaan";

    //Login
    notLoggedIn: string = "Niet ingelogd";
    notLoggedInMessage: string = "Login met uw my pbsound account om gebruik te maken van deze applicatie";

    //Not connected
    notConnected: string = "Er is geen verbinding met speler";
    noEngineMessage: string = "Waarschijnlijk staat de speler uit of heeft geen internet verbinding";

    //Names
    companyFull: string = "PB-SOUND";
    applicationName: string = "PB-PLAYER CONTROLLER";


    //Errors
    oeps: string = "Oeps";
    unknowConnectionErrorFull: string = "Onbekende netwerk fout opgetreden";
    unknowConnectionErrorMin: string = "Netwerkfout";
    tryConnectionManual: string = "Probeer het later opnieuw";


    //Connecting
    connected: string = "Verbonden";
    connecting: string = "Verbinden";
    pollingLocalConnection: string = "Verbinding controleren";
    connectingToEngine: string = "Bezig met verbinden met de speler";

    //About screen
    back: string = "Terug";

    //Search
    seachPlaceholder: string = "Typ hier een deel van de artiest of titel";
    trackAddedPopupMessage: string = "${trackartist} - ${tracktitle} is toegevoegt aan de wachtlijst";

    //Automat controls
    lastUsedAutomatPrograms: string = "Geschiedenis";
    automatHeader: string = "Active lijsten";

    //Player
    player: string = "Player";
    search: string = "Zoeken";
    home: string = "Home";
    playlists: string = "Playlists";
    playlist: string = "Playlist";
    variousArtists: string = "Various artists";
    nowPlaying: string = "Now playing...";
    upNext: string = "Next...";
}
