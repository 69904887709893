import { IConnectionService, ConnectionErrorReason } from "../IConnectionService";
import { EventEmitter, IEventEmitter } from "../../../lib/utils/eventbase/EventEmitter";
import { IObservable, ObservableDef } from "../../../lib/utils/eventbase/Observable";
import { MessageC2E } from "../../messages/MessageC2E";
import { IEngineDescription } from "../login/EngineSelectionService";
import { ReasonableError } from "../../../lib/utils/ReasonableError";
import { ConnectionState } from "../hub/ConnectionState";
import { ChannelType } from "../../messages/ChannelType";
import { IConnectedEngines } from "../../models/engine/IConnectedEngines";
import { WebsocketService } from "../websocket/WebsocketService";
import { WebsocketState } from "../websocket/WebsocketState";
import { StateMachine, FixedEvent } from "../../../lib/utils/state/StateMachine";
import { SubscriptionContainer } from "../../../lib/utils/eventbase/SubscriptionContainer";
import { MessageE2DC } from "../../messages/MessageE2DC";
import { IMessageToControllerE2DC } from "../../messages/E2DC/IMessageToControllerE2DC";
import { IChannelOpenedResultE2DC } from "../../messages/E2DC/IChannelOpenedResultE2DC";
import { OpenChannelMessageDC2E } from "../../messages/DC2E/OpenChannelMessageDC2E";
import { CloseChannelMessageDC2E } from "../../messages/DC2E/CloseChannelMessageDC2E";
import { FromControllerMessageDC2E } from "../../messages/DC2E/FromControllerDC2E";
import { LoginControllerMessageDC2E } from "../../messages/DC2E/LoginMessageDC2E";

export class DirectEngineConnectionService implements IConnectionService {

    private static _singleton: DirectEngineConnectionService;
    public static getInstance(): DirectEngineConnectionService {
        return this._singleton || (this._singleton = new DirectEngineConnectionService());
    }

    private readonly _subscriptionContainer: SubscriptionContainer;
    private readonly _websocketService: WebsocketService;
    private readonly _stateMachine: StateMachine<StateMachineState, Event>;

    private _receivedFromEngineEvent: EventEmitter<IMessageToControllerE2DC> = new EventEmitter<IMessageToControllerE2DC>();
    private _channelOpened: EventEmitter<ChannelType> = new EventEmitter<ChannelType>();
    private _onError: EventEmitter<ReasonableError<ConnectionErrorReason>> = new EventEmitter<ReasonableError<ConnectionErrorReason>>();
    private _connectedEnginesChangedEvent: EventEmitter<IConnectedEngines> = new EventEmitter<IConnectedEngines>();
    private _obsConnectionsState: ObservableDef<ConnectionState> = new ObservableDef<ConnectionState>(ConnectionState.Disconnected);

    private _requestedEngineDescription: IEngineDescription | null = null;
    private _engineDescription: IEngineDescription | null = null;
    private _requestedPlayerNbr: number | null = null;
    private _playerNbr: number | null = null;

    private constructor() {
        this._subscriptionContainer = new SubscriptionContainer(this);
        this._websocketService = new WebsocketService();
        this._websocketService.obsWebsocketState.subscribe(this._subscriptionContainer, (state) => this.onWebsocketStateChange(state));
        this._websocketService.receivedFromWebsocketEvent.subscribe(this._subscriptionContainer, (message) => (this.receiveMessage(message)));

        this._stateMachine = new StateMachine<StateMachineState, Event>(StateMachineState.Idle,
            (state, event) => this.proccesStateChange(state, event));

        //this._stateMachine.onStateChanged((oldState, newState) => {
        //    console.log("EngineConnection state: " + StateMachineState[oldState] + " => " + StateMachineState[newState]);
        //});

        //this._stateMachine.onProcessEvent((event, state) => {
        //    console.log("EngineConnection event: " + Event[event] + " (state: " + StateMachineState[state] + ")");
        //});
    }

    private onWebsocketStateChange(state: WebsocketState): void {
        switch (state.state) {
            case "Closed":
                this._stateMachine.processEvent(Event.WebsocketClosed);
                break;
            case "Connecting":
                this._stateMachine.processEvent(Event.WebsocketConnecting);
                break;
            case "Connected":
                this._stateMachine.processEvent(Event.WebsocketConnected);
                break;
        }
    }

    private proccesStateChange(state: StateMachineState, event: Event | FixedEvent): StateMachineState | null {
        switch (state) {
            case StateMachineState.Idle:
                return this.proccesIdle(event);
            case StateMachineState.ValidateInfo:
                return this.proccesValidateInfo(event);
            case StateMachineState.WebSocketConnecting:
                return this.proccesWebSocketConnecting(event);
            case StateMachineState.WebSocketDisconnecting:
                return this.proccesWebSocketDisconnecting(event);
            case StateMachineState.EngineConnecting:
                return this.proccesEngineConnecting(event);
            case StateMachineState.Open:
                return this.proccesOpen(event);
            case StateMachineState.Error:
                return this.proccesError(event);
            default:
                return this.assertUnreachable(state);
        }
    }

    private proccesIdle(event: Event | FixedEvent): StateMachineState | null {
        switch (event) {
            case FixedEvent.EnterState:
                this._obsConnectionsState.emit(ConnectionState.Disconnected);
                return null;
            case Event.ConnectRequest:
                return StateMachineState.ValidateInfo;
            default:
                return null;
        }
    }

    private proccesValidateInfo(event: Event | FixedEvent): StateMachineState | null {
        switch (event) {
            case FixedEvent.EnterState:
                console.log(this._requestedEngineDescription);
                if (this._requestedEngineDescription != null &&
                    this._requestedEngineDescription.localUrl &&
                    this._requestedPlayerNbr != null) {

                    this._playerNbr = this._requestedPlayerNbr;
                    this._engineDescription = this._requestedEngineDescription;
                    this._websocketService.connect("ws://" + this._requestedEngineDescription.localUrl + "/Control");
                    return StateMachineState.WebSocketConnecting;
                } else {
                    return StateMachineState.Idle;
                }
            default:
                return null;
        }
    }

    private proccesWebSocketConnecting(event: Event | FixedEvent): StateMachineState | null {
        switch (event) {
            case FixedEvent.EnterState:
                this._obsConnectionsState.emit(ConnectionState.Connecting);
                return null;
            case Event.WebsocketConnected:
                return StateMachineState.EngineConnecting;
            case Event.WebsocketClosed:
                this._onError.emit(new ReasonableError<ConnectionErrorReason>(ConnectionErrorReason.Unknow, "Connection could not be made"));
                return StateMachineState.Error;
            default:
                return null;
        }
    }

    private proccesWebSocketDisconnecting(event: Event | FixedEvent): StateMachineState | null {
        this._websocketService.disconnect();
        return StateMachineState.Idle;
    }

    private proccesEngineConnecting(event: Event | FixedEvent): StateMachineState | null {
        switch (event) {
            case FixedEvent.EnterState:
                if (this._playerNbr != null) {
                    this._websocketService.send(new LoginControllerMessageDC2E(this._playerNbr));
                    return null;
                } else {
                    return StateMachineState.Error;
                }
            case Event.EngineConnected:
                return StateMachineState.Open;
            case Event.EngineTimeOut:
                this._onError.emit(new ReasonableError<ConnectionErrorReason>(ConnectionErrorReason.Unknow, "Engine took to long to respond"));
                return StateMachineState.Error;
            case Event.WebsocketClosed:
                this._onError.emit(new ReasonableError<ConnectionErrorReason>(ConnectionErrorReason.Unknow, "Connection could not be made"));
                return StateMachineState.Error;
            case Event.DisconnectRequest:
                return StateMachineState.WebSocketDisconnecting;
            default:
                return null;
        }
    }

    private proccesOpen(event: Event | FixedEvent): StateMachineState | null {
        switch (event) {
            case FixedEvent.EnterState:
                this._obsConnectionsState.emit(ConnectionState.Open);
                return null;
            case Event.WebsocketClosed:
                this._onError.emit(new ReasonableError<ConnectionErrorReason>(ConnectionErrorReason.Unknow, "Connection could not be made"));
                return StateMachineState.Error;
            case Event.DisconnectRequest:
                return StateMachineState.WebSocketDisconnecting;
            default:
                return null;
        }
    }

    private proccesError(event: Event | FixedEvent): StateMachineState | null {
        switch (event) {
            case FixedEvent.EnterState:
                this._obsConnectionsState.emit(ConnectionState.Disconnected);
                return null;
            case Event.ConnectRequest:
                return StateMachineState.ValidateInfo;
            default:
                return null;
        }
    }

    private assertUnreachable(x: never): never {
        throw new Error("Didn't expect to get here");
    }

    private receiveMessage(obj: any): any {
        const message = obj as MessageE2DC;
        switch (message.type) {
            case "ConnectionSuccesE2DC":
                this._stateMachine.processEvent(Event.EngineConnected);
                this._connectedEnginesChangedEvent.emit({ controllingEngineNbr: 1, connectedEngines: [{ engineNbr: 1, connectedDateTime: "10-10-2017" }] } as IConnectedEngines);
                //console.log("Engine connected");
                break;
            case "MessageToControllerE2DC":
                this._receivedFromEngineEvent.emit(message);
                break;
            case "ChannelOpenedResultE2DC":
                this.emitChannelChange(message);
                break;
            default:
                console.warn("Received a unknow message:", obj);
                break;
        }
    }

    private emitChannelChange(message: IChannelOpenedResultE2DC) {
        if (message.isOpen) {
            this._channelOpened.emit(message.channelId);
        }
    }

    //#region IConnectionService
    public openChannel(channelId: ChannelType): void {
        this._websocketService.send(new OpenChannelMessageDC2E(channelId));
    }

    public closeChannel(channelId: ChannelType): void {
        this._websocketService.send(new CloseChannelMessageDC2E(channelId));
    }

    public sendToEngine(message: MessageC2E, channelId: ChannelType): void {
        this._websocketService.send(new FromControllerMessageDC2E(channelId, message));
    }

    public disconnectFromEngine(): void {
        this._stateMachine.processEvent(Event.DisconnectRequest);
    }

    public connectToEngine(engine: IEngineDescription, playerNbr: number): void {
        //console.log("Connecting to engine " + playerNbr + ":", engine);
        this._requestedEngineDescription = engine;
        this._requestedPlayerNbr = playerNbr;
        this._stateMachine.processEvent(Event.ConnectRequest);
    }

    public get receivedFromEngineEvent(): IEventEmitter<IMessageToControllerE2DC> {
        return this._receivedFromEngineEvent;
    }
    public get channelOpened(): IEventEmitter<ChannelType> {
        return this._channelOpened
    }
    public get onError(): IEventEmitter<ReasonableError<ConnectionErrorReason>> {
        return this._onError;
    }
    public get connectedEnginesChangedEvent(): IEventEmitter<IConnectedEngines> {
        return this._connectedEnginesChangedEvent;
    }
    public get obsConnectionsState(): IObservable<ConnectionState> {
        return this._obsConnectionsState;
    }
    //#endregion


}

enum Event {
    DisconnectRequest = 1,
    ConnectRequest = 2,

    WebsocketClosed = 3,
    WebsocketConnecting = 4,
    WebsocketConnected = 5,

    EngineConnected = 8,
    EngineTimeOut = 9
}

export enum StateMachineState {
    Idle = 0,
    ValidateInfo = 1,

    WebSocketConnecting = 10,
    WebSocketDisconnecting = 11,

    EngineConnecting = 20,

    Error = 99,
    Open = 100,
}