import { ViewModel, IViewModel } from "../common/ViewModel";
import {ComponentContainer} from "../../components/ComponentContainer";
import {ViewStatusService} from "../../components/ViewStatusService";

export class ComponentContainerViewModel extends ViewModel {

    private _componentContainers: ComponentContainer[] = new Array<ComponentContainer>();

    public static async getComponentViewModelContainerAsync(viewModel: ViewModel): Promise<ComponentContainer> {

        await ViewStatusService.waitForViewsReadyAsync();

        let search: IViewModel | null = viewModel;
        while (search) {
            if (search instanceof ComponentContainerViewModel) {
                let componentContainer = search.getComponentContainerForViewModel(viewModel);
                if (componentContainer) {
                    return componentContainer;
                }
            }
            search = search.parent;
        }

        throw "No ViewFactory found for viewmodel: " + viewModel.name + " in the ComponentContainerViewModels";
    }

    public addComponentContainer(componentContainer: ComponentContainer): void {
        this.removeComponentContainer(componentContainer);
        this._componentContainers.push(componentContainer);
    }

    public removeComponentContainer(componentContainer: ComponentContainer): void {
        this._componentContainers = this._componentContainers.filter(d => d !== componentContainer);
    }

    private getComponentContainerForViewModel(viewModel: ViewModel): ComponentContainer | undefined {
        for (let componentContainer of this._componentContainers) {
            if (componentContainer.factoryDoesExistsForView(viewModel)) {
                return componentContainer;
            }
        }
        return undefined;
    }

    public isChildActive(viewmodel: IViewModel): boolean {
        for (let compContainer of this._componentContainers) {
            let vms = compContainer.obsViewModels.value;
            if (vms.length > 0 && vms[vms.length - 1] == viewmodel) {
                return true;
            }
        }
        return false;
    }

}