import { GridContainer } from "../../../../lib/ui/parts/containers/GridContainer";
import { HomeComponentViewModel } from "./HomeComponentViewModel";
import { CurrentSongControlsView } from "./current_track/CurrentSongControlsView";
import { WaitlistPreviewView } from "./waitlist/WaitlistPreviewView";
import { ScrollContainerPart, StyleBinding, IPartStyle } from "../../../../lib/index";
import theme from "../../../../app/style/Theme";
import screenPropertiesService from "../../../../lib/services/ScreenPropertiesService";

export class HomeComponentView extends GridContainer {
    constructor(currentTrackViewModel: HomeComponentViewModel) {
        super({
            style: {
                gridTemplateRows: "minmax(0, 1fr)",

            },
            bindings: [
                new StyleBinding({
                    subscription: screenPropertiesService.obsIsSmall,
                    styleFactory: (small) => {
                        if (!small) {
                            return { padding: `0 5.5% ${theme.getSpacePx(10)} 5.5%` };
                        }
                        return {};
                    }
                })
            ],
            children: [
                new ScrollContainerPart({
                    style: {
                        //display: "grid",
                        //boxSizing: "border-box",
                        ////gridAutoRows: "auto",
                        //gridTemplateRows: "auto minmax(0, 1fr)",
                        //gridTemplateColumns: "1fr",
                        //gridRowGap: theme.getSpacePx(60),
                    },
                    children: [
                        new CurrentSongControlsView(currentTrackViewModel.songControlsViewModel),
                        new WaitlistPreviewView(currentTrackViewModel.waitlistControlsViewModel)
                    ]
                }),

            ]
        });
    }

}