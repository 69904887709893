import { ViewPart, IParamViewPart } from "../ViewPart";

export interface IParamButtonPart extends IParamViewPart {
}

export class ButtonPart extends ViewPart {

    constructor(par: IParamButtonPart) {

        ViewPart.setDefaultStyle(par, {
            cursor: "pointer",
            userSelect: "none",
        });

        super("BUTTON", par);
    }

    protected get button(): HTMLButtonElement { return this.root as HTMLButtonElement };

    protected onClickEnabled(): void {
        super.onClickEnabled();
        this.button.disabled = false;
        this.applyStyle({
            cursor: "pointer"
        });
    }

    protected onClickDisabled(): void {
        super.onClickDisabled();
        this.button.disabled = true;
        this.applyStyle({
            cursor: "unset"
        });
    }
}