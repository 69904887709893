import { ComponentViewModel } from "../viewmodels/components/ComponentViewModel";
import { ObservableDef, IObservableDef } from "../../utils/eventbase/Observable";
import { ViewModel, IViewModel } from "../viewmodels/common/ViewModel";
import { ComponentContainerViewModel } from "../viewmodels/components/ComponentContainerViewModel";
import { IViewFactoryDetector } from "./IViewFactoryDetector";
import { IComponentContainer } from "./IComponentContainer";

export class ComponentContainer implements IComponentContainer {


    private _viewModels: ComponentViewModel[] = new Array<ComponentViewModel>();
    private readonly _obsViewModels: ObservableDef<ComponentViewModel[]> = new ObservableDef(new Array<ComponentViewModel>());
    private _detectors: IViewFactoryDetector[] = new Array<IViewFactoryDetector>();

    public get obsViewModels(): IObservableDef<ComponentViewModel[]> { return this._obsViewModels; }

    public addViewDetector(detector: IViewFactoryDetector): void {
        this.removeViewDetector(detector);
        this._detectors.push(detector);
    }

    public removeViewDetector(detector: IViewFactoryDetector): void {
        this._detectors = this._detectors.filter(d => d !== detector);
    }

    public pushViewModel(viewmodel: ComponentViewModel): void {
        let viewmodels = this._viewModels.map(vm => vm);

        viewmodels.push(viewmodel);
        this._obsViewModels.emit(viewmodels);
        this._viewModels = viewmodels;
    }

    public popViewModel(viewmodel: ComponentViewModel): void {
        let viewmodels = this._viewModels.map(vm => vm);
        viewmodels.pop();

        this._obsViewModels.emit(viewmodels);
        this._viewModels = viewmodels;
    }

    public async closeNextChildComponentsAsync(componentViewModel: ComponentViewModel): Promise<boolean> {
        let viewmodels = this._viewModels.map(vm => vm);
        for (var i = viewmodels.length - 1; i >= 0; i--) {
            let vm = viewmodels[i];
            if (vm === componentViewModel) {
                return true;
            }

            let result = await vm.canCloseComponentAsync();
            if (result) {
                vm.closeComponent();
            } else {
                break;
            }
        }
        return false;
    }

    public async closeComponentsAsync(componentViewModel: ComponentViewModel): Promise<boolean> {
        let viewmodels = this._viewModels.map(vm => vm);
        for (var i = viewmodels.length - 1; i >= 0; i--) {
            let vm = viewmodels[i];

            let result = await vm.canCloseComponentAsync();
            if (result) {
                vm.closeComponent();
            } else {
                break;
            }

            if (vm === componentViewModel) {
                return true;
            }

        }
        return false;
    }

    public factoryDoesExistsForView(viewModel: ViewModel): boolean {
        for (let detector of this._detectors) {
            if (detector.viewDoesExistsForViewModel(viewModel)) {
                return true;
            }
        }
        return false;
    }
}