import { ViewModel, IViewModel } from "../common/ViewModel";
import { ObservableDef, IObservableDef } from "../../../utils/eventbase/Observable";
import { ComponentContainerViewModel } from "./ComponentContainerViewModel";

export class ComponentViewModel extends ViewModel {
    private _obsComponentTitle: ObservableDef<string>;
    private _readyCallback: (() => void) | undefined;

    constructor(parent: IViewModel, componentTitle: string) {
        super(parent);
        this._obsComponentTitle = new ObservableDef(componentTitle);
    }

    public setComponentTitle(title: string) { this._obsComponentTitle.emit(title); }

    public get obsComponentTitle(): IObservableDef<string> { return this._obsComponentTitle; }

    protected executeComponentAsync(): Promise<void> {
        return ComponentViewModel.executeComponentAsync(this);
    }

    public static async executeComponentAsync(viewModel: ComponentViewModel): Promise<void> {

        let componentContainer = await ComponentContainerViewModel.getComponentViewModelContainerAsync(viewModel);
        if (componentContainer) {
            componentContainer.pushViewModel(viewModel);
            try {
                viewModel.closeComponent();
                await viewModel.onOpenComponentAsync();
                await new Promise<void>(resolve => {
                    viewModel._readyCallback = () => {
                        resolve();
                    }
                });
            } finally {
                componentContainer.popViewModel(viewModel);
            }
        }
    }


    public closeComponent(): void {
        if (this._readyCallback) {
            this._readyCallback();
            this._readyCallback = undefined;
        }
    }

    public async tryCloseComponentAsync(): Promise<boolean> {
        let componentContainer = await ComponentContainerViewModel.getComponentViewModelContainerAsync(this);
        return componentContainer.closeComponentsAsync(this);
    }

    public async canCloseComponentAsync(): Promise<boolean> {
        return true;
    }

    public async closeChildComponentsAsync(): Promise<boolean> {
        let componentContainer = await ComponentContainerViewModel.getComponentViewModelContainerAsync(this);
        if (componentContainer) {
            return await componentContainer.closeNextChildComponentsAsync(this);
        }

        return true;
    }

    protected async onOpenComponentAsync(): Promise<void> {

    }

    public closeCurrentComponent() {
        return this.tryCloseComponentAsync();
    }
}