export abstract class LocalStorage<T extends string> {

    constructor(private namespace: string) {
    }

    protected getItem<Ty>(name: T): Ty | null {
        let dataString = localStorage.getItem(this.namespace + "." + name);
        if (dataString) {
            try {
                return JSON.parse(dataString);
            } catch (err) {
                return null;
            }
        } else {
            return null;
        }
        
    }

    protected setItem<Ty>(name: T, value: Ty) {
        localStorage.setItem(this.namespace + "." + name, JSON.stringify(value));
    }

}