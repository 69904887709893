import { ViewModel } from "../../../../lib/ui/viewmodels/common/ViewModel";
import { ObservableDef, IObservable } from "../../../../lib/utils/eventbase/Observable";
import { IAutomatProgramDescription } from "../../../models/IAutomatProgramDescription";
import { AutomatComponentViewModel } from "./AutomatComponentViewModel";

export class RecentAutomatProgramItemViewModel extends ViewModel {

    private _programName: ObservableDef<string> = new ObservableDef<string>("");

    public get programName(): IObservable<string> { return this._programName; };

    private _programGenre: ObservableDef<string> = new ObservableDef<string>("");

    public get programGenre(): IObservable<string> { return this._programGenre; };

    constructor(private _parent: AutomatComponentViewModel, private _automatProgramDiscription: IAutomatProgramDescription) {
        super(_parent);
        this.setDiscription(_automatProgramDiscription);
    }

    public setDiscription(automatProgram: IAutomatProgramDescription) {
        this._automatProgramDiscription = automatProgram;
        this._programName.emit(automatProgram.name);
        this._programGenre.emit(automatProgram.genreName);
    }

    public programClick() {
        this._parent.automatProgramClicked(this._automatProgramDiscription.id);
    }
}