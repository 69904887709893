import { ViewPart, IParamViewPart } from "../ViewPart";

export interface IParamLinkPart extends IParamViewPart {
    href?: string;

}

export class LinkPart extends ViewPart {

    constructor(par: IParamLinkPart) {
        super("A", par);
        if (par.href) {
            this.link.href = par.href;
        }
    }

    private get link(): HTMLLinkElement { return this.root as HTMLLinkElement };

    protected onClickEnabled(): void {
        super.onClickEnabled();
        this.link.disabled = false;
        this.applyStyle({
            cursor: "pointer",
            pointerEvents: "auto",
        });
    }

    protected onClickDisabled(): void {
        super.onClickDisabled();
        this.link.disabled = true;
        this.applyStyle({
            cursor: "default",
            pointerEvents: "none",
        });
    }
}