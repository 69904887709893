import { MessageC2H } from "../MessageC2H";
import { MessageC2E } from "../MessageC2E";

export class ToAllEnginesMessageC2H extends MessageC2H {
    constructor(message: MessageC2E, channelId:string) {
        super();
        this.payload = message;
        this.channelId = channelId;
    }

    public readonly payload: MessageC2E;
    public readonly channelId: string;
}