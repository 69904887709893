import { LoginMessageC2H } from "../../messages/C2H/LoginMessageC2H";
import { MessageH2C } from "../../messages/MessageH2C";
import { MessageC2E } from "../../messages/MessageC2E";
import { WebsocketService } from "../websocket/WebsocketService";
import { WebsocketState } from "../websocket/WebsocketState";
import { ConnectionState } from "./ConnectionState";
import { MessageC2H } from "../../messages/MessageC2H";
import { ToAllEnginesMessageC2H } from "../../messages/C2H/ToAllEnginesMessageC2H";
import { OpenChannelC2H } from "../../messages/C2H/OpenChannelC2H";
import { CloseChannelC2H } from "../../messages/C2H/CloseChannelC2H";
import { ChannelType } from "../../messages/ChannelType";
import { IFromEngineMessageH2C } from "../../messages/H2C/IFromEngineMessageH2C";
import { IEngineDescription } from "../login/EngineSelectionService";
import { IEngineStatusMessageH2C } from "../../messages/H2C/IEngineStatusMessageH2C";
import { SubscriptionContainer } from "../../../lib/utils/eventbase/SubscriptionContainer";
import { ObservableDef, IObservable } from "../../../lib/utils/eventbase/Observable";
import { EventEmitter, IEventEmitter } from "../../../lib/utils/eventbase/EventEmitter";
import { ReasonableError } from "../../../lib/utils/ReasonableError";
import { StateMachine, FixedEvent } from "../../../lib/utils/state/StateMachine";
import { IConnectionService, ConnectionErrorReason } from "../IConnectionService";
import { IConnectedEngines } from "../../models/engine/IConnectedEngines";

export class HubConnectionService implements IConnectionService {
    private static singleton: HubConnectionService;

    private readonly _subscriptionContainer: SubscriptionContainer;
    private readonly _websocketService: WebsocketService;
    private readonly _obsHubConnectionsState = new ObservableDef<ConnectionState>(ConnectionState.Disconnected);
    private readonly _obsHubVersion = new ObservableDef<string>("unknown");

    private readonly _onError = new EventEmitter<ReasonableError<ConnectionErrorReason>>();
    private readonly _receivedFromEngineEvent = new EventEmitter<IFromEngineMessageH2C>();
    private readonly _receivedEngineStatusEvent = new EventEmitter<IConnectedEngines>();
    private readonly _stateMachine: StateMachine<StateMachineState, Event>;
    private readonly _channelOpened: EventEmitter<ChannelType> = new EventEmitter<ChannelType>();

    private _requestedPlayerNbr: number | null = null;
    private _playerNbr: number | null = null;
    private _requestedEngine: IEngineDescription | null = null;
    private _engine: IEngineDescription | undefined;


    public static getInstance(): HubConnectionService {
        return this.singleton || (this.singleton = new HubConnectionService());
    }

    private constructor() {
        this._subscriptionContainer = new SubscriptionContainer(this);
        this._websocketService = new WebsocketService();

        this._stateMachine = new StateMachine<StateMachineState, Event>(StateMachineState.Idle, (currentState, event) => { return this.process(currentState, event) });
        this._stateMachine.onStateChanged((oldState, newState) => {
            console.log("EngineConnection state: " + StateMachineState[oldState] + " => " + StateMachineState[newState]);
        });

        this._stateMachine.onProcessEvent((event, state) => {
            console.log("EngineConnection event: " + Event[event] + " (state: " + StateMachineState[state] + ")");
        });

        this._websocketService.obsWebsocketState.subscribeInitial(this._subscriptionContainer, (state) => (this.onWebsocketStateChanged(state)));
        this._websocketService.receivedFromWebsocketEvent.subscribe(this._subscriptionContainer, (message) => (this.onReceivedFromWebsocket(message)));
    }


    public get obsConnectionsState(): IObservable<ConnectionState> { return this._obsHubConnectionsState; }

    public get onError(): IEventEmitter<ReasonableError<ConnectionErrorReason>> { return this._onError }

    public get receivedFromEngineEvent(): IEventEmitter<IFromEngineMessageH2C> { return this._receivedFromEngineEvent }
    public get connectedEnginesChangedEvent(): IEventEmitter<IConnectedEngines> { return this._receivedEngineStatusEvent }

    public get obsHubVersion(): IObservable<string> { return this._obsHubVersion; }

    public get channelOpened(): EventEmitter<ChannelType> { return this._channelOpened; }

    public disconnectFromEngine(): void {
        this._requestedPlayerNbr = null;
        this._requestedEngine = null;
        this._stateMachine.processEvent(Event.DisconnectRequest);
    }

    public connectToEngine(engine: IEngineDescription, playerNbr: number): void {
        this._requestedPlayerNbr = playerNbr;
        this._requestedEngine = engine;
        this._stateMachine.processEvent(Event.ConnectRequest);
    }

    public openChannel(channelId: ChannelType): void {
        this.sendToHub(new OpenChannelC2H(channelId));
    }

    public closeChannel(channelId: string): void {
        this.sendToHub(new CloseChannelC2H(channelId));
    }


    public sendToEngine(message: MessageC2E, channelId: ChannelType): void {
        this.sendToHub(new ToAllEnginesMessageC2H(message, channelId));
    }

    private sendToHub(message: MessageC2H) {
        this._websocketService.send(message);
    }

    private onUserIsLoggedInChange(userIsLoggedIn: boolean): void {
        if (!userIsLoggedIn) {
            this.disconnectFromEngine();
        }
    }

    private loginEngine() {
        if (this._engine) {
            const message = new LoginMessageC2H(this._engine.token, this._playerNbr ? this._playerNbr : 1);
            this.sendToHub(message);
        }
    }

    private onWebsocketError(err: Error | null) {
        const error = err != null && err.message != "0"
            ? new ReasonableError<ConnectionErrorReason>(ConnectionErrorReason.Unknow, err.message)
            : new ReasonableError<ConnectionErrorReason>(ConnectionErrorReason.Unknow);
        this._onError.emit(error);
        //this._obsEngineConnectionsState.value = HubConnectionState.Error;
    }

    private onReceivedFromWebsocket(obj: any): void {
        let message = obj as MessageH2C;
        switch (message.type) {
            case "LoginSuccesH2C":
                this._obsHubVersion.emit(message.version);
                this._stateMachine.processEvent(Event.HubLoggedIn);
                break;
            case "LoginFailedH2C":
                this._stateMachine.processEvent(Event.HubLoginError);
                break;
            case "FromEngineH2C":
                this._receivedFromEngineEvent.emit(message);
                break;
            case "EngineStatusH2C":
                this.engineStatusChange(message);
                break;
            case "ChannelIsOpenedH2C":
                this._channelOpened.emit(message.channelId as ChannelType);
                break;
            default:
        }
    };

    private engineStatusChange(message: IEngineStatusMessageH2C) {
        this._receivedEngineStatusEvent.emit({
            connectedEngines: message.connectedEngines,
            controllingEngineNbr: message.controllingEngineNbr
        } as IConnectedEngines);
    }

    private onWebsocketStateChanged(state: WebsocketState): void {
        switch (state.state) {
            case "Closed":
                const error = state.errorMessage && state.errorMessage != "0"
                    ? new ReasonableError<ConnectionErrorReason>(ConnectionErrorReason.Unknow, state.errorMessage)
                    : new ReasonableError<ConnectionErrorReason>(ConnectionErrorReason.Unknow);
                this._onError.emit(error);
                this._stateMachine.processEvent(Event.WebsocketClosed);
                break;
            case "Connecting":
                this._stateMachine.processEvent(Event.WebsocketConnecting);
                break;
            case "Connected":
                this._stateMachine.processEvent(Event.WebsocketConnected);
                break;
            default:
                this.assertUnreachable(state);
        }
    }


    private process(currentState: StateMachineState, event: Event | FixedEvent): StateMachineState | null {

        switch (currentState) {
            case StateMachineState.Idle: return this.processIdle(event);
            case StateMachineState.ValidateInfo: return this.processValidateInfo(event);
            case StateMachineState.WebSocketConnecting: return this.processWebSocketConnecting(event);
            case StateMachineState.HubConnecting: return this.processEngineConnecting(event);
            case StateMachineState.Error: return this.processError(event);
            case StateMachineState.Open: return this.processOpen(event);
            case StateMachineState.WebSocketDisconnecting: return this.processWebSocketDisconnecting(event);
            default: return this.assertUnreachable(currentState);
        }
    }

    private processIdle(event: Event | FixedEvent): StateMachineState | null {
        switch (event) {
            case FixedEvent.EnterState:
                this._obsHubConnectionsState.emit(ConnectionState.Disconnected);
                return null;
            case Event.ConnectRequest:
                return StateMachineState.ValidateInfo;
            default:
                return null;
        }
    }

    private processValidateInfo(event: Event | FixedEvent): StateMachineState | null {
        switch (event) {
            case FixedEvent.EnterState:
                const engine = this._requestedEngine;
                if (this._requestedPlayerNbr !== null && engine) {
                    this._playerNbr = this._requestedPlayerNbr;
                    this._engine = engine;
                    this._websocketService.connect(engine.hubApiUrl + "controller/v1");
                    return StateMachineState.WebSocketConnecting;
                }
                return StateMachineState.Idle;
            default:
                return null;
        }
    }

    private processWebSocketConnecting(event: Event | FixedEvent): StateMachineState | null {

        switch (event) {
            case FixedEvent.EnterState:
                this._obsHubConnectionsState.emit(ConnectionState.Connecting);
                return null;
            case Event.WebsocketClosed:
                return StateMachineState.Error;
            case Event.WebsocketConnected:
                return StateMachineState.HubConnecting;
            default:
                return null;
        }
    }

    private processEngineConnecting(event: Event | FixedEvent): StateMachineState | null {
        switch (event) {
            case FixedEvent.EnterState:
                this.loginEngine();
                return StateMachineState.HubConnecting;
            case Event.HubLoggedIn:
                return StateMachineState.Open;
            case Event.HubLoginError:
                this._obsHubConnectionsState.emit(ConnectionState.NoLogin);
                this._websocketService.disconnect();
                return StateMachineState.Error;
            default:
                return null;
        }
    }

    private processError(event: Event | FixedEvent): StateMachineState | null {
        switch (event) {
            case FixedEvent.EnterState:
                if (this._obsHubConnectionsState.value == ConnectionState.Open) {
                    console.log("Schedule retry");
                }
                this._obsHubConnectionsState.emit(ConnectionState.Disconnected);
                return null;
            case Event.ConnectRequest:
                return StateMachineState.ValidateInfo;
            default:
                return null;
        }
    }

    private processOpen(event: Event | FixedEvent): StateMachineState | null {
        switch (event) {
            case FixedEvent.EnterState:
                this._obsHubConnectionsState.emit(ConnectionState.Open);
                if (this._requestedPlayerNbr !== this._playerNbr) {
                    return StateMachineState.WebSocketDisconnecting;
                }
                return null;
            case Event.DisconnectRequest:
                return StateMachineState.WebSocketDisconnecting;
            case Event.WebsocketClosed:
                return StateMachineState.Error;
            case Event.ConnectRequest:
                if (this._requestedPlayerNbr !== this._playerNbr) {
                    return StateMachineState.WebSocketDisconnecting;
                }
                return null;
            default:
                return null;
        }
    }

    private processWebSocketDisconnecting(event: Event | FixedEvent): StateMachineState | null {
        switch (event) {
            case FixedEvent.EnterState:
                if (this._websocketService.obsWebsocketState.value && this._websocketService.obsWebsocketState.value.state === "Closed") {
                    return StateMachineState.Idle;
                }
                this._websocketService.disconnect();
                return null;
            case Event.WebsocketConnected:
                return StateMachineState.Idle;
            case Event.WebsocketClosed:
                return StateMachineState.ValidateInfo;
            default:
                return null;
        }
    }


    private assertUnreachable(x: never): never {
        throw new Error("Didn't expect to get here");
    }


}

enum Event {
    DisconnectRequest = 1,
    ConnectRequest = 2,

    WebsocketClosed = 3,
    WebsocketConnecting = 4,
    WebsocketConnected = 5,

    HubLoggedIn = 8,
    HubLoginError = 9,
}


export enum StateMachineState {
    Idle = 0,
    ValidateInfo = 1,

    WebSocketConnecting = 10,
    WebSocketDisconnecting = 11,

    HubConnecting = 20,

    Error = 99,
    Open = 100,
}