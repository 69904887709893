import { ComponentViewModel, IViewFactory, IViewModel, ObservableDef, IObservable, IObservableDef, ObservableOpt } from "../../lib/index";
import { IEngineDescription, EngineSelectionService, EngineList } from "../../server_com/services/login/EngineSelectionService";
import { EngineListItemViewModel } from "./engine_list/EngineListItemViewModel";
import { ViewModelList } from "../../lib/ui/viewmodels/common/ViewModelList";
import { ApplicationConstants } from "../../app/ApplicationConstants";
import { LoginService } from "../../server_com/services/login/LoginService";
import { ConnectCodeDialogViewModel } from "./connect_code_dialog/ConnectCodeDialogViewModel";

export class EngineSelectComponentViewModel extends ComponentViewModel {

    private _engineSelectionService: EngineSelectionService;
    private _loginService: LoginService;

    private _engineList: ViewModelList<EngineListItemViewModel, IEngineDescription> = new ViewModelList((data) => this.createListItem(data));
    public get engineList(): ViewModelList<EngineListItemViewModel, IEngineDescription> { return this._engineList; }

    private _deviceNr: ObservableOpt<string> = new ObservableOpt<string>();
    public get deviceNr(): IObservable<string> { return this._deviceNr; }

    private _connectHelperText: ObservableOpt<string> = new ObservableOpt<string>();
    public get connectHelperText(): IObservable<string> { return this._connectHelperText; }

    private _versionText = new ObservableDef<string>(ApplicationConstants.versionString);
    public get versionText(): IObservableDef<string> { return this._versionText; };

    private _selectedEngine: IEngineDescription | null = null;

    private _connectCodeViewModel: ConnectCodeDialogViewModel;

    public static async showSelectionAsync(parent: IViewModel) {
        let viewmodel = new EngineSelectComponentViewModel(parent);
        await ComponentViewModel.executeComponentAsync(viewmodel);
        viewmodel._engineList.setItems([]);
        viewmodel.dispose();
    }

    constructor(parent: IViewModel) {
        super(parent, "Engine selection");
        this._engineSelectionService = EngineSelectionService.instance;
        this._engineSelectionService.obsEngines.subscribeInitial(this.subscriptionContainer, (el) => this.onEngines(el));
        this._loginService = LoginService.getInstance();
        this._engineList.selectedViewmodel.subscribe(this.subscriptionContainer, (vm) => this.onEngineSelect(vm));
        this._connectCodeViewModel = new ConnectCodeDialogViewModel(this);

        this._deviceNr.emit(`Apparaat nummer: ${this._loginService.deviceNr}`);
    }

    public async showConnectCodeDialogAsync() {
        const res = await this._connectCodeViewModel.show();
        if (res) {
            this._engineSelectionService.invalidateList();
            await this._engineSelectionService.getEnginesAsync();
        }
    }

    private onEngines(list: EngineList | null): void {
        if (list) {
            this._engineList.setItems(list.engines);
            if (list.engines.length == 0) {
                this._connectHelperText.emit("Om deze controller te koppelen gebruik de onderstaande knop.");
            } else {
                this._connectHelperText.emit("");
            }
        } else {
            //todo give error
        }
    }
    
    // private async showEngines() {
    //     let list = await this._engineSelectionService.getEnginesAsync();
    //     if (list != null) {
    //         this._engineList.setItems(list.engines);
    //         if (list.engines.length == 0) {
    //             //this._connectHelperText.emit("Om deze controller te koppelen neem contact op met uw leverancier en geef het apparaat nummer door.");
    //             this._connectHelperText.emit("Om deze controller te koppelen gebruik de onderstaande knop.");
    //         } else {
    //             this._connectHelperText.emit("");
    //         }
    //     } else {
    //         //todo give error
    //     }

    // }

    private onEngineSelect(vm: EngineListItemViewModel | null) {
        if (vm) {
            this._selectedEngine = vm.engine;
            this._engineSelectionService.setCurrentEngine(this._selectedEngine);
            this.tryCloseComponentAsync();
        }
    }

    private createListItem(data: IEngineDescription) {
        return new EngineListItemViewModel(this, data);
    }
}