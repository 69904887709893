import theme from "../../../../app/style/Theme";

export class StyleConstants {
    public static get defaultBorderRadius(): string { return theme.scalePx(5) }

    public static get defaultBoxShadow(): string { return "rgb(20, 20, 20) 0px 5px 10px" } //"rgb(91, 93, 97) 0px 2px 2px"

    public static get defaultTransitionSpeed(): number { return 300; }

    public static get defaultTransitionSpeedString(): string { return (StyleConstants.defaultTransitionSpeed / 1000) + "s"; }

    public static get cardMaxWidth(): number { return 1200 }

    public static get cardSmallMaxWidth(): number { return 300 }

    public static get cardPadding(): string { return theme.scalePx(10) }

    public static get cardBorder(): string { return "solid rgb(91, 93, 97) 1px" }

    public static get primaryBackGroundImage(): string { return "url(res/img/backgrounds/hex.svg) repeat left top/ 11px auto, linear-gradient(rgb(117, 117, 117), rgb(50, 50, 50))"; }

    public static get transition(): string { return `all ${StyleConstants.defaultTransitionSpeedString} ease`; }

    public static get logoImageURL(): string { return "res/img/logo/logo.png"; }
    public static get automaatImageURL(): string { return "res/img/icons/automaat.svg"; }

    public static get playButtonIcon(): string { return "icon-play"; }
    
    //public static get fontSizeSmall(): string { return "9pt" }
    //public static get fontSizeNormal(): string { return "12pt" }
    //public static get fontSizeTitle(): string { return "24pt" }
    //public static get fontSizeHeader(): string { return "36pt" }
    //public static get fontSizeError(): string { return "12pt" }

    //public static get fontSizeTrackArtist(): string { return "12pt" }
    //public static get fontSizeTrackTitle(): string { return "12pt" }
    //public static get fontSizeTrackYear(): string { return "12pt" }
    //public static get fontsizeLicenceHeader(): string { return "12pt" }

    //public static get fontSizeBigTrackArtist(): string { return "24pt" }
    //public static get fontSizeBigTrackTitle(): string { return "36pt" }


    //public static get fontSizeMenuButton(): string { return "12pt" }

    //public static get fontSizeInput(): string { return "12pt" }
    //public static get fontSizeLabel(): string { return "12pt" }
    //public static get fontSizeButton(): string { return "12pt" }

    //public static get fontSizeAutomatTitle(): string { return "12pt" }
    //public static get fontSizeAutomatGenre(): string { return "12pt" }

    //public static get fontSizePlaylistNameHdr(): string { return "12pt" }
    //public static get fontSizePlaylistName(): string { return "12pt" }
   

    //public static get fontSizePlaylistDetailsTrackArtist(): string { return "11pt" }


}