import { ContainerPart } from "../containers/ContainerPart";
import { ITransition } from "../../transitions/ITransition";
import { ViewPart, PartSize } from "../ViewPart";
import { ViewModel } from "../../viewmodels/common/ViewModel";
import {IViewFactory} from "./IViewFactory";

export class SwitchableContainerPart extends ContainerPart {

    private _viewFactory: IViewFactory;
    private _fadeIn: ITransition | undefined;
    private _fadeOut: ITransition | undefined;
    private _currentView: ViewPart | null = null;

    constructor(viewFactory: IViewFactory) {
        super({
            partSize: PartSize.FullSize,
        });

        this._viewFactory = viewFactory; 
        
        this._fadeIn = viewFactory.inTrans;
        if (this._fadeIn) {
        }

        this._fadeOut = viewFactory.outTrans;
        this.hide();
    }

    public get contentName(): string {
        return this._viewFactory.contentName;
    }

    private removeContent() {
        if (this._currentView) {
            this._currentView.dispose();
            this._currentView = null;
        }
        this.visible = false;
    }

    public async hideContent(): Promise<void> {
        if (this._fadeOut) {
            // this.animateAll();
            await this._fadeOut.playAsync(this);
            this.removeContent();
        } else {
            this.removeContent();
        }
    }

    public showContent(vm: ViewModel): void {
        if (!this._currentView) {
            this._currentView = this._viewFactory.createView(vm);
            this.addChild(this._currentView);
        }

        if (this._fadeIn) {
            this._fadeIn.playAsync(this);
        } else {
            this.show();
        }
    }
}