export class Colors {

    public static get themeColor(): string { return "rgb(255, 120, 17)" }
    public static get themeSoftColor(): string { return "rgb(206, 139, 86)" }
    

    //Text colors
    public static get headerTextColor(): string { return "#FFF" }

    public static get titleTextColor(): string { return "#FFF" }

    public static get subtitleTextColor(): string { return "#FFF" }

    public static get primaryTextColor(): string { return "#FFF" }

    public static get bigTrackTitleColor(): string { return "#FF9933" }

    public static get errorTextColor(): string { return "#F44" }

    public static get inputTextColor(): string { return "#FFF" }

    //Background colors
    public static get primaryBackgroundColor(): string { return "rgb(26, 26, 26)" }

    public static get seconderyBackgroundColor(): string { return "rgb(52,52,52)" }

    public static get inputFocusBackgroundColor(): string { return "rgb(130,130,130)" }
    public static get inputBackgroundColor(): string { return "rgb(80,80,80)" }
    
    public static get inputDisabledBackgroundColor(): string { return "#555" }

    public static get inputDisabledTextColor(): string { return "#AAA" }

    public static get sideMenuBackgorundColor(): string { return "#000" }

    public static get playlistHDRBackground(): string { return "#000" }

    //Card background colors
    public static get cardNormalBackgroundColor(): string { return "linear-gradient(rgb(0,0,0), rgb(84, 87, 90))" }

    public static get cardLightBackgroundColor(): string { return "linear-gradient(rgb(40, 40, 40),rgb(70, 70, 70))" }

    public static get cardLighterBackgroundColor(): string { return "linear-gradient(rgb(120, 120, 120),rgb(160, 160, 160))" }

    public static get cardWhiteBackgroundColor(): string { return "linear-gradient(rgb(250, 250, 250),rgb(200, 200, 200))" }

    public static get cardBlackBackgroundColor(): string { return "rgb(35, 35, 35)" }

    public static get cardRedBackgroundColor(): string { return "linear-gradient(rgb(0, 0, 0), rgb(200, 60, 60))" }

    //Progress bar
    public static get progressbarColor(): string { return "rgb(200, 200, 200)" }
    public static get progressbarThumpColor(): string { return "#ff9d38" }
    public static get progressbarPassedColor(): string { return "#ff9d38" }

    //Shadow color
    public static get boxShadowColor(): string { return "rgba(100, 100, 100, 100)" }

    //Button
    public static get buttonColor(): string { return "rgb(80,80,80)" }
    public static get buttonTextColor(): string { return "#EEE" }
    public static get buttonDisabledColor(): string { return "#565454" }
    public static get buttonDisabledTextColor(): string { return "#AAA" }

    //listitems
    public static get listItemBackground(): string { return "rgb(51,51,51)" }
    public static get listItemBackgroundHighlight(): string { return "rgb(71,71,71)" }
}