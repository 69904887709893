import { IObservable, ObservableDef } from "../../../utils/eventbase/Observable";

export interface ICommand {
    executeAsync(): Promise<void>;
    readonly obsVisible: IObservable<boolean>;
    readonly obsEnabled: IObservable<boolean>;
}


//Abstract MainViewModel
export abstract class Command implements ICommand {
    private readonly _obsVisible: ObservableDef<boolean> = new ObservableDef<boolean>(true);
    private readonly _obsEnabled: ObservableDef<boolean> = new ObservableDef<boolean>(true);

    constructor(executeAsync: () => Promise<void>) {
        if (executeAsync) {
            this.executeAsync = executeAsync;
        }
    }

    public get obsEnabled(): IObservable<boolean> {
        return this._obsEnabled;
    }

    public set enabled(enabled: boolean) {
        this._obsEnabled.emitOnChanged(enabled);
    }

    public get enabled(): boolean {
        return this._obsEnabled.value ? this._obsEnabled.value : false;
    }

    public get obsVisible(): IObservable<boolean> {
        return this._obsVisible;
    }

    public set visible(visible: boolean) {
        this._obsVisible.emitOnChanged(visible);
    }

    public get visible(): boolean {
        return this._obsVisible.value ? this._obsVisible.value : false;
    }

    public async executeAsync(): Promise<void> {
    }


}