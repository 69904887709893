import {MessageC2H} from "../MessageC2H";
import { ChannelType } from "../ChannelType";

export class OpenChannelC2H extends MessageC2H {
    constructor(channelId: ChannelType) {
        super();
        this.channelId = channelId;
    }

    public readonly channelId: string;
}