import { GridContainer, IParamGridContainer } from "../../../lib/ui/parts/containers/GridContainer";
import { SmallAblumCover } from "../../../common/ui/parts/image/SmallAlbumCover";
import { NormalText } from "../../../common/ui/parts/text/NormalText";
import texts from "../../../common/ui/localization/Language";
import { TrackNameText } from "../../../common/ui/parts/text/TrackNameText";
import { PlayerIconButton } from "../../../common/ui/parts/buttons/PlayerIconButton";
import { ProgressBar } from "../../../common/ui/parts/progressbar/ProgressBar";
import { PlayerBarActionButton } from "../../../common/ui/parts/player/PlayerBarActionButton";
import { ImagePart } from "../../../lib/ui/parts/image/ImagePart";
import { StyleConstants } from "../../../common/ui/parts/style/StyleConstants";
import theme from "../../../app/style/Theme";
import { IconButtonSize } from "../../../common/ui/parts/buttons/IconButton";
import { Part, StyleBinding } from "../../../lib/index";
import screenPropertiesService from "../../../lib/services/ScreenPropertiesService";
import { Colors } from "../../../common/ui/parts/colors/Colors";
import { JukeboxControlBarViewModel } from "./JukeboxControlBarViewModel";

export interface IParamPlayerControlBar extends IParamGridContainer {

}

export class JukeboxControlBarView extends GridContainer {
    constructor(par: IParamPlayerControlBar, viewmodel: JukeboxControlBarViewModel) {
        JukeboxControlBarView.setDefaultStyle(par, {
            padding: theme.getSpacePx(5),
            backgroundColor: Colors.seconderyBackgroundColor,
        });

        par.children = [
            new GridContainer({
                style: {
                    alignItems: "center",
                    gridTemplateAreas: `"none buttons logo"`,
                    gridTemplateColumns: `${theme.scalePx(90)} 1fr  ${theme.scalePx(90)}`,
                },
                children:[
                    JukeboxControlBarView.createButtons(viewmodel),
                    JukeboxControlBarView.createLogo(viewmodel),
                ]
            }),
        ]
        super(par);
    }

    private static createButtons(viewmodel: JukeboxControlBarViewModel): Part {
        return new GridContainer({
            style: {
                gridArea: "buttons",
                gridTemplateColumns: "auto auto",
                alignItems: "center",
                justifyContent: "center",
                paddingRight: theme.getSpacePx(10),
                gridColumnGap: theme.getSpacePx(10)
            },
            children: [
                new PlayerBarActionButton({
                    style: {
                        margin: theme.getSpacePx(5),
                    },
                    iconName: "icon-search",
                    onclickAsync: () => viewmodel.playerNavigationManager.searchButtonClickAsync(true),
                    activatedSubscription: viewmodel.playerNavigationManager.searchMenuButtonActive
                })
            ]
        });
    }

    private static createLogo(viewmodel: JukeboxControlBarViewModel): Part {
        return new ImagePart({
            style: {
                gridArea: "logo",
                height: theme.scalePx(45),
            },
            source: StyleConstants.logoImageURL
        });
    }
}