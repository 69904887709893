import { TextViewBase, IParamTextViewBase } from "../../../../lib/ui/parts/bases/TextViewBase";
import { IObservable } from "../../../../lib/utils/eventbase/Observable";
import { ViewPart } from "../../../../lib/ui/parts/ViewPart";
import { ImagePart } from "../../../../lib/ui/parts/image/ImagePart";
import theme from "../../../../app/style/Theme";



//Implementation of the google material iconset

export interface IParamHybridIcon extends IParamTextViewBase {
    icon?: string,
    iconSubscription?: IObservable<string>
    iconSize?: string,
}

export class HybridIcon extends TextViewBase {

    private _image: ImagePart | undefined;
    private size: string;

    public constructor(par: IParamHybridIcon) {
        
        ViewPart.setDefaultStyle(par, {
            textAlign: "center",
            verticalAlign: "middle",
            userSelect: "none",
            width: "auto",
            height: "auto",
        });

        super("i", par);
        this.size = par.iconSize || theme.scalePx(30);
        this.root.className = "material-icons-outlined";

        if (par.icon) {
            this.setIcon(par.icon)
        }
        if (par.iconSubscription) {
            par.iconSubscription.subscribeInitial(this.subscriptionContainer, (icon) => {
                this.setIcon(icon);
            });
        }

        if (par.iconSize) {
            this.setIconSize(par.iconSize);
        }

    }

    public setIconSize(size: string) {
        this.applyStyle({
            fontSize: size,
        });
    }

    private setIcon(icon: string) {
        let path = this.getIconPath(icon);

        if (path == null) {
            if (this._image != undefined) {
                this.removeChild(this._image);
                this._image.dispose();
                this._image = undefined;
            }
            this.setText(icon);
        } else {
            this.setImage(path);
        }
    }

    private setImage(iconPath:string) {
        if (this._image != undefined) {
            this._image.changeSource(iconPath);
        } else {
            this._image = new ImagePart({
                source: iconPath,
                style: {
                    width: this.size,
                    height: this.size,
                }
            });
            this.addChild(this._image);
        }
    }


    private getIconPath(icon: string): string | null {
        let resFolder = "res/img/icons/";
        switch (icon) {
            case "icon-back":
                return resFolder + "icon-back.svg";
            case "icon-grey-next":
                return resFolder + "icon-grey-next.svg";
            case "icon-grey-pause":
                return resFolder + "icon-grey-pause.svg";
            case "icon-grey-play":
                return resFolder + "icon-grey-play.svg";
            case "icon-grey-previous":
                return resFolder + "icon-grey-previous.svg";
            
            case "icon-next":
                return resFolder + "icon-next.svg";
            case "icon-pause":
                return resFolder + "icon-pause.svg";
            case "icon-previous":
                return resFolder + "icon-previous.svg";

            case "icon-home":
                return resFolder + "icon-home.svg";
            case "icon-information":
                return resFolder + "icon-information.svg";
            case "icon-mixer":
                return resFolder + "icon-mixer.svg";
            case "icon-new-playlist":
                return resFolder + "icon-new-playlist.svg";
            case "icon-play":
                return resFolder + "icon-play.svg";
            case "icon-playlists":
                return resFolder + "icon-playlists.svg";
            case "icon-search":
                return resFolder + "icon-search.svg";
            case "icon-settings":
                return resFolder + "icon-settings.svg";
            case "icon-jukebox":
                return resFolder + "icon-jukebox.png";
        }

        return null;
    }
}