import { ContentSwitchViewModel } from "./ContentSwitchViewModel";
import { IViewModel, ViewModel } from "./ViewModel";

type ViewmodelFactory = () => ViewModel;

class NamedViewmodelFactory{
    constructor(public name: string, public factory: ViewmodelFactory) {

    }
}

export class NamedContentSwitch<TNames extends string> extends ContentSwitchViewModel {
    private factorys: NamedViewmodelFactory[] = [];
    private _currentName: TNames | null = null;;

    public addViewModelFactory(name: TNames, factory: ViewmodelFactory) {
        this.factorys.push(new NamedViewmodelFactory(name, factory));
    }

    public switchToName(name: TNames) {
        if (this._currentName == name) {
            return;
        }
        let newViewModelFactory = this.factorys.find((i) => i.name == name);
        if (newViewModelFactory != undefined) {
            this._currentName = name;
            let oldVm = this.activeChildViewModel.value;
            this.switchToContent(newViewModelFactory.factory());
            if (oldVm) oldVm.dispose();
        } else {
            throw new Error("No viewmodelfactory for " + name);
        }
    }
}