export type PartStyleJustifyItems = 'start' | 'end' | 'center' | 'stretch';
export type PartGridAutoFlow = "row" | "column" | "row dense" | "column dense";

export type PartStyleTextAlign =
    "left" |
    "right" |
    "center";

export type PartStyleVerticalAlign =
    "baseline" |
    "length" |
    "sub" |
    "super" |
    "top" |
    "text-top" |
    "middle" |
    "bottom" |
    "text-bottom" |
    "initial" |
    "inherit";

export type PartStyleAlignItems =
    "center" |
    "flex-end" |
    "flex-start" |
    "stretch" |
    "baseline" |
    "initial" |
    "inherit";

export type PartStyleAlignSelf =
    "center" |
    "flex-end" |
    "flex-start" |
    "stretch" |
    "baseline" |
    "auto" |
    "initial"|
    "inherit" |
    "self-start"|
    "self-end"|
    "end"|
    "normal";



export type PartStyleDisplay =
    // Displays an element as an inline element(like<span>).Any height and width properties will have no effect	
    "inline" |
    // Displays an element as a block element(like<p>).It starts on a new line, and takes up the whole width
    "block" |
    // Makes the container disappear, making the child elements children of the element the next level up in the DOM
    "contents" |
    // Displays an element as a block - level flex container
    "flex" |
    // Displays an element as a block - level grid container
    "grid" |
    // Displays an element as an inline - level block container.The element itself is formatted as an inline element, but you can apply height and width values
    "inline-block" |
    // Displays an element as an inline - level flex container
    "inline-flex" |
    // Displays an element as an inline - level grid container
    "inline-grid" |
    // The element is displayed as an inline - level table
    "inline-table" |
    // Let the element behave like a < li > element
    "list-item" |
    // Displays an element as either block or inline, depending on context
    "run-in" |
    // Let the element behave like a < table > element
    "table" |
    // Let the element behave like a < caption > element
    "table-caption" |
    // Let the element behave like a < colgroup > element
    "table-column - group" |
    // Let the element behave like a < thead > element
    "table-header-group" |
    // Let the element behave like a < tfoot > element
    "table-footer-group" |
    // Let the element behave like a < tbody > element
    "table-row-group" |
    // Let the element behave like a < td > element
    "table-cell" |
    // Let the element behave like a < col > element
    "table-column" |
    // Let the element behave like a < tr > element
    "table-row" |
    // The element is completely removed
    "none" |
    // Sets this property to its default value.Read about initial
    "initial" |
    "inherit";


export type PartStyleFlexDirection =
    "row" |
    "column" |
    "column-reverse" |
    "row-reverse";

export type PartStyleFlexWrap =
    "nowrap" |
    "wrap" |
    "wrap-reverse";

export type PartStyleFlexJustifyContent =
    "center" |
    "flex-end" |
    "flex-start" |
    "space-between" |
    "space-evenly" |
    "space-around";

export type PartStyleJustifyContent = PartStyleFlexJustifyContent |
    "start" |
    "end";




/* 
 * This is a copy from CSSStyleDeclaration, "x: string | null" is changed to "x?: string | null" 
 * Missing properties are added at the end
 */
export interface IPartStyle {
    alignContent?: string | null;
    alignItems?: PartStyleAlignItems | null;
    alignmentBaseline?: string | null;
    alignSelf?: PartStyleAlignSelf | null;
    animation?: string | null;
    animationDelay?: string | null;
    animationDirection?: string | null;
    animationDuration?: string | null;
    animationFillMode?: string | null;
    animationIterationCount?: string | null;
    animationName?: string | null;
    animationPlayState?: string | null;
    animationTimingFunction?: string | null;
    backfaceVisibility?: string | null;
    background?: string | null;
    backgroundAttachment?: string | null;
    backgroundClip?: string | null;
    backgroundColor?: string | null;
    backgroundImage?: string | null;
    backgroundOrigin?: string | null;
    backgroundPosition?: string | null;
    backgroundPositionX?: string | null;
    backgroundPositionY?: string | null;
    backgroundRepeat?: string | null;
    backgroundSize?: string | null;
    baselineShift?: string | null;
    border?: string | null;
    borderBottom?: string | null;
    borderBottomColor?: string | null;
    borderBottomLeftRadius?: string | null;
    borderBottomRightRadius?: string | null;
    borderBottomStyle?: string | null;
    borderBottomWidth?: string | null;
    borderCollapse?: string | null;
    borderColor?: string | null;
    borderImage?: string | null;
    borderImageOutset?: string | null;
    borderImageRepeat?: string | null;
    borderImageSlice?: string | null;
    borderImageSource?: string | null;
    borderImageWidth?: string | null;
    borderLeft?: string | null;
    borderLeftColor?: string | null;
    borderLeftStyle?: string | null;
    borderLeftWidth?: string | null;
    borderRadius?: string | null;
    borderRight?: string | null;
    borderRightColor?: string | null;
    borderRightStyle?: string | null;
    borderRightWidth?: string | null;
    borderSpacing?: string | null;
    borderStyle?: string | null;
    borderTop?: string | null;
    borderTopColor?: string | null;
    borderTopLeftRadius?: string | null;
    borderTopRightRadius?: string | null;
    borderTopStyle?: string | null;
    borderTopWidth?: string | null;
    borderWidth?: string | null;
    bottom?: string | null;
    boxShadow?: string | null;
    boxSizing?: string | null;
    breakAfter?: string | null;
    breakBefore?: string | null;
    breakInside?: string | null;
    captionSide?: string | null;
    clear?: string | null;
    clip?: string | null;
    clipPath?: string | null;
    clipRule?: string | null;
    color?: string | null;
    colorInterpolationFilters?: string | null;
    columnCount?: any;
    columnFill?: string | null;
    columnGap?: any;
    columnRule?: string | null;
    columnRuleColor?: any;
    columnRuleStyle?: string | null;
    columnRuleWidth?: any;
    columns?: string | null;
    columnSpan?: string | null;
    columnWidth?: any;
    content?: string | null;
    counterIncrement?: string | null;
    counterReset?: string | null;
    cssFloat?: string | null;
    cssText?: string | null;
    cursor?: string | null;
    direction?: string | null;
    display?: PartStyleDisplay | null;
    dominantBaseline?: string | null;
    emptyCells?: string | null;
    enableBackground?: string | null;
    fill?: string | null;
    fillOpacity?: string | null;
    fillRule?: string | null;
    filter?: string | null;
    flex?: string | null;
    flexBasis?: string | null;
    flexDirection?: PartStyleFlexDirection | null;
    flexFlow?: string | null;
    flexGrow?: string | null;
    flexShrink?: string | null;
    flexWrap?: PartStyleFlexWrap | null;
    floodColor?: string | null;
    floodOpacity?: string | null;
    font?: string | null;
    fontFamily?: string | null;
    fontFeatureSettings?: string | null;
    fontSize?: string | null;
    fontSizeAdjust?: string | null;
    fontStretch?: string | null;
    fontStyle?: string | null;
    fontVariant?: string | null;
    fontWeight?: "normal" | "bold" | null;
    glyphOrientationHorizontal?: string | null;
    glyphOrientationVertical?: string | null;
    height?: string | null;
    imeMode?: string | null;
    justifyContent?: PartStyleJustifyContent | null;
    kerning?: string | null;
    layoutGrid?: string | null;
    layoutGridChar?: string | null;
    layoutGridLine?: string | null;
    layoutGridMode?: string | null;
    layoutGridType?: string | null;
    left?: string | null;
    readonly length?: number;
    letterSpacing?: string | null;
    lightingColor?: string | null;
    lineBreak?: string | null;
    lineHeight?: string | null;
    listStyle?: string | null;
    listStyleImage?: string | null;
    listStylePosition?: string | null;
    listStyleType?: string | null;
    margin?: string | null;
    marginBottom?: string | null;
    marginLeft?: string | null;
    marginRight?: string | null;
    marginTop?: string | null;
    marker?: string | null;
    markerEnd?: string | null;
    markerMid?: string | null;
    markerStart?: string | null;
    mask?: string | null;
    maxHeight?: string | null;
    maxWidth?: string | null;
    minHeight?: string | null;
    minWidth?: string | null;
    msContentZoomChaining?: string | null;
    msContentZooming?: string | null;
    msContentZoomLimit?: string | null;
    msContentZoomLimitMax?: any;
    msContentZoomLimitMin?: any;
    msContentZoomSnap?: string | null;
    msContentZoomSnapPoints?: string | null;
    msContentZoomSnapType?: string | null;
    msFlowFrom?: string | null;
    msFlowInto?: string | null;
    msFontFeatureSettings?: string | null;
    msGridColumn?: any;
    msGridColumnAlign?: string | null;
    msGridColumns?: string | null;
    msGridColumnSpan?: any;
    msGridRow?: any;
    msGridRowAlign?: string | null;
    msGridRows?: string | null;
    msGridRowSpan?: any;
    msHighContrastAdjust?: string | null;
    msHyphenateLimitChars?: string | null;
    msHyphenateLimitLines?: any;
    msHyphenateLimitZone?: any;
    msHyphens?: string | null;
    msImeAlign?: string | null;
    msOverflowStyle?: string | null;
    msScrollChaining?: string | null;
    msScrollLimit?: string | null;
    msScrollLimitXMax?: any;
    msScrollLimitXMin?: any;
    msScrollLimitYMax?: any;
    msScrollLimitYMin?: any;
    msScrollRails?: string | null;
    msScrollSnapPointsX?: string | null;
    msScrollSnapPointsY?: string | null;
    msScrollSnapType?: string | null;
    msScrollSnapX?: string | null;
    msScrollSnapY?: string | null;
    msScrollTranslation?: string | null;
    msTextCombineHorizontal?: string | null;
    msTextSizeAdjust?: any;
    msTouchAction?: string | null;
    msTouchSelect?: string | null;
    msUserSelect?: string | null;
    msWrapFlow?: string | null;
    msWrapMargin?: any;
    msWrapThrough?: string | null;
    opacity?: string | null;
    order?: string | null;
    orphans?: string | null;
    outline?: string | null;
    outlineColor?: string | null;
    outlineOffset?: string | null;
    outlineStyle?: string | null;
    outlineWidth?: string | null;
    overflow?: string | null;
    overflowX?: string | null;
    overflowY?: string | null;
    padding?: string | null;
    paddingBottom?: string | null;
    paddingLeft?: string | null;
    paddingRight?: string | null;
    paddingTop?: string | null;
    pageBreakAfter?: string | null;
    pageBreakBefore?: string | null;
    pageBreakInside?: string | null;
    readonly parentRule?: CSSRule;
    perspective?: string | null;
    perspectiveOrigin?: string | null;
    pointerEvents?: string | null;
    position?: string | null;
    quotes?: string | null;
    right?: string | null;
    rotate?: string | null;
    rubyAlign?: string | null;
    rubyOverhang?: string | null;
    rubyPosition?: string | null;
    scale?: string | null;
    stopColor?: string | null;
    stopOpacity?: string | null;
    stroke?: string | null;
    strokeDasharray?: string | null;
    strokeDashoffset?: string | null;
    strokeLinecap?: string | null;
    strokeLinejoin?: string | null;
    strokeMiterlimit?: string | null;
    strokeOpacity?: string | null;
    strokeWidth?: string | null;
    tableLayout?: string | null;
    textAlign?: PartStyleTextAlign;
    textAlignLast?: PartStyleTextAlign;
    textAnchor?: string | null;
    textDecoration?: string | null;
    textIndent?: string | null;
    textJustify?: string | null;
    textKashida?: string | null;
    textKashidaSpace?: string | null;
    textOverflow?: string | null;
    textShadow?: string | null;
    textTransform?: string | null;
    textUnderlinePosition?: string | null;
    top?: string | null;
    touchAction?: string | null;
    transform?: string | null;
    transformOrigin?: string | null;
    transformStyle?: string | null;
    transition?: string | null;
    transitionDelay?: string | null;
    transitionDuration?: string | null;
    transitionProperty?: string | null;
    transitionTimingFunction?: string | null;
    translate?: string | null;
    unicodeBidi?: string | null;
    verticalAlign?: PartStyleVerticalAlign | null;
    visibility?: string | null;
    webkitAlignContent?: string | null;
    webkitAlignItems?: string | null;
    webkitAlignSelf?: string | null;
    webkitAnimation?: string | null;
    webkitAnimationDelay?: string | null;
    webkitAnimationDirection?: string | null;
    webkitAnimationDuration?: string | null;
    webkitAnimationFillMode?: string | null;
    webkitAnimationIterationCount?: string | null;
    webkitAnimationName?: string | null;
    webkitAnimationPlayState?: string | null;
    webkitAnimationTimingFunction?: string | null;
    webkitAppearance?: string | null;
    webkitBackfaceVisibility?: string | null;
    webkitBackgroundClip?: string | null;
    webkitBackgroundOrigin?: string | null;
    webkitBackgroundSize?: string | null;
    webkitBorderBottomLeftRadius?: string | null;
    webkitBorderBottomRightRadius?: string | null;
    webkitBorderImage?: string | null;
    webkitBorderRadius?: string | null;
    webkitBorderTopLeftRadius?: string | null;
    webkitBorderTopRightRadius?: string | null;
    webkitBoxAlign?: string | null;
    webkitBoxDirection?: string | null;
    webkitBoxFlex?: string | null;
    webkitBoxOrdinalGroup?: string | null;
    webkitBoxOrient?: string | null;
    webkitBoxPack?: string | null;
    webkitBoxSizing?: string | null;
    webkitColumnBreakAfter?: string | null;
    webkitColumnBreakBefore?: string | null;
    webkitColumnBreakInside?: string | null;
    webkitColumnCount?: any;
    webkitColumnGap?: any;
    webkitColumnRule?: string | null;
    webkitColumnRuleColor?: any;
    webkitColumnRuleStyle?: string | null;
    webkitColumnRuleWidth?: any;
    webkitColumns?: string | null;
    webkitColumnSpan?: string | null;
    webkitColumnWidth?: any;
    webkitFilter?: string | null;
    webkitFlex?: string | null;
    webkitFlexBasis?: string | null;
    webkitFlexDirection?: string | null;
    webkitFlexFlow?: string | null;
    webkitFlexGrow?: string | null;
    webkitFlexShrink?: string | null;
    webkitFlexWrap?: string | null;
    webkitJustifyContent?: string | null;
    webkitOrder?: string | null;
    webkitPerspective?: string | null;
    webkitPerspectiveOrigin?: string | null;
    webkitTapHighlightColor?: string | null;
    webkitTextFillColor?: string | null;
    webkitTextSizeAdjust?: any;
    webkitTextStroke?: string | null;
    webkitTextStrokeColor?: string | null;
    webkitTextStrokeWidth?: string | null;
    webkitTransform?: string | null;
    webkitTransformOrigin?: string | null;
    webkitTransformStyle?: string | null;
    webkitTransition?: string | null;
    webkitTransitionDelay?: string | null;
    webkitTransitionDuration?: string | null;
    webkitTransitionProperty?: string | null;
    webkitTransitionTimingFunction?: string | null;
    webkitUserModify?: string | null;
    webkitUserSelect?: string | null;
    webkitWritingMode?: string | null;
    whiteSpace?: string | null;
    widows?: string | null;
    width?: string | null;
    wordBreak?: string | null;
    wordSpacing?: string | null;
    wordWrap?: string | null;
    writingMode?: string | null;
    zIndex?: string | null;
    zoom?: string | null;
    resize?: string | null;
    userSelect?: string | null;

    /* Missing properties */

    gridTemplateColumns?: string | null;
    gridTemplateRows?: string | null;
    gridTemplateAreas?: string | null;
    gridColumnGap?: string | null;
    gridRowGap?: string | null;
    justifyItems?: PartStyleJustifyItems | null;
    gridArea?: string | null;

    gridRow?: string | null;
    gridColumn?: string | null;
    backgroundBlendMode?: string | null;

    gridAutoFlow?: PartGridAutoFlow | null;
    gridAutoColumns?: string | null;
    gridAutoRows?: string | null;

}
