import { ViewPart, PartSize, IParamViewPart } from "../ViewPart";

export interface IParamContainerBase extends IParamViewPart {
    borderRadius?: number,
}

export abstract class ContainerBase extends ViewPart {
    constructor(par: IParamContainerBase) {
        super("DIV", par);

        if (par.borderRadius) {
            this.setBorderRadius(par.borderRadius);
        }
    }

    public setBorderRadius(amount: number) {
        this.applyStyle({
            borderRadius: amount + "px"
        });
    }

}