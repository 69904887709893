import { ServiceChannelBase } from "../../ServiceChannelBase";
import { MessageE2C } from "../../../messages/MessageE2C";
import { ILicenseInformationE2C } from "../../../messages/E2C/ILicenseInformationE2C";
import { ObservableOpt, IObservable } from "../../../../lib/utils/eventbase/Observable";

export class LicenseService extends ServiceChannelBase<"License"> {

    private static _singleton: LicenseService;
    public static getInstance(): LicenseService {
        return this._singleton || (this._singleton = new LicenseService());
    }

    private constructor() {
        super("License");
    }

    private _obsLicenseName: ObservableOpt<string> = new ObservableOpt();
    private _obsCustomerName: ObservableOpt<string> = new ObservableOpt();
    public get obsLicenseName(): IObservable<string> { return this._obsLicenseName; }
    public get obsCustomerName(): IObservable<string> { return this._obsCustomerName; }

    protected onMessageOnChannel(message: MessageE2C): void {
        switch (message.type) {
            case "LicenseInformationE2C":
                this.receiveLicenseInformationE2CMessage(message);
        }
    }

    private receiveLicenseInformationE2CMessage(message: ILicenseInformationE2C) {
        this._obsLicenseName.emit(message.licenseName);
        this._obsCustomerName.emit(message.customerName);
    }
}