import { ITransition } from "./ITransition";
import { EventEmitter, IEventEmitter } from "../../utils/eventbase/EventEmitter";
import { ViewPart } from "../parts/ViewPart";

export abstract class TransitionBase implements ITransition {
    private _stepDelay: number;

    constructor(private _sm: number, private readonly _stepCount: number) {
        if (_sm < 1 || _stepCount < 1) {
            throw Error("Cant animate direct changes");
        }
        this._stepDelay = _sm / _stepCount;
    }

    protected abstract beforeAnimate(view: ViewPart): void;

    protected abstract animateStep(view: ViewPart, step: number): void;

    protected abstract afterAnimate(view: ViewPart): void;

    private async nextStepAsync(view: ViewPart, step: number): Promise<void> {
        this.animateStep(view, step);
        await this.delayAsync(this._stepDelay - 20);
        if (step < this._stepCount) {

            await this.nextStepAsync(view, step + 1);
        } else {
            this.afterAnimate(view);
        }
    }

    public async playAsync(view: ViewPart): Promise<void> {

        this.beforeAnimate(view);
        await this.delayAsync(10);
        view.applyStyle({
            transition: `all ${this._stepDelay / 1000}s ease`,
            webkitPerspective: "1000",
            webkitBackfaceVisibility: "hidden",
        });
        await this.delayAsync(10);
        await this.nextStepAsync(view, 0);

    }

    private delayAsync(ms: number): Promise<void> {
        return new Promise<void>(resolve => {
            setTimeout(resolve, ms);
        });
    }
}