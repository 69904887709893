import { KeyCounter } from './KeyCounter';

export class DeviceVerificationHelper {
    private _db: IDBDatabase | undefined;

    private readonly _keyCounter = new KeyCounter();
    private readonly _dbName = "devicekeystore";
    private readonly _storeName = "devicekeystore";
    private readonly _storeObjectName = "devicekeyobject";

    public async getPublicKeyAsync(): Promise<string> {
        let keypair = await this.getSavedKeypair();
        if (!keypair) {
            keypair = await this.createKeyAsync();
            await this.saveKeypairAsync(keypair);
        }
        const spki = await window.crypto.subtle.exportKey("spki", keypair.publicKey);
        console.log("Key count:", this._keyCounter.get());
        return btoa(String.fromCharCode(...new Uint8Array(spki)));
    }

    public async createSignedStringAsync(text: string): Promise<ISignResult> {
        const keypair = await this.getSavedKeypair();
        if (!keypair) {
            return {
                result: "NoKeyPair",
            };
        }
        return this.signWithKeypairAsync(text, keypair);
    }

    private async getIndexedDb(): Promise<IDBDatabase> {
        return new Promise<IDBDatabase>((resolve, reject) => {
            if (this._db) {
                resolve(this._db);
            }

            const dbFactory = window.indexedDB.open(this._dbName, 1);
            dbFactory.onupgradeneeded = () => {
                dbFactory.result.createObjectStore(this._storeName, { autoIncrement: true });
            };

            const timeoutHandle = setTimeout(()=>{reject(new Error("Timeout creating database"))}, 2000);

            dbFactory.onsuccess = () => {
                this._db = dbFactory.result;
                dbFactory.transaction?.commit();
                clearTimeout(timeoutHandle);
                resolve(this._db);
            };
            dbFactory.onerror = (e)=>{
                clearTimeout(timeoutHandle);
                reject("Error creating db: " + e.type);
            }
            
            dbFactory.onblocked = (e)=>{
                clearTimeout(timeoutHandle);
                reject("DB access blocked: " + e.type);
            }
        });
    }

    private async getSavedKeypair(): Promise<ICryptoKeySet | undefined> {
        const db = await this.getIndexedDb();
        return new Promise<ICryptoKeySet | undefined>((resolve, reject) => {
            const timeoutHandle = setTimeout(()=>{reject(new Error("Timeout reading database"))}, 2000);
            const transaction = db.transaction(this._storeName, "readonly");
            transaction.onerror = (e) => {
                clearTimeout(timeoutHandle);
                reject(new Error("Error creating database transaction: " + e.type));
            };
            const store = transaction.objectStore(this._storeName);
            const x = store.get(this._storeObjectName);

            x.onsuccess = () => {
                clearTimeout(timeoutHandle);
                resolve(x.result as ICryptoKeySet);
            };

            x.onerror = (e) => {
                clearTimeout(timeoutHandle);
                reject(new Error("Error reading database record: " + e.type));
            };
            
        });
    }

    private async saveKeypairAsync(keypair: ICryptoKeySet) {
        const db = await this.getIndexedDb();
        await new Promise<void>((resolve, reject) => {
            const transaction = db.transaction(this._storeName, "readwrite");
            const store = transaction.objectStore(this._storeName);
            const res = store.put(keypair, this._storeObjectName);
            res.onerror = () => {
                reject(new Error("Error saving keypair"));
            };

            res.onsuccess = () => {
                const t = transaction as unknown as { commit?():void};
                if(t.commit){
                    t.commit();
                }
                
                resolve();
            }

            transaction.onerror = () => {
                reject(new Error("Error saving keypair(from transaction)"));
            };
        });
        if(navigator.storage && navigator.storage.persisted){
            const persisted = await navigator.storage.persisted();
            if (persisted) {
                const persistedRes = await navigator.storage.persist();
                console.log(persistedRes ? "Storage is persisted" : "Storage is NOT persisted");
            } else {
                console.log("Storage is already persisted");
            }
        }
        
    }

    private async signWithKeypairAsync(msg: string, keypair: ICryptoKeySet): Promise<ISignResult> {
        const enc = new TextEncoder(); // always utf-8
        const data = enc.encode(msg);

        const spki = await window.crypto.subtle.exportKey("spki", keypair.publicKey);

        const signature = await window.crypto.subtle.sign(
            {
                name: "RSA-PSS",
                saltLength: 32, //the length of the salt
            },
            keypair.privateKey, //from generateKey or importKey above
            data
        );

        // const dataBase64 = btoa(String.fromCharCode(...new Uint8Array(data)));
        const signatureBase64 = btoa(String.fromCharCode(...new Uint8Array(signature)));
        const publicKeyBase64 = btoa(String.fromCharCode(...new Uint8Array(spki)));
        return {
            result: "Ok",
            signature: signatureBase64,
            publicKey: publicKeyBase64
        };
    }

    private async createKeyAsync(): Promise<ICryptoKeySet> {
        this._keyCounter.up();
        const cryptoKeyPair = await window.crypto.subtle.generateKey({
            name: "RSA-PSS",
            modulusLength: 2048, //can be 1024, 2048, or 4096
            publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
            hash: { name: "SHA-256" }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
        },
            false, //whether the key is extractable (i.e. can be used in exportKey)
            ["sign", "verify"] //can be any combination of "sign" and "verify")
        );

        if (cryptoKeyPair.publicKey && cryptoKeyPair.privateKey) {
            const savedObject: ICryptoKeySet = {
                publicKey: cryptoKeyPair.publicKey,
                privateKey: cryptoKeyPair.privateKey,
            };
            return savedObject;
        }

        throw new Error("Crypto key not defined");
    }
}

type ISignResult = ISignResultOk | ISignResultNoKeyPair;

interface ISignResultOk {
    result: "Ok";
    signature: string;
    publicKey: string;
}

interface ISignResultNoKeyPair {
    result: "NoKeyPair";
}

interface ICryptoKeySet {
    readonly publicKey: CryptoKey;
    readonly privateKey: CryptoKey;
}

