export type FetchErrorType = "NotAuthorized" | "NoAccess" | "ServerError" | "NoInternet" | "InternalError" | "NotFound";

export interface FetchResultError {
    resultType: "error";
    errorType: FetchErrorType;
    error: string;
    userError: string;
}

export interface FetchResultOkContent<TData> {
    resultType: "data";
    data: TData;
}

export interface FetchResultOkNoContent {
    resultType: "ok";
}

export type FetchResultContent<TData> = FetchResultOkContent<TData> | FetchResultError;
export type FetchResultNoContent = FetchResultOkNoContent | FetchResultError;
