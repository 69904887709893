import * as EventEmitter from "./EventEmitter";
import { IUnsubscribable } from "./IUnsubscribable";
import { ISubscription } from "./ISubscription";
import { SubscriptionContainer } from "./SubscriptionContainer";


export abstract class SubsribableBase<T> implements EventEmitter.IEventEmitter<T>, IUnsubscribable {

    private _subscriptions: ISubscription<T>[] = new Array<ISubscription<T>>();

    constructor(private _async: boolean) {
    }

    public subscriberChangeCallback: undefined | (() => void);

    protected emitInternal(value: T): void {
        for (let sub of this._subscriptions) {
            try {
                if (this._async) {
                    sub.callback(value);
                } else {
                    this.runCallback(sub, value);
                }

            } catch (err) {
                console.error("Event failed to emit", sub, value, err);
            }
        }
    }

    public subscribe(subscriptionContainer: SubscriptionContainer, callback: (value: T) => (void)) {
        if (!subscriptionContainer) {
            throw new Error("Can not subscribe with no SubscriptionContainer given");
        }
        const sub = {
            callback,
            subscriptionContainer
        } as ISubscription<T>;
        this._subscriptions.push(sub);
        subscriptionContainer.addUnsubscribable(this);

        setTimeout(() => {
            if (this.subscriberChangeCallback) {
                this.subscriberChangeCallback();
            }
        }, 0);
    }

    private async runCallback(sub: ISubscription<T>, value: T) {
        sub.callback(value);
    }

    public unsubscribe(subscriptionContainer: SubscriptionContainer) {
        let item = this._subscriptions.find((a) => (a.subscriptionContainer === subscriptionContainer));
        if (item != null) {
            this._subscriptions = this._subscriptions.filter((s) => s !== item);
        }
    }

    public get hasSubscribers() { return this._subscriptions.length > 0; }
}