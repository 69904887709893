import { TextViewBase, IParamTextViewBase } from "../bases/TextViewBase";

export interface IParamH4TextPart extends IParamTextViewBase {
}

export class H4TextPart extends TextViewBase {

    constructor(par: IParamH4TextPart) {
        super("H4", par);
    }
}