import { MessageDC2E } from "../MessageDC2E";
import { ChannelType } from "../ChannelType";

export class OpenChannelMessageDC2E extends MessageDC2E{
    public constructor(channel: ChannelType) {
        super();
        this.channelId = channel;
    }

    public channelId: ChannelType;
}