import { GridContainer } from "../../../../../lib/ui/parts/containers/GridContainer";
import { IconButton, IconButtonSize } from "../../../../../common/ui/parts/buttons/IconButton";
import { WaitlistTrackViewModel } from "./WaitlistTrackViewModel";
import { StateTransitionVisibilityHorizontal } from "../../../../../lib/ui/transitions/StateTransitionVisibilityHorizontal";
import { TrackNameText } from "../../../../../common/ui/parts/text/TrackNameText";
import { HybridIcon } from "../../../../../common/ui/parts/icon/HybridIcon";
import theme from "../../../../../app/style/Theme";
import { Colors } from "../../../../../common/ui/parts/colors/Colors";

export class WaitlistTrackView extends GridContainer {

    constructor(viewModel: WaitlistTrackViewModel) {
        super({
            style: {
                marginBottom: theme.getSpacePx(2),
                borderRadius: theme.scalePx(100),
                backgroundColor: viewModel.mediaStoreState.value === "Error" ? "RED" : Colors.listItemBackground,
                gridTemplateColumns: `${theme.scalePx(40)} min-content 1fr auto`,
                gridTemplateRows: theme.scalePx(40),
                overflow: "hidden",
                alignItems: "center",
            },
            onclick: () => viewModel.backgroundClicked(),
            children: [

                new HybridIcon({
                    style: {
                        gridRow: "1",
                        gridColumn: "1",
                        color: Colors.primaryTextColor,
                    },
                    iconSize: theme.scalePx(30),
                    iconSubscription: viewModel.icon,
                }),
                new HybridIcon({
                    style: {
                        gridRow: "1",
                        gridColumn: "2",
                        color: Colors.primaryTextColor,
                        marginRight: theme.getSpacePx(5)
                    },
                    visibleSubscription: viewModel.isRequest,
                    iconSize: theme.scalePx(30),
                    icon: "icon-jukebox",
                }),
                new TrackNameText({
                    style: {
                        gridRow: "1",
                        gridColumn: "3 / span 4",
                    },
                    textSubscription: viewModel.trackName
                }),
                new GridContainer({
                    style: {
                        gridRow: "1",
                        gridColumn: "4",
                        gridTemplateRows: "auto",
                        gridTemplateColumns: "0.5fr auto auto auto auto 0.5fr",
                        backgroundColor: Colors.listItemBackgroundHighlight,
                        boxShadow: `${Colors.listItemBackgroundHighlight} 0px 0px ${theme.getSpacePx(10)} ${theme.getSpacePx(10)}`,
                        alignItems: "center",
                        paddingRight: theme.getSpacePx(10)
                    },
                    visibleSubscription: viewModel.showButtons,
                    visibleTransition: StateTransitionVisibilityHorizontal,
                    children: [
                        new IconButton({
                            style: {
                                gridColumn: "2 / 2",
                                transform: "rotate(-90deg)"
                            },
                            icon: "last_page",
                            iconButtonSize: IconButtonSize.Medium,
                            onclick: () => (viewModel.moveTrackToTop())
                        }),
                        new IconButton({
                            style: {
                                gridColumn: "3 / 3",
                                transform: "rotate(-90deg)"
                            },
                            icon: "play_arrow",
                            iconButtonSize: IconButtonSize.Medium,
                            onclick: () => (viewModel.moveTrackUp())
                        }),
                        new IconButton({
                            style: {
                                gridColumn: "4 / 4",
                                transform: "rotate(90deg)"
                            },
                            icon: "play_arrow",
                            iconButtonSize: IconButtonSize.Medium,
                            onclick: () => (viewModel.moveTrackDown())
                        }),
                        new IconButton({
                            style: {
                                gridColumn: "5 / 5"
                            },
                            icon: "delete",
                            iconButtonSize: IconButtonSize.Medium,
                            onclick: () => (viewModel.removeTrack())
                        }),

                    ]
                })
            ]
        });
    }
}