import { ViewModel } from "../../../lib/ui/viewmodels/common/ViewModel";
import { ComponentContainerViewModel, ComponentContainer } from "../../../lib/index";
import { EngineComponentViewModel } from "../../engine/EngineComponentViewModel";
import { EngineSelectionService } from "../../../server_com/services/login/EngineSelectionService";
import { EngineSelectComponentViewModel } from "../../engine_list/EngineSelectComponentViewModel";

export class MainMenuViewModel extends ComponentContainerViewModel {

    private _engineSelectionService: EngineSelectionService;

    private _componentContainer: ComponentContainer;
    public get componentContainer(): ComponentContainer {
        return this._componentContainer;
    }

    constructor(parent:ViewModel) {
        super(parent);
        this._componentContainer = new ComponentContainer();
        this.addComponentContainer(this._componentContainer);

        this._engineSelectionService = EngineSelectionService.instance;

        this.tryRestoreLastEngine();

    }

    private async tryRestoreLastEngine() {
        let lastUsed = await this._engineSelectionService.getLastUsedEngineAsync();
        if (lastUsed != null) {
            let engineComponent = new EngineComponentViewModel(this);
            engineComponent.show();
        } else {
            this.promptForEngine();
        }
    }

    private async promptForEngine() {
        await EngineSelectComponentViewModel.showSelectionAsync(this);
        let engineComponent = new EngineComponentViewModel(this);
        engineComponent.show();
    }

}