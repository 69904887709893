import { IDisposable } from "../../../utils/disposable/IDisposable";
import { SubscriptionContainer } from "../../../utils/eventbase/SubscriptionContainer";
import { NavigationPath } from "../../navigation/NavigationPath";

export interface IViewModel extends IDisposable {
    readonly name: string;
    readonly parent: IViewModel | null;
    invalidateHash(): void;
    navigateToViewModel(child: IViewModel): void;
    closeCurrentComponent(): Promise<boolean> ;
}

export abstract class ViewModel implements IViewModel {

    private readonly _subscriptionContainer: SubscriptionContainer;

    constructor(parent: IViewModel | null) {
        this.parent = parent;
        this._subscriptionContainer = new SubscriptionContainer(this);
    }

    public parent: IViewModel | null;

    public get subscriptionContainer(): SubscriptionContainer {
        return this._subscriptionContainer;
    }

    public get name(): string {
        return this.constructor.name;
    };

    public dispose(): void {
        this._subscriptionContainer.dispose();
        this.parent = null;
    }

    public navigateTo(navPath: NavigationPath) {
    }

    public fillNavigationPath(navPath: NavigationPath) {
    }

    public invalidateHash(): void {
        if (this.parent != null) {
            this.parent.invalidateHash();
        }
    }

    public navigateToHere() {
        if (this.parent != null) {
            this.parent.navigateToViewModel(this);
        }
    }

    public navigateToViewModel(child: IViewModel): void {
        if (this.parent != null && child != null) {
            this.parent.navigateToViewModel(this);
        }
    }

    public delayAsync(ms: number): Promise<void> {
        return new Promise<void>(resolve => {
            setTimeout(resolve, ms);
        });
    }

    public async closeCurrentComponent(): Promise<boolean> {
        if (this.parent) {
            return this.parent.closeCurrentComponent();
        } else {
            throw new Error("Can not close current component because the parent is none");
        }
    }
}