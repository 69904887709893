import { ObservableDef, IObservable, ObservableOpt, IObservableOpt } from "../../../lib/utils/eventbase/Observable";
import { ViewModel } from "../../../lib/ui/viewmodels/common/ViewModel";
import { ComponentContainerViewModel } from "../../../lib/ui/viewmodels/components/ComponentContainerViewModel";
import { ComponentContainer } from "../../../lib/ui/components/ComponentContainer";
import { HomeComponentViewModel } from "../../player/components/home/HomeComponentViewModel";
import { AutomatComponentViewModel } from "../../player/components/automat/AutomatComponentViewModel";
import { PlaylistsOverviewComponentViewModel } from "../../player/components/playlists_overview/PlaylistsOverviewComponentViewModel";
import { ComponentViewModel } from "../../../lib/ui/viewmodels/components/ComponentViewModel";
import { SearchComponentViewModel } from "../../player/components/search/SearchComponentViewModel";
import { LicenseService } from "../../../server_com/services/player/license/LicenseService";
import { IPlayerNavigationManager } from "./IPlayerNavigationManager";
import { PlayerControlBarViewModel } from "./PlayerControlBarViewModel";
import { PlayerSideBarViewModel } from "./PlayerSideBarViewModel";
import screenPropertiesService from "../../../lib/services/ScreenPropertiesService";
import { SettingsViewModel } from "../../player/components/settings/SettingsViewModel";
import { AboutComponentViewModel } from "../../player/components/about/AboutComponentViewModel";
import { EngineSelectionService } from "../../../server_com/services/login/EngineSelectionService";
import { JukeboxControlBarViewModel } from "./JukeboxControlBarViewModel";


export class PlayerViewModel extends ComponentContainerViewModel implements IPlayerNavigationManager {

    private _homeComponentViewModel: HomeComponentViewModel;
    private _automatComponentViewModel: AutomatComponentViewModel;
    private _playlistsOverviewComponentViewModel: PlaylistsOverviewComponentViewModel;
    private _SearchComponentViewModel: SearchComponentViewModel;
    private _settingsViewModel: SettingsViewModel;
    private _aboutComponentViewModel: AboutComponentViewModel;

    private _licenseService: LicenseService;

    private _playerBarViewModel: PlayerControlBarViewModel;
    public get playerBarViewModel(): PlayerControlBarViewModel { return this._playerBarViewModel; }

    private _jukeboxBarViewModel: JukeboxControlBarViewModel;
    public get jukeboxBarViewModel(): JukeboxControlBarViewModel { return this._jukeboxBarViewModel; }

    private _playerSizeMenuViewModel: PlayerSideBarViewModel;
    public get playerSizeMenuViewModel(): PlayerSideBarViewModel { return this._playerSizeMenuViewModel; }

    private _obsLicenseName: ObservableOpt<string> = new ObservableOpt<string>();
    public get obsLicenseName(): IObservable<string> { return this._obsLicenseName; }

    private _obsCustomerName: ObservableOpt<string> = new ObservableOpt<string>();
    public get obsCustomerName(): IObservable<string> { return this._obsCustomerName; }

    private _automatMenuButtonActive: ObservableDef<boolean> = new ObservableDef<boolean>(false);
    public get automatMenuButtonActive(): IObservable<boolean> { return this._automatMenuButtonActive; }

    private _searchMenuButtonActive: ObservableDef<boolean> = new ObservableDef<boolean>(false);
    public get searchMenuButtonActive(): IObservable<boolean> { return this._searchMenuButtonActive; }

    private _obsShowBackButton: ObservableDef<boolean> = new ObservableDef<boolean>(false);
    public get obsShowBackButton(): IObservable<boolean> { return this._obsShowBackButton; }

    private _obsShowMenu: ObservableDef<boolean> = new ObservableDef<boolean>(false);
    public get obsShowMenu(): IObservable<boolean> { return this._obsShowMenu; }

    private _obsShowControlBar: ObservableDef<boolean> = new ObservableDef<boolean>(true);
    public get obsShowControlBar(): IObservable<boolean> { return this._obsShowControlBar; }

    private _obsShowJukeboxBar: ObservableDef<boolean> = new ObservableDef<boolean>(true);
    public get obsShowJukeboxBar(): IObservable<boolean> { return this._obsShowJukeboxBar; }

    private _componentContainer: ComponentContainer = new ComponentContainer();
    public get componentContainer(): ComponentContainer { return this._componentContainer; }

    private _popupComponentContainer: ComponentContainer = new ComponentContainer();
    public get popupComponentContainer(): ComponentContainer { return this._popupComponentContainer; }

    private _isClosingCurrentComp: boolean = false;

    constructor(parent: ViewModel) {
        super(parent);
        this.addComponentContainer(this._componentContainer);
        this.addComponentContainer(this._popupComponentContainer);

        this._licenseService = LicenseService.getInstance();
        this._licenseService.obsCustomerName.subscribeInitial(this.subscriptionContainer, (v) => this._obsCustomerName.emit(v));
        this._licenseService.obsLicenseName.subscribeInitial(this.subscriptionContainer, (v) => this._obsLicenseName.emit(v));

        this._playerBarViewModel = new PlayerControlBarViewModel(this, this);
        this._jukeboxBarViewModel = new JukeboxControlBarViewModel(this, this);
        this._playerSizeMenuViewModel = new PlayerSideBarViewModel(this, this);

        this._homeComponentViewModel = new HomeComponentViewModel(this);
        this._automatComponentViewModel = new AutomatComponentViewModel(this);
        this._playlistsOverviewComponentViewModel = new PlaylistsOverviewComponentViewModel(this);
        this._SearchComponentViewModel = new SearchComponentViewModel(this);
        this._settingsViewModel = new SettingsViewModel(this);
        this._aboutComponentViewModel = new AboutComponentViewModel(this);

        this._componentContainer.obsViewModels.subscribeInitial(this.subscriptionContainer, (vms) => this.updateViewmodels(vms))

        screenPropertiesService.obsIsSmall.subscribeInitial(this.subscriptionContainer, (small) => this.onLayoutSwitch(small))

        EngineSelectionService.instance.obsCurrentEngine.subscribeInitial(this.subscriptionContainer, engine => {
            this._obsShowControlBar.emit(engine?.hasRoleController ?? false);
            this._obsShowJukeboxBar.emit(engine?.hasRoleJukebox ?? false);
        });

        this._licenseService.activate(this);
        setTimeout(() => this._homeComponentViewModel.showAsync(), 100);
    }

    public dispose() {
        this._licenseService.deactivate(this);
        this.closeCurrentComponentAsync();
        this._homeComponentViewModel.tryCloseComponentAsync();
        super.dispose();
    }

    public async closeCurrentComponentAsync(): Promise<void> {
        console.log("Closing all but home");
        this._componentContainer.closeNextChildComponentsAsync(this._homeComponentViewModel);
    }

    public async openComponentAsync(com: IPlayerComponent): Promise<boolean> {
        console.log("try open: ", com);
        let currentViewmodels = this._componentContainer.obsViewModels.value;
        if (this._isClosingCurrentComp || (currentViewmodels.length > 0 && com == currentViewmodels[currentViewmodels.length - 1])) {
            return false;
        }
        this._isClosingCurrentComp = true;
        await this.closeCurrentComponentAsync();
        com.showAsync();
        this._isClosingCurrentComp = false;
        return true;
    }

    public async playlistButtonClickAsync() {
        await this.openComponentAsync(this._playlistsOverviewComponentViewModel);
    }

    public async searchButtonClickAsync(toggle: boolean) {
        let currentViewmodels = this._componentContainer.obsViewModels.value;
        if (currentViewmodels.length > 0) {
            let currentViewmodel = currentViewmodels[currentViewmodels.length - 1];
            if (currentViewmodel == this._SearchComponentViewModel && toggle) {
                await this.closeCurrentComponentAsync();
            } else {
                await this.openComponentAsync(this._SearchComponentViewModel);
            }
        }
    }

    public async automatButtonClickAsync() {
        let currentViewmodels = this._componentContainer.obsViewModels.value;
        if (currentViewmodels.length > 0) {
            let currentViewmodel = currentViewmodels[currentViewmodels.length - 1];
            if (currentViewmodel == this._automatComponentViewModel) {
                await this.closeCurrentComponentAsync();
            } else {
                await this.openComponentAsync(this._automatComponentViewModel);
            }
        }
    }

    public async settingsButtonClickAsync() {
        await this.openComponentAsync(this._settingsViewModel);

    }
    public async aboutButtonClickAsync() {
        await this.openComponentAsync(this._aboutComponentViewModel);
    }

    public async homeButtonClickAsync() {
        await this.closeCurrentComponentAsync();
    }


    private updateButtonHighlight() {
        let currentViewmodels = this._componentContainer.obsViewModels.value;
        console.log(currentViewmodels);
        if (currentViewmodels.length > 0) {
            let currentViewmodel = currentViewmodels[currentViewmodels.length - 1];
            if (currentViewmodel == this._automatComponentViewModel) {
                this._automatMenuButtonActive.emit(true);
                this._searchMenuButtonActive.emit(false);
            } else if (currentViewmodel == this._SearchComponentViewModel) {
                this._automatMenuButtonActive.emit(false);
                this._searchMenuButtonActive.emit(true);
            } else {
                this._automatMenuButtonActive.emit(false);
                this._searchMenuButtonActive.emit(false);
            }
        } else {
            this._automatMenuButtonActive.emit(false);
            this._searchMenuButtonActive.emit(false);
        }
    }

    public async backButtonClickAsync() {
        let currentViewmodels = this._componentContainer.obsViewModels.value
        if (currentViewmodels.length > 1) {
            let lastComp = currentViewmodels[currentViewmodels.length - 1];
            this._componentContainer.closeComponentsAsync(lastComp);

        }
    }

    public showPlayer() {
        this.homeButtonClickAsync();
    }

    public toggleMenu() {
        this._obsShowMenu.emit(!this._obsShowMenu.value);
    }

    public closeMenuClick(): void {
        this._obsShowMenu.emit(false);
    }

    private onLayoutSwitch(small: boolean) {
        this._obsShowMenu.emit(false);
    }

    private updateViewmodels(vms: ComponentViewModel[]) {
        this._obsShowBackButton.emit(vms.length > 1);
        this.updateButtonHighlight();
    }
}

interface IPlayerComponent extends ComponentViewModel {
    showAsync(): Promise<void>,
    tryCloseComponentAsync(): Promise<boolean>
}