export interface IKeyedCollection<T> {
    add(key: string, value: T):void;
    clear(): void;
    containsKey(key: string): boolean;
    count(): number;
    item(key: string): T | undefined;
    remove(key: string): T;

    readonly keys: string[]; 
    readonly values: T[];
}