export class KeyCounter {
    private static KEY_NAME = "KeyCounterController";

    public up(): void {
        let cur = this.getOrCreate();
        cur++;
        this.set(cur);
    }

    public get(): number {
        return this.getOrCreate();
    }

    private getOrCreate(): number {
        const item = localStorage.getItem(KeyCounter.KEY_NAME);
        if (item === null) {
            this.set(0);
            console.log("New KeyCounter created");
            return 0;
        } else {
            return Number(item);
        }
    }

    private set(value: number) {
        localStorage.setItem(KeyCounter.KEY_NAME, value.toString());
    }
}