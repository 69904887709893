import { StyleConstants } from "../style/StyleConstants";
import {ContainerBase, IParamContainerBase} from "../../../../lib/ui/parts/bases/ContainerBase";
import { IPartStyle } from "../../../../lib/ui/parts/IPartStyle";
import theme from "../../../../app/style/Theme";
import { Colors } from "../colors/Colors";

export interface IParamCardContainer extends IParamContainerBase {
    size: CardSizeMode;
    color?: CardBackgroundColor;
}

export class CardContainer extends ContainerBase {


    constructor(par: IParamCardContainer) {
        //parent: ViewPart, private _size: CardSizeMode, private _color: CardBackgroundColor | null) {
        let style = CardContainer.getDefaultStyle(par.size, par.color ? par.color : CardBackgroundColor.Normal);
        CardContainer.setDefaultStyle(par, style);
        super(par);
    }

    private static getDefaultStyle(size: CardSizeMode, color: CardBackgroundColor) {
        let colorString = "";
        switch (color) {
            case CardBackgroundColor.Normal:
                colorString = Colors.cardNormalBackgroundColor;
                break;
            case CardBackgroundColor.Light:
                colorString = Colors.cardLightBackgroundColor;
                break;
            case CardBackgroundColor.Lighter:
                colorString = Colors.cardLighterBackgroundColor;
                break;
            case CardBackgroundColor.White:
                colorString = Colors.cardWhiteBackgroundColor;
                break;
            case CardBackgroundColor.DarkGray:
                colorString = Colors.cardBlackBackgroundColor;
                break;
            case CardBackgroundColor.Red:
                colorString = Colors.cardRedBackgroundColor;
                break;
            case CardBackgroundColor.Black:
                colorString = "black";
                break;
        }
        let style = {
            overflow: "hidden",
            background: colorString,
            borderRadius: StyleConstants.defaultBorderRadius,
            padding: StyleConstants.cardPadding,
            //boxShadow: StyleConstants.defaultBoxShadow,
            boxSizing: "border-box",
            border: StyleConstants.cardBorder
        };
        return CardContainer.styleMerge(style, CardContainer.getSizeStyle(size));
    }

    private static getSizeStyle(size: CardSizeMode): IPartStyle {
        let style = {};
        switch (size) {
            case CardSizeMode.Maximal:
                style = {
                    width: "100%",
                    height: "100%"
                };
                break;
            case CardSizeMode.MaximalAsContent:
                style = {
                    width: "100%",
                    height: `calc(100% - ${StyleConstants.cardPadding})`,
                    maxWidth: theme.scalePx(StyleConstants.cardMaxWidth)
                };
                break;
            case CardSizeMode.Minimal:
                style = {
                    width: "100%",
                    maxWidth: theme.scalePx(StyleConstants.cardSmallMaxWidth),
                    height: "auto"
                };
                break;
            case CardSizeMode.FullWidth:
                style = {
                    width: "100%",
                    height: "auto"
                };
                break;
        }
        return style;
    }


    public dispose(): void {
        super.dispose();
    }
}

export enum CardBackgroundColor {
    Normal,
    Light,
    Lighter,
    White,
    DarkGray,
    Black,
    Red
}

export enum CardSizeMode {
    Maximal,
    MaximalAsContent,
    Minimal,
    FullWidth,
    Custom
}