import { MessageE2C } from "../../../messages/MessageE2C";
import { ServiceChannelBase } from "../../ServiceChannelBase";
import { EventEmitter } from "../../../../lib/utils/eventbase/EventEmitter";
import { IAutomatPresetProgramListE2C, IPresetProgram } from "../../../messages/E2C/IAutomatPresetProgramListE2C";
import { IAutomatLastUsedProgramListE2C, ILastUsedProgram } from "../../../messages/E2C/IAutomatLastUsedProgramListE2C";
import { IAutomatProgramListMessageE2C, IProgramGenreItem } from "../../../messages/E2C/IAutomatProgramsListMessageE2C";
import { ObservableDef, IObservable } from "../../../../lib/utils/eventbase/Observable";

export class AutomatProgramsService extends ServiceChannelBase<"AutomatData">{

    private static singleton: AutomatProgramsService;
    private _genres: ObservableDef<IProgramGenreItem[]> = new ObservableDef<IProgramGenreItem[]>([]);
    private _presetPrograms: ObservableDef<IPresetProgram[]> = new ObservableDef<IPresetProgram[]>([]);
    private _lastUsedPrograms: ObservableDef<ILastUsedProgram[]> = new ObservableDef<ILastUsedProgram[]>([]);

    public static getInstance(): AutomatProgramsService {
        return this.singleton || (this.singleton = new AutomatProgramsService());
    }

    constructor() {
        super("AutomatData");
    }

    public get automatProgramGenres(): IObservable<IProgramGenreItem[]> {
        return this._genres;
    }

    public get automatLastUsedPrograms(): IObservable<ILastUsedProgram[]> {
        return this._lastUsedPrograms;
    }

    public get automatPresetPrograms(): IObservable<IPresetProgram[]> {
        return this._presetPrograms;
    }

    protected onMessageOnChannel(message: MessageE2C): void {
        switch (message.type) {
            case "AutomatProgramListE2C":
                this.receiveAutomatProgramList(message);
                break;
            case "AutomatPresetProgramListE2C":
                this.receiveAutomatPresetProgramList(message);
                break;
            case "AutomatLastUsedProgramListE2C":
                this.receiveAutomatLastUsedProgramList(message);
                break;
        }
    }

    private receiveAutomatProgramList(message: IAutomatProgramListMessageE2C) {
        this._genres.emit(message.programGenres);
    }

    private receiveAutomatPresetProgramList(automatPresetProgramListE2C: IAutomatPresetProgramListE2C) {
        this._presetPrograms.emit(automatPresetProgramListE2C.presetProgramGenres);
    }

    private receiveAutomatLastUsedProgramList(automatLastUsedProgramList: IAutomatLastUsedProgramListE2C) {
        this._lastUsedPrograms.emit(automatLastUsedProgramList.lastUsedProgramGenres);
    }
}