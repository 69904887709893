import { CurrentSongControlsViewModel } from "./current_track/CurrentSongControlsViewModel";
import { WaitlistPreviewViewModel } from "./waitlist/WaitlistPreviewViewModel";
import { ComponentViewModel } from "../../../../lib/ui/viewmodels/components/ComponentViewModel";
import { ViewModel } from "../../../../lib/ui/viewmodels/common/ViewModel";
import { LicenseService } from "../../../../server_com/services/player/license/LicenseService";

export class HomeComponentViewModel extends ComponentViewModel {

    private _songControlsViewModel: CurrentSongControlsViewModel;
    private _waitlistPreviewViewModel: WaitlistPreviewViewModel;
    private _licenseService: LicenseService;

    constructor(parent: ViewModel) {
        super(parent, "Now Playing");
        this._licenseService = LicenseService.getInstance();
        this._licenseService.obsLicenseName.subscribeInitial(this.subscriptionContainer, (lcn) => this.setName(lcn));
        this._songControlsViewModel = new CurrentSongControlsViewModel(this);
        this._waitlistPreviewViewModel = new WaitlistPreviewViewModel(this);
    }

    public get songControlsViewModel(): CurrentSongControlsViewModel {
        return this._songControlsViewModel;
    }

    public get waitlistControlsViewModel(): WaitlistPreviewViewModel {
        return this._waitlistPreviewViewModel;
    }

    public async showAsync(): Promise<void> {
        await this.executeComponentAsync();
        this.dispose();
    }

    public dispose(): void {
        this._songControlsViewModel.dispose();
        this._waitlistPreviewViewModel.dispose();
        super.dispose();
    }


    private setName(name: string) {
        this.setComponentTitle(name + " | Now Playing");
    }
}