import { MessageE2C } from "../../../messages/MessageE2C";
import { IAutomatStatusMessageE2C } from "../../../messages/E2C/IAutomatStatusMessageE2C";
import { AutomatTurnOffMessageC2E as AutomatTurnOffC2E } from "../../../messages/C2E/AutomatTurnOffMessageC2E";
import { ServiceChannelBase } from "../../ServiceChannelBase";
import { PlayAutomatProgramMessageC2E } from "../../../messages/C2E/PlayAutomatProgramMessageC2E";
import {SubscriptionContainer} from "../../../../lib/utils/eventbase/SubscriptionContainer";
import { ObservableDef} from "../../../../lib/utils/eventbase/Observable";
import {AutomatRemoveProgramC2E} from "../../../messages/C2E/AutomatRemoveProgramC2E";
import {AutomatIncreaseProgramC2E} from "../../../messages/C2E/AutomatIncreaseProgramC2E";
import {AutomatDecreaseProgramC2E} from "../../../messages/C2E/AutomatDecreaseProgramC2E";

export class AutomatService extends ServiceChannelBase<"PlayAutomat"> {

    private static singleton: AutomatService;
    public static getInstance(): AutomatService {
        return this.singleton || (this.singleton = new AutomatService());
    }

    private _obsHasStatus = new ObservableDef<boolean>(false);
    public get hasStatus() {
        return this._obsHasStatus;
    }

    private _obsStatus = new ObservableDef<AutomatStatus | null>(null);
    public get status() {
        return this._obsStatus;
    }

    constructor() {
        super("PlayAutomat");
    }

    protected onMessageOnChannel(message: MessageE2C): void {
        switch (message.type) {
            case "AutomatStatusE2C":
                this.receiveAutomatStatusMessageE2C(message);
                break;
            default:
        }
    }

    private receiveAutomatStatusMessageE2C(message: IAutomatStatusMessageE2C) {
        this._obsHasStatus.emit(true);
        this._obsStatus.emit(new AutomatStatus(message.activated, message.currentPrograms));
    }

    public turnOffAutomat(): void {
        this.sendOnChannel(new AutomatTurnOffC2E());
    }

    public removeAutomatProgram(id: string) {
        this.sendOnChannel(new AutomatRemoveProgramC2E(id));
    }

    public increaseAutomatProgram(id:string) {
        this.sendOnChannel(new AutomatIncreaseProgramC2E(id));
    }

    public decreaseAutomatProgram(id: string) {
        this.sendOnChannel(new AutomatDecreaseProgramC2E(id));
    }

    public playProgram(programId: string) {
        this.sendOnChannel(new PlayAutomatProgramMessageC2E(programId));
    }

}

export class AutomatStatus {
    get activated(): boolean {
        return this._activated;
    }

    get currentPrograms(): IAutomatProgramStatus[] {
        return this._currentPrograms;
    }

    constructor(private _activated: boolean, private _currentPrograms: IAutomatProgramStatus[]) {

    }
}

export interface IAutomatProgramStatus {
    automatProgramId: string;
    genreName: string;
    programName: string;
    tracklistId: string;
    useFactor: number;
}