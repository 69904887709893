import {MainViewModelBase} from "../../../lib/ui/viewmodels/MainViewModelBase";
import { LoginViewModel } from "./login/LoginViewModel";
import { LoginService, LoginStatus } from "../../../server_com/services/login/LoginService";
import { MainMenuViewModel } from "./MainMenuViewModel";

export class MainViewModel extends MainViewModelBase {

    private _loginService: LoginService;
    private _nextViewModelTimerHandle: number | null = null;

    constructor() {
        super();
        this._loginService = LoginService.getInstance();
        this.switchToContent(new LoginViewModel(this));

        this._loginService.obsLoginStatus.subscribeInitial(this.subscriptionContainer, (newStatus) => this.loginStatusChange(newStatus));
        this._loginService.tryLoginAsync();
    }


    private loginStatusChange(status: LoginStatus) {
        if (this._nextViewModelTimerHandle != null) {
            window.clearTimeout(this._nextViewModelTimerHandle);
        }
        if (status == "logged_in") {
            this._nextViewModelTimerHandle = window.setTimeout(() => this.nextViewModelCallback(), 1000);
        } else {
            this.switchToContent(new LoginViewModel(this));
        }
    }

    private nextViewModelCallback() {
        this.switchToContent(new MainMenuViewModel(this));
    }

}