import { MessageC2E } from "../MessageC2E";

export class AddTracklistToAutomatC2E extends MessageC2E {

    constructor(tracklistId: string, removeOtherLists: boolean) {
        super();
        this.tracklistId = tracklistId;
        this.removeOtherLists = removeOtherLists;
    }

    public readonly tracklistId: string;
    public readonly removeOtherLists: boolean;

}