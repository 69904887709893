import { CollapsibleContainer } from "../../../../../common/ui/parts/containers/CollapsibleContainer";
import { TitleText } from "../../../../../common/ui/parts/text/TitleText";
import { PlayListItemViewModel } from "./PlayListItemViewModel";
import { ItemPosition, ItemsFactoryPart } from "../../../../../lib/ui/parts/nonvisible/ItemsFactoryPart";
import { GridContainer } from "../../../../../lib/ui/parts/containers/GridContainer";
import { ExpandIcon } from "../../../../../common/ui/parts/etc/ExpandIcon";
import { PlaylistsSubItemView } from "./sub_item/PlaylistsSubItemView";
import { ContainerPart } from "../../../../../lib/ui/parts/containers/ContainerPart";
import { PartSize } from "../../../../../lib/ui/parts/ViewPart";
import { PlaylistHdrText } from "../../../../../common/ui/parts/text/PlaylistHdrText";
import { StateTransitionVisibilityVertical } from "../../../../../lib/ui/transitions/StateTransitionVisibilityVertical";
import { IconButton, IconButtonSize } from "../../../../../common/ui/parts/buttons/IconButton";
import { StyleConstants } from "../../../../../common/ui/parts/style/StyleConstants";
import theme from "../../../../../app/style/Theme";
import { Colors } from "../../../../../common/ui/parts/colors/Colors";


export class PlaylistsItemView extends ContainerPart {

    constructor(viewmodel: PlayListItemViewModel, position: ItemPosition) {


        super({
            onclick: () => (viewmodel.onclick()),
            partSize: PartSize.AutoHeight,
            scrollToThisObservable: viewmodel.obsScrollTo,
            style: {
                
                boxSizing: "border-box",
                height: "fit-content",
                minHeight: theme.scalePx(40),
                paddingRight: theme.getSpacePx(35),
            },
            children: [
                new GridContainer({
                    style: {
                        gridTemplateColumns: "1fr",
                        gridTemplateRows: `${theme.getSpacePx(40)} auto`,
                    },
                    children: [
                        new GridContainer({
                            style: {
                                gridTemplateColumns: "auto 1fr auto",
                                gridTemplateRows: theme.getSpacePx(40),
                                overflow: "hidden",
                                backgroundColor: Colors.playlistHDRBackground,
                                paddingRight: theme.getSpacePx(5),
                                paddingLeft: theme.getSpacePx(5),
                                borderRadius: theme.getSpacePx(20),
                                alignItems: "center"
                            },
                            children: [
                                new IconButton({
                                    iconButtonSize: IconButtonSize.Medium,
                                    icon: StyleConstants.playButtonIcon
                                }),
                                new PlaylistHdrText({
                                    style: {
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                        padding: theme.getSpacePx(0, 5),
                                    },
                                    textSubscription: viewmodel.obsTitle
                                }),
                                new ExpandIcon({
                                    iconSize: theme.getSpacePx(35),
                                    openedSubscription: viewmodel.obsOpen
                                }),
                            ]
                        }),
                        new GridContainer({
                            style: {
                                gridRow: "2",
                                gridColumn: "1 / span 2",
                                gridAutoFlow: "row",
                                gridRowGap: theme.getSpacePx(10),
                                gridTemplateRows: "auto",
                                gridTemplateColumns: "1fr",
                                padding: theme.getSpacePx(8, 0, 0, 65),
                            },
                            visibleSubscription: viewmodel.obsOpen,
                            visibleTransition: StateTransitionVisibilityVertical,
                            partSize: PartSize.AutoHeight,
                            children: [
                                new ItemsFactoryPart({
                                    itemsSourceSubscription: viewmodel.obsSubPlayLists,
                                    itemViewFactory: (vm, pos) => new PlaylistsSubItemView(vm, pos),
                                }),
                            ]
                        })

                    ]
                }),
            ]
        });
    }
}