import { ComponentViewModel } from "../../../../lib/ui/viewmodels/components/ComponentViewModel";
import { IViewModel } from "../../../../lib/ui/viewmodels/common/ViewModel";
import { IObservable, ObservableDef } from "../../../../lib/utils/eventbase/Observable";
import { ObservableTwoWayDef, IObservableTwoWay } from "../../../../lib/utils/eventbase/ObservableTwoWay";
import { SearchService } from "../../../../server_com/services/player/search/SearchService";
import { SearchResultListItemViewModel } from "./SearchResultListItemViewModel";

export class SearchComponentViewModel extends ComponentViewModel {

    private _obsSearchText: ObservableTwoWayDef<string> = new ObservableTwoWayDef<string>("", (v) => this.searchTextUpdated(v))
    public get obsSearchText(): IObservableTwoWay<string> { return this._obsSearchText; }

    private _obsSearchResults: ObservableDef<SearchResultListItemViewModel[]> = new ObservableDef<SearchResultListItemViewModel[]>([]);
    public get obsSearchResults(): IObservable<SearchResultListItemViewModel[]> { return this._obsSearchResults }

    private _searchTimeoutId: number;
    private _isSearching: boolean;
    private _lastSearchText: string;

    private _searchService: SearchService;

    constructor(parent: IViewModel) {
        super(parent, "Search");
        this._isSearching = false;
        this._lastSearchText = "";
        this._searchService = SearchService.getInstance();
        this._searchTimeoutId = -1;
    }

    public async showAsync() {
        this._searchService.activate(this);
        await this.executeComponentAsync();
        this._searchService.deactivate(this);
    }

    private async searchTextUpdated(value: string) {
        window.clearTimeout(this._searchTimeoutId);
        this._searchTimeoutId = window.setTimeout(() => this.searchAsync(), 400);
    }

    public async searchAsync() {
        if (this._lastSearchText == this._obsSearchText.value || this._obsSearchText.value.length < 2) {
            return;
        }
        let reSearch = false;
        let searchResultsPromise = this._searchService.searchTracksAsync(this._obsSearchText.value);
        this._lastSearchText = this._obsSearchText.value;
        if (this._isSearching == true) {
            reSearch = true;
        }
        this._isSearching = true;
        let results = await searchResultsPromise;
        this._obsSearchResults.emit(results.map((t) => new SearchResultListItemViewModel(this, t)));
        if (reSearch) {
            this.searchAsync();
        }
    }

}