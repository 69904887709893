import { ITransition } from "./ITransition";
import { IEventEmitter, EventEmitter } from "../../utils/eventbase/EventEmitter";
import { ViewPart } from "../parts/ViewPart";

export class ShowTransition implements ITransition {
    
    public playAsync(view: ViewPart): Promise<void> {
        return new Promise((resolve) => {
            view.visible = true;
            resolve();
        })
    }
}