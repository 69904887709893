import { FetchService } from "../../apilib/index";
import { UrlRepository } from "../../utils/UrlRepository";
import { LoginService } from "./LoginService";

export class EngineConnectService {
    private static _singleton: EngineConnectService;
    public static getInstance(): EngineConnectService {
        return this._singleton || (this._singleton = new EngineConnectService());
    }

    private _fetchService = new FetchService("omit");
    private readonly _loginService: LoginService;

    private constructor() {
        this._loginService = LoginService.getInstance();
    }

    public async addEngineWithCodeAsync(connectCode: string): Promise<ConnectResult> {
        const data: IConnectEngineRequest = {
            connectCode
        };
        const url = await UrlRepository.getInstance().getConnectEngineApiUrlAsync();
        const deviceToken = await this._loginService.runDeviceVerification();
        if (url === null || deviceToken.result !== "Succes") {
            return "error";
        }

        const res = await this._fetchService.fetchPostAsync(url, data, (headers) => {
            headers.append("recordcasetoken", deviceToken.token);
        });
        if (res.resultType === "ok") {
            return "ok";
        } else {
            return res.errorType === "NotFound" ? "not_valid" : "error";
        }
    }

}

type ConnectResult = "ok" | "not_valid" | "error";

interface IConnectEngineRequest {
    connectCode: string;
}