import { ViewPart, IParamViewPart } from "../ViewPart";

export interface IParamTableCellHeaderPart extends IParamViewPart {
}

export class TableCellHeaderPart extends ViewPart {

    constructor(par: IParamTableCellHeaderPart) {
        super("th", par);
    }
}

