import { ViewModel } from "../../../lib/ui/viewmodels/common/ViewModel";
import { IPlayerNavigationManager } from "./IPlayerNavigationManager";
import { EngineSelectComponentViewModel } from "../../engine_list/EngineSelectComponentViewModel";

export class PlayerSideBarViewModel extends ViewModel {

    private _playerNavigationManager: IPlayerNavigationManager;
    public get playerNavigationManager(): IPlayerNavigationManager { return this._playerNavigationManager; }

    constructor(parent: ViewModel, navigationManager: IPlayerNavigationManager) {
        super(parent);
        this._playerNavigationManager = navigationManager;
    }

    public homeButtonClick() {
        this._playerNavigationManager.closeMenuClick();
        this._playerNavigationManager.homeButtonClickAsync();
    }

    public playlistButtonClick() {
        this._playerNavigationManager.closeMenuClick();
        this._playerNavigationManager.playlistButtonClickAsync();
    }

    public searchButtonClick() {
        this._playerNavigationManager.closeMenuClick();
        this._playerNavigationManager.searchButtonClickAsync(false);
    }


    public gotoZones() {
        this._playerNavigationManager.closeMenuClick();
        EngineSelectComponentViewModel.showSelectionAsync(this);
    }

    public gotoSettings() {
        this._playerNavigationManager.closeMenuClick();
        this._playerNavigationManager.settingsButtonClickAsync();
    }

    public gotoInformation() {
        this._playerNavigationManager.closeMenuClick();
        this._playerNavigationManager.aboutButtonClickAsync();
    }

}