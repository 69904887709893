import { GridContainer } from "../../../../lib/ui/parts/containers/GridContainer";
import { CardContainer } from "../../../../common/ui/parts/containers/CardContainer";
import { TextPart } from "../../../../lib/ui/parts/text/TextPart";
import { RecentAutomatProgramItemViewModel } from "./RecentAutomatProgramItemViewModel";
import { PartSize } from "../../../../lib/ui/parts/ViewPart";
import { HeaderText } from "../../../../common/ui/parts/text/HeaderText";
import { NormalText } from "../../../../common/ui/parts/text/NormalText";
import { IconButton, IconButtonSize } from "../../../../common/ui/parts/buttons/IconButton";
import { AutomatTitleText } from "../../../../common/ui/parts/text/AutomatTitleText";
import { AutomatGenreText } from "../../../../common/ui/parts/text/AutomatGenreText";
import theme from "../../../../app/style/Theme";
import { Colors } from "../../../../common/ui/parts/colors/Colors";

export class RecentAutomatProgramListItemView extends GridContainer {

    constructor(viewModel: RecentAutomatProgramItemViewModel) {
        super({
            style: {
                padding: theme.getSpacePx(5, 20, 5, 20),
                gridTemplateAreas:
                    `"title_text button_add" "genre_text button_add"`,
                gridTemplateRows: "0.5fr 0.5fr",
                gridTemplateColumns: "1fr min-content",
                alignItems: "center",
                gridRowGap: theme.getSpacePx(2),
                height: "auto",
                backgroundColor: Colors.listItemBackground,
                borderRadius: "100px",
                overflow: "hidden",
            },
            children: [
                // programName
                new AutomatTitleText({
                    style: {
                        gridArea: "title_text",
                    },
                    textSubscription: viewModel.programName,
                }),
                //programGenre
                new AutomatGenreText({
                    style: {
                        gridArea: "genre_text",
                    },
                    textSubscription: viewModel.programGenre,
                }),
                //add button 
                new IconButton({
                    icon: "add",
                    onclick: () => viewModel.programClick(),
                    style: {
                        gridArea: "button_add",
                    }
                })
            ]
        });
    }
}