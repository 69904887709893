import { GridContainer } from "../../../../lib/ui/parts/containers/GridContainer";
import { AutomatComponentViewModel } from "./AutomatComponentViewModel";
import { AutomatControlsView } from "./controls/AutomatControlsView";
import { CardContainer, CardSizeMode, CardBackgroundColor } from "../../../../common/ui/parts/containers/CardContainer";
import { ScrollContainerPart } from "../../../../lib/ui/parts/containers/ScrollContainerPart";
import { TitleText } from "../../../../common/ui/parts/text/TitleText";
import { ItemsFactoryPart } from "../../../../lib/ui/parts/nonvisible/ItemsFactoryPart";
import { RecentAutomatProgramListItemView } from "./RecentAutomatProgramListItemView";
import { PartSize } from "../../../../lib/ui/parts/ViewPart";
import { LoadSpinner } from "../../../../common/ui/parts/spinners/LoadSpinner";
import texts from "../../../../common/ui/localization/Language";
import theme from "../../../../app/style/Theme";

export class AutomatComponentView extends GridContainer {
    constructor(private viewmodel: AutomatComponentViewModel) {

        super({
            style: {
                gridTemplateRows: "auto 1fr",
                gridRowGap: theme.getSpacePx(10)
            },
            children: [
                new AutomatControlsView(viewmodel.automatControlsViewModel),
                new GridContainer({
                    style: {
                        gridTemplateColumns: "1fr",
                        gridTemplateRows: "auto minmax(0, 1fr)"
                    },

                    children: [
                        new LoadSpinner({
                            style: {
                                margin: "auto"
                            },
                            size: 75,
                            visibleSubscription: viewmodel.loaderVisibility
                        }),
                        new TitleText({
                            style: {
                                textAlign: "center"
                            },
                            text: texts.lastUsedAutomatPrograms
                        }),
                        new ScrollContainerPart({
                            style: {
                                padding: theme.getSpacePx(0,5)
                            },
                            children: [
                                new ItemsFactoryPart({
                                    itemsSourceSubscription: viewmodel.lastUsed,
                                    itemViewFactory: (vm) => new RecentAutomatProgramListItemView(vm)
                                })
                            ],
                        })
                    ]
                })
            ]
        });
    }
}