import { GridContainer, ContainerPart } from "../../../../../lib/index";
import { CurrentSongControlsViewModel } from "./CurrentSongControlsViewModel";
import theme from "../../../../../app/style/Theme";
import { AlbumCover } from "../../../../../common/ui/parts/image/AlbumCover";
import { NormalText } from "../../../../../common/ui/parts/text/NormalText";
import texts from "../../../../../common/ui/localization/Language";
import { BigTrackArtistText } from "../../../../../common/ui/parts/text/BigTrackArtistText";
import { BigTrackTitleText } from "../../../../../common/ui/parts/text/BigTrackTitleText";
import { PlayerIconButton } from "../../../../../common/ui/parts/buttons/PlayerIconButton";
import { IconButtonSize } from "../../../../../common/ui/parts/buttons/IconButton";
import { Colors } from "../../../../../common/ui/parts/colors/Colors";

export class CurrentSongControlsSmallView extends GridContainer{
    public constructor(viewmodel: CurrentSongControlsViewModel){
        super({
            style: {
                gridTemplateColumns: "minmax(0, 1fr)",
                gridTemplateRows: "auto",
                height: "auto",
                gridTemplateAreas: "current_track"
            },
            children: [
                new AlbumCover({
                    style: {
                        gridArea: "current_track",
                        width: "100vw",
                        height: "100vw",
                    }
                }),
                new ContainerPart({
                    style: {
                        gridArea: "current_track",
                        width: "100vw",
                        height: "100vw",
                        background: "linear-gradient(to bottom, rgba(26,26,26,0.4), rgba(26,26,26,0.8))"
                    }
                }),
                new GridContainer({
                    style: {
                        gridArea: "current_track",
                        gridTemplateRows: "1fr min-content min-content min-content",
                        gridTemplateColumns: "1fr",
                        gridRowGap: theme.getSpacePx(5),
                        alignContent: "space-evenly",
                        paddingTop: theme.getSpacePx(40),
                        paddingBottom: theme.getSpacePx(40),
                        paddingLeft: theme.getSpacePx(15),
                        paddingRight: theme.getSpacePx(15)
                    },
                    children: [
                        //Track title & artist
                        new NormalText({
                            style: {
                                color: Colors.primaryTextColor
                            },
                            text: texts.nowPlaying
                        }),
                        new BigTrackArtistText({
                            style: {
                                color: Colors.primaryTextColor
                            },
                            textSubscription: viewmodel.obsCurrentTrackArtist
                        }),
                        new BigTrackTitleText({
                            style: {

                            },
                            textSubscription: viewmodel.obsCurrentTrackTitle
                        }),

                        //controls
                        new GridContainer({
                            style: {
                                width: theme.scalePx(160),
                                justifyContent: "space-between",
                                alignContent: "start",
                                gridTemplateColumns: "auto auto auto"
                            },
                            visibleSubscription: viewmodel.obsShowControls,
                            children: [
                                new PlayerIconButton({
                                    icon: "icon-previous",
                                    onclick: () => viewmodel.back(),
                                    iconButtonSize: IconButtonSize.Big,
                                    clickDisabledSubscription: viewmodel.obsControlsDisabled
                                }),
                                new PlayerIconButton({
                                    icon: "icon-play",
                                    onclick: () => viewmodel.play(),
                                    iconButtonSize: IconButtonSize.Big,
                                    visibleSubscription: viewmodel.obsPlayButtonVisibility,
                                    clickDisabledSubscription: viewmodel.obsControlsDisabled
                                }),
                                new PlayerIconButton({
                                    icon: "icon-pause",
                                    onclick: () => viewmodel.pause(),
                                    iconButtonSize: IconButtonSize.Big,
                                    invisibleSubscription: viewmodel.obsPlayButtonVisibility,
                                    clickDisabledSubscription: viewmodel.obsControlsDisabled
                                }),
                                new PlayerIconButton({
                                    icon: "icon-next",
                                    onclick: () => viewmodel.next(),
                                    iconButtonSize: IconButtonSize.Big,
                                    clickDisabledSubscription: viewmodel.obsControlsDisabled
                                }),
                            ]
                        }),
                    ]
                })
            ]
        });
    }
}