export class WorkerCom {
    //private mWorker: Worker;

    constructor() {

    }

    public start() {
        //if (!(navigator as any).serviceWorker) {
        //    console.warn("Browser does not support workers");
        //    return;
        //}
        ////this.mWorker = new Worker("res/js/worker.js");

        //(navigator as any).serviceWorker.register("worker.js").then(function (registration : any) {
        //    // Registration was successful
        //    //console.log('ServiceWorker registration successful with scope: ', registration.scope);
        //},
        //    function (err: any) {
        //        // registration failed :(
        //        console.log("ServiceWorker registration failed: ", err);
        //    });


        //this.mWorker.addEventListener("message", e => {
        //    console.log(e);
        //}, false);
        //this.mWorker.addEventListener("error", e => {
        //    console.log(e);
        //}, false);
        //this.mWorker.postMessage({cmd:"YouOk"});
    }
}