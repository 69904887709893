import { ViewPart } from "./ViewPart";

export class RootPart extends ViewPart {

    public constructor(htmlRoot: HTMLElement) {
        super("div", {
            style: {
                width: "100%",
                height: "100%",
                overflow: "hidden",
            },
        });

        if (htmlRoot) {
            htmlRoot.appendChild(this.root);
        }
    }
}