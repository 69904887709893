class Theme {

    private _styleBaseValues: StyleBaseValues = {
        transitionSpeed: 300,
        fontSizeSmallBase: 9,
        fontSizeNormalBase: 12,
        fontSizeLargeBase: 24,
        fontSizeXLargeBase: 36
    };

    private _scale: number;
    public get scale(): number{
        return this._scale;
    }

    constructor() {
        this._scale = 0.8;
        let customScale = Number(location.hash.replace('#', ''));
        if (!isNaN(customScale) && customScale > 0) {
            this._scale = customScale;
        }
    }

    private getSpacer(space:number, multiplier: number) {
        return (space * this._scale * multiplier) + "px";
    }

    private getFontSizeSmallBase(multiplier: number) {
        return (this._styleBaseValues.fontSizeSmallBase * this._scale * multiplier) + "pt";
    }

    private getFontSizeNormalBase(multiplier: number) {
        return (this._styleBaseValues.fontSizeNormalBase * this._scale * multiplier) + "pt";
    }

    private getFontSizeLargeBase(multiplier: number) {
        return (this._styleBaseValues.fontSizeLargeBase * this._scale * multiplier) + "pt";
    }

    private getFontSizeXLargeBase(multiplier: number) {
        return (this._styleBaseValues.fontSizeXLargeBase * this._scale * multiplier) + "pt";
    }

    //Default text sizes
    public fontSizeSmall(multiplier: number = 1.0): string { return this.getFontSizeSmallBase(multiplier); }
    public fontSizeNormal(multiplier: number = 1.0): string { return this.getFontSizeNormalBase(multiplier); }
    public fontSizeTitle(multiplier: number = 1.0): string { return this.getFontSizeLargeBase(multiplier); }
    public fontSizeHeader(multiplier: number = 1.0): string { return this.getFontSizeXLargeBase(multiplier); }

    //public space05px(multiplier: number = 1.0) {
    //    return this.getSpacer(5, multiplier);
    //}
    //public space10px(multiplier: number = 1.0) {
    //    return this.getSpacer(10, multiplier);
    //}
    //public space15px(multiplier: number = 1.0) {
    //    return this.getSpacer(15, multiplier);
    //}
    //public space20px(multiplier: number = 1.0) {
    //    return this.getSpacer(20, multiplier);
    //}
    //public space25px(multiplier: number = 1.0) {
    //    return this.getSpacer(25, multiplier);
    //}
    //public space30px(multiplier: number = 1.0) {
    //    return this.getSpacer(30, multiplier);
    //}
    //public space35px(multiplier: number = 1.0) {
    //    return this.getSpacer(35, multiplier);
    //}
    //public space40px(multiplier: number = 1.0) {
    //    return this.getSpacer(40, multiplier);
    //}
    //public space45px(multiplier: number = 1.0) {
    //    return this.getSpacer(45, multiplier);
    //}
    //public space50px(multiplier: number = 1.0) {
    //    return this.getSpacer(50, multiplier);
    //}

    //public customSpace(space:number) {
    //    return this.getSpacer(space, 1.0);
    //}

    public getSpacePx(...spaces: number[]) {
        let strings = spaces.map((v) => this.getSpacer(v, 1.0));
        return strings.join(' ');
    }

    public scalePx(value: number) {
        return this.getSpacer(value, 1);
    }

    

    public get fontSizeError(): string { return this.fontSizeNormal() }
           
    public get fontSizeTrackArtist(): string { return this.fontSizeNormal() }
    public get fontSizeTrackTitle(): string { return this.fontSizeNormal()}
    public get fontSizeTrackYear(): string { return this.fontSizeNormal() }
    public get fontsizeLicenceHeader(): string { return this.fontSizeNormal() }
           
    public get fontSizeBigTrackArtist(): string { return this.fontSizeTitle() }
    public get fontSizeBigTrackTitle(): string { return this.fontSizeHeader() }
           
    public get fontSizeMenuButton(): string { return this.fontSizeNormal() }
           
    public get fontSizeInput(): string { return this.fontSizeNormal() }
    public get fontSizeLabel(): string { return this.fontSizeNormal() }
    public get fontSizeButton(): string { return this.fontSizeNormal() }
           
    public get fontSizeAutomatTitle(): string { return this.fontSizeNormal() }
    public get fontSizeAutomatGenre(): string { return this.fontSizeNormal() }
           
    public get fontSizePlaylistNameHdr(): string { return this.fontSizeNormal() }
    public get fontSizePlaylistName(): string { return this.fontSizeNormal() }

    public get defaultTransitionSpeedString(): string { return (this._styleBaseValues.transitionSpeed / 1000) + "s"; }
    public get transition(): string { return `all ${this.defaultTransitionSpeedString} ease`; }
           
    public get defaultBoxShadow(): string { return "rgb(20, 20, 20) 0px 5px 10px" }

}

interface StyleBaseValues {
    transitionSpeed: number,
    fontSizeSmallBase: number,
    fontSizeNormalBase: number,
    fontSizeLargeBase: number,
    fontSizeXLargeBase: number,
}

const theme = new Theme();
export default theme;