import { ViewModel } from "../../../../../lib/ui/viewmodels/common/ViewModel";
import { PlayerService } from "../../../../../server_com/services/player/PlayerService";
import { ObservableOpt, IObservable, ObservableDef } from "../../../../../lib/utils/eventbase/Observable";
import { PlayerPosition } from "../../../../../server_com/models/player/PlayerPosition";
import { PlayerTrack } from "../../../../../server_com/models/player/PlayerTrack";
import { EngineSelectionService } from "../../../../../server_com/services/login/EngineSelectionService";

export class CurrentSongControlsViewModel extends ViewModel {

    private readonly _playerService: PlayerService;

    private _obsCurrentTrackTitle: ObservableOpt<string> = new ObservableOpt<string>();
    private _obsCurrentTrackArtist: ObservableOpt<string> = new ObservableOpt<string>();
    private _obsPlayButtonVisibility: ObservableDef<boolean> = new ObservableDef<boolean>(true);
    private _obsControlsDisabled: ObservableOpt<boolean> = new ObservableOpt<boolean>();
    private _obsShowControls: ObservableOpt<boolean> = new ObservableOpt<boolean>();

    public get obsCurrentTrackTitle(): IObservable<string> { return this._obsCurrentTrackTitle; }
    public get obsCurrentTrackArtist(): IObservable<string> { return this._obsCurrentTrackArtist; }
    public get obsPlayButtonVisibility(): IObservable<boolean> { return this._obsPlayButtonVisibility; }
    public get obsControlsDisabled(): IObservable<boolean> { return this._obsControlsDisabled; }
    public get obsShowControls(): IObservable<boolean> { return this._obsShowControls; }


    constructor(parent: ViewModel) {
        super(parent);

        EngineSelectionService.instance.obsCurrentEngine.subscribeInitial(this.subscriptionContainer, engine => {
            this._obsShowControls.emit(engine?.hasRoleController ?? false);
        });

        this._playerService = PlayerService.getInstance();
        this._playerService.onPlayerPositionUpdate.subscribeInitial(this.subscriptionContainer,
            (t) => (this.updatePlayerPosition(t)));
        this._playerService.onPlayerTrackUpdate.subscribeInitial(this.subscriptionContainer,
            (t) => (this.updatePlayerTrack(t)));

        this._playerService.activate(this);
    }



    private updatePlayerPosition(playerPosition: PlayerPosition) {
        this._obsControlsDisabled.emit(false);
        this._obsPlayButtonVisibility.emit(!playerPosition.playing);
    }

    private updatePlayerTrack(playerTrack: PlayerTrack) {
        this._obsCurrentTrackTitle.emit(playerTrack.title);
        this._obsCurrentTrackArtist.emit(playerTrack.artist);

    }

    public back(): void {
        this._playerService.back();
    }
    public play(): void {
        this._playerService.play();
    }
    public pause(): void {
        this._playerService.pause();
    }
    public next(): void {
        this._playerService.next();
    }

    public dispose() {
        super.dispose();
        this._playerService.deactivate(this);
    }
}