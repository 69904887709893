import { ButtonPart, IParamButtonPart } from "../../../../lib/ui/parts/button/ButtonPart";
import { IconPart } from "../../../../lib/ui/parts/icon/IconPart";
import { NormalText } from "../text/NormalText";
import { IconButtonSize } from "./IconButton";
import { HybridIcon } from "../icon/HybridIcon";
import theme from "../../../../app/style/Theme";
import { Colors } from "../colors/Colors";

export interface IParamTextWithIconButton extends IParamButtonPart{
    icon: string;
}

export class TextWithIconButton extends ButtonPart{
    constructor(par: IParamTextWithIconButton) {
        let text = par.text;
        let textSubscription = par.textSubscription;
        delete par.text;
        delete par.textSubscription;
        Object.assign(par, {
            style: {
                display: "flex",
                alignItems: "center",
                border: "none",
                background: "none",
                padding: theme.getSpacePx(5, 0),
                textAlign: "left",
            },
            children: [
                new HybridIcon({
                    style: {
                        color: Colors.primaryTextColor,
                        display: "inline-block",
                        marginRight: theme.getSpacePx(20)
                    },
                    icon: par.icon,
                    iconSize: theme.scalePx(38)
                }),
                new NormalText({
                    style: {
                        color: Colors.primaryTextColor,
                        display: "inline-block",
                        fontWeight: "bold"
                    },
                    text: text,
                    textSubscription: textSubscription
                })
            ]
        } as IParamButtonPart);
        super(par);

        
    }
}