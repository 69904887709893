import { ViewModel, IViewModel } from "./ViewModel";
import { ViewModelList } from "./ViewModelList";

export abstract class ListItemViewModel extends ViewModel {
    constructor(parent: IViewModel | null) {
        super(parent);

    }

    private _viewModelList: ViewModelList<ListItemViewModel, any> | undefined;
    private _selected: boolean = false;
    public get isSelected() {return this._selected;}

    protected abstract onSelect():void;
    protected abstract onDeselect():void;

    public select() {
        if (this._selected == true) {
            return;
        }
        this._selected = true;
        this.onSelect();
        this._viewModelList?.selectViewmodel(this);
    }

    public deselect() {
        if (this._selected == false) {
            return;
        }
        this._selected = false;
        this.onDeselect()
        this._viewModelList?.deselectViewmodel(this);
    }

    public setParentList(vml: ViewModelList<ListItemViewModel, any>) {
        this._viewModelList = vml;
    }

}