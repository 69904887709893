import { IEngineDescription } from "../services/login/EngineSelectionService";
import { FetchService } from "../apilib/index";
import { PromiseAggregator } from "../../common/utils/index";

export class UrlRepository {

    private _urls: IUrls | null = null;
    private _fetchService: FetchService;
    private _getUrlsPromiseArragator: PromiseAggregator<IUrls | null>;

    //private static controllerUri = "https://recordcasemanager.pbsound.nl/api/controller";
    //private static securityUri = "https://recordcasemanager.pbsound.nl/api/security";

    private static _singleton: UrlRepository | null = null;
    public static getInstance(): UrlRepository {
        return this._singleton || (this._singleton = new UrlRepository());
    }

    private constructor() {
        this._fetchService = new FetchService();
        this._getUrlsPromiseArragator = new PromiseAggregator<IUrls| null>(() => this.fetchUrls());
    }

    private getUrls(): Promise<IUrls | null>{
        return this._getUrlsPromiseArragator.executeAsync();
    }

    private async fetchUrls(): Promise<IUrls | null> {
        if (this._urls == null) {
            let urlResult = await this._fetchService.fetchGetAsync<IUrls>("api/config");
            if (urlResult.resultType == "data") {
                this._urls = urlResult.data;
                return this._urls;
            } else {
                console.error("Can't fetch urls");
                return null;
            }
        } else {
            return this._urls;
        }        
    }

    public async getAccessTokenApiUrlAsync(): Promise<string | null> {
        let urls = await this.getUrls();
        if (urls == null) {
            return null;
        }
        return `${urls.securityApiUri}/controllertoken`;
    }

    public async getDeviceApiUrlAsync(): Promise<string | null> {
        let urls = await this.getUrls();
        if (urls == null) {
            return null;
        }
        return urls.deviceApiUrl;
    }

    public async getConfigApiUrlAsync(): Promise<string | null> {
        let urls = await this.getUrls();
        if (urls == null) {
            return null;
        }
        return `${urls.controllerApiUri}/config`;
    }

    public async getRefreshTokenApiUrlAsync(): Promise<string | null> {
        let urls = await this.getUrls();
        if (urls == null) {
            return null;
        }
        return `${urls.controllerApiUri}/registernew`;
    }

    public async getEnginesApiUrlAsync(): Promise<string | null> {
        let urls = await this.getUrls();
        if (urls == null) {
            return null;
        }
        return `${urls.controllerApiUri}V2/engines`;
    }

    public async getConnectEngineApiUrlAsync(): Promise<string | null> {
        let urls = await this.getUrls();
        if (urls == null) {
            return null;
        }
        return `${urls.controllerApiUri}V2/connect`;
    }

    public static get myPbUrl(): string { return "https://my.pbsound.nl/controller";}
}

interface IUrls {
    controllerApiUri: string;
    securityApiUri: string;
    deviceApiUrl: string;
}