import { ViewModel } from "../../../lib/ui/viewmodels/common/ViewModel";
import { IPlayerNavigationManager } from "./IPlayerNavigationManager";

export class JukeboxControlBarViewModel extends ViewModel {

    private _playerNavigationManager: IPlayerNavigationManager;
    public get playerNavigationManager(): IPlayerNavigationManager { return this._playerNavigationManager; }

    constructor(parent: ViewModel, navigationManager: IPlayerNavigationManager) {
        super(parent);
        this._playerNavigationManager = navigationManager;
    }

    public dispose() {
        super.dispose();
    }
}