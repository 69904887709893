import { ContainerPart, IParamContainerPart } from "../../../../lib/ui/parts/containers/ContainerPart";
import { ImagePart } from "../../../../lib/ui/parts/image/ImagePart";
import { AlbumCoverBase, IParamAlbumCoverBase } from "./AlbumCoverBase";
import theme from "../../../../app/style/Theme";

export interface IParamSmallAblumCover extends IParamAlbumCoverBase{

}

export class SmallAblumCover extends AlbumCoverBase {

    constructor(par: IParamSmallAblumCover) {
        SmallAblumCover.setDefaultStyle(par, {
            width: theme.scalePx(65),
            height: theme.scalePx(65),
        });
        super(par);      

    }
}