import { ViewModel } from "./ViewModel";
import { ContentSwitchViewModel } from "./ContentSwitchViewModel";
import { NavigationPath } from "../../navigation/NavigationPath";

class NamedItem<T extends string> {
    constructor(public name: T, public viewModel: ViewModel) {
    }
}

export abstract class MenuContentSwitchViewModel<T extends string> extends ContentSwitchViewModel {

    private _items: NamedItem<T>[] = [];
    private _selectedItem: NamedItem<T> | undefined;

    protected switchToName(name: T) {
        this._selectedItem = this.getItem(name);
        if (this._selectedItem != null) {
            this.switchToContent(this._selectedItem.viewModel);
        } else {
            this.switchToContent(null);
        }
    }

    private getItem(name: T): NamedItem<T> | undefined {
        const item = this._items.find(i => i.name === name);
        return item;
    }

    private getItemByViewModel(vm: ViewModel): NamedItem<T> | undefined {
        const item = this._items.find(i => i.viewModel === vm);
        return item;
    }

    public get navigationName(): string | null { return this._selectedItem ? this._selectedItem.name : null; }

    public get navigationViewModel(): ViewModel | null { return this._selectedItem ? this._selectedItem.viewModel : null; }

    protected createMenuItem(name: T, vm: ViewModel) {
        this._items.push(new NamedItem(name, vm));
    }

    public navigateTo(navPath: NavigationPath) {
        const part = navPath.popPath();
        if (part != null) {
            const item = this.getItem(part as T);
            if (item != null && item.viewModel != null) {
                this._selectedItem = item;
                // First set navigation of children, then of this viewmodel to prevent flickering
                item.viewModel.navigateTo(navPath);
                this.switchToContent(item.viewModel);
                return;
            }
        }
    }

    public fillNavigationPath(navPath: NavigationPath) {
        if (this._selectedItem != null) {
            navPath.pushPath(this._selectedItem.name);
            this._selectedItem.viewModel.fillNavigationPath(navPath);
        }
    }

    public navigateToViewModel(child: ViewModel): void {
        const item = this.getItemByViewModel(child);
        if (item != null) {
            this._selectedItem = item;
            this.switchToContent(item.viewModel);
            this.navigateToHere();
        }
    }

    protected navigateToName(name: T): void {
        const item = this.getItem(name);
        if (item != null) {
            this._selectedItem = item;
            this.switchToContent(item.viewModel);
            this.navigateToHere();
        }
    }

}