import {IParamParagraphPart, ParagraphPart } from "../../../../lib/ui/parts/text/ParagraphPart";
import {ViewPart} from "../../../../lib/ui/parts/ViewPart";
import { StyleConstants } from "../style/StyleConstants";
import theme from "../../../../app/style/Theme";
import { Colors } from "../colors/Colors";

export interface IParamNormalText extends IParamParagraphPart {
}

export class PlaylistNameText extends ParagraphPart {

    constructor(par: IParamNormalText) {

        ViewPart.setDefaultStyle(par, {
            color: Colors.primaryTextColor,
            fontSize: theme.fontSizePlaylistName,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        });

        super(par);
    }
}