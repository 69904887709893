import { ViewModel } from "../../../../lib/ui/viewmodels/common/ViewModel";
import { AutomatProgramsService } from "../../../../server_com/services/player/automat_programs/AutomatProgramsService";
import { AutomatService } from "../../../../server_com/services/player/automat_programs/AutomatService";
import { ObservableDef, ObservableOpt, IObservableOpt, IObservable } from "../../../../lib/utils/eventbase/Observable";
import { ILastUsedProgram } from "../../../../server_com/messages/E2C/IAutomatLastUsedProgramListE2C";
import { RecentAutomatProgramItemViewModel } from "./RecentAutomatProgramItemViewModel";
import { AutomatControlsViewModel } from "./controls/AutomatControlsViewModel";
import { ComponentViewModel } from "../../../../lib/ui/viewmodels/components/ComponentViewModel";
import { PlayerViewModel } from "../../../engine/player/PlayerViewModel";

export class AutomatComponentViewModel extends ComponentViewModel {

    private _automatProgramsService: AutomatProgramsService;
    private _automatService: AutomatService;
    private _lastUsedPrograms: ObservableDef<RecentAutomatProgramItemViewModel[]> = new ObservableDef<RecentAutomatProgramItemViewModel[]>([]);

    private _automatControlsViewModel: AutomatControlsViewModel | undefined;

    private _loaderVisibility: ObservableDef<boolean> = new ObservableDef<boolean>(true);
    public get loaderVisibility(): IObservable<boolean> { return this._loaderVisibility; }
    public get automatControlsViewModel(): AutomatControlsViewModel {
        if (!this._automatControlsViewModel)
        {
            throw new Error();
        }
        
        return this._automatControlsViewModel;
    }

    constructor(private _parent: PlayerViewModel) {
        super(_parent, "Automat");


        this._automatProgramsService = AutomatProgramsService.getInstance();
        this._automatProgramsService.automatLastUsedPrograms.subscribeInitial(this.subscriptionContainer, (programs) => (this.setLastUsedPrograms(programs)));
        this._automatService = AutomatService.getInstance();

    }

    public get lastUsed(): IObservable<RecentAutomatProgramItemViewModel[]> { return this._lastUsedPrograms; };

    public async showAsync(): Promise<void> {
        this._automatProgramsService.activate(this);
        this._automatService.activate(this);
        this._automatControlsViewModel = new AutomatControlsViewModel(this);
        await this.executeComponentAsync();
        this._automatControlsViewModel.dispose();
        this._automatProgramsService.deactivate(this);
        this._automatService.deactivate(this);
    }

    public automatProgramClicked(program: string) {
        this._loaderVisibility.emit(false);
        this._automatService.playProgram(program);
        this._parent.showPlayer();
    }

    private setLastUsedPrograms(programs: ILastUsedProgram[]) {
        this._loaderVisibility.emit(false);
        this._lastUsedPrograms.emit(programs.map((p) => (new RecentAutomatProgramItemViewModel(this, p))));
    }
}