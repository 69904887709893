import { DeviceVerificationHelper } from '../../../device_verification/DeviceVerificationHelper';
import { FetchService } from '../../apilib/index';
import { IObservable, ObservableOpt } from '../../../lib/utils/eventbase/Observable';
import { PromiseAggregator } from '../../../common/utils/PromiseAggregator';
import { UrlRepository } from '../../utils/UrlRepository';


export class DeviceVerificationService {
    private static _singleton: DeviceVerificationService;
    public static getInstance(): DeviceVerificationService {
        return this._singleton || (this._singleton = new DeviceVerificationService());
    }

    private _deviceEncryptionHelper: DeviceVerificationHelper = new DeviceVerificationHelper();
    private _fetchService = new FetchService("omit");
    private _promiseAggregator: PromiseAggregator<GetDeviceTokenResult>;

    private _verifyStep: ObservableOpt<string> = new ObservableOpt<string>();
    public get obsVerifyStep(): IObservable<string> { return this._verifyStep; }

    private constructor() {
        this._promiseAggregator = new PromiseAggregator<GetDeviceTokenResult>(() => this.tryGetTokenInternalAsync());
    }

    public async tryGetTokenAsync(): Promise<GetDeviceTokenResult> {
        return this._promiseAggregator.executeAsync();
    }

    private  async tryGetTokenInternalAsync(): Promise<GetDeviceTokenResult> {
        try{
            
            this._verifyStep.emit("Ophalen publieke sleutel");
            const publicKey = await this._deviceEncryptionHelper.getPublicKeyAsync();
            this._verifyStep.emit("Ophalen verificatie gegevens");
            const secretResult = await this.getSecretAsync(publicKey);
            if (secretResult.resultType !== "data") {
                return {
                    result: "Error",
                    message: "Could not get a secret from the server"
                };
            }
            this._verifyStep.emit("Gegevens ondertekenen");
            const signature = await this._deviceEncryptionHelper.createSignedStringAsync(secretResult.data.secret);
            if (signature.result === "NoKeyPair") {
                return {
                    result: "Error",
                    message: "No key pair"
                };
            }
            this._verifyStep.emit("Controleren signatuur");
            const tokenResult = await this.getTokenAsync(signature.publicKey, signature.signature);
            if (tokenResult.resultType === "error") {
                return {
                    result: "Error",
                    message: "Could not verify device"
                };
            }
            console.log(tokenResult.data);
            this._verifyStep.emit("Verificatie geslaagd");
            return {
                result: "Succes",
                token: tokenResult.data.deviceToken,
                deviceNr: tokenResult.data.deviceNumber
            };
        }catch(err){
            if(err instanceof Error){
                this._verifyStep.emit(err.stack || "Geen stack");
            }            
            return {
                result: "Error",
                message: String(err)
            };
        }
    }

    private async getSecretAsync(publicKey: string){
        const baseUrl = await UrlRepository.getInstance().getDeviceApiUrlAsync();
        console.log(baseUrl);
        const url = `${baseUrl}/getcontrollersecret`
        return this._fetchService.fetchPostWithResultAsync<IControllerSecretResult>(url, {
            publicKey
        });
    }

    private async getTokenAsync(publicKey: string, signature: string) {
        const baseUrl = await UrlRepository.getInstance().getDeviceApiUrlAsync();
        const url = `${baseUrl}/getcontrollertoken`
        return this._fetchService.fetchPostWithResultAsync<IControllerTokenResult>(url, {
            publicKey,
            signature
        });
    }
}

(window as any).deviceVerificationService = DeviceVerificationService.getInstance();

interface IControllerSecretResult {
    secret: string;
    validUntil: string;
}

interface IControllerTokenResult {
    deviceNumber: number;
    deviceToken: string;
}

export type GetDeviceTokenResult = GetDeviceTokenResultError | GetDeviceTokenResultNotCoupled | GetDeviceTokenResultSucces;

interface GetDeviceTokenResultError {
    result: "Error";
    message: string;
}

interface GetDeviceTokenResultNotCoupled{
    result: "NotCoupled";
}

interface GetDeviceTokenResultSucces {
    result: "Succes";
    token: string;
    deviceNr: number;
}