import { MessageDC2E } from "../MessageDC2E";
import { ChannelType } from "../ChannelType";
import {MessageC2E} from "../MessageC2E";

export class FromControllerMessageDC2E extends MessageDC2E{
    constructor(channel: ChannelType, payload: MessageC2E) {
        super();
        this.channelId = channel;
        this.payload = payload;
    }
    public channelId: ChannelType;
    public payload: MessageC2E;
}