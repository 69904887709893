import { ItemPosition } from "../../../../../../lib/ui/parts/nonvisible/ItemsFactoryPart";
import { PlayListSubItemViewModel } from "./PlayListSubItemViewModel";
import { GridContainer } from "../../../../../../lib/ui/parts/containers/GridContainer";
import { TitleText } from "../../../../../../common/ui/parts/text/TitleText";
import { IconPart } from "../../../../../../lib/ui/parts/icon/IconPart";
import { PlaylistNameText } from "../../../../../../common/ui/parts/text/PlaylistNameText";
import { IconButton } from "../../../../../../common/ui/parts/buttons/IconButton";
import { StyleConstants } from "../../../../../../common/ui/parts/style/StyleConstants";
import theme from "../../../../../../app/style/Theme";
import { Colors } from "../../../../../../common/ui/parts/colors/Colors";

export class PlaylistsSubItemView extends GridContainer {

    constructor(viewmodel: PlayListSubItemViewModel, position: ItemPosition) {
        super({
            style: {
                height: theme.scalePx(40),
                gridTemplateColumns: "auto 1fr auto",
                backgroundColor: Colors.seconderyBackgroundColor,
                boxSizing: "border-box",
                paddingRight: theme.getSpacePx(5),
                paddingLeft: theme.getSpacePx(5),
                borderRadius: theme.getSpacePx(20),
                alignItems: "center"
            },
            onclick: () => viewmodel.itemClicked(),
            children: [
                new IconButton({
                    icon: StyleConstants.playButtonIcon
                }),
                new PlaylistNameText({
                    style: {
                        padding: theme.getSpacePx(0, 5)
                    },
                    textSubscription: viewmodel.obsTitle
                }),
                new IconPart({
                    style: {
                        color: "white"
                    },
                    icon: "chevron_right",
                    iconSize: theme.scalePx(35)
                })
            ]
        });

    }
}