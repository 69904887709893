import { FetchResultContent, FetchResultNoContent } from "./FetchResult";
import { IFetchOptions, CredentialsType } from "./FetchOptions";
//import { IIdentityToken } from "../../services/token/Interfaces";



export class FetchService {
    private _headerFactories = new Array<(headers: Headers) => Promise<void>>();
    private _credentials: CredentialsType | undefined;

    constructor(credentials?: CredentialsType) {
        this._credentials = credentials ? credentials : "same-origin";
    }

    public addHeaderAsyncFactory(asyncHeaderFactory: (headers: Headers) => Promise<void>): void {
        this._headerFactories.push(asyncHeaderFactory);
    }

    public setCredentials(credentials: CredentialsType): void {
        this._credentials = credentials;
    }

    public async fetchGetAsync<T>(url: string, headerFactory?: (headers: Headers) => void): Promise<FetchResultContent<T>> {
        const headers = await this.makeHeadersAsync(headerFactory);

        const data: IFetchOptions = {
            method: "GET",
            headers,
            credentials: this._credentials,
            // mode: "no-cors",
        };

        const response = await this.jsonFetchResultAsync<T>(url, data);

        return response;
    }

    public async fetchPostWithResultAsync<T>(url: string, postData: any, headerFactory?: (headers: Headers) => void): Promise<FetchResultContent<T>> {
        const headers = await this.makeHeadersAsync(headerFactory);
        headers.append("Content-Type", "application/json");
        const data: IFetchOptions = {
            method: "POST",
            body: JSON.stringify(postData),
            headers,
            credentials: this._credentials,
        };

        const response = await this.jsonFetchResultAsync<T>(url, data);
        return response;
    }

    public async fetchPostAsync(url: string, postData: any, headerFactory?: (headers: Headers) => void): Promise<FetchResultNoContent> {
        const headers = await this.makeHeadersAsync(headerFactory);
        headers.append("Content-Type", "application/json");
        const data: IFetchOptions = {
            method: "POST",
            body: JSON.stringify(postData),
            headers,
            credentials: this._credentials,
        };

        return await this.jsonFetchResultEmptyAsync(url, data);
    }

    public async fetchPutAsync(url: string, postData: any, headerFactory?: (headers: Headers) => void): Promise<FetchResultNoContent> {
        const headers = await this.makeHeadersAsync(headerFactory);
        headers.append("Content-Type", "application/json");
        const data: IFetchOptions = {
            method: "PUT",
            body: JSON.stringify(postData),
            headers,
            credentials: this._credentials,
        };
        return await this.jsonFetchResultEmptyAsync(url, data);
    }

    public async fetchPutWithResultAsync<T>(url: string, postData: any, headerFactory?: (headers: Headers) => void): Promise<FetchResultContent<T>> {
        const headers = await this.makeHeadersAsync(headerFactory);
        headers.append("Content-Type", "application/json");
        const data: IFetchOptions = {
            method: "PUT",
            body: JSON.stringify(postData),
            headers,
            credentials: this._credentials,
        };

        return await this.jsonFetchResultAsync<T>(url, data);
    }

    public async fetchDeleteAsync(url: string, headerFactory?: (headers: Headers) => void): Promise<FetchResultNoContent> {
        const headers = await this.makeHeadersAsync(headerFactory);
        const data: IFetchOptions = {
            method: "DELETE",
            headers,
            credentials: this._credentials,
        };
        return await this.jsonFetchResultEmptyAsync(url, data);
    }

    private async jsonFetchResultAsync<TResult>(url: string, data: IFetchOptions): Promise<FetchResultContent<TResult>> {
        const response = await (window as any).fetch(url, data) as Response;

        if (response.status === 401) {
            return {
                resultType: "error",
                errorType: "NotAuthorized",
                error: "The user is not logged in",
                userError: "You are not logged in",
            };
        }

        if (response.status === 402) {
            return {
                resultType: "error",
                errorType: "NoAccess",
                error: "The user has no access",
                userError: "You have no access",
            };
        }

        if (response.status === 404) {
            return {
                resultType: "error",
                errorType: "NotFound",
                error: "The data was not found",
                userError: "The data was not found",
            };
        }

        if (response.status === 400) {

            let errorObject: IServerErrorCollection | undefined;

            try {
                const errorText = await response.text();
                errorObject = JSON.parse(errorText);
            } catch (err) {
                // tslint:disable-next-line: no-console
                console.error(err);
            }

            if (errorObject) {
                return {
                    resultType: "error",
                    errorType: "ServerError",
                    error: `Fetch failed with status code: ${response.status}`,
                    userError: "Server error",
                };
            } else {
                return {
                    resultType: "error",
                    errorType: "ServerError",
                    error: `Server failed with status 400. No futher data.`,
                    userError: "Server error",
                };
            }
        }

        if (response.status !== 200) {
            return {
                resultType: "error",
                errorType: "ServerError",
                error: `response code ${response.status}`,
                userError: "Server error",
            };
        }

        try {
            const data1 = await response.json();
            return {
                resultType: "data",
                data: data1,
            };
        } catch (err) {
            return {
                resultType: "error",
                errorType: "InternalError",
                error: "Data is not json. " + err,
                userError: "Internal error",
            };
        }
    }

    private async jsonFetchResultEmptyAsync<TResult>(url: string, data: IFetchOptions): Promise<FetchResultNoContent> {
        const response = await (window as any).fetch(url, data) as Response;

        if (response.status === 401) {
            return {
                resultType: "error",
                errorType: "NotAuthorized",
                error: "The user is not logged in",
                userError: "You are not logged in",
            };
        }

        if (response.status === 402) {
            return {
                resultType: "error",
                errorType: "NoAccess",
                error: "The user has no access",
                userError: "You have no access",
            };
        }

        if (response.status === 404) {
            return {
                resultType: "error",
                errorType: "NotFound",
                error: "The data was not found",
                userError: "The data was not found",
            };
        }

        if (response.status === 400) {

            let errorObject: IServerErrorCollection | undefined;

            try {
                const errorText = await response.text();
                errorObject = JSON.parse(errorText);
            } catch (err) {
                // tslint:disable-next-line: no-console
                console.error(err);
            }

            if (errorObject) {
                return {
                    resultType: "error",
                    errorType: "ServerError",
                    error: `Fetch failed with status code: ${response.status}`,
                    userError: "Server error",
                };
            } else {
                return {
                    resultType: "error",
                    errorType: "ServerError",
                    error: `Server failed with status 400. No futher data.`,
                    userError: "Server error",
                };
            }
        }

        if (response.status !== 200 && response.status !== 202) {
            return {
                resultType: "error",
                errorType: "ServerError",
                error: `response code ${response.status}`,
                userError: "Server error",
            };
        }

        return { resultType: "ok" };
    }

    private async makeHeadersAsync(headerFactory?: (headers: Headers) => void): Promise<Headers> {
        // let headers : Headers = new (window as any).Headers();

        const headers: Headers = new Headers();
        const promises = this._headerFactories.map((asyncFactory) => asyncFactory(headers));

        for (const p of promises) {
            await p;
        }

        if (headerFactory) {
            headerFactory(headers);
        }

        return headers;
    }
}

export interface IServerErrorCollection {
    errors: IServerError[];
}

export interface IServerError {
    error: string;
}

