export class PlayerPosition {

    constructor(positionMsec: number, durationMsec: number, playing: boolean, pitch: number | null) {
        this.positionMsec = positionMsec;
        this.durationMsec = durationMsec;
        this.playing = playing;
        this.pitch = pitch ? pitch : 1;
    }

    public positionMsec: number;
    public durationMsec: number;
    public playing: boolean;
    public pitch: number;
}