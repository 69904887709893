import { AboutView } from "./about/AboutView";
import { AboutViewModel } from "../../viewmodels/main/about/AboutViewModel";
import { ContentSwitchPart } from "../../../lib/ui/parts/contentmanagers/ContentSwitchPart";
import { MainViewModelBase } from "../../../lib/ui/viewmodels/MainViewModelBase";
import { ContainerPart } from "../../../lib/ui/parts/containers/ContainerPart";
import { TransitionFadeIn } from "../../../common/ui/transitions/TransitionFadeIn";
import { TransitionFadeOut } from "../../../common/ui/transitions/TransitionFadeOut";
import { ViewFactory } from "../../../lib/ui/parts/contentmanagers/ViewFactory";
import { LoginViewModel } from "../../viewmodels/main/login/LoginViewModel";
import { LoginView } from "./login/LoginView";
import { MainMenuViewModel } from "../../viewmodels/main/MainMenuViewModel";
import { MainMenuView } from "./MainMenuView";
import { Colors } from "../../../common/ui/parts/colors/Colors";



export class MainView extends ContainerPart {

    constructor(viewModel: MainViewModelBase) {
        super({
            style: {
                //background: StyleConstants.primaryBackGroundImage,
                backgroundColor: Colors.primaryBackgroundColor
            },
            children: [
                new ContentSwitchPart({
                    viewFactories: [
                        new ViewFactory(AboutViewModel, (vm) => new AboutView(vm), new TransitionFadeIn(), new TransitionFadeOut()),
                        new ViewFactory(MainMenuViewModel, (vm) => new MainMenuView(vm), new TransitionFadeIn(), new TransitionFadeOut()),
                        new ViewFactory(LoginViewModel, (vm) => new LoginView(vm), new TransitionFadeIn(), new TransitionFadeOut()),
                    ],
                    viewModelSubscription: viewModel.activeChildViewModel
                })
            ]
        });
    }


}
