import { ContainerBase, IParamContainerBase } from "../../../../lib/ui/parts/bases/ContainerBase";
import { ViewPart } from "../../../../lib/ui/parts/ViewPart";
import { ContainerPart } from "../../../../lib/ui/parts/containers/ContainerPart";
import { IPartStyle } from "../../../../lib/ui/parts/IPartStyle";
import theme from "../../../../app/style/Theme";

export interface IParamLoadSpinner extends IParamContainerBase {
    size: number;
}

export class LoadSpinner extends ContainerBase {
    constructor(par: IParamLoadSpinner) {

        par.size = par.size || 60;

        ViewPart.setDefaultStyle(par, {
            width: theme.scalePx(par.size),
            height: theme.scalePx(par.size),
            position: "relative",
        });

        super(par);

        const spin1 = new ContainerPart({});
        this.addChild(spin1);

        const spin2 = new ContainerPart({});
        this.addChild(spin2);

        spin1.applyStyle(this.makeLoaderStyle(par.size, 0));
        spin2.applyStyle(this.makeLoaderStyle(par.size, 1));
    }

    public show() {
        this.root.style.display = "block";
    }

    public hide() {
        this.root.style.display = "none";
    }

    private makeLoaderStyle(size: number, spinnerIndex: number): IPartStyle {
        const color = (["gray", "rgba(255,120,17,1)", "rgba(255,255,255,0.5)"])[spinnerIndex];
        const bordersizes = ([0.15 * size, 0.15 * size, 0.01 * size]);
        const style: IPartStyle = {
            width: theme.scalePx(size),
            height: theme.scalePx(size),
            position: "absolute",
            top: "0px",
            left: "0px",
            border: `solid rgba(0,0,0,0) ${bordersizes[spinnerIndex]}px`,
            borderRadius: "9999px",
            boxSizing: "border-box"
        };

        if (spinnerIndex === 0) {
            style.border = `solid ${bordersizes[spinnerIndex]}px ${color}`;
        } else if (spinnerIndex === 1) {
            style.animation = "spin 1.5s linear infinite";
            style.borderTop = `solid ${bordersizes[spinnerIndex]}px ${color}`;
        }

        return style;
    }

}