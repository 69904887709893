import { ComponentViewModel } from "../../../../lib/ui/viewmodels/components/ComponentViewModel";
import { PlaylistsService } from "../../../../server_com/services/player/playlists/PlaylistsService";
import { ViewModel } from "../../../../lib/ui/viewmodels/common/ViewModel";
import { ObservableOpt, IObservable } from "../../../../lib/utils/eventbase/Observable";
import { IProgramGenreItem } from "../../../../server_com/messages/E2C/IAutomatProgramsListMessageE2C";
import { PlayListItemViewModel } from "./playlist_list_item/PlayListItemViewModel";
import { ITracklistHdr } from "../../../../server_com/models/player/tracklist/ITracklistHdr";
import { TracklistsService } from "../../../../server_com/services/player/playlist/TracklistsService";
import { ViewModelList } from "../../../../lib/ui/viewmodels/common/ViewModelList";
import texts from "../../../../common/ui/localization/Language";

export class PlaylistsOverviewComponentViewModel extends ComponentViewModel {

    private tracklistService: TracklistsService;
    private _playlistsViewModelList: ViewModelList<PlayListItemViewModel, ITracklistHdr>;

    constructor(parent: ViewModel) {
        super(parent, texts.playlists);
        this._playlistsViewModelList = new ViewModelList((d) => this.viewmodelFactory(d));
        this.tracklistService = TracklistsService.getInstance();
        this.tracklistService.tracklistHdrs.subscribeInitial(this.subscriptionContainer, (items) => this.setPlayLists(items));
    }

    public get playlistsViewModels(): IObservable<PlayListItemViewModel[]> { return this._playlistsViewModelList.obsViewmodels }

    private setPlayLists(items: ITracklistHdr[]) {
        this._playlistsViewModelList.setItems(items);
    }

    private viewmodelFactory(item: ITracklistHdr): PlayListItemViewModel {
        return new PlayListItemViewModel(this, item);
    }

    public async showAsync(): Promise<void> {
        this.tracklistService.activate(this);
        await this.executeComponentAsync();
        this.tracklistService.deactivate(this);
    }
}