import { ComponentViewModel, ObservableTwoWayOpt, IObservableTwoWay, ViewModel } from "../../../../lib/index";
import texts from "../../../../common/ui/localization/Language";
import { SettingsService } from "../../../../server_com/services/settings/SettingsService";

export class SettingsViewModel extends ComponentViewModel {
    private settingsService: SettingsService;

    private _obsTwSettingControllername: ObservableTwoWayOpt<string> = new ObservableTwoWayOpt<string>();
    public get obsTwSettingControllername(): IObservableTwoWay<string> { return this._obsTwSettingControllername; }

    constructor(parent: ViewModel) {
        super(parent, texts.settings);
        this.settingsService = SettingsService.getInstance();
        
    }

    public async showAsync() {
        this.loadSettingsAsync();
        return this.executeComponentAsync();
    }

    private async loadSettingsAsync() {
        let settings = await this.settingsService.getSettings();
        if (settings == null) {
            //Could load settings
            return;
        }
        this._obsTwSettingControllername.emitOnChanged(settings.controllerName);
    }



}