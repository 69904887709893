import { ViewModel, IViewModel } from "../../../../lib/ui/viewmodels/common/ViewModel";
import { ITrackSearchResult } from "../../../../server_com/models/player/search/ITrackSearchResult";
import { ObservableOpt, IObservable } from "../../../../lib/utils/eventbase/Observable";
import { WaitlistService } from "../../../../server_com/services/player/waitlist/WaitlistService";
import { SearchService } from "../../../../server_com/services/player/search/SearchService";
import { TextPopupViewModel } from "../popups/TextPopupViewModel";
import texts, { formatTexts } from "../../../../common/ui/localization/Language";
import { StyleConstants } from "../../../../common/ui/parts/style/StyleConstants";

export class SearchResultListItemViewModel extends ViewModel{

    private _obsTrackName: ObservableOpt<string> = new ObservableOpt();
    private _obsButtonIcon: ObservableOpt<string> = new ObservableOpt();
    private _obsIcon: ObservableOpt<string> = new ObservableOpt();
    private _trackId: string;

    public get obsTrackName(): IObservable<string> { return this._obsTrackName }
    public get obsButtonIcon(): IObservable<string> { return this._obsButtonIcon }
    public get obsIcon(): IObservable<string> { return this._obsIcon }

    private _searchService: SearchService;

    constructor(parent: IViewModel, private tracklistItem: ITrackSearchResult) {
        super(parent);
        this._searchService = SearchService.getInstance();
        this._trackId = tracklistItem.trackId;
        this._obsTrackName.emit(`${tracklistItem.artist} - ${tracklistItem.title}`);
        this._obsButtonIcon.emit(tracklistItem.inWaitlist ? "playlist_add_check" : "playlist_add");
        this._obsIcon.emit(this.getTrackIcon(tracklistItem));
    }

    private getTrackIcon(track: ITrackSearchResult): string {
        if (track.isKaraoke) {
            return "mic";
        }else if (track.isVideo) {
            return "movie";
        }
        return StyleConstants.playButtonIcon;
    }

    public onAddButtonClick(): void {
        this._obsButtonIcon.emit("playlist_add_check");
        this._searchService.addSearchResultToWaitlist(this._trackId, null);
        let popup = new TextPopupViewModel(this);
        popup.show(formatTexts(texts.trackAddedPopupMessage, this.tracklistItem.title, this.tracklistItem.artist));
    }

    dispose() {
        console.log("Deactivated waitlistservice");
        super.dispose();
    }
}