import {IEngineListResult, IEngineDescription} from "../../services/login/EngineSelectionService";
import {LocalStorage} from "../../../lib/app/local_storeage/LocalStorage";
import { ObservableDef, IObservable } from "../../../lib/utils/eventbase/Observable";
type DataTypes = "EngineList" | "LastUsed";

export class EngineListStore extends LocalStorage<DataTypes> {
    private static singleton: EngineListStore;

    public static getInstance(): EngineListStore {
        return this.singleton || (this.singleton = new EngineListStore());
    }

    private _engines: ObservableDef<IEngineListResult | null> = new ObservableDef<IEngineListResult | null>(null);
    public get engines(): IObservable<IEngineListResult | null> {
        return this._engines;
    }

    constructor() {
        super("Engines");
        let engines = this.getItem<IEngineListResult>("EngineList");
        if (engines) {
            this._engines.emit(engines);
        }
    }

    public setEngines(engineList: IEngineListResult) {
        this._engines.emit(engineList);
        this.setItem<IEngineListResult>("EngineList", engineList);
    }

    public setLastUsed(engine: IEngineDescription) {
        this.setItem<IEngineDescription>("LastUsed", engine);
    }

    public getLastUsed() {
        return this.getItem<IEngineDescription>("LastUsed");
    }
}