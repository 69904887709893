import { ViewModel } from "../../../lib/ui/viewmodels/common/ViewModel";
import { ObservableOpt, IObservable, ObservableDef } from "../../../lib/utils/eventbase/Observable";
import { PlayerService } from "../../../server_com/services/player/PlayerService";
import { PlayerTrack } from "../../../server_com/models/player/PlayerTrack";
import { PlayerPosition } from "../../../server_com/models/player/PlayerPosition";
import { IPlayerNavigationManager } from "./IPlayerNavigationManager";

export class PlayerControlBarViewModel extends ViewModel {

    private _playerProgressTimerHandle: number | undefined;
    private _playerProgressMs: number =0;
    private _playerTrackTotalMs: number = 0;
    private _playerService: PlayerService;

    private _obsTrackName: ObservableOpt<string> = new ObservableOpt<string>();
    public get obsTrackName(): IObservable<string> { return this._obsTrackName; }

    private _obsTrackProgress: ObservableOpt<number> = new ObservableOpt<number>();
    public get obsTrackProgress(): IObservable<number> { return this._obsTrackProgress; }

    private _obsTrackPlaying: ObservableDef<boolean> = new ObservableDef<boolean>(false);
    public get obsTrackPlaying(): IObservable<boolean> { return this._obsTrackPlaying; }

    private _playerNavigationManager: IPlayerNavigationManager;
    public get playerNavigationManager(): IPlayerNavigationManager { return this._playerNavigationManager; }

    private _trackProgressText: ObservableOpt<string> = new ObservableOpt();
    public get obsTrackProgressText(): IObservable<string> { return this._trackProgressText; }

    private _trackTotalText: ObservableOpt<string> = new ObservableOpt();
    public get obsTrackTotalText(): IObservable<string> { return this._trackTotalText; }

    constructor(parent: ViewModel, navigationManager: IPlayerNavigationManager) {
        super(parent);
        this._playerNavigationManager = navigationManager;
        this._playerService = PlayerService.getInstance();
        this._playerService.onPlayerTrackUpdate.subscribeInitial(this.subscriptionContainer, (t) => this.showTrackName(t));
        this._playerService.onPlayerPositionUpdate.subscribeInitial(this.subscriptionContainer, (p) => this.showPlayerPosition(p));
        this._playerService.activate(this);
    }

    public dispose() {
        super.dispose();
        this._playerService.deactivate(this);
    }

    private showTrackName(track: PlayerTrack) {
        this._obsTrackName.emit(`${track.artist} - ${track.title}`);

    }

    private showPlayerPosition(position: PlayerPosition) {
        this._obsTrackProgress.emit(position.positionMsec / position.durationMsec * 100);
        this._obsTrackPlaying.emit(position.playing);


        this._trackTotalText.emit(this.msToTimeLabel(position.durationMsec));
        this._trackProgressText.emit(this.msToTimeLabel(position.positionMsec));
        this._playerProgressMs = position.positionMsec;
        this._playerTrackTotalMs = position.durationMsec;
        
        if (this._playerProgressTimerHandle) {
            clearInterval(this._playerProgressTimerHandle);
            this._playerProgressTimerHandle = 0;
        }

        if (position.playing) {
            this._playerProgressTimerHandle = window.setInterval(() => this.updateTrackProgress(), 100);
        }
    }

    private updateTrackProgress() {
        if (this._playerProgressMs < this._playerTrackTotalMs) {
            this._playerProgressMs += 100;
        }
        this._obsTrackProgress.emit(this._playerProgressMs / this._playerTrackTotalMs * 100);
        this._trackProgressText.emit(this.msToTimeLabel(this._playerProgressMs));
    }

    public buttonPlay() {
        this._playerService.play();
    }

    public buttonPause() {
        this._playerService.pause();
    }

    public buttonNext() {
        this._playerService.next();
    }

    public buttonBack() {
        this._playerService.back();
    }

    private msToTimeLabel(ms: number) {
        let minutes = Math.floor(ms / 60000);
        let seconds = Math.round((ms / 1000) % 60);
        let secondsString = (seconds.toString() as any).padStart(2, "0") as string;
        return `${minutes}:${secondsString}`
    }

}