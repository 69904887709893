import { ViewModel } from "./ViewModel";
import { ObservableDef, IObservableDef } from "../../../utils/eventbase/Observable";

export abstract class ContentSwitchViewModel extends ViewModel {

    private _activeChildViewModel: ObservableDef<ViewModel | null>;

    constructor(parent: ViewModel | null) {
        super(parent);
        this._activeChildViewModel = new ObservableDef<ViewModel | null>(null);
        this._activeChildViewModel.subscribe(this.subscriptionContainer, () => (this.invalidateHash()));
    }

    public get activeChildViewModel(): IObservableDef<ViewModel | null> { return this._activeChildViewModel };

    public switchToContent(viewModel: ViewModel | null) {

        if (viewModel && this._activeChildViewModel.value !== viewModel) {
            this._activeChildViewModel.emit(viewModel);
        }
    }

}