import { PromiseAggregator } from "../../../common/utils/index";
import { LoginService } from "../login/LoginService";
import { FetchService } from "../../apilib/index";
import { UrlRepository } from "../../utils/UrlRepository";

export class SettingsService {
    private static _singleton: SettingsService;
    static getInstance(): any {
        return this._singleton || (this._singleton = new SettingsService());
    }

    private readonly _loginService: LoginService;
    private readonly _fetchService: FetchService;
    private readonly _getSettingsPromiseAggregator: PromiseAggregator<IControllerSettings | null>;

    private _settings: IControllerSettings | null;

    private constructor() {
        this._loginService = LoginService.getInstance();
        this._fetchService = new FetchService();
        this._getSettingsPromiseAggregator = new PromiseAggregator(() => this.getSettingsinternal());
        this._settings = null;
    }

    public async getSettings(): Promise<IControllerSettings | null>{
        return this._getSettingsPromiseAggregator.executeAsync();
    }

    private async getSettingsinternal(): Promise<IControllerSettings | null> {
        let url = await UrlRepository.getInstance().getConfigApiUrlAsync();
        if (url == null) {
            return null;
        }
        const accessToken = await this._loginService.runDeviceVerification();
        if (accessToken.result === "Succes") {
            let settingsResult = await this._fetchService.fetchGetAsync<IControllerSettings>(url, (headers) => {
                headers.append("x-controller-access-token", accessToken.token);
            }); 

            if (settingsResult.resultType == "data") {
                this._settings = settingsResult.data;
                return settingsResult.data;
            } else {
                return this._settings;
            }
        } else {
            return this._settings;
        }
    }

}

export interface IControllerSettings {
    controllerName: string;
    userIsLoggedIn: string;
    userName: string;
}