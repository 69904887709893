import { PlayerPosition } from "../../models/player/PlayerPosition";
import { PlayerTrack } from "../../models/player/PlayerTrack";
import { IPlayerTrackProgressMessageE2C } from "../../messages/E2C/IPlayerTrackProgressMessageE2C";
import { MessageE2C } from "../../messages/MessageE2C";
import { PlayerPlayMessageC2E } from "../../messages/C2E/PlayerPlayMessageC2E";
import { PlayerPauseMessageC2E } from "../../messages/C2E/PlayerPauseMessageC2E";
import { PlayerNextMessageC2E } from "../../messages/C2E/PlayerNextMessageC2E";
import { IPlayerTrackInfoMessageE2C } from "../../messages/E2C/IPlayerTrackInfoMessageE2C";
import { ServiceChannelBase } from "../ServiceChannelBase";
import {EventEmitter, IEventEmitter } from "../../../lib/utils/eventbase/EventEmitter";
import { PlayerBackMessageC2E } from "../../messages/C2E/PlayerBackMessageC2E";
import { ObservableOpt, IObservableOpt } from "../../../lib/utils/eventbase/Observable";

export class PlayerService extends ServiceChannelBase<"Play"> {
    private static singleton: PlayerService;
    private _nextSongTimer = -1;
    private _currentTrack: IPlayerTrackProgressMessageE2C | null = null;
    private _playTime = 0;
    private _playerPositionUpdate = new ObservableOpt<PlayerPosition>();
    private _playerTrackUpdate = new ObservableOpt<PlayerTrack>();

    public static getInstance(): PlayerService {
        return this.singleton || (this.singleton = new PlayerService());
    }

    constructor() {
        super("Play");
    }

    public get onPlayerPositionUpdate(): IObservableOpt<PlayerPosition> { return this._playerPositionUpdate; };

    public get onPlayerTrackUpdate(): IObservableOpt<PlayerTrack> { return this._playerTrackUpdate; };

    public back() {
        this.sendOnChannel(new PlayerBackMessageC2E());
    }

    public play() {
        this.sendOnChannel(new PlayerPlayMessageC2E());
    }

    public pause() {
        this.sendOnChannel(new PlayerPauseMessageC2E());
    }

    public next() {
        this.sendOnChannel(new PlayerNextMessageC2E());
    }
    
    public receivePlayerTrackInfo(trackInfo: IPlayerTrackInfoMessageE2C) {
        this._playerTrackUpdate.emit(new PlayerTrack(trackInfo.title, trackInfo.artist, trackInfo.album));
    }

    public receivePlayerTrackProgress(progress: IPlayerTrackProgressMessageE2C) {
        this._playerPositionUpdate.emit(
            new PlayerPosition(
                progress.positionMsec,
                progress.durationMsec,
                progress.playing,
                progress.pitch));
    }

    protected onMessageOnChannel(message: MessageE2C):void {
        switch (message.type) {
            case "PlayerTrackInfoE2C":
                this.receivePlayerTrackInfo(message);
                break;
            case "PlayerTrackProgressE2C":
                this.receivePlayerTrackProgress(message);
                break;
        }
    }
}