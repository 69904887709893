export type WebsocketState =
    WebsocketStateClosed |
    WebsocketStateConnecting |
    WebsocketStateConnected;

export class WebsocketStateClosed {

    constructor() {
        this.state = "Closed";
    }

    state: "Closed";
    errorMessage: string | null = null;
    closedByUser: boolean = false;
}

export class WebsocketStateConnecting {

    constructor(url: string) {
        this.state = "Connecting";
        this.url = url;
    }

    state: "Connecting";
    url: string;
}

export class WebsocketStateConnected {

    constructor(url: string) {
        this.state = "Connected";
        this.url = url;
    }

    state: "Connected";
    url: string;
}
