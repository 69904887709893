import { SubscriptionContainer } from "./SubscriptionContainer";
import { SubsribableBase } from "./SubsribableBase";

export interface IEventEmitter<T> {

    subscribe(subscriptionContainer: SubscriptionContainer, callback: (value: T) => void): void;
}

export class EventEmitter<T> extends SubsribableBase<T> {

    constructor(async: boolean = true) {
        super(async);
    }

    public emit(value: T): void {
        super.emitInternal(value);
    }

}