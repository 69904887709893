import { ContainerPart, IParamContainerPart } from "./ContainerPart";
import { ViewPart, PartSize } from "../ViewPart";

export interface IParamScrollContainerPart extends IParamContainerPart {
}

export class ScrollContainerPart extends ContainerPart {
    constructor(par: IParamScrollContainerPart) {

        if (!par.partSize) {
            par.partSize = PartSize.FullSize;
        }

        ViewPart.setDefaultStyle(par, {
            overflowY: "auto",
            overflowX: "hidden"
        });

        super(par);
    }

    public onAddChildToViewPart(child: ViewPart): void {

        child.applyStyle({
            height: "auto",
        });

        super.onAddChildToViewPart(child);
    }
}