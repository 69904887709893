import { MessageE2C } from "../../../messages/MessageE2C";
import { IWaitlistTrack } from "../../../models/player/waitlist/WaitlistTrack";
import { IWaitlistMessageE2C, IWaitlistItem } from "../../../messages/E2C/IWaitlistMessageE2C";
import { ServiceChannelBase } from "../../ServiceChannelBase";
import { ObservableDef } from "../../../../lib/utils/eventbase/Observable";
import { MoveWaitlistItemUpC2E } from "../../../messages/C2E/MoveWaitlistItemUpC2E";
import { MoveWaitlistItemDownC2E } from "../../../messages/C2E/MoveWaitlistItemDownC2E";
import { MoveWaitlistItemToTopC2E } from "../../../messages/C2E/MoveWaitlistItemToTopC2E";
import { RemoveWaitlistItemC2E } from "../../../messages/C2E/RemoveWaitlistItemC2E";
import { AddSearchResultToWaitlistC2E } from "../../../messages/C2E/AddTrackToWaitlistC2E";

export class WaitlistService extends ServiceChannelBase<"Waitlist"> {

    private static _singleton: WaitlistService;
    public static getInstance(): WaitlistService {
        return this._singleton || (this._singleton = new WaitlistService());
    }

    constructor() {
        super("Waitlist");
    }

    private _waitlist = new ObservableDef<IWaitlistTrack[]>([]);
    public get waitlist() { return this._waitlist; }


    protected onMessageOnChannel(message: MessageE2C): void {
        switch (message.type) {
            case "WaitlistE2C":
                this.receiveWaitlist(message);
                break;
        }
    }

    private receiveWaitlist(message: IWaitlistMessageE2C) {
        let tracks = message.waitlistItems.map((track): IWaitlistTrack => (
            {
                album: track.album,
                artist: track.artist,
                title: track.title,
                trackId: track.trackId,
                waitlistItemId: track.waitlistItemId,
                year: track.year,
                mediaStoreState: track.mediaStoreState,
                isAutomat: track.isAutomat,
                isKaraoke: track.isKaraoke,
                isVideo: track.isVideo,
                isRequest: track.isRequest
            }
        ));
        this._waitlist.emit(tracks);
    }

    public moveWaitlistItemUp(id: string) {
        this.sendOnChannel(new MoveWaitlistItemUpC2E(id));
    }

    public moveWaitlistItemDown(id: string) {
        this.sendOnChannel(new MoveWaitlistItemDownC2E(id));
    }

    public moveWaitlistItemToTop(id: string) {
        this.sendOnChannel(new MoveWaitlistItemToTopC2E(id));
    }

    public removeWaitlistItem(id: string) {
        this.sendOnChannel(new RemoveWaitlistItemC2E(id));
    }


}