import { IDisposable } from "../../utils/disposable/IDisposable";
import { SubscriptionContainer } from "../../utils/eventbase/SubscriptionContainer";
import { ViewPart } from "./ViewPart";


export interface IParamPart {
    children?: (Part | undefined)[] | undefined;
}

export class Part implements IDisposable {
    private _subscriptionContainer: SubscriptionContainer;
    private _parentPart: Part | null = null;
    private _childs: Part[] | undefined;
    private _isDisposed: boolean = false;

    constructor(par: IParamPart) {
        this._subscriptionContainer = new SubscriptionContainer(this);

        //this.addChildren(par.children);

        this._childs = [];

        if (par.children) {
            for (let child of par.children) {
                if (child) {
                    this._childs.push(child);
                    child._parentPart = this;
                }
            }
        }
    }

    public get viewName(): string {
        return this.constructor.name;
    }

    public get parentPart2(): Part | null {
        return this._parentPart;
    }

    public get subscriptionContainer(): SubscriptionContainer {
        return this._subscriptionContainer;
    }

    public addChildren(children: Part[] | undefined): void {
        if (children) {
            for (let child of children) {
                this.addChild(child);
            }
        }
    }

    public addChild<TChild extends Part>(child: TChild): TChild {
        if (!this._childs) {
            this._childs = [];
        }

        this._childs.push(child);
        child._parentPart = this;
        this.onAddNewChildToPart(child);
        this.onAddNewVisibleChildToPart(child);
        return child;
    }

    protected onAddNewChildToPart(child: Part): void {
    }

    // This part is not visible, so try parent of this Part
    protected onAddNewVisibleChildToPart(child: Part): void {
        if (this._parentPart) {
            this._parentPart.onAddNewVisibleChildToPart(child);
        }
    }

    public removeChildren() {
        if (this._childs) {
            for (let c of this._childs) {
                c._parentPart = null;
                c.dispose();
            }
        }
    }

    public removeChild(child: Part): void {
        if (this._childs) {
            this._childs = this._childs.filter((c) => c !== child);
            child._parentPart = null;
            child.dispose();
        }
    }

    public iterateVisibleChilds(elementCallback: (child: ViewPart) => void): void {
        if (this._childs) {
            for (var child of this._childs) {
                child.iterateVisibleChilds(elementCallback);
            }
        }
    }

    public delay(ms: number): Promise<void> {
        return new Promise<void>(resolve => {
            setTimeout(resolve, ms);
        });
    }

    public get isDisposed(): boolean {
        return this._isDisposed;
    }

    public dispose(): void {
        if (this._isDisposed) {
            console.log("Object is already disposed: " + this.viewName);
            return;
        }

        this._isDisposed = true;

        this._subscriptionContainer.dispose();
        this.removeChildren();

        //console.log("Disposing part: " + this.viewName);
        this.onDispose();
    }

    protected onDispose(): void {
    }
}

