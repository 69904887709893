import { TextViewBase, IParamTextViewBase } from "../bases/TextViewBase";

export interface IParamParagraphPart extends IParamTextViewBase {
}

export class ParagraphPart extends TextViewBase {

    constructor(par: IParamParagraphPart) {
        super("P", par);
    }
}