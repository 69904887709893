import { H3TextPart, IParamH3TextPart } from "../../../../lib/ui/parts/text/H3TextPart";
import {ViewPart} from "../../../../lib/ui/parts/ViewPart";
import theme from "../../../../app/style/Theme";
import { Colors } from "../colors/Colors";

export interface IParamTitleText extends IParamH3TextPart {
}

export class TitleText extends H3TextPart {

    constructor(par: IParamTitleText) {
        ViewPart.setDefaultStyle(par, {
            color: Colors.titleTextColor,
            fontSize: theme.fontSizeTitle()
        });

        super(par);
    }
}
