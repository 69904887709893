import { AboutViewModel } from "../../../viewmodels/main/about/AboutViewModel";
import { FlexContainerPart } from "../../../../lib/ui/parts/containers/FlexContainerPart";
import { CardContainer, CardSizeMode, CardBackgroundColor } from "../../../../common/ui/parts/containers/CardContainer";
import { TitleText } from "../../../../common/ui/parts/text/TitleText";
import { NormalText } from "../../../../common/ui/parts/text/NormalText";
import { GridContainer } from "../../../../lib/ui/parts/containers/GridContainer";
import { GridPart } from "../../../../lib/ui/parts/containers/GridPart";
import { H3TextPart } from "../../../../lib/ui/parts/text/H3TextPart";
import { Button } from "../../../../common/ui/parts/buttons/Button";
import texts from "../../../../common/ui/localization/Language";
import { Colors } from "../../../../common/ui/parts/colors/Colors";

export class AboutView extends FlexContainerPart {
    constructor(viewModel: AboutViewModel) {
        super({
            flexAlignItems: "center",
            children: [
                new CardContainer({
                    size: CardSizeMode.Minimal,
                    color: CardBackgroundColor.Normal,
                    children: [
                        new GridPart({
                            gridTemplateRows: "auto auto",
                            gridTemplateColumns: "1fr auto",
                            children: [
                                new H3TextPart({
                                    style: {
                                        gridRow: "1",
                                        gridColumn: "1 / 2",
                                        color: Colors.titleTextColor,
                                    },
                                    textSubscription: viewModel.aboutTitle,
                                }),
                                new NormalText({
                                    style: {
                                        gridRow: "2",
                                        gridColumn: "1",
                                    },
                                    textSubscription: viewModel.versionText,
                                }),
                                new Button({
                                    style: {
                                        gridRow: "2",
                                        gridColumn: "2",
                                    },
                                    text: texts.back,
                                    onclick: ()=>viewModel.backButtonClick()
                                })
                            ]
                        })
                    ]
                })
            ]
        });
    }

}