import { LabelTextPart, IParamLabelTextPart } from "../../../../lib/ui/parts/text/LabelTextPart";
import { StyleConstants } from "../style/StyleConstants";
import theme from "../../../../app/style/Theme";
import { Colors } from "../colors/Colors";


export interface IParamLabelText extends IParamLabelTextPart {

}

export class LabelText extends LabelTextPart {
    constructor(par: IParamLabelText) {
        LabelText.setDefaultStyle(par, {
            color: Colors.primaryTextColor,
            fontSize: theme.fontSizeLabel
        });
        super(par);
    }
}