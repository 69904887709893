import { ViewPart } from "../ViewPart";
import { ViewModel } from "../../viewmodels/common/ViewModel";
import { ObjectType } from "../../../typedi/types/ObjectType";
import { ITransition } from "../../transitions/ITransition";
import {IViewFactory} from "./IViewFactory";


export class ViewFactory<TViewModel extends ViewModel> implements IViewFactory {

    private _viewConstructor: (vm: ViewModel) => ViewPart;
    private _viewModelName: string;


    constructor(viewModelType: ObjectType<TViewModel>, viewConstructor: (vm: TViewModel) => ViewPart, inTrans?: ITransition, outTrans?: ITransition) {
        this._viewConstructor = viewConstructor as ((vm: ViewModel) => ViewPart);
        this._viewModelName = viewModelType.name;
        this.inTrans = inTrans;
        this.outTrans = outTrans;
    }

    public inTrans: ITransition | undefined;
    public outTrans: ITransition | undefined;

    public get contentName(): string {
        return this._viewModelName;
    }

    public createView(vm: ViewModel): ViewPart {
        return this._viewConstructor(vm);
    }
}
