import { TextViewBase, IParamTextViewBase } from "../bases/TextViewBase";
import { ViewPart } from "../ViewPart";

export interface IParamLabelTextPart extends IParamTextViewBase {
    attachId : string;
}

export class LabelTextPart extends TextViewBase {

    constructor(par: IParamLabelTextPart) {
        super("LABEL", par);
        if (par.attachId) {
            this.attachToId(par.attachId);
        }
    }

    public attach(forInput: ViewPart) {
        if (!forInput.id) {
            forInput.id = "LabelAttachId_" + Math.round(Math.random() * 89999 + 10000);
        }
        this.attachToId(forInput.id);
    }

    public attachToId(id: string) {
        this.root.setAttribute("for", id);
    }
}