import { ViewPart, PartSize } from "../ViewPart";
import { IPartStyle } from "../IPartStyle";
import { ContainerBase, IParamContainerBase } from "../bases/ContainerBase";

export interface IParamOverlapContainerPart extends IParamContainerBase {
}

export class OverlapContainerPart extends ContainerBase {

    constructor(par: IParamOverlapContainerPart) {

        if (!par.partSize) {
            par.partSize = PartSize.FullSize;
        }

        ViewPart.setDefaultStyle(par, {
            display: "grid",
            gridTemplateRows: "100%",
            gridTemplateColumns: "100%"
        });

        super(par);
    }

    protected onAddChildToViewPart(child: ViewPart): void {
        child.applyStyle({
            gridRow: "1",
            gridColumn: "1",
            width: "auto",
            height: "auto",
        });
        super.onAddChildToViewPart(child);
    }

}