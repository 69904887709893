import { ObservableDef, IObservable, ObservableOpt } from "./Observable";

export interface IObservableTwoWay<T> extends IObservable<T> {
    changeValueAsync(value: T): Promise<void>;
}


export class ObservableTwoWayDef<T> extends ObservableDef<T> implements IObservableTwoWay<T> {

    private _changedCallbackAsync: undefined | ((value: T) => Promise<void>);

    constructor(initvalue: T, callback?: (value: T) => Promise<void>) {
        super(initvalue);
        if (callback) {
            this.setValueChangedCallback(callback);
        }
    }

    public changeValueAsync(value: T): Promise<void> {

        if (this._changedCallbackAsync) {
            this.silentSet(value);
            return this._changedCallbackAsync(value);
        }
        this.emit(value);
        
        return Promise.resolve();
    }

    public setValueChangedCallback(changedCallbackAsync: (value: T) => Promise<void>): void {
        this._changedCallbackAsync = changedCallbackAsync;
    }
}

export class ObservableTwoWayOpt<T> extends ObservableOpt<T> implements IObservableTwoWay<T> {

    private _changedCallback: undefined | ((value: T) => Promise<void>);

    constructor(callback?: (value: T) => Promise<void>) {
        super();

        if (callback) {
            this.setValueChangedCallback(callback);
        }
    }

    public changeValueAsync(value: T): Promise<void> {

        if (this._changedCallback) {
            return this._changedCallback(value);
        }

        this.emit(value);
        return new Promise((resolve) => { resolve(); });
    }

    public setValueChangedCallback(changedCallback: (value: T) => Promise<void>): void {
        this._changedCallback = changedCallback;
    }
}