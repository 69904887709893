import { ServiceChannelBase } from "../../ServiceChannelBase";
import { IObservable, ObservableOpt } from "../../../../lib/utils/eventbase/Observable";
import { ITracklistHdr } from "../../../models/player/tracklist/ITracklistHdr";
import { MessageE2C } from "../../../messages/MessageE2C";
import { ITracklistHdrsE2C } from "../../../messages/E2C/ITracklistHdrsE2C";
import { SubTracklistsRequestC2E } from "../../../messages/C2E/SubTracklistsRequestC2E";
import { ITracklistHdrDetails } from "../../../models/player/tracklist/ITracklistHdrDetails";
import { ITracklistHdrSubitem } from "../../../models/player/tracklist/ITracklistHdrSubitem";
import { TracklistRequestC2E } from "../../../messages/C2E/TracklistRequestC2E";
import { ITracklistDetails } from "../../../models/player/tracklist/ITracklistDetails";
import { ITracklistDetailsE2C } from "../../../messages/E2C/ITracklistDetailsE2C";
import { AddTracklistToAutomatC2E } from "../../../messages/C2E/AddTracklistToAutomatC2E";
import { AddTracklistTrackToWaitlistC2E } from "../../../messages/C2E/AddTracklistTrackToWaitlistC2E";

export class TracklistsService extends ServiceChannelBase<"Tracklist">{

    private static _singleton: TracklistsService;
    public static getInstance(): TracklistsService {
        return this._singleton || (this._singleton = new TracklistsService());
    }

    private readonly _tracklistHdrs: ObservableOpt<ITracklistHdr[]> = new ObservableOpt();
    public get tracklistHdrs(): IObservable<ITracklistHdr[]> { return this._tracklistHdrs; }

    constructor() {
        super("Tracklist");
    }

    public async getTracklistHdrDetailsAsync(id: string): Promise<ITracklistHdrDetails> {
        let result = await this.sendRequestAsync(
            new SubTracklistsRequestC2E(id),
            (request, response) => (response.type === 'TracklistHdrDetailsE2C' && request.parentTrackListId == response.id)
        );        
        return this.receiveTracklistHdrDetails(result);
    }

    public async getTracklistDetailsAsync(id: string): Promise<ITracklistDetails> {
        let result = await this.sendRequestAsync(
            new TracklistRequestC2E(id),
            (request, response) => (response.type === 'TracklistDetailsE2C' && request.tracklistId == response.id)
        );
        return this.receiveTracklistDetails(result);
    }

    public addTrackToWaitlist(id:string, sourcelist:string) {
        this.sendOnChannel(new AddTracklistTrackToWaitlistC2E(id, sourcelist));
    }

    private receiveTracklistHdrDetails(message: MessageE2C): ITracklistHdrDetails {
        if (message.type === 'TracklistHdrDetailsE2C') {
            return {
                id: message.id,
                name: message.name,
                sublists: message.items.map((t) => ({
                    id: t.id,
                    name: t.name
                } as ITracklistHdrSubitem))
            } as ITracklistHdrDetails;
        }
        throw new Error("Message is not a 'TracklistHdrDetailsE2C' message")
    }

    private receiveTracklistDetails(message: MessageE2C): ITracklistDetails {
        if (message.type == 'TracklistDetailsE2C') {
            return message;
        }
        throw new Error("Message is not a 'TracklistDetailsE2C' message")
    }

    public addList(tracklistId: string, removeOthers:boolean) {
        this.sendOnChannel(new AddTracklistToAutomatC2E(tracklistId, removeOthers));
    }

    protected onMessageOnChannel(message: MessageE2C): void {
        switch (message.type) {
            case "TracklistHdrsE2C":
                this.receiveTrackList(message);
                break;
        }
    }

    private receiveTrackList(message: ITracklistHdrsE2C) {
        this._tracklistHdrs.emit(message.tracklistHdrs.map(t => {
            return {
                id: t.id,
                name: t.name
            } as ITracklistHdr;
        }));
    }

}