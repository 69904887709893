export class ReasonableError<T extends unknown> extends Error {
    private _reason: T;

    constructor(reason: T, message?: string) {
        if (!message) {
            message = new String(reason).toString();
        }
        super(message);
        this._reason = reason;
    }

    public get reason(): T { return this._reason };
}