import { ButtonPart, IParamButtonPart } from "../../../../lib/ui/parts/button/ButtonPart";
import { ImagePart } from "../../../../lib/ui/parts/image/ImagePart";
import { PartSize, ViewPart } from "../../../../lib/ui/parts/ViewPart";
import { IPartStyle } from "../../../../lib/ui/parts/IPartStyle";
import { StyleConstants } from "../style/StyleConstants";
import { IObservable } from "../../../../lib/utils/eventbase/Observable";
import { IconButton, IParamIconButton } from "./IconButton";


export class PlayerIconButton extends IconButton {
    constructor(par: IParamIconButton) {
        ViewPart.setDefaultStyle(par, {
            backgroundColor: "transparent",
        });

        super(par);

    }    
}

