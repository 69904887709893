import { CardContainer, CardBackgroundColor, CardSizeMode } from "../../../../../common/ui/parts/containers/CardContainer";
import { AutomatControlsViewModel } from "./AutomatControlsViewModel";
import { GridPart } from "../../../../../lib/ui/parts/containers/GridPart";
import { ItemsFactoryPart } from "../../../../../lib/ui/parts/nonvisible/ItemsFactoryPart";
import { ProgramStatusView } from "./program_status/ProgramStatusView";
import theme from "../../../../../app/style/Theme";
import { GridContainer } from "../../../../../lib/index";
import { TitleText } from "../../../../../common/ui/parts/text/TitleText";
import texts from "../../../../../common/ui/localization/Language";

export class AutomatControlsView extends GridContainer {

    constructor(viewModel: AutomatControlsViewModel) {
        super({
            visibleSubscription: viewModel.obsAutomatStatus,
            children: [
                new GridPart({
                    children: [
                        new TitleText({
                            style: {
                                textAlign: "center",

                            },
                            text: texts.automatHeader
                        }),
                        new ItemsFactoryPart({
                            itemsSourceSubscription: viewModel.obsAutomatStatusItems,
                            itemViewFactory: (vm) => new ProgramStatusView(vm),
                        })
                    ]
                })
            ]
        });
    }
}