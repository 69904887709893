import { ViewPart } from "../../../../lib/ui/parts/ViewPart";
import { IParamInputTextPart, InputTextPart } from "../../../../lib/ui/parts/input/InputTextPart";
import { StyleConstants } from "../style/StyleConstants";
import theme from "../../../../app/style/Theme";
import { Colors } from "../colors/Colors";

export interface IParamInputText extends IParamInputTextPart {
}


export class TextInput extends InputTextPart {

    private hasFocus: boolean;

    constructor(par: IParamInputText) {

        ViewPart.setDefaultStyle(par, {
            border: "none",
            //borderBottom: "solid rgb(200,200,200) 2px",
            padding: theme.getSpacePx(12),
            borderRadius: theme.getSpacePx(100),
            display: "block",
            outline: "none",
            fontSize: theme.fontSizeInput,
            transition: StyleConstants.transition,
        });

        super(par);
        this.hasFocus = false;
        this.root.addEventListener("focus", () => {
            this.hasFocus = true;
            this.setColors();
        });
        this.root.addEventListener("blur", () => {
            this.hasFocus = false;
            this.setColors();
        });
        this.setColors();
    }

    public enable(): void {
        super.enable();
        this.setColors();
    }

    public disable(): void {
        super.disable();
        this.setColors();
    }

    private setColors() {
        if (this.inputElement.disabled) {
            this.applyStyle({
                background: Colors.inputDisabledBackgroundColor,
                color: Colors.inputDisabledTextColor,
            });
        } else {
            if (this.hasFocus) {
                this.applyStyle({
                    background: Colors.inputFocusBackgroundColor,
                    color: Colors.inputTextColor,
                });
            } else {
                this.applyStyle({
                    background: Colors.inputBackgroundColor,
                    color: Colors.inputTextColor,
                });
            }
        }
    }
}