import { NavigationManager } from "../../../lib/ui/navigation/NavigationManager";
import { ViewModel, ObservableOpt, IObservable } from "../../../lib/index";
import { IEngineDescription, EngineSelectionService } from "../../../server_com/services/login/EngineSelectionService";
import { NavigationPaths } from "../../navigation/NavigationPaths";
import { ListItemViewModel } from "../../../lib/ui/viewmodels/common/ListItemViewModel";

export class EngineListItemViewModel extends ListItemViewModel{
    
    private _obsLicenseName: ObservableOpt<string> = new ObservableOpt();
    public get obsEngineName(): IObservable<string> { return this._obsLicenseName; }

    private _obsCompanyName: ObservableOpt<string> = new ObservableOpt();
    public get obsCompanyName(): IObservable<string> { return this._obsCompanyName; }

    private _obsRole: ObservableOpt<string> = new ObservableOpt();
    public get obsRole(): IObservable<string> { return this._obsRole; }

    private _engine: IEngineDescription;
    public get engine(): IEngineDescription{
        return this._engine;
    }

    constructor(parent: ViewModel, engineDecs: IEngineDescription) {
        super(parent);
        this._engine = engineDecs;
        this._obsLicenseName.emit(engineDecs.licenseDescription);
        this._obsCompanyName.emit(engineDecs.companyName);

        if (engineDecs.hasRoleController)
        {
            this._obsRole.emit("Controller");
        }
        else if (engineDecs.hasRoleJukebox)
        {
            this._obsRole.emit("Jukebox");
        }
        else
        {
            this._obsRole.emit("No Role!");
        }
    }

    public onClick(): void {
        //console.log("Engine click", this._engine);

        //let engineSelectionService = EngineSelectionService.getInstance();
        //if (this._engine) {
        //    engineSelectionService.setLastusedEngine(this._engine);
        //    let mainVM = NavigationManager.getInstance().mainViewModel
        //    if (mainVM) {
        //        mainVM.navigateTo(NavigationPaths.getEnginePath(this._engine));
        //    } else {
        //        console.log("No main vm");
        //    }
        //}
        this.select();
    }

    protected onSelect(): void {

    }
    protected onDeselect(): void {

    }
}