import { ChannelType } from "../messages/ChannelType";
import { MessageE2C } from "../messages/MessageE2C";
import { MessageC2E } from "../messages/MessageC2E";
import { IFromEngineMessageH2C } from "../messages/H2C/IFromEngineMessageH2C";
import { EngineConnectionService, EngineConnectionState } from "./engine/EngineConnectionService";
import { SubscriptionContainer } from "../../lib/utils/eventbase/SubscriptionContainer";
import { ObservableDef } from "../../lib/utils/eventbase/Observable";
import { IMessageToControllerE2DC } from "../messages/E2DC/IMessageToControllerE2DC";

export abstract class ServiceChannelBase<T extends ChannelType>{

    protected engineConnectionService: EngineConnectionService;
    protected subscriptionContainer: SubscriptionContainer = new SubscriptionContainer(this);
    protected constructor(private readonly _channelName: T) {
        this._dependedObjects = new Array<any>();
        this.engineConnectionService = EngineConnectionService.getInstance();
        this.engineConnectionService.obsEngineConnectionsState.subscribe(this.subscriptionContainer, (state) => (this.onConnectionChange(state)));
        this.engineConnectionService.receivedChannelOpenMessage.subscribe(this.subscriptionContainer, (channelType) => (this.channelOpened(channelType)));
        this.engineConnectionService.receivedFromEngineEvent.subscribe(this.subscriptionContainer, (message) => (this.receiveMessage(message)));
    }

    private readonly _enableLogging: boolean = false;
    private _dependedObjects: any[];
    private _closeTimeOut: number = -1;
    private _channelState: ObservableDef<ServiceChannelState> = new ObservableDef<ServiceChannelState>(ServiceChannelState.Deactivated);
    private _reopenOnConnected: boolean = false;
    protected getchannelState() { return this._channelState; }

    private _requestsHandlers: RequestHandler[] = [];
    protected sendRequestAsync<RequestT extends MessageC2E>(message: RequestT, validator: (requestmessage: RequestT, responsemessage: MessageE2C) => boolean): Promise<MessageE2C> {
        return new Promise<MessageE2C>((resolve, reject) => {
            this.sendOnChannel(message);
            this._requestsHandlers.push({
                validate: (res: MessageE2C) => {
                    let awnser = validator(message, res);
                    if (awnser) {
                        resolve(res);
                        return true;
                    }
                    return false;
                },
            });
        });
    }

    private channelOpened(channelType: ChannelType) {
        if (channelType == this._channelName) {
            this._channelState.emit(ServiceChannelState.Activated);
        }
    }

    private checkForDeactivate() {
        if (this._dependedObjects.length === 0 && this._channelState.value !== ServiceChannelState.Deactivated) {
            this._reopenOnConnected = false;
            this.deactivateChannel();
        }
    }

    private deactivateChannel() {
        this.engineConnectionService.closeChannel(this._channelName);
        this._channelState.emit(ServiceChannelState.Deactivated);
    }

    private activateChannel() {
        if (this._channelState.value == ServiceChannelState.Deactivated) {
            this.engineConnectionService.openChannel(this._channelName);
            this._channelState.emit(ServiceChannelState.Activating);
        }
    }

    private receiveMessage(message: IFromEngineMessageH2C | IMessageToControllerE2DC) {
        if (message.channelId === this._channelName) {
            this.checkMessageResponse(message);
            this.onMessageOnChannel(message.payload);
        }
    }

    private checkMessageResponse(message: IFromEngineMessageH2C | IMessageToControllerE2DC) {
        for (let i = 0; i < this._requestsHandlers.length; i++) {
            let response = this._requestsHandlers[i].validate(message.payload);
            if (response) {
                this._requestsHandlers.splice(i, 1);
            }
        }
    }

    private onConnectionChange(state: EngineConnectionState) {
        switch (state) {
            case EngineConnectionState.Connected:
                if (this._reopenOnConnected) {
                    this.activateChannel();
                }
                break;
            default:
                if (this._channelState.value != ServiceChannelState.Deactivated) {
                    this._reopenOnConnected = true;
                    this._channelState.emit(ServiceChannelState.Deactivated);
                }
                break;
        }
    }

    protected sendOnChannel(message: MessageC2E) {
        if (this._channelState.value != ServiceChannelState.Activated) {
            console.warn(`Channel ${this._channelName} is not activated. Message will be ignored`);
        }
        this.engineConnectionService.sendToEngine(message, this._channelName);
    }

    protected abstract onMessageOnChannel(message: MessageE2C): void;

    public activate(byWho: any): void {
        this.log("activate: " + this._channelName);
        if (this._dependedObjects.findIndex((t) => (t == byWho)) == -1) {
            this._dependedObjects.push(byWho);
            this.activateChannel();
        }
        if (this._closeTimeOut != -1) {
            clearTimeout(this._closeTimeOut);
            this._closeTimeOut = -1;
        }
    }

    public deactivate(byWho: any): void {
        this.log("deactivate: " + this._channelName);
        this._dependedObjects = this._dependedObjects.filter((d) => d !== byWho);
            if (this._closeTimeOut != -1) {
                clearTimeout(this._closeTimeOut);
                this._closeTimeOut = -1;
            }
            if (this._dependedObjects.length == 0) {
                setTimeout(() => (this.checkForDeactivate()), 3000);
            }
        }

    private log(msg: any) {
            if (this._enableLogging) {
                console.log(msg);
            }
        }
    }

export enum ServiceChannelState {
    Deactivated,
    Activating,
    Activated
}
interface RequestHandler {
    validate: (res: MessageE2C) => boolean;
}