import { ImagePart } from "../../../../lib/ui/parts/image/ImagePart";
import { IParamImageBase } from "../../../../lib/ui/parts/bases/ImageBase";
import { IParamViewPart } from "../../../../lib/ui/parts/ViewPart";

export interface IParamBarLoadingSpinner extends IParamViewPart {
}

export class BarLoadingSpinner extends ImagePart{

    constructor(par: IParamBarLoadingSpinner) {
        let imagePar = par as IParamImageBase;
        imagePar.source = "res/img/loading_spinner.gif";
        super(imagePar);
    }
}