import {NavigationPath} from "../../lib/ui/navigation/NavigationPath";

export class NavigationPaths {
    public static getEnginePath(engineIndex: number): NavigationPath {
        return new NavigationPath(["Control", engineIndex.toString(), "1"]);
    }

    public static getPlayersMenuPath(): NavigationPath {
        return new NavigationPath(["Menu", "Players"]);
    }
    public static getSettingsMenuPath(): NavigationPath {
        return new NavigationPath(["Menu", "Settings"]);
    }
    public static getAboutMenuPath(): NavigationPath {
        return new NavigationPath(["Menu", "About"]);
    }


}