import { SwitchableContainerPart } from "./SwitchableContainerPart";
import { ViewModel } from "../../viewmodels/common/ViewModel";
import { OverlapContainerPart } from "../containers/OverlapContainerPart";
import { IPartStyle } from "../IPartStyle";
import { IObservable } from "../../../utils/eventbase/Observable";
import { IViewFactory } from "./IViewFactory";
import { PartSize } from "../ViewPart";
import {ViewStatusService} from "../../components/ViewStatusService";

export interface IParamContentSwitchPart {
    viewModelSubscription : IObservable<ViewModel | null>,
    viewFactories: IViewFactory[],
    partSize?: PartSize,
    style?: IPartStyle,
    visible?: boolean,
    visibleSubscribtion?: IObservable<boolean>,
}

export class ContentSwitchPart extends OverlapContainerPart {

    private _content: SwitchableContainerPart[] = [];
    private _activeContent: SwitchableContainerPart | null = null;
    private _stagedViewToShow = "";
    private _stagedViewModel: ViewModel | null = null;
    private _stageTimeOut = -1;

    constructor(par: IParamContentSwitchPart) {
        super(par);

        for (var viewFactory of par.viewFactories) {
            this.addViewFactory(viewFactory);
        }

        par.viewModelSubscription.subscribeInitial(this.subscriptionContainer, vm => {
            this.showContentByViewModel(vm);
        });
      
    }

    public addViewFactory(vf: IViewFactory) {
        const container = new SwitchableContainerPart(vf);
        this.addChild(container);
        this._content.push(container);
    }

    public showContentByViewModel(viewModel: ViewModel | undefined | null) {
        if (viewModel) {
            this.showContent(viewModel.name, viewModel);
        } else {
            this.showContent("empty", null);
        }
    }

    private stageSwitch(name: string, viewModel: ViewModel | null) {
        ViewStatusService.markViewAsBusy(this);
        window.clearTimeout(this._stageTimeOut);
        this._stageTimeOut = window.setTimeout(() => (this.showStagedView()), 300);
        this._stagedViewToShow = name;
        this._stagedViewModel = viewModel;
    }

    public showContent(name: string, viewModel: ViewModel | null): void {

        if (this._stageTimeOut !== -1) {
            this.stageSwitch(name, viewModel);
            return;
        }
        if (this._activeContent != null && name === this._activeContent.contentName) {
            return;
        }
        this._stageTimeOut = window.setTimeout(() => (this.showStagedView()), 300);

        if (viewModel) {
            if (this._activeContent != null) {
                this._activeContent.applyStyle({ zIndex: "0" });
                this._activeContent.hideContent();

                this._activeContent = this.findContent(name);
                if (this._activeContent) {
                    this._activeContent.applyStyle({
                        zIndex: "1",
                        opacity: "1"
                    });
                    this._activeContent.show();
                    this._activeContent.showContent(viewModel);
                } else {
                    console.warn(`No content ${name}`);
                    this.stageSwitch(name, viewModel);
                }

            } else {
                this._activeContent = this.findContent(name);
                let content = this._activeContent;
                if (content) {
                    content.showContent(viewModel);
                } else {
                    console.warn(`No content${name}`);
                    this.stageSwitch(name, viewModel);
                }
            }
        }
    }

    private findContent(name: string): SwitchableContainerPart | null {
        let x = this._content.find((c) => c.contentName === name);
        return x !== undefined ? x : null;
    }

    private showStagedView() {

        this._stageTimeOut = -1;
        if (this._stagedViewToShow) {
            this.showContent(this._stagedViewToShow, this._stagedViewModel);
            this._stagedViewToShow = "";
            this._stagedViewModel = null;
            ViewStatusService.unMarkViewAsBusy(this);
        }
    }

    public get activeViewName(): string {
        if (this._stagedViewToShow) {
            return this._stagedViewToShow;
        }
        if (this._activeContent != null) {
            return this._activeContent.contentName;
        }
        return "Empty";
    }

}