import { GridContainer } from "../../../../lib/ui/parts/containers/GridContainer";
import { SearchComponentViewModel } from "./SearchComponentViewModel";
import { CardContainer, CardSizeMode, CardBackgroundColor } from "../../../../common/ui/parts/containers/CardContainer";
import { NormalText } from "../../../../common/ui/parts/text/NormalText";
import { TextInput } from "../../../../common/ui/parts/input/TextInput";
import { IconButton } from "../../../../common/ui/parts/buttons/IconButton";
import { ScrollContainerPart } from "../../../../lib/ui/parts/containers/ScrollContainerPart";
import { ItemsFactoryPart } from "../../../../lib/ui/parts/nonvisible/ItemsFactoryPart";
import { SearchResultListItemView } from "./SearchResultListItemView";
import texts from "../../../../common/ui/localization/Language";
import theme from "../../../../app/style/Theme";
import { TitleText } from "../../../../common/ui/parts/text/TitleText";

export class SearchComponentView extends GridContainer {

    constructor(viewmodel: SearchComponentViewModel) {
        super({
            style: {
                gridTemplateRows: "min-content minmax(0, 1fr)"
            },
            children: [

                new GridContainer({
                    style: {
                        gridTemplateRows: "auto auto",
                        gridTemplateColumns: "1fr auto",
                        alignItems: "center",
                        padding: theme.getSpacePx(5, 10, 5, 10),

                    },
                    children: [
                        new TitleText({
                            style: {
                                gridRow: "1",
                                gridColumn: "1 / span 2",
                                textAlign: "center"
                            },
                            text: texts.search
                        }),
                        new TextInput({
                            style: {
                                gridRow: "2",
                                gridColumn: "1 / span 2",
                                paddingRight: theme.getSpacePx(30),
                                maxWidth: "100%",
                                textOverflow: "ellipsis",
                                wordWrap: "nowrap"
                            },
                            textSubscriptionTwoWay: viewmodel.obsSearchText,
                            placeholder: texts.seachPlaceholder
                        }),
                        new IconButton({
                            style: {
                                gridRow: "2",
                                gridColumn: "2",
                                margin: theme.getSpacePx(0, 10)
                            },
                            icon: "search",
                            onclickAsync: () => viewmodel.searchAsync()
                        })
                    ]
                }),
                new ScrollContainerPart({
                    style: {
                        padding: theme.getSpacePx(0, 5, 0, 5)
                    },
                    children: [
                        new ItemsFactoryPart({
                            itemsSourceSubscription: viewmodel.obsSearchResults,
                            itemViewFactory: (vm, pos) => new SearchResultListItemView(vm, pos)
                        })
                    ]
                })
            ]
        });
    }
}