import { InputBase, IParamInputBase } from "../bases/InputBase";

export interface IParamInputFilePart extends IParamInputBase {
    multiple?: boolean;
    onChangedAsync?: (fileList: FileList | null) => Promise<void>;
}

export class InputFilePart extends InputBase {
    private _changedCallback: ((fileList: FileList | null) => Promise<void>) | undefined;

    constructor(par: IParamInputFilePart) {

        super("file", par);

        this.inputElement.onchange = () => this.change();

        if (par.multiple) {
            this.inputElement.multiple = true;
        }

        if (par.onChangedAsync) {
            this._changedCallback = par.onChangedAsync;
        }
    }

    public dispose(): void {
        super.dispose();
    }

    private async change(): Promise<void> {
        if (this._changedCallback) {
            this._changedCallback(this.inputElement.files);
        }
    }
}