import { IObservable } from "../../../utils/eventbase/Observable";
import { IPartStyle } from "../IPartStyle";
import { ViewPart } from "../ViewPart";
import { SubscriptionContainer } from "../../../utils/eventbase/SubscriptionContainer";
import { Binding } from "./Binding";

export interface IParamStyleBinding<TValue> {
    subscription: IObservable<TValue>,
    styleFactory: (value: TValue) => IPartStyle;
}

export class StyleBinding<TValue> extends Binding {

    constructor(private _par: IParamStyleBinding<TValue>) {
        super();
    }

    public initPart(viewPart: ViewPart, subscriptionContainer: SubscriptionContainer) {
        this._par.subscription.subscribeInitial(subscriptionContainer, value => {
            let style = this._par.styleFactory(value);
            viewPart.applyStyle(style);
        });
    }
}