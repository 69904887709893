import { EngineConnectionService, EngineConnectionState } from "../../../server_com/services/engine/EngineConnectionService";
import { HubConnectionService } from "../../../server_com/services/hub/HubConnectionService";
import { EngineConnectionViewModel } from "../EngineConnectionViewModel";
import { ViewModel } from "../../../lib/ui/viewmodels/common/ViewModel";
import { ObservableDef, IObservable, ObservableOpt } from "../../../lib/utils/eventbase/Observable";
import { ReasonableError } from "../../../lib/utils/ReasonableError";
import { ConnectionErrorReason } from "../../../server_com/services/IConnectionService";
import texts from "../../../common/ui/localization/Language";
import { EngineSelectComponentViewModel } from "../../engine_list/EngineSelectComponentViewModel";

export class EngineConnectionMenuViewModel extends ViewModel {

    private _engineConnectionService: EngineConnectionService;
    private _hubConnectionService: HubConnectionService;

    private _obsTitleText: ObservableOpt<string> = new ObservableOpt<string>();
    private _obsStatusText: ObservableOpt<string> = new ObservableOpt<string>();
    private _obsErrorText: ObservableOpt<string> = new ObservableOpt<string>();
    private _obsLoaderVisibility: ObservableOpt<boolean> = new ObservableOpt<boolean>();
    private _obsRetryButtonVisiblity: ObservableOpt<boolean> = new ObservableOpt<boolean>();


    public get obsErrorText(): IObservable<string> { return this._obsErrorText; }
    public get obsTitleText(): IObservable<string> { return this._obsTitleText; }
    public get obsStatusText(): IObservable<string> { return this._obsStatusText; }
    public get obsLoaderVisibility(): IObservable<boolean> { return this._obsLoaderVisibility; }
    public get obsRetryButtonVisiblity(): IObservable<boolean> { return this._obsRetryButtonVisiblity; }

    constructor(private _parent: EngineConnectionViewModel) {
        super(_parent);
        this._engineConnectionService = EngineConnectionService.getInstance();
        this._hubConnectionService = HubConnectionService.getInstance();

        this._engineConnectionService.obsEngineConnectionsState.subscribeInitial(this.subscriptionContainer, (state) => (this.engineConnectionChange(state)));
    }

    public goToMenu() {
        EngineSelectComponentViewModel.showSelectionAsync(this);
    }

    private engineConnectionChange(state: EngineConnectionState) {
        switch (state) {
            case EngineConnectionState.Connected:
                this._obsLoaderVisibility.emit(false);
                this._obsErrorText.emit("");
                this._obsTitleText.emit(texts.connected);
                this._obsStatusText.emit("");
                this._obsRetryButtonVisiblity.emit(false);
                this._obsErrorText.emit("");
                break;
            case EngineConnectionState.PollingLocalConnection:
                this._obsLoaderVisibility.emit(true);
                this._obsTitleText.emit(texts.connecting);
                this._obsStatusText.emit(texts.pollingLocalConnection);
                this._obsRetryButtonVisiblity.emit(false);
                this._obsErrorText.emit("");
                break;
            case EngineConnectionState.WaitingForEngine:
                this._obsLoaderVisibility.emit(true);
                this._obsTitleText.emit(texts.connecting);
                this._obsStatusText.emit(texts.connectingToEngine);
                this._obsRetryButtonVisiblity.emit(false);
                this._obsErrorText.emit("");
                break;
            case EngineConnectionState.NoEngine:
                this._obsLoaderVisibility.emit(false);
                this._obsTitleText.emit(texts.notConnected);
                this._obsStatusText.emit(texts.noEngineMessage);
                this._obsRetryButtonVisiblity.emit(false);
                break;
            case EngineConnectionState.Connecting:
                this._obsLoaderVisibility.emit(true);
                this._obsTitleText.emit(texts.connecting);
                this._obsStatusText.emit(texts.connectingToEngine);
                this._obsRetryButtonVisiblity.emit(false);
                this._obsErrorText.emit("");
                break;
            case EngineConnectionState.Disconnected:
                this._obsTitleText.emit(texts.notConnected);
                this._obsStatusText.emit(texts.tryConnectionManual);
                this._obsRetryButtonVisiblity.emit(true);
                break;
            case EngineConnectionState.NoLogin:
                this._obsTitleText.emit(texts.notConnected);
                this._obsStatusText.emit(texts.tryConnectionManual);
                this._obsRetryButtonVisiblity.emit(false);
                this.showEngineListAsync();
                break;
            default:
        }
    }

    public retryConnection() {
        this._parent.retryConnection();
    }

    private async showEngineListAsync() {
        await EngineSelectComponentViewModel.showSelectionAsync(this);
    }

}

export enum EngineConnectionProgress {
    NotConnected,
    PollingLocalConnection,
    ConnectingToEngine,
    NoEngine,
    Error,
    Connected//useless?
}