export class LocalStorageManager {

    private static singleton: LocalStorageManager;

    public static getInstance(): LocalStorageManager {
        return this.singleton || (this.singleton = new LocalStorageManager());
    }

    public getSizeInKb(): number {
        return Math.ceil(JSON.stringify(localStorage).length / 1024);
    }

    public checkReset() {
        if (localStorage.getItem("reset") == "true") {
            localStorage.clear();
        }
    }

    public clearData() {
        localStorage.setItem("reset", "true");
        location.reload();
    }

}