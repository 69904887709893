import theme from "../../../app/style/Theme";
import texts from "../../../common/ui/localization/Language";
import { Button } from "../../../common/ui/parts/buttons/Button";
import { Colors } from "../../../common/ui/parts/colors/Colors";
import { TextInput } from "../../../common/ui/parts/input/TextInput";
import { ErrorText } from "../../../common/ui/parts/text/ErrorText";
import { NormalText } from "../../../common/ui/parts/text/NormalText";
import { TitleText } from "../../../common/ui/parts/text/TitleText";
import { GridContainer } from "../../../lib/index";
import { ConnectCodeDialogViewModel } from "./ConnectCodeDialogViewModel";

export class ConnectCodeDialogView extends GridContainer {

    constructor(viewModel: ConnectCodeDialogViewModel) {
        super({
            style: {
                padding: theme.getSpacePx(10),
                gridTemplateRows: "auto",
                gridTemplateColumns: "auto",
                justifyContent: "center",
                alignContent: "center",
            },
            children: [
                new GridContainer({
                    style: {
                        justifyContent: "center",
                        alignContent: "center",
                        gridRowGap: "10px",
                        padding: "10px",
                        borderRadius: "10px",
                        background: Colors.cardLightBackgroundColor
                    },
                    children: [
                        new TitleText({
                            style: {
                                textAlign: "center"
                            },
                            text: texts.connectEngineTitle
                        }),
                        new NormalText({
                            text: texts.connectEngineHelperText,
                        }),
                        new ErrorText({
                            textSubscription: viewModel.obsErrorMessage
                        }), 
                        new TextInput({
                            textSubscriptionTwoWay: viewModel.obsConnectCode,
                            placeholder: "Koppelcode"
                        }),
                        new GridContainer({
                            style: {
                                gridTemplateColumns: "0.5fr 0.5fr",
                                columnGap: "5px",
                            },
                            children: [
                                new Button({
                                    onclick: () => viewModel.cancel(),
                                    text: texts.cancel,
                                }),
                                new Button({
                                    onclick: () => viewModel.submitAsync(),
                                    text: texts.add,
                                    enabledSubscription: viewModel.obsCanSubmit
                                }),
                            ]
                        })
                    ]
                }),
                
            ]
        });
    }

}