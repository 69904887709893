import { GridContainer, ContainerPart } from "../../lib/index";
import { EngineComponentViewModel } from "./EngineComponentViewModel";
import { EngineConnectionView } from "./EngineConnectionView";

export class EngineComponentView extends ContainerPart {
    constructor(viewmodel: EngineComponentViewModel) {
        super({
            children: [
                new EngineConnectionView(viewmodel.engineConnectionViewModel)
            ]
        });
    }
}