import { ViewModel } from "../../viewmodels/common/ViewModel";
import { OverlapContainerPart, IParamOverlapContainerPart } from "../containers/OverlapContainerPart";
import { ViewPart } from "../ViewPart";
import { IViewFactory } from "../contentmanagers/IViewFactory";
import { ComponentViewModel } from "../../viewmodels/components/ComponentViewModel";
import { IViewFactoryDetector } from "../../components/IViewFactoryDetector";
import { IComponentContainer } from "../../components/IComponentContainer";
import { ITransition } from "../../transitions/ITransition";
import { ShowTransition } from "../../transitions/ShowTransition";
import { HideTransition } from "../../transitions/HideTransition";
import { ViewStatusService } from "../../components/ViewStatusService";

export interface IParamComponentContainerPart extends IParamOverlapContainerPart {
    componentContainer: IComponentContainer,
    viewFactories: IViewFactory[],

    transitionActivateFirst?: ITransition;
    transitionDeactivateLast?: ITransition;

    transitionDeactiveteNew?: ITransition;
    transitionActivateNew?: ITransition;

    transitionDeactiveteExisting?: ITransition;
    transitionActivateExisting?: ITransition;
}

export interface IComponentContainerPartTransitions {
    activateFirst: ITransition;
    deactivateLast: ITransition;

    deactiveteNew: ITransition;
    activateNew: ITransition;

    deactiveteExisting: ITransition;
    activateExisting: ITransition;
}

export class ComponentContainerPart extends OverlapContainerPart implements IViewFactoryDetector {

    private _componentContainer: IComponentContainer;
    private _viewFactories: IViewFactory[];
    private _componentViews: ComponentView[] = [];
    private _requestedViews: ComponentView[] = [];

    private _isUpdating: boolean = false;
    private _viewChanges: boolean = false;
    private _transitions: IComponentContainerPartTransitions;
    private _requestUpdateHandle = -1;

    constructor(par: IParamComponentContainerPart) {
        super(par);
        this._transitions = {
            activateExisting: par.transitionActivateExisting || new ShowTransition(),
            activateFirst: par.transitionActivateFirst || new ShowTransition(),
            activateNew: par.transitionActivateNew || new ShowTransition(),
            deactivateLast: par.transitionDeactivateLast || new HideTransition(),
            deactiveteExisting: par.transitionDeactiveteExisting || new HideTransition(),
            deactiveteNew: par.transitionDeactiveteNew || new HideTransition(),
        };
        this._viewFactories = par.viewFactories;
        this._componentContainer = par.componentContainer;
        this._componentContainer.obsViewModels.subscribeInitial(this.subscriptionContainer, (vms) => this.viewModelsChanged(vms));
        this._componentContainer.addViewDetector(this);
    }

    public onDispose(): void {
        this._componentContainer.removeViewDetector(this);
        super.onDispose();
    }

    private viewModelsChanged(viewModels: ComponentViewModel[]): void {
        let index = 0;
        let views = this._requestedViews.map(x => x);
        for (let viewModel of viewModels) {

            let componentView: ComponentView | null = null;
            if (index < views.length) {
                componentView = views[index];
                if (componentView.viewModel !== viewModel) {
                    componentView = null;
                    while (views.length > index) {
                        views.pop();
                    }
                }
            }

            if (!componentView) {
                componentView = this.createView(viewModel);
                views.push(componentView);
            }
            index = index + 1;
        }

        while (views.length > index) {
            views.pop();
        }
        this._requestedViews = views;
        this.requestUpdate();
    }

    private async requestUpdate() {
        if (this._isUpdating) {
            this._viewChanges = true;
            return;
        }
        this._isUpdating = true;

        await this.delay(10);
        this.update();
    }

    private async update() {
        ViewStatusService.markViewAsBusy(this);

        try {
            let views = this._requestedViews.map(x => x);
            let index = 0;
            let visibleView: ComponentView | undefined;
            let lastView: ComponentView | undefined;
            let lastItemAction: ("none" | "remove" | "stack") = "none";
            if (this._componentViews.length > 0) {
                lastView = this._componentViews[this._componentViews.length - 1];
            }

            //remove old items
            for (let componentView of this._componentViews) {
                if (componentView !== views[index]) {
                    lastItemAction = "remove";
                    this._componentViews.pop(); //remove the last
                    while (this._componentViews.length > index) {
                        let cv = this._componentViews.pop();
                        if (cv) {
                            cv.remove();
                        }
                    }
                    break;
                }
                index++;
            }

            //animate outgoing
            let transOutPromise: Promise<ComponentView> | undefined;
            if (lastItemAction === "remove" && lastView) {
                if (this._componentViews.length === 0) {
                    transOutPromise = lastView.playTransition(this._transitions.deactivateLast);
                } else {
                    transOutPromise = lastView.playTransition(this._transitions.deactiveteExisting);
                }
            }

            //add new items
            for (let i = index; i < views.length; i++) {
                visibleView = views[i];
                visibleView.add();
                this._componentViews.push(visibleView);
                lastItemAction = lastItemAction === "remove" ? "remove" : "stack";
            }
            if (lastItemAction === "stack" && lastView) {
                transOutPromise = lastView.playTransition(this._transitions.deactiveteNew);
            }

            //set visibility for the last one
            for (let i = 0; i < this._componentViews.length; i++) {
                if (i === this._componentViews.length - 1 ||
                    (lastItemAction !== "none" && lastView === this._componentViews[i])) {
                    this._componentViews[i].setVisibility(true);
                } else {
                    this._componentViews[i].setVisibility(false);
                }
            }

            //transition in
            let transInPromise: Promise<ComponentView> | undefined = undefined;
            let topView = this._componentViews.length > 0
                ? this._componentViews[this._componentViews.length - 1]
                : undefined;
            if (visibleView) {
                visibleView.add();
                if (this._componentViews.length === 1) {
                    transInPromise = visibleView.playTransition(this._transitions.activateFirst);
                } else {
                    transInPromise = visibleView.playTransition(this._transitions.activateNew);
                }
            } else if (topView && lastView !== topView) {
                transInPromise = topView.playTransition(this._transitions.activateExisting);
            }

            //wait for the promises to end
            if (transOutPromise) {
                let transOutItem = await transOutPromise;
                if (lastItemAction === "remove") {
                    transOutItem.remove();
                }
                if (lastItemAction === "stack") {
                    transOutItem.setVisibility(false);
                }
            }

            if (transInPromise) {
                await transInPromise;
            }

            this._isUpdating = false;

        } finally {
            ViewStatusService.unMarkViewAsBusy(this);
        }

        if (this._viewChanges) {
            this._viewChanges = false;
            this.update();
        }
    }

    createView(viewModel: ComponentViewModel): ComponentView {
        for (var viewFactory of this._viewFactories) {
            if (viewFactory.contentName === viewModel.name) {
                return new ComponentView(this, viewModel, viewFactory.createView(viewModel));
            }
        }
        throw "View not found: " + viewModel.name;
    }

    public viewDoesExistsForViewModel(viewModel: ViewModel): boolean {
        for (var viewFactory of this._viewFactories) {
            if (viewFactory.contentName === viewModel.name) {
                return true;
            }
        }
        return false;
    }
}

class ComponentView {
    //TODO: transitions
    private readonly _componentContainer: ComponentContainerPart;
    private readonly _viewPart: ViewPart;
    private readonly _viewModel: ComponentViewModel;
    private _isAdded: boolean = false;

    constructor(componentContainer: ComponentContainerPart, viewModel: ComponentViewModel, viewPart: ViewPart) {
        this._componentContainer = componentContainer;
        this._viewPart = viewPart;
        this._viewModel = viewModel;
    }

    public get viewPart(): ViewPart { return this._viewPart; }

    public get viewModel(): ComponentViewModel { return this._viewModel; }

    public get visible(): boolean { return this._viewPart.visible; }

    public add() {
        if (this._isAdded) {
            return;
        }
        this._isAdded = true;
        this._componentContainer.addChild(this.viewPart);
    }

    public remove() {
        if (!this._isAdded) {
            return;
        }
        this._isAdded = false;
        this._componentContainer.removeChild(this.viewPart);
    }

    public async playTransition(trans: ITransition): Promise<ComponentView> {
        await trans.playAsync(this.viewPart);
        return this;
    }

    public setVisibility(visible: boolean): void {
        this._viewPart.visible = visible;
    }

}

