import { ContainerPart, IParamContainerPart } from "./ContainerPart";
import { PartSize, ViewPart } from "../ViewPart";
import { ObservableDef } from "../../../utils/eventbase/Observable";
import { IPartStyle, PartStyleFlexDirection, PartStyleFlexWrap, PartStyleFlexJustifyContent, PartStyleAlignItems } from "../IPartStyle";

export interface IParamFlexContainerPart extends IParamContainerPart {
    flexDirection?: PartStyleFlexDirection;
    flexWrap?: PartStyleFlexWrap;
    flexJustifyContent?: PartStyleFlexJustifyContent;
    flexAlignItems?: PartStyleAlignItems;
}

export class FlexContainerPart extends ContainerPart {

    constructor(par: IParamFlexContainerPart) {
        if (!par.partSize) {
            par.partSize = PartSize.FullSize;
        }

        if (!par.flexDirection) {
            par.flexDirection = "row";
        }

        if (!par.flexWrap) {
            par.flexWrap = "nowrap";
        }

        if (!par.flexJustifyContent) {
            par.flexJustifyContent = "center";
        }

        if (!par.flexAlignItems) {
            par.flexAlignItems = "flex-start";
        }

        let defaultStyle: IPartStyle = {
            display: "flex",
            flexDirection: par.flexDirection,
            flexWrap: par.flexWrap,
            justifyContent: par.flexJustifyContent,
            alignItems: par.flexAlignItems,
        };

        ViewPart.setDefaultStyle(par, defaultStyle);

        super(par);
    }
}

