import texts from "../../../common/ui/localization/Language";
import { ComponentViewModel, IObservable, IObservableTwoWay, IViewModel, ObservableDef, ObservableTwoWayDef } from "../../../lib/index";
import { EngineConnectService } from "../../../server_com/services/login/EngineConnectService";

export class ConnectCodeDialogViewModel extends ComponentViewModel {

    private _engineConnectService: EngineConnectService;

    private _obsConnectCode: ObservableTwoWayDef<string> = new ObservableTwoWayDef<string>("", (val)=>this.checkCanSubmit(val));
    public get obsConnectCode(): IObservableTwoWay<string> { return this._obsConnectCode; }

    private _obsCanSubmit: ObservableDef<boolean> = new ObservableDef<boolean>(false);
    public get obsCanSubmit(): IObservable<boolean> { return this._obsCanSubmit; }

    private _obsErrorMessage: ObservableDef<string | undefined> = new ObservableDef<string | undefined>(undefined);
    public get obsErrorMessage(): IObservable<string | undefined> { return this._obsErrorMessage; }

    private _succes: boolean = false;

    constructor(parent: IViewModel) {
        super(parent, "Connect code dialog");
        this._engineConnectService = EngineConnectService.getInstance();
    }

    public async submitAsync(): Promise<void> {
        this._obsErrorMessage.emit(undefined);
        const code = this._obsConnectCode.value;
        const res = await this._engineConnectService.addEngineWithCodeAsync(code);
        if (res === "ok") {
            this._succes = true;
            this.closeComponent();
        } else if (res === "not_valid") {
            this._obsErrorMessage.emit(texts.connectEngineNotFound);
        } else {
            this._obsErrorMessage.emit(texts.connectEngineError);
        }
        
    }

    public cancel(): void {
        this.closeComponent();
    }

    private checkCanSubmit(val: string): Promise<void> {
        const codeOk = (val.length === 7 && val[3] === '.') || (val.length === 8);

        this._obsCanSubmit.emitOnChanged(codeOk);
        return Promise.resolve();
    }

    public async show(): Promise<boolean> {
        this._succes = false;
        await this.executeComponentAsync();
        await this.delayAsync(1000);
        return this._succes;
    }
}