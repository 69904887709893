import { TransitionBase } from "../../../lib/ui/transitions/TransitionBase";
import { ViewPart } from "../../../lib/ui/parts/ViewPart";
import { StyleConstants } from "../parts/style/StyleConstants";

export class TransitionFadeOut extends TransitionBase {
    constructor() {
        super(StyleConstants.defaultTransitionSpeed, 1);
    }

    public beforeAnimate(view: ViewPart): void {
        view.applyStyle({ pointerEvents: "none" });
        view.setOpacity(1);
    }

    public animateStep(view: ViewPart, step: number): void {
        view.setOpacity(0);
    }

    public afterAnimate(view: ViewPart): void {
        view.applyStyle({ pointerEvents: "auto" });
        view.setOpacity(1);
        view.hide();
    }
}