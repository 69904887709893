export class NavigationPath {

    private _pathParts: string[];
    private _pathIndex = 0;

    public static fromUrl(url: string): NavigationPath {
        if (url.length == null) {
            return new NavigationPath();
        }
        if (url[0] === "#") {
            url = url.substr(1);
        }

        return new NavigationPath(url.split("/").filter((c) => (c)));
    }

    constructor(pathParts: string[] | null = null) {
        if (pathParts != null) {
            this._pathParts = pathParts;
        } else {
            this._pathParts = [];
        }
    }



    public popPath(): string|null {
        if (this._pathIndex >= this._pathParts.length) {
            return null;
        }

        const path = this._pathParts[this._pathIndex];
        this._pathIndex += 1;
        return path;
    }

    public pushPath(part: string): void {
        if (part != null) {
            this._pathParts.push(part);
            this._pathIndex = 0;
        }
    }

    public getHash() {
        if (this._pathParts.length > 0) {
            return `#/${this._pathParts.join("/")}`;
        } else {
            return "";
        }
    }

}