import { MessageC2E } from "../MessageC2E";

export class SearchTracksRequestC2E extends MessageC2E {

    constructor(searchText: string) {
        super();
        this.requestId = 100000 + Math.floor(Math.random() * 899999);
        this.searchText = searchText;
    }

    public readonly searchText: string;
    public readonly requestId: number;
}