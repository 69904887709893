import { StateTransition } from "./StateTransition";
import { ViewPart } from "../parts/ViewPart";

export class StateTransitionVisibilityVertical implements StateTransition<boolean>{

    private _hideTimeout: number = -1;
    private _showTimeout: number = -1;

    public set(p: ViewPart, s: boolean) {
        p.applyStyle({
            transition: "all 0.3s ease",
            webkitPerspective: "1000",
            webkitBackfaceVisibility: "hidden",
            overflow:"hidden"
        });
        if (s) {
            this.show(p);
        } else {
            this.hide(p);
        }
    }

    private hide(p: ViewPart) {
        window.clearTimeout(this._showTimeout);
        p.applyStyle({
            height: "0px",
        });
        window.clearTimeout(this._hideTimeout);
        this._hideTimeout = window.setTimeout(() => p.setVisibilityInternal(false), 300);
    }

    private show(p: ViewPart) {
        window.clearTimeout(this._hideTimeout);
        p.setVisibilityInternal(true);
        this.setHeightContent(p);
        window.clearTimeout(this._showTimeout);
        this._showTimeout = window.setTimeout(() => this.setHeightContent(p), 30);

    }

    private setHeightContent(p: ViewPart) {
        let h = p.scrollHeigth + "px";
        p.applyStyle({
            height: h
        });
    }

}