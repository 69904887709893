
export enum FixedEvent {
    EnterState,
    LeaveState,
    Timeout,
}


export class StateMachine<TState, TEvent> {
    private _callback: (currentState: TState, event: TEvent | FixedEvent) => TState | null;
    private _state: TState;
    private _timerId: number | undefined;

    private _onStateChangedCallback: ((oldState: TState, newState: TState) => void) | undefined;
    private _onProcessEventCallback: ((event: TEvent | FixedEvent, state: TState) => void) | undefined;

    constructor(startState: TState, callback: (currentState: TState, event: TEvent | FixedEvent) => TState | null) {
        this._callback = callback;
        this._state = startState;
    }

    public processEvent(event: TEvent) {
        if (this._timerId) {
            window.clearTimeout(this._timerId);
            this._timerId = undefined;
        }


        this.processEventInternal(event);
    }


    public setTimeoutForState(time: number) {
        this._timerId = window.setTimeout(this.onTimeout, time);
    }

    public onStateChanged(callback: (oldState: TState, newState: TState) => void): void {
        this._onStateChangedCallback = callback;
    }

    public onProcessEvent(callback: (event: TEvent | FixedEvent, state: TState) => void): void {
        this._onProcessEventCallback = callback;
    }


    private onTimeout() {
        this._timerId = undefined;
        this.processEventInternal(FixedEvent.Timeout);
    }

    private processEventInternal(event: TEvent | FixedEvent) {

        if (this._onProcessEventCallback) {
            this._onProcessEventCallback(event, this._state);
        }

        var newState = this._callback(this._state, event);

        while (newState != null && newState !== this._state) {

            if (this._onStateChangedCallback) {
                this._onStateChangedCallback(this._state, newState);
            }

            this._callback(this._state, FixedEvent.LeaveState);
            this._state = newState;
            newState = this._callback(this._state, FixedEvent.EnterState);
        }
    }

}