import { GridContainer, ItemsFactoryPart } from "../../../../lib/index";
import { HeaderText } from "../../../../common/ui/parts/text/HeaderText";
import texts from "../../../../common/ui/localization/Language";
import { NormalText } from "../../../../common/ui/parts/text/NormalText";
import theme from "../../../../app/style/Theme";
import { AboutComponentViewModel } from "./AboutComponentViewModel";

export class AboutView extends GridContainer {
    constructor(viewmodel: AboutComponentViewModel) {
        super({
            style: {
                gridAutoFlow: "row",
                gridAutoRows: "min-content",
                padding: theme.getSpacePx(10),
                gridRowGap: theme.getSpacePx(10)
            },
            children: [
                new HeaderText({
                    text: texts.about
                }),
                new NormalText({
                    textSubscription: viewmodel.obsAboutText
                }),
                new NormalText({
                    textSubscription: viewmodel.obsRoleText
                }),
                new ItemsFactoryPart({
                    itemsSourceSubscription: viewmodel.obsScreenSizes,
                    itemViewFactory: (t) => new NormalText({
                        text: t
                    }),
                })
            ]
        });
    }
}