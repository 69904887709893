import { ListItemViewModel } from "./ListItemViewModel";
import { ObservableOpt, IObservable, IObservableOpt } from "../../../utils/eventbase/Observable";

type ListItemViewModelFactory<Tvm, Tdata> = (data: Tdata) => Tvm;

export class ViewModelList<Tvm extends ListItemViewModel, Tdata> {

    private _vmFactory: ListItemViewModelFactory<Tvm, Tdata>
    private _viewmodels: Tvm[] = new Array<Tvm>();
    private _obsViewmodels: ObservableOpt<Tvm[]> = new ObservableOpt();
    public get obsViewmodels(): IObservable<Tvm[]> { return this._obsViewmodels; }

    private _selectedViewModel: ObservableOpt<Tvm| null> = new ObservableOpt();
    public get selectedViewmodel(): IObservableOpt<Tvm| null> { return this._selectedViewModel; }

    constructor(vmFactory: ListItemViewModelFactory<Tvm, Tdata>) {
        this._vmFactory = vmFactory;
    }

    public setItems(items: Tdata[]) {
        this._viewmodels = items.map((d) => {
            let vm = this._vmFactory(d);
            vm.setParentList(this as any); // TODO 'as any' is NOT good!!!!!!!!!!!!!!!!
            return vm;
        });
        this._obsViewmodels.emit(this._viewmodels);
    }

    public selectViewmodel(vm: Tvm) {
        if (vm == this._selectedViewModel.optValue) {
            return;
        }
        for (let lvm of this._viewmodels) {
            if (lvm === vm) {
                lvm.select();
                this._selectedViewModel.emit(lvm);
            } else {
                lvm.deselect();
            }
        }
    }

    public deselectViewmodel(vm: Tvm) {
        if (vm != this._selectedViewModel.optValue) {
            return;
        }
        for (let lvm of this._viewmodels) {
            if (lvm === vm) {
                lvm.deselect();
                this._selectedViewModel.emit(null);
            }
        }
    }
    
    
}