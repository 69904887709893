import { ContainerBase, IParamContainerBase } from "../bases/ContainerBase";
import { ViewPart, PartSize } from "../ViewPart";

export interface IParamGridContainer extends IParamContainerBase {
}

export class GridContainer extends ContainerBase {

    public constructor(par: IParamGridContainer) {

        if (!par.partSize) {
            par.partSize = PartSize.FullSize;
        }

        par.style = par.style || {}; 
        par.style.display = "grid";

        super(par);
    }

    public setRowTemplate(sizes: string[]): void {
        this.applyStyle(
            {
                gridTemplateRows: sizes.join(" "),
            } as any
        );
    }

    public setColumnTemplate(sizes: string[]): void {
        this.applyStyle(
            {
                gridTemplateColumns: sizes.join(" "),
            } as any
        );
    }
}