import { IObservable, ObservableOpt } from "../utils/eventbase/Observable";
import theme from "../../app/style/Theme";

class ScreenPropertiesService {

    private _timeoutHandle: number | null;

    private _switchWidth: number;

    private _obsIsSmall: ObservableOpt<boolean> = new ObservableOpt();
    public get obsIsSmall(): IObservable<boolean> { return this._obsIsSmall; }

    private _obsScreenProperties: ObservableOpt<string[]> = new ObservableOpt();
    public get obsScreenProperties(): IObservable<string[]> { return this._obsScreenProperties }

    constructor() {
        this._timeoutHandle = null;
        window.onresize = () => this.change();
        window.onorientationchange = () => this.change();
        this._switchWidth = 700 * theme.scale;
        this.change();
    }

    private change() {
        this.checkSizes();
        if (this._timeoutHandle != null) {
            clearTimeout(this._timeoutHandle);
        }
        this._timeoutHandle = window.setTimeout(()=>this.checkSizes(), 200);
    }

    private checkSizes() {
        this._obsIsSmall.emitOnChanged(innerWidth < this._switchWidth);
        this.makeScreenProperties();
    }

    private makeScreenProperties() {
        let properties: string[] = [];
        properties.push(`Screen resolution: ${screen.width}x${screen.height}(${screen.availWidth}x${screen.availHeight})`);
        properties.push(`Inner size: ${window.innerWidth}x${window.innerHeight}`);
        properties.push(`Outer size: ${window.outerWidth}x${window.outerHeight}`);
        properties.push(`body client size: ${document.body.clientWidth}x${document.body.clientHeight}`);

        properties.push(`Scale: ${theme.scale} (${Math.round(theme.scale * 100)}%)`);
        properties.push(`Scaled layout switch: ${this._switchWidth}`);
        //properties.push(`Scaled Inner size: ${Math.round(window.innerWidth * theme.scale)}x${Math.round(window.innerHeight * theme.scale)}`);
        //properties.push(`Scaled Outer size: ${Math.round(window.outerWidth * theme.scale)}x${Math.round(window.outerHeight * theme.scale)}`);
        //properties.push(`Scaled body client size: ${Math.round(document.body.clientWidth * theme.scale)}x${Math.round(document.body.clientHeight * theme.scale)}`);
        this._obsScreenProperties.emit(properties);
    }

}

const screenPropertiesService = new ScreenPropertiesService();
export default screenPropertiesService;