import { ViewPart, IParamViewPart } from "../ViewPart";

export interface IParamSelectBase extends IParamViewPart {
}

export abstract class SelectBase extends ViewPart {

    protected selectElement: HTMLSelectElement;

    constructor(par: IParamSelectBase) {
        super("SELECT", par);

        this.selectElement = this.root as HTMLSelectElement;
    }
}

export interface IParamSelectOptionBase extends IParamViewPart {
    isSelected?: boolean;
    value?: string;
}

export abstract class SelectOptionBase extends ViewPart {

    protected optionElement: HTMLOptionElement;

    constructor(par: IParamSelectOptionBase) {
        super("OPTION", par);
        this.optionElement = this.root as HTMLOptionElement;

        if (par.value) {
            this.optionElement.value = par.value;
        }

        if (par.isSelected) {
            this.selected = true;
        }
    }

    public set selected(selected: boolean) {
        //if (selected) {
        //    this.optionElement.setAttribute("selected", "selected");
        //} else {
        //    this.optionElement.removeAttribute("selected");
        //}
        this.optionElement.selected = true;
    }

    public get selected() {
        return this.optionElement.selected;
    }

    public get value(): string {
        return this.optionElement.value;
    }
}