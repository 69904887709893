import { CardContainer, CardSizeMode, CardBackgroundColor } from "../../../../../common/ui/parts/containers/CardContainer";
import { WaitlistPreviewViewModel } from "./WaitlistPreviewViewModel";
import { ItemsFactoryPart } from "../../../../../lib/ui/parts/nonvisible/ItemsFactoryPart";
import { WaitlistTrackView } from "./WaitlistTrackView";
import { ScrollContainerPart } from "../../../../../lib/ui/parts/containers/ScrollContainerPart";
import { GridContainer } from "../../../../../lib/ui/parts/containers/GridContainer";
import { NormalText } from "../../../../../common/ui/parts/text/NormalText";
import texts from "../../../../../common/ui/localization/Language";
import theme from "../../../../../app/style/Theme";

export class WaitlistPreviewView extends GridContainer {

    constructor(viewmodel: WaitlistPreviewViewModel) {
        super({
            style: {
                //overflow: "auto",
                gridTemplateRows: "auto minmax(0, 1fr)",
                gridRowGap: theme.getSpacePx(10),
            },
            children: [
                new NormalText({
                    text: texts.upNext
                }),


                new ItemsFactoryPart({
                    itemsSourceSubscription: viewmodel.obsWaitlistItems,
                    itemViewFactory: (vm) => new WaitlistTrackView(vm),
                    itemsContainerFactory: () => new ScrollContainerPart({
                        style: {
                            display: "grid",
                            gridAutoFlow: "row",
                            gridAutoRows: "min-content",
                            gridRowGap: theme.getSpacePx(5),
                        }
                    }),
                })

            ]
        });
    }

}