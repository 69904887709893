import { IconPart, IParamIconPart } from "../../../../lib/ui/parts/icon/IconPart";
import { StyleConstants } from "../style/StyleConstants";
import { IObservable } from "../../../../lib/utils/eventbase/Observable";

export interface IParamExpandIcon extends IParamIconPart{
    opened?: boolean;
    openedSubscription?: IObservable<boolean>;
}

export class ExpandIcon extends IconPart {

    constructor(par: IParamExpandIcon) {
        ExpandIcon.setDefaultStyle(par, {
            transition: StyleConstants.transition,
            filter: "invert(100%)"
        });
        par.icon = "expand_more";
        super(par);

        if (typeof par.opened === "boolean") {
            if (par.opened) {
                this.open();
            } else {
                this.close();
            }
        }
        if (par.openedSubscription) {
            par.openedSubscription.subscribeInitial(this.subscriptionContainer, (v) => {
                if (v) {
                    this.open();
                } else {
                    this.close();
                }
            });
        }
        
    }

    private close() {
        this.applyStyle({
            transform: "rotate(0deg)"
        })
    }

    private open() {
        this.applyStyle({
            transform: "rotate(-180deg)"
        })
    }
}