export class PromiseAggregator<IToken> {
    private _resolvers: (Array<(token: IToken) => void>) | undefined;
    private _rejectors: (Array<(reason?: any) => void>) | undefined;
    private _callback: () => Promise<IToken>;

    constructor(callback: () => Promise<IToken>) {
        this._callback = callback;
    }

    public executeAsync() {
    return new Promise<IToken>(async (resolve, reject) => {
        if (!this._resolvers || !this._rejectors) {
            this._resolvers = [];
            this._rejectors = [];
            this._resolvers.push(resolve);
            this._rejectors.push(reject);
            this.start();
        } else {
            this._resolvers.push(resolve);
            this._rejectors.push(reject);
        }
    }); }

    private async start(): Promise<void> {
        try {
            const result = await this._callback();
            const resolvers = this._resolvers;
            this._rejectors = undefined;
            this._resolvers = undefined;
            if (resolvers) {
                for (const resolver of resolvers) {
                    resolver(result);
                }
            }
        } catch (reason) {
            const rejectors = this._rejectors;
            this._rejectors = undefined;
            this._resolvers = undefined;
            if (rejectors) {
                for (const rejector of rejectors) {
                    rejector(reason);
                }
            }
        }
    }

}
