import { IObservable } from "../../utils/eventbase/Observable";
import { IPartStyle } from "./IPartStyle";
import { Part, IParamPart } from "./Part";
import { Binding } from "./bindings/Binding";
import { StateTransition } from "../transitions/StateTransition";
import { StateTransitionVisibility } from "../transitions/StateTransitionVisibility";
import { ObjectType } from "../../typedi";

export interface IParamViewPart extends IParamPart {
    partSize?: PartSize,
    style?: IPartStyle,
    visible?: boolean,
    visibleSubscription?: IObservable<boolean>,
    visibleTransition?: ObjectType<StateTransition<boolean>>
    invisible?: boolean,
    invisibleSubscription?: IObservable<boolean>,
    title?: string,
    text?: string;
    textSubscription?: IObservable<string | undefined>;
    bindings?: Binding[];

    clickEnabled?: boolean;
    clickEnabledSubscription?: IObservable<boolean>;
    clickDisabled?: boolean;
    clickDisabledSubscription?: IObservable<boolean>;
    clickOnlyOnThisElement?: boolean,
    onclick?: (ev: MouseEvent) => void;
    onclickAsync?: (ev: MouseEvent) => Promise<void>;
    scrollToThisObservable?: IObservable<boolean>,
    id?: string;
}

export abstract class ViewPart extends Part {
    private readonly _onClick: ((ev: MouseEvent) => void) | undefined;
    private readonly _onClickAsync: ((ev: MouseEvent) => void) | undefined;
    private readonly _clickOnlyOnThisElement: boolean;
    private _root?: HTMLElement;
    private _oldDisplay: string | null;
    private _visible: boolean;
    private _disabled: boolean;
    private _disabledTemp: boolean;
    private _visibleTransition: StateTransition<boolean>;


    constructor(tag: string, par: IParamViewPart) {
        super(par);

        this._oldDisplay = "block";

        this._root = document.createElement(tag);
        this._root.setAttribute("instanceof", this.constructor.name);

        this._onClick = par.onclick;
        this._onClickAsync = par.onclickAsync;
        if (this._onClick || this._onClickAsync) {
            this.applyStyle({ cursor: "pointer" });
        }
        if (par.visibleTransition) {
            this._visibleTransition = new par.visibleTransition();
        } else {
            this._visibleTransition = StateTransitionVisibility.instance;
        }

        this._visible = true;
        this.onVisibilityChanged();

        if (par.partSize) {
            var partStyle = this.getPartSizeStyle(par.partSize);
            ViewPart.setDefaultStyle(par, partStyle);
        }
        this.applyStyle(par.style);
        this._root.addEventListener("click", this._clickedHandler);

        if (par.visible || par.visible === false) {
            this.visible = par.visible;
        }

        if (par.visibleSubscription) {
            this.visibleSubscribtion(par.visibleSubscription);
        }

        if (par.invisible || par.invisible === false) {
            this.visible = !par.invisible;
        }

        if (par.invisibleSubscription) {
            this.invisibleSubscribtion(par.invisibleSubscription);
        }

        if (par.title) {
            this._root.title = par.title;
        }

        if (par.text) {
            this.setText(par.text);
        }

        if (par.textSubscription) {
            this.setTextSubscription(par.textSubscription);
        }


        if (par.clickEnabled === false || par.clickDisabled) {
            this.disableClick();
        } else {
            this.enableClick();
        }

        this._disabled = false;
        this._disabledTemp = false;

        if (par.clickEnabledSubscription) {
            par.clickEnabledSubscription.subscribeInitial(this.subscriptionContainer, (enabled) => {
                if (enabled) this.enableClick();
                else this.disableClick();
            });
        }

        if (par.clickDisabledSubscription) {
            par.clickDisabledSubscription.subscribeInitial(this.subscriptionContainer, (disabled) => {
                if (disabled) this.disableClick();
                else this.enableClick();
            });
        }

        if (par.bindings) {
            for (let binding of par.bindings) {
                this.addBinding(binding);
            }
        }

        if (par.id) {
            this.root.id = par.id;
        }

        if (par.children) {
            for (let child of par.children) {
                if (child) {
                    this.addChildViewPartToViewPart(child);
                    if (child instanceof ViewPart) {
                        this.onAddChildToViewPart(child);
                    }
                }
            }
        }
        setTimeout(() => {
            if (par.scrollToThisObservable && !this.isDisposed) {
                par.scrollToThisObservable.subscribeInitial(this.subscriptionContainer, (scroll) => {
                    if (scroll) {
                        this.root.scrollIntoView({
                            behavior: "smooth",
                            block: "nearest"
                        } as ScrollIntoViewOptions);
                    }

                });
            }
        }, 100);

        this._clickOnlyOnThisElement = par.clickOnlyOnThisElement || false;

    }

    public get root(): HTMLElement {
        if (!this._root) {
            console.error("Part already disposed", this);
            throw new Error();
        }
        return this._root;
    }

    public static get defaultName(): string {
        return this.name;
    }

    public get absoluteWidth(): number {
        return this.root.offsetWidth;
    }

    public get absoluteHeigth(): number {
        return this.root.offsetHeight;
    }

    public get scrollWidth(): number {
        return this.root.scrollWidth;
    }

    public get scrollHeigth(): number {
        return this.root.scrollHeight;
    }

    protected onAddNewChildToPart(child: Part): void {
        if (child instanceof ViewPart) {
            this.onAddChildToViewPart(child);
        }
    }

    protected onAddChildToViewPart(child: ViewPart): void {
    }

    public onAddNewVisibleChildToPart(child: Part): void {
        this.addChildViewPartToViewPart(child);
    }

    private addChildViewPartToViewPart(child: Part): void {
        child.iterateVisibleChilds((child: ViewPart) => this.root.appendChild(child.root));
    }

    public iterateVisibleChilds(elementCallback: (child: ViewPart) => void): void {
        elementCallback(this);
    }

    public setText(text: string | undefined) {
        this.root.innerText = text || "";
    }

    public setTextSubscription(textObs: IObservable<string | undefined>): void {
        textObs.subscribeInitial(this.subscriptionContainer, (t: string | undefined) => {
            this.setText(t);
        });
    }

    protected setHtml(html: string) {
        this.root.innerHTML = html;
    }

    public static setDefaultStyle(par: IParamViewPart, defaultStyle: IPartStyle): void {
        par.style = Object.assign(defaultStyle, par.style);
    }

    public static styleMerge(targetStyle: IPartStyle, sourceStyle: IPartStyle | undefined): IPartStyle {
        return Object.assign(targetStyle, sourceStyle);
    }

    public applyStyle(css: IPartStyle | undefined) {
        if (!this.isDisposed) {
            Object.assign(this.root.style, css);
        }
    }

    public addBinding(binding: Binding): void {
        binding.initPart(this, this.subscriptionContainer);
    }

    public show() {
        if (!this.isDisposed && this._visible === false) {
            this._visible = true;
            this.onVisibilityChanged();
        }
    }

    public hide() {
        if (!this.isDisposed && this._visible) {
            this._visible = false;
            this.onVisibilityChanged();
        }
    }

    public get visible(): boolean {
        return this._visible;
    }

    public set visible(value: boolean) {
        if (value) this.show();
        else this.hide();
    }

    protected onVisibilityChanged() {
        this._visibleTransition.set(this, this._visible);
    }

    public setVisibilityInternal(v: boolean) {
        if (this.isDisposed) {
            return;
        }
        if (v) {
            if (this.root.style.display === "none") {
                this.root.style.display = this._oldDisplay || "unset";
            }
        } else {
            if (this.root.style.display !== "none") {
                this._oldDisplay = this.root.style.display;
                this.root.style.display = "none";
            }
        }
    }


    public visibleSubscribtion(visibleObservable: IObservable<boolean>) {
        visibleObservable.subscribeInitial(this.subscriptionContainer, visible => {
            if (visible) {
                this.show();
            } else {
                this.hide();
            }
        });
    }

    public invisibleSubscribtion(invisibleObservable: IObservable<boolean>) {
        invisibleObservable.subscribeInitial(this.subscriptionContainer, invisible => {
            if (invisible) {
                this.hide();
            } else {
                this.show();
            }
        });
    }

    public setOpacity(amount: number) {
        this.applyStyle({
            opacity: amount.toString()
        });
    }

    public get id() {
        return this.root.id;
    }

    public set id(value: string) {
        this.root.id = value;
    }

    private removeFromParent() {
        if (this.root.parentElement) {
            this.root.parentElement.removeChild(this.root);
        }
    }

    private _clickedHandler = (ev: MouseEvent) => {
        this.clickedHandler(ev);
    };

    private async clickedHandler(ev: MouseEvent) {

        if (this._disabled || this._disabledTemp) return;
        let clickThisOrChildElement = !this._clickOnlyOnThisElement;
        if (this._onClick) {
            if (ev.srcElement === this._root || clickThisOrChildElement) {
                this._onClick(ev);
                ev.stopPropagation();
            }
        }

        if (this._onClickAsync) {

            if (ev.srcElement === this._root || clickThisOrChildElement) {
                this.disableClickTemporaly();
                ev.stopPropagation();
                await this._onClickAsync(ev);
                if (!this.isDisposed) {
                    this.restoreDisableClickTemporaly();
                }
            }
        }
    };



    public disableClick(): void {
        this._disabled = true;
        this.onClickDisabled();
    }

    public enableClick(): void {
        this._disabled = false;
        this.onClickEnabled();
    }

    protected onClickDisabled(): void {
    }

    protected onClickEnabled(): void {
    }

    private disableClickTemporaly(): void {
        this._disabledTemp = true;
        this.onClickDisabled();
    }

    private restoreDisableClickTemporaly(): void {
        this._disabledTemp = false;
        if (!this._disabled) {
            this.onClickEnabled();
        }
    }


    protected onDispose(): void {
        this.root.removeEventListener("click", this._clickedHandler);

        this.removeFromParent();

        delete this._root;
    }



    private getPartSizeStyle(partsize: PartSize): IPartStyle {
        switch (partsize) {
            case PartSize.AutoSize: return { width: "auto", height: "auto" };
            case PartSize.FullSize: return { width: "100%", height: "100%" };
            case PartSize.AutoWidth: return { width: "auto", height: "100%" };
            case PartSize.AutoHeight: return { width: "100%", height: "auto" };
            case PartSize.Undefined: return {};
        }
    }

    public setTitle(title: string): void {
        this.root.title = title;
    }

}

export enum PartSize {
    Undefined,
    AutoSize,
    FullSize,
    AutoWidth,
    AutoHeight,
}