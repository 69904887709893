import { ViewPart } from "../../../../lib/ui/parts/ViewPart";
import { ButtonPart, IParamButtonPart } from "../../../../lib/ui/parts/button/ButtonPart";
import { StyleConstants } from "../style/StyleConstants";
import { IObservable } from "../../../../lib/utils/eventbase/Observable";
import theme from "../../../../app/style/Theme";
import { Colors } from "../colors/Colors";

interface IParamButton extends IParamButtonPart {
    enabled?: boolean;
    enabledSubscription?: IObservable<boolean>;
    disabled?: boolean;
    disabledSubscription?: IObservable<boolean>;
}

export class Button extends ButtonPart {

    constructor(par: IParamButton) {

        ViewPart.setDefaultStyle(par, {
            backgroundColor: Colors.buttonColor,
            color: Colors.buttonTextColor,
            border: "none",
            padding: theme.getSpacePx(12),
            borderRadius: theme.scalePx(100),
            outline: "0",
            fontSize: theme.fontSizeButton,
            transition: StyleConstants.transition,
        });

        super(par);

        if (par.enabled === false) {
            this.disableClick();
        } else if (par.disabled) {
            this.disableClick();
        } else {
            this.enableClick();
        }

        if (par.enabledSubscription) {
            par.enabledSubscription.subscribeInitial(this.subscriptionContainer, (enabled) => {
                if (enabled) this.enableClick();
                else this.disableClick();
            });
        }

        if (par.disabledSubscription) {
            par.disabledSubscription.subscribeInitial(this.subscriptionContainer, (disabled) => {
                if (disabled) this.disableClick();
                else this.enableClick();
            });
        }
    }

    //private disable() {
    //    this.root.setAttribute("disabled", "disabled");
    //}

    //private enable() {
    //    this.root.removeAttribute("disabled");
    //}

    protected onClickDisabled(): void {
        super.onClickDisabled();
        this.applyStyle({
            backgroundColor: Colors.buttonDisabledColor,
            color: Colors.buttonDisabledTextColor,
        });
    }

    protected onClickEnabled(): void {
        super.onClickEnabled();
        this.applyStyle({
            backgroundColor: Colors.buttonColor,
            color: Colors.inputTextColor,
        });
    }
}