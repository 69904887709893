import { ContainerBase, IParamContainerBase } from "../bases/ContainerBase";
import { PartSize } from "../ViewPart";

export interface IParamContainerPart extends IParamContainerBase  {
}

export class ContainerPart extends ContainerBase {
    constructor(par: IParamContainerPart) {

        if (!par.partSize) {
            par.partSize = PartSize.FullSize;
        }

        super(par);
    }
}