import { TextViewBase, IParamTextViewBase } from "../bases/TextViewBase";

export interface IParamH3TextPart extends IParamTextViewBase {
}

export class H3TextPart extends TextViewBase {

    constructor(par: IParamH3TextPart) {
        super("H3", par);
    }
}