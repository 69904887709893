import {ViewModel} from "../../../../lib/ui/viewmodels/common/ViewModel";
import { ObservableDef, IObservable } from "../../../../lib/utils/eventbase/Observable";
import { ApplicationConstants } from "../../../../app/ApplicationConstants";
import { NavigationPath } from "../../../../lib/ui/navigation/NavigationPath";
import { NavigationPaths } from "../../../navigation/NavigationPaths";
import { NavigationManager } from "../../../../lib/ui/navigation/NavigationManager";
import texts from "../../../../common/ui/localization/Language";

export class AboutViewModel extends ViewModel {

    private _aboutTitle = new ObservableDef<string>(texts.applicationName);
    private _versionText = new ObservableDef<string>(ApplicationConstants.versionString);
    public get versionText(): IObservable<string> { return this._versionText; };

    public get aboutTitle(): IObservable<string> { return this._aboutTitle; };

    constructor(parent: ViewModel) {
        super(parent);
    }

    public backButtonClick() {
        let path = NavigationPaths.getPlayersMenuPath();
        let vm = NavigationManager.getInstance().mainViewModel;
        if (vm && path) {
            vm.navigateTo(path);
        }
    }

}