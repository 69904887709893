import { WaitlistTrackViewModel } from "./WaitlistTrackViewModel";
import { ViewModel } from "../../../../../lib/ui/viewmodels/common/ViewModel";
import { ObservableDef, IObservable } from "../../../../../lib/utils/eventbase/Observable";
import { EventEmitter } from "../../../../../lib/utils/eventbase/EventEmitter";
import { WaitlistService } from "../../../../../server_com/services/player/waitlist/WaitlistService";
import { IWaitlistTrack } from "../../../../../server_com/models/player/waitlist/WaitlistTrack";
import { ViewModelList } from "../../../../../lib/ui/viewmodels/common/ViewModelList";

export class WaitlistPreviewViewModel extends ViewModel {

    private _playWaitlistService: WaitlistService;


    private waitlistTrackViewmodelList: ViewModelList<WaitlistTrackViewModel, IWaitlistTrack>;

    constructor(parent: ViewModel | null) {
        super(parent);
        this.waitlistTrackViewmodelList = new ViewModelList(this.waitlistTrackViewModelFactory)
        this._playWaitlistService = WaitlistService.getInstance();

        this._playWaitlistService.waitlist.subscribeInitial(this.subscriptionContainer, (wl) => (this.showWaitlist(wl)));

        this._playWaitlistService.activate(this);
    }

    public get obsWaitlistItems(): IObservable<WaitlistTrackViewModel[]> { return this.waitlistTrackViewmodelList.obsViewmodels; }


    private showWaitlist(wl: IWaitlistTrack[]) {
        this.waitlistTrackViewmodelList.setItems(wl);
    }

    private waitlistTrackViewModelFactory = (wld: IWaitlistTrack) => {
        return new WaitlistTrackViewModel(this, wld, true);
    };

    public dispose() {
        super.dispose();
        this._playWaitlistService.deactivate(this);
    }
}