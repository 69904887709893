import { ContainerPart } from "../../../lib/ui/parts/containers/ContainerPart";
import { MainMenuViewModel } from "../../viewmodels/main/MainMenuViewModel";
import { ViewFactory } from "../../../lib/ui/parts/contentmanagers/ViewFactory";
import { ComponentContainerPart } from "../../../lib/index";
import { EngineComponentViewModel } from "../../engine/EngineComponentViewModel";
import { EngineComponentView } from "../../engine/EngineComponentView";
import { EngineSelectComponentViewModel } from "../../engine_list/EngineSelectComponentViewModel";
import { EngineSelectComponentView } from "../../engine_list/EngineSelectComponentView";
import { TransitionFadeInFromRight } from "../../../common/ui/transitions/TransitionFadeInFromRight";
import { TransitionFadeOutToRight } from "../../../common/ui/transitions/TransitionFadeOutToRight";
import { TransitionFadeIn } from "../../../common/ui/transitions/TransitionFadeIn";
import { TransitionFadeOut } from "../../../common/ui/transitions/TransitionFadeOut";
import { TransitionFadeOutToLeft } from "../../../common/ui/transitions/TransitionFadeOutToLeft";
import { TransitionFadeInFromLeft } from "../../../common/ui/transitions/TransitionFadeInFromLeft";
import { ConnectCodeDialogViewModel } from "../../engine_list/connect_code_dialog/ConnectCodeDialogViewModel";
import { ConnectCodeDialogView } from "../../engine_list/connect_code_dialog/ConnectCodeDialogView";

export class MainMenuView extends ContainerPart {

    constructor(viewModel: MainMenuViewModel) {
        super({
            children: [
                //new ContentSwitchPart({
                //    viewModelSubscription: viewModel.activeChildViewModel,
                //    viewFactories: [
                //        new ViewFactory(MenuViewModel, (vm) => new MenuView(vm)),
                //        new ViewFactory(EngineConnectionViewModel, (vm) => new EngineConnectionView(vm))
                //    ]
                //})
                new ComponentContainerPart({
                    componentContainer: viewModel.componentContainer,
                    viewFactories: [
                        new ViewFactory(EngineComponentViewModel, (vm) => new EngineComponentView(vm), new TransitionFadeIn(), new TransitionFadeOut()),
                        new ViewFactory(EngineSelectComponentViewModel, (vm) => new EngineSelectComponentView(vm), new TransitionFadeInFromRight(), new TransitionFadeOutToRight()),
                        new ViewFactory(ConnectCodeDialogViewModel, (vm) => new ConnectCodeDialogView(vm), new TransitionFadeInFromRight(), new TransitionFadeOutToRight()),
                    ],
                    transitionActivateFirst: new TransitionFadeIn(),
                    transitionDeactivateLast: new TransitionFadeOut(),
                    transitionDeactiveteNew: new TransitionFadeOutToLeft(),
                    transitionActivateNew: new TransitionFadeInFromRight(),
                    transitionDeactiveteExisting: new TransitionFadeOutToRight(),
                    transitionActivateExisting: new TransitionFadeInFromLeft(),
                })
            ]
        });
    }


}