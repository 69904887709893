import { H2TextPart, IParamH2TextPart } from "../../../../lib/ui/parts/text/H2TextPart";
import { ViewPart } from "../../../../lib/ui/parts/ViewPart";
import { StyleConstants } from "../style/StyleConstants";
import theme from "../../../../app/style/Theme";
import { Colors } from "../colors/Colors";

export interface IParamHeaderText extends IParamH2TextPart {
}

export class HeaderText extends H2TextPart {

    constructor(par: IParamHeaderText) {

        ViewPart.setDefaultStyle(par, {
            color: Colors.headerTextColor,
            fontSize: theme.fontSizeHeader()
        });

        super(par);
    }
}