import { ViewModel } from "../../../../../lib/ui/viewmodels/common/ViewModel";
import { ObservableDef, IObservable, IObservableDef, ObservableOpt } from "../../../../../lib/utils/eventbase/Observable";
import { IWaitlistTrack } from "../../../../../server_com/models/player/waitlist/WaitlistTrack";
import { MediaStoreState } from "../../../../../server_com/messages/E2C/IWaitlistMessageE2C";
import { WaitlistService } from "../../../../../server_com/services/player/waitlist/WaitlistService";
import { ListItemViewModel } from "../../../../../lib/ui/viewmodels/common/ListItemViewModel";
import { StyleConstants } from "../../../../../common/ui/parts/style/StyleConstants";
import { EngineSelectionService } from "../../../../../server_com/services/login/EngineSelectionService";

export class WaitlistTrackViewModel extends ListItemViewModel {
    private _hasRoleController: boolean = false;

    private readonly _trackName: ObservableDef<string>;
    public get trackName(): IObservable<string> { return this._trackName; }

    private readonly _year: ObservableDef<string>;
    public get year(): IObservable<string> { return this._year; }

    private readonly _album: ObservableDef<string>;
    public get album(): IObservable<string> { return this._album; }

    private readonly _icon: ObservableDef<string>;
    public get icon(): IObservable<string> { return this._icon; }

    private readonly _showButtons: ObservableDef<boolean> = new ObservableDef<boolean>(false);
    public get showButtons(): IObservable<boolean> { return this._showButtons; }

    private readonly _isRequest: ObservableOpt<boolean> = new ObservableOpt<boolean>();
    public get isRequest(): IObservable<boolean> { return this._isRequest; }

    private readonly _mediaStoreState: ObservableDef<MediaStoreState>;
    public get mediaStoreState(): IObservableDef<MediaStoreState> { return this._mediaStoreState; }

    private _waitlistItemId: string;

    constructor(parent: ViewModel, track: IWaitlistTrack, private _editable: boolean) {
        super(parent);
        this._waitlistItemId = track.waitlistItemId;
        this._trackName = new ObservableDef<string>(`${track.artist} - ${track.title}`);
        this._year = new ObservableDef<string>(this.formatYear(track.year));
        this._album = new ObservableDef<string>(track.album);
        this._mediaStoreState = new ObservableDef<MediaStoreState>(track.mediaStoreState);
        this._icon = new ObservableDef<string>(this.getTrackIcon(track));
        this._isRequest.emit(track.isRequest);
        EngineSelectionService.instance.obsCurrentEngine.subscribeInitial(this.subscriptionContainer, engine => {
            this._hasRoleController = engine?.hasRoleController ?? false;
        });
    }

    private getTrackIcon(track: IWaitlistTrack): string {
        if (track.isKaraoke) {
            return "mic";
        } else if (track.isVideo) {
            return "movie";
        }
        return StyleConstants.playButtonIcon;
    }

    private formatYear(year: string): string {
        return year == "0" ? "" : year;
    }

    public backgroundClicked(): void {
        if (!this._editable) {
            return;
        }
        this.select();
    }

    protected onSelect(): void {
        this._showButtons.emitOnChanged(this._hasRoleController);
    }
    protected onDeselect(): void {
        this._showButtons.emitOnChanged(false);
    }

    public moveTrackUp() {
        let w = WaitlistService.getInstance();
        w.moveWaitlistItemUp(this._waitlistItemId);
    }
    public moveTrackDown() {
        let w = WaitlistService.getInstance();
        w.moveWaitlistItemDown(this._waitlistItemId);
    }
    public moveTrackToTop() {
        let w = WaitlistService.getInstance();
        w.moveWaitlistItemToTop(this._waitlistItemId);
    }

    public removeTrack() {
        let w = WaitlistService.getInstance();
        w.removeWaitlistItem(this._waitlistItemId);
    }

}