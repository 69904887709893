import { TextViewBase, IParamTextViewBase } from "../bases/TextViewBase";

export interface IParamTextPart extends IParamTextViewBase {
}

export class TextPart extends TextViewBase {

    constructor(par: IParamTextPart) {
        super("SPAN", par);
    }

    //public setTextObservable(observable: IObservable<string>): void {
    //    observable.subscribeInitial(this.subscriptionContainer, value => {
    //        this.setText(value);
    //    });
    //}

}