import { EngineMenuViewModel } from "./EngineMenuViewModel";
import { EngineConnectionService, EngineConnectionState } from "../../server_com/services/engine/EngineConnectionService";
import { EngineSelectionService, IEngineDescription } from "../../server_com/services/login/EngineSelectionService";
import { EngineConnectionMenuViewModel } from "./connection/EngineConnectionMenuViewModel";
import { ContentSwitchViewModel, WindowEvents, ViewModel } from "../../lib/index";
import { PlayerViewModel } from "./player/PlayerViewModel";
import { NamedContentSwitch } from "../../lib/ui/viewmodels/common/NamedContentSwitch";

type Names = "Player" | "Loader";

export class EngineConnectionViewModel extends NamedContentSwitch<Names> {

    private readonly _engineConnectionService: EngineConnectionService;
    private readonly _engineSelectionService: EngineSelectionService;

    private _currentConnectionToEngine: IEngineDescription | null = null;

    constructor(parent: ViewModel) {
        super(parent);

        this.addViewModelFactory("Player", () => new PlayerViewModel(this));
        this.addViewModelFactory("Loader", () => new EngineConnectionMenuViewModel(this));

        this.switchToName("Loader");

        this._engineConnectionService = EngineConnectionService.getInstance();
        this._engineSelectionService = EngineSelectionService.instance;

        this._engineConnectionService.obsEngineConnectionsState.subscribeInitial(this.subscriptionContainer, (engineConnectionsState) => this.onEngineConnectionStateChanged(engineConnectionsState));
        this._engineSelectionService.obsCurrentEngine.subscribeInitial(this.subscriptionContainer, (engine) => this.setConnection(engine));
    }

    public onEngineConnectionStateChanged(engineConnectionsState: EngineConnectionState): void {
        if (engineConnectionsState == EngineConnectionState.Connected) {
            this.switchToName("Player");
        } else {
            this.switchToName("Loader");
        }
    }

    public retryConnection() {
        if (this._currentConnectionToEngine != null) {
            this.connectTo(this._currentConnectionToEngine);
        }
    }

    private setConnection(engine: IEngineDescription | null) {
        if (engine) {
            this.connectTo(engine);
        }

    }

    public connectTo(engine: IEngineDescription) {
        this._engineConnectionService.connectTo(engine, 0);

        //let connectionChanged = this._currentConnectionToEngine != engine;
        //this._currentConnectionToEngine = engine;

        //if (this._engineConnectionService.obsEngineConnectionsState.value == EngineConnectionState.Connected && connectionChanged) {
        //    console.log("Disconnecting");
        //    this._engineConnectionService.disconnectFromEngine();
        //}

        //if (this._engineConnectionService.obsEngineConnectionsState.value != EngineConnectionState.Connected || connectionChanged) {
        //    console.log("Connecting");
        //    this._engineConnectionService.connectToEngine(engine, 0);
        //}
    }


}