import { ILocalStrings } from "./ILocalStrings";
import { ObservableOpt, IObservable } from "../../../lib/utils/eventbase/Observable";
import { DefaultStrings } from "./DefaultStrings";
import { LanguageStorage } from "./LanguageStorage";

class Language {

    public texts: ILocalStrings;

    constructor() {
        this.texts = new DefaultStrings();
        let langStore = LanguageStorage.getInstance();
        let lang = langStore.getLanguage();
        if (lang != null) {
            this.texts = Object.assign(this.texts, lang);
        }        
    }

    

}

const language = new Language();
const texts: Readonly<ILocalStrings> = language.texts;
export default texts;
export function formatTexts(text: string, ...params: string[]): string{
    for (let i = 0; i < params.length; i++) {
        text = text.replace(`{${i}}`, params[i]);
    }
    return text;
}