export class ViewStatusService {

    private static busyTokens : any[] = new Array();
    private static resolveCallbacks: (() => void)[] = new Array<(() => void)>();

    public static waitForViewsReadyAsync() : Promise<void> {
        return new Promise<void>(resolve => {
            this.resolveCallbacks.push(resolve);
            this.checkReady();
        });
    }
    
    public static markViewAsBusy(token: any) {
        this.busyTokens.push(token);
    }

    public static unMarkViewAsBusy(token: any) {
        this.busyTokens = this.busyTokens.filter(p => p !== token);
        ViewStatusService.checkReady();
    }

    private static checkReady() {
        if (this.busyTokens.length === 0) {
            let callbacks = this.resolveCallbacks;
            this.resolveCallbacks = new Array<(() => void)>();
            for (let resolveCallback of callbacks) {
                resolveCallback();
            }
        }
    }
}