import {StateContentSwitchViewModel} from "./common/StateContentSwitchViewModel";
import {EventEmitter, IEventEmitter } from "../../utils/eventbase/EventEmitter";

//Abstract MainViewModel
export abstract class MainViewModelBase extends StateContentSwitchViewModel {
    private _updateHash: EventEmitter<null> = new EventEmitter<null>();

    constructor() {
        super(null);
    }


    public get onUpdateHash(): IEventEmitter<null> { return this._updateHash }

    public invalidateHash(): void {
        this._updateHash.emit(null);
    }

    public navigateToHere() {
        this._updateHash.emit(null);
    }
}