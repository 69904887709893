import { ComponentViewModel } from "../../../../lib/ui/viewmodels/components/ComponentViewModel";
import { ObservableOpt, IObservable } from "../../../../lib/utils/eventbase/Observable";
import { TracklistsService } from "../../../../server_com/services/player/playlist/TracklistsService";
import { ViewModel } from "../../../../lib/ui/viewmodels/common/ViewModel";
import { ITracklistDetails } from "../../../../server_com/models/player/tracklist/ITracklistDetails";
import { TracklistItemViewModel } from "./TracklistItemViewModel";
import { AutomatService } from "../../../../server_com/services/player/automat_programs/AutomatService";
import { TextPopupViewModel } from "../popups/TextPopupViewModel";
import texts from "../../../../common/ui/localization/Language";
import { EngineSelectionService } from "../../../../server_com/services/login/EngineSelectionService";

export class TracklistComponentViewModel extends ComponentViewModel {


    private _obsLoading: ObservableOpt<boolean> = new ObservableOpt();
    public get obsLoading(): IObservable<boolean> { return this._obsLoading; }

    private _obsShowButtons: ObservableOpt<boolean> = new ObservableOpt();
    public get obsShowButtons(): IObservable<boolean> { return this._obsShowButtons; }

    private _obsTitle: ObservableOpt<string> = new ObservableOpt();
    public get obsTitle(): IObservable<string> { return this._obsTitle; }

    private _obsTracks: ObservableOpt<TracklistItemViewModel[]> = new ObservableOpt();
    public get obsTracks(): IObservable<TracklistItemViewModel[]> { return this._obsTracks; }

    private _tracklistId: string;

    private _playlistService: TracklistsService;

    constructor(parent: ViewModel, id: string) {
        super(parent, texts.loadingMin);
        this._tracklistId = id;
        this._playlistService = TracklistsService.getInstance();

        EngineSelectionService.instance.obsCurrentEngine.subscribeInitial(this.subscriptionContainer, engine => {
            this._obsShowButtons.emit(engine?.hasRoleController ?? false);
        });
    }

    public async openPlayListAsync() {
        this.loadPlayList();
        await this.executeComponentAsync();
    }

    private async loadPlayList() {
        this._obsLoading.emit(true);
        this._obsTitle.emit(texts.loadingMin);
        
        let res = await this._playlistService.getTracklistDetailsAsync(this._tracklistId);
        this.setTracklist(res);
        this._obsLoading.emit(false);
    }

    private setTracklist(tracklist: ITracklistDetails) {
        this._obsTitle.emit(tracklist.name);
        this.setComponentTitle(tracklist.name);
        this._obsTracks.emit(tracklist.tracks.map((i) => {
            return new TracklistItemViewModel(this, i, this._tracklistId);
        }));
    }

    public playListNow() {
        this._playlistService.addList(this._tracklistId, true);
        let popup = new TextPopupViewModel(this);
        popup.show(this._obsTitle.optValue + " is toegevoegt aan de automaat.");
    }
    public addList() {
        this._playlistService.addList(this._tracklistId, false);
        let popup = new TextPopupViewModel(this);
        popup.show(this._obsTitle.optValue + " is toegevoegt aan de automaat.");
    }
}