import { IObservable } from "../../../utils/eventbase/Observable";
import { Part } from "../Part";

export interface IParamItemsFactoryPart<TViewModel> {
    itemsSource?: TViewModel[];
    itemsSourceSubscription?: IObservable<TViewModel[] | null>;
    itemViewFactory?: (vm: TViewModel, position: ItemPosition) => Part;
    itemsContainerFactory?: () => Part;
    headerViewFactory?: (count: number) => Part;
    footerViewFactory?: (count: number) => Part;
    nullViewFactory?: () => Part;
}

export class ItemsFactoryPart<TViewModel> extends Part {

    constructor(par: IParamItemsFactoryPart<TViewModel>) {

        super({});

        if (par.itemsSource) {
            ItemsFactoryPart.createItems(par.itemsSource, par, this);

        }
        else if (par.itemsSourceSubscription) {
            par.itemsSourceSubscription.subscribeInitial(this.subscriptionContainer, (viewModels: TViewModel[] | null) => {

                // TODO: Maybee in the future: Optimize performance by not deleting all childs and rebuild them all.
                this.removeChildren();

                if (viewModels) {
                    ItemsFactoryPart.createItems(viewModels, par, this);
                } else {
                    if (par.nullViewFactory) {
                        this.addChild(par.nullViewFactory());
                    }
                }
            });
        }
    }

    private static createItems<TViewModel>(viewModels: TViewModel[], par: IParamItemsFactoryPart<TViewModel>, part: Part) {
        let count = viewModels.length;

        if (par.headerViewFactory) {
            part.addChild(par.headerViewFactory(count));
        }

        let container: Part = part;
        if (par.itemsContainerFactory) {
            container = par.itemsContainerFactory();
            part.addChild(container);
        }

        if (par.itemViewFactory) {
            for (let i = 0; i < viewModels.length; i++) {
                let viewModel = viewModels[i];
                let part = par.itemViewFactory(
                    viewModel, {
                        itemsCount: viewModels.length,
                        itemNr: i
                    } as ItemPosition);
                container.addChild(part);
            }
        }

        if (par.footerViewFactory) {
            part.addChild(par.footerViewFactory(count));
        }

    }
}
export interface ItemPosition {
    itemNr: number;
    itemsCount: number;
}