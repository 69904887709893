import {EventEmitter} from "../utils/eventbase/EventEmitter";

export class WindowEvents {
    private static _singelton: WindowEvents;
    public static getInstance(): WindowEvents {
        return this._singelton || (this._singelton = new WindowEvents());
    }

    private constructor() {
        window.addEventListener("blur", () => (this._onBlurEvent.emit({})));
        window.addEventListener("focus", () => (this._onFocusEvent.emit({})));
        window.addEventListener("offline", () => {
            this._onOfflineEvent.emit({});
        });
        window.addEventListener("online", () => {
            this._onOnlineEvent.emit({});
        });
        window.addEventListener("resize", ()=>(this._onResizeEvent.emit({})));
        
    }

    private _onBlurEvent: EventEmitter<{}> = new EventEmitter<{}>();
    private _onFocusEvent: EventEmitter<{}> = new EventEmitter<{}>();
    private _onOfflineEvent: EventEmitter<{}> = new EventEmitter<{}>();
    private _onOnlineEvent: EventEmitter<{}> = new EventEmitter<{}>();
    private _onResizeEvent: EventEmitter<{}> = new EventEmitter<{}>();


    public get onBlurEvent() { return this._onBlurEvent; }
    public get onFocusEvent() { return this._onFocusEvent; }
    public get onOfflineEvent() { return this._onOfflineEvent; }
    public get onOnlineEvent() { return this._onOnlineEvent; }
    public get onResizeEvent() { return this._onResizeEvent; }
}