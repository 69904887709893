import { ViewModel } from "../../../../../lib/ui/viewmodels/common/ViewModel";
import { ObservableOpt, IObservable } from "../../../../../lib/utils/eventbase/Observable";
import { PlayListSubItemViewModel } from "./sub_item/PlayListSubItemViewModel";
import { IProgramGenreItem } from "../../../../../server_com/messages/E2C/IAutomatProgramsListMessageE2C";
import { ITracklistHdr } from "../../../../../server_com/models/player/tracklist/ITracklistHdr";
import { TracklistsService } from "../../../../../server_com/services/player/playlist/TracklistsService";
import { ListItemViewModel } from "../../../../../lib/ui/viewmodels/common/ListItemViewModel";

export class PlayListItemViewModel extends ListItemViewModel {

    private _obsOpen: ObservableOpt<boolean> = new ObservableOpt();
    public get obsOpen(): IObservable<boolean> { return this._obsOpen; }

    private _obsScrollTo: ObservableOpt<boolean> = new ObservableOpt();
    public get obsScrollTo(): IObservable<boolean> { return this._obsScrollTo; }

    private _obsTitle: ObservableOpt<string> = new ObservableOpt();
    public get obsTitle(): IObservable<string> { return this._obsTitle; }
    private readonly _tracklistId: string;

    private _obsSubPlayLists: ObservableOpt<PlayListSubItemViewModel[]> = new ObservableOpt();
    public get obsSubPlayLists(): IObservable<PlayListSubItemViewModel[]> { return this._obsSubPlayLists; }

    private _tracklistsService: TracklistsService;

    private _subItemsLoaded: boolean;

    constructor(parent: ViewModel, item: ITracklistHdr) {
        super(parent);
        this._tracklistsService = TracklistsService.getInstance();
        this._tracklistId = item.id;
        this._obsOpen.emit(false);
        this._obsTitle.emit(item.name);
        this._subItemsLoaded = false;
        this._obsOpen.subscribeInitial(this.subscriptionContainer, (v) => {
            setTimeout(() => this._obsScrollTo.emit(v), 300);
        });
    }

    protected onSelect(): void {
        if (!this._subItemsLoaded) {
            this.loadSubitemsAsync();
        } else {
            this._obsOpen.emit(true);
        }

    }

    protected onDeselect(): void {
        this._obsOpen.emit(false);
    }

    public onclick() {
        if (this.isSelected) {
            this.deselect();
        } else {
            this.select();
        }
    }

    private async loadSubitemsAsync() {
        let tracklist = await this._tracklistsService.getTracklistHdrDetailsAsync(this._tracklistId);
        this._obsSubPlayLists.emit(tracklist.sublists.map((i) => {
            return new PlayListSubItemViewModel(this, i);
        }));
        this._subItemsLoaded = true;
        this._obsOpen.emit(true);
    }
}