import { IComponentContainer } from "../../components/IComponentContainer";
import { IViewFactory } from "../contentmanagers/IViewFactory";
import { IViewFactoryDetector } from "../../components/IViewFactoryDetector";
import { ComponentViewModel } from "../../viewmodels/components/ComponentViewModel";
import { ViewModel } from "../../viewmodels/common/ViewModel";
import { ViewPart } from "../ViewPart";
import { ContainerPart, IParamContainerPart } from "../containers/ContainerPart";
import { DefaultPopupOverlay } from "../popup/DefaultPopupOverlay";


export interface IOverlayViewModel {
    readonly contentViewPart: ViewPart;
    readonly contentViewModel: ComponentViewModel;
}

export interface IParamPopupComponentContainerPart extends IParamContainerPart {
    componentContainer: IComponentContainer,
    overlayFactory?: ((vm: IOverlayViewModel) => ViewPart),
    viewFactories: IViewFactory[],
}


export class PopupComponentContainerPart extends ContainerPart implements IViewFactoryDetector {

    private _componentContainer: IComponentContainer;
    private _viewFactories: IViewFactory[];
    private _componentViews: ComponentView[] = [];
    private _stagedViewToShow = "";
    private _stagedViewModel: ComponentViewModel | null = null;
    private _stageTimeOut = -1;
    private _overlayFactory: ((vm: IOverlayViewModel) => ViewPart);


    constructor(par: IParamPopupComponentContainerPart) {
        super(par);

        this._viewFactories = par.viewFactories;
        this._componentContainer = par.componentContainer;
        this._componentContainer.obsViewModels.subscribe(this.subscriptionContainer, (vms) => this.viewModelsChanged(vms));
        this._componentContainer.addViewDetector(this);

        this._overlayFactory = par.overlayFactory ? par.overlayFactory : (vm) => new DefaultPopupOverlay(vm);
        this.visible = false;
    }

    public onDispose(): void {
        this._componentContainer.removeViewDetector(this);
        super.onDispose();
    }

    private viewModelsChanged(viewModels: ComponentViewModel[]): void {
        let index = 0;
        for (var viewModel of viewModels) {
            let componentView: ComponentView | null = null;
            if (index < this._componentViews.length) {
                componentView = this._componentViews[index];
                if (componentView.contentViewModel !== viewModel) {
                    componentView = null;
                    while (this._componentViews.length > index) {
                        let cv = this._componentViews.pop();
                        if (cv) {
                            cv.remove();
                        }
                    }
                }
            }

            if (!componentView) {
                let contentView = this.createView(viewModel);
                componentView = new ComponentView(this, viewModel, contentView, this._overlayFactory);
                this._componentViews.push(componentView);
            }

            index = index + 1;
        }

        while (this._componentViews.length > index) {
            let cv = this._componentViews.pop();
            if (cv) {
                cv.remove();
            }
        }

        this.visible = this._componentViews.length > 0;
    }

    private createView(viewModel: ViewModel): ViewPart {
        for (var viewFactory of this._viewFactories) {
            if (viewFactory.contentName === viewModel.name) {
                return viewFactory.createView(viewModel);
            }
        }

        throw "View not found: " + viewModel.name;
    }

    public viewDoesExistsForViewModel(viewModel: ViewModel): boolean {
        for (var viewFactory of this._viewFactories) {
            if (viewFactory.contentName === viewModel.name) {
                return true;
            }
        }
        return false;
    }
}

class ComponentView implements IOverlayViewModel {
    private readonly _componentContainer: PopupComponentContainerPart;
    private readonly _overlayViewPart: ViewPart;
    private readonly _contentViewPart: ViewPart;
    private readonly _viewModel: ComponentViewModel;

    constructor(componentContainer: PopupComponentContainerPart, viewModel: ComponentViewModel, content: ViewPart, overlayFactory: ((vm: IOverlayViewModel) => ViewPart)) {
        this._componentContainer = componentContainer;
        this._contentViewPart = content;
        this._viewModel = viewModel;

        this._overlayViewPart = overlayFactory(this);

        componentContainer.addChild(this._overlayViewPart);
    }

    public get contentViewPart(): ViewPart { return this._contentViewPart; }

    public get contentViewModel(): ComponentViewModel { return this._viewModel; }

    public remove() {
        this._componentContainer.removeChild(this._overlayViewPart);
    }
}