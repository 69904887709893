export class FetchManager {

    private static singeton: FetchManager;

    public static getInstance(): FetchManager {
        return this.singeton || (this.singeton = new FetchManager());
    }

    public fetch<T>(url: string, data?: { [index: string]: string }): Promise<T> {
        const promise = new Promise<T>((resolve, reject) => {

            const requestData = {
                credentials: "same-origin"
            } as RequestInit;
            window.fetch(url + this.formatUrlParameters(data), requestData)
                .then(
                (response: any) => {
                    if (response.status !== 200) {
                        reject(Error(`Fetch failed with status code: ${response.status}`));
                        return;
                    } else {
                        response.json().then((data: any) => {
                            resolve(data as T);
                        });
                    }
                }
                )
                .catch((err: any) => {
                    reject(err);
                });
        });
        return promise;
    }

    public fetchPostWithResult<T>(url: string, postdata: any): Promise<T> {
        const promise = new Promise<T>((resolve, reject) => {
            const headers = this.makeHeaders();
            headers.append("Content-Type", "application/json");
            const data: any = {
                method: "POST",
                body: JSON.stringify(postdata),
                headers: headers,
                credentials: "same-origin"
            };
            (window as any).fetch(url, data)
                .then(
                (response: any) => {
                    if (response.status !== 200) {
                        reject(response);
                        return;
                    } else {
                        response.json().then((data: any) => {
                            resolve(data as T);
                        });
                    }
                })
                .catch((err: any) => {
                    reject(err);
                });
        });
        return promise;
    }

    public fetchPost(url: string, postdata: any): Promise<{}> {
        const promise = new Promise<{}>((resolve, reject) => {
            const headers = this.makeHeaders();
            headers.append("Content-Type", "application/json");
            const data: any = {
                method: "POST",
                body: JSON.stringify(postdata),
                headers: headers,
                credentials: "same-origin"
            };
            (window as any).fetch(url, data)
                .then(
                (response: any) => {
                    if (response.status !== 200) {
                        reject(Error(`Fetch failed with status code: ${response.status}`));
                        return;
                    } else {
                        resolve({});
                    }
                })
                .catch((err: any) => {
                    reject(err);
                });
        });
        return promise;
    }

    private formatUrlParameters(data?: { [index: string]: string }): string {
        if (!data || data == {}) {
            return "";
        }
        let parameters: string[] = []
        for (let prop in data) {
            if (typeof data[prop] === "string") {
                parameters.push(`${prop}=${encodeURIComponent(data[prop])}`);
            }
        }
        return "?" + parameters.join('&');
    }

    private makeHeaders(): any {
        return new (window as any).Headers();
    }
}