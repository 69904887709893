import { ButtonPart, IParamButtonPart } from "../../../../lib/ui/parts/button/ButtonPart";
import { ImagePart } from "../../../../lib/ui/parts/image/ImagePart";
import { PartSize, ViewPart } from "../../../../lib/ui/parts/ViewPart";
import { IPartStyle } from "../../../../lib/ui/parts/IPartStyle";
import { StyleConstants } from "../style/StyleConstants";
import { IObservable } from "../../../../lib/utils/eventbase/Observable";
import { HybridIcon } from "../icon/HybridIcon";
import theme from "../../../../app/style/Theme";

export enum IconButtonSize {
    Small,
    Medium,
    Big,
    Huge,
}

export interface IParamIconButton extends IParamButtonPart {
    icon?: string;
    iconSubscription?: IObservable<string>;
    iconButtonSize?: IconButtonSize;

}

export class IconButton extends ButtonPart {

    private _iconPart: HybridIcon;

    constructor(par: IParamIconButton) {

        if (!par.partSize) par.partSize = PartSize.FullSize;
        ViewPart.setDefaultStyle(par, {
            backgroundColor: "transparent",
            filter: "",
            border: "none",
            outline: "0",
            transition: StyleConstants.transition,
            color: "white",
            width: "fit-content",
            height: "fit-content"
        });
        super(par);
        this._iconPart = new HybridIcon({
            icon: par.icon,
            iconSize: par.iconButtonSize != undefined ? this.getButtonSize(par.iconButtonSize) : theme.scalePx(30),
            iconSubscription: par.iconSubscription
        });
        this.addChild(this._iconPart);
    }

    public getButtonSize(size: IconButtonSize) {
        switch (size) {
            case IconButtonSize.Small:
                return theme.scalePx(15);
            case IconButtonSize.Medium:
                return theme.scalePx(30);
            case IconButtonSize.Big:
                return theme.scalePx(38);
            case IconButtonSize.Huge:
                return theme.scalePx(45);
            default:
                return theme.scalePx(30);
        }
    }

    private applyDefaultStyle() {
        let filter = "";
        if (this.button.disabled) {
            filter += " contrast(60%)";
        }

        const style: IPartStyle = {
            filter: filter,
        };
        this.applyStyle(style);
    }

    protected onClickDisabled(): void {
        super.onClickDisabled();
        this.applyDefaultStyle();
    }

    protected onClickEnabled(): void {
        super.onClickEnabled();
        this.applyDefaultStyle();
    }
}

