import { TextViewBase, IParamTextViewBase } from "../bases/TextViewBase";

export interface IParamH2TextPart extends IParamTextViewBase {
}

export class H2TextPart extends TextViewBase {

    constructor(par: IParamH2TextPart) {
        super("H2", par);
    }
}