import { ViewModel } from "../../../../../lib/ui/viewmodels/common/ViewModel";
import { AutomatService, AutomatStatus } from "../../../../../server_com/services/player/automat_programs/AutomatService";
import { ObservableDef, IObservable } from "../../../../../lib/utils/eventbase/Observable";
import { ProgramStatusViewModel } from "./program_status/ProgramStatusViewModel";

export class AutomatControlsViewModel extends ViewModel {

    private _automatService: AutomatService;
    private _obsAutomatStatus = new ObservableDef<boolean>(false);
    private _obsAutomatStatusItems = new ObservableDef<ProgramStatusViewModel[]>([]);

    constructor(parent: ViewModel | null) {
        super(parent);
        this._automatService = AutomatService.getInstance();
        this._automatService.status.subscribeInitial(this.subscriptionContainer, (s) => (this.automatStatusChange(s)));

        this._automatService.activate(this);
    }

    public get obsAutomatStatus(): IObservable<boolean> { return this._obsAutomatStatus; }
    public get obsAutomatStatusItems(): IObservable<ProgramStatusViewModel[]> { return this._obsAutomatStatusItems; }

    private automatStatusChange(s: AutomatStatus | null): void {
        if (s) {
            this._obsAutomatStatus.emit(s.activated);
            this._obsAutomatStatusItems.emit(s.currentPrograms.map(p => (new ProgramStatusViewModel(this, p))));
        } else {
            this._obsAutomatStatus.emit(false);
            this._obsAutomatStatusItems.emit([]);
        }

    }

    public dispose() {
        super.dispose();
        this._automatService.deactivate(this);
    }

    public turnOffAutomatClick() {
        this._automatService.turnOffAutomat();
    }

    public removeAutomatProgram(id: string): void {
        this._automatService.removeAutomatProgram(id);
    }

    public increaseAutomatProgram(id: string): void {
        this._automatService.increaseAutomatProgram(id);
    }

    public decreaseAutomatProgram(id: string): void {
        this._automatService.decreaseAutomatProgram(id);
    }

}