import { DeviceVerificationService, GetDeviceTokenResult } from './DeviceVerificationService';
import { FetchService } from '../../apilib/index';
import { IObservable, ObservableOpt } from '../../../lib/utils/eventbase/Observable';
import { IRefreshToken, RefreshTokenStore } from '../../utils/stores/RefreshTokenStore';
import { PromiseAggregator } from '../../../common/utils/index';
import { UrlRepository } from '../../utils/UrlRepository';

export type LoginStatus = "no_licence" | "logged_in" | "busy" | "not_started" | "error";

export class LoginService {
    
    private static _singleton: LoginService;
    public static getInstance(): LoginService {
        return this._singleton || (this._singleton = new LoginService());
    }

    private _deviceVerificationService: DeviceVerificationService;
    private _accesTokenPromiseArregator: PromiseAggregator<GetDeviceTokenResult>;
    public get deviceNr(): number {
        if (!this._controllerTokens) {
            throw new Error("No device nr when not loaded");
        }
        return this._controllerTokens?.deviceNr
    }
    //Todo: remove this te use the default login

    private _controllerTokens: IControllerToken | null = null;
    //private _controllerTokens: IControllerToken | null = {
    //    controllerRefreshToken: "aYstIhYq4c0bgeRPQ0qkfxXccrn3i/HIWIJ7OB36YGJSmhVKUHFty7HsoIzancwLURZOxfDhYgptpCbxxcV5pg==.1.eyJJZCI6ImIxOTYzNjc3LWY2OTEtNGNhMi1iMDRkLTYyY2ZkZjBmYzBkZCIsIkV4cCI6IjIwMjAtMTItMjhUMTQ6MDQrMDA6MDAiLCJVc2UiOiJDb250cm9sbGVyUmZyc2gifQ==",
    //    refreshTokenValidUntilDateTime: "2020-12-28T14:04:00+00:00",
    //    controllerAccessToken: "kQTwRA0gdZkg0/eInWay4mDn/cP/KCFvGy4Wv1O4s/q3Hy2uK2xPg2VqmR5A7MQ7ZO7ejAIl8NlwD66aa4X7kA==.1.eyJJZCI6ImIxOTYzNjc3LWY2OTEtNGNhMi1iMDRkLTYyY2ZkZjBmYzBkZCIsIkV4cCI6IjIwMTktMDgtMjhUMDg6MzkrMDA6MDAiLCJVc2UiOiJDb250cm9sbGVyQWNjcyJ9",
    //    accessTokenValidUntilDateTime: "2019-08-28T08:39:17.7366913+00:00"
    //};

    private _loginStatus: ObservableOpt<LoginStatus> = new ObservableOpt<LoginStatus>();
    public get obsLoginStatus(): IObservable<LoginStatus> { return this._loginStatus; }

    private _loginError: ObservableOpt<string> = new ObservableOpt<string>();
    public get obsLoginError(): IObservable<string> { return this._loginError; }


    public get obsLoginStep(): IObservable<string> { return this._deviceVerificationService.obsVerifyStep; }

    private constructor() {
        this._deviceVerificationService = DeviceVerificationService.getInstance();
        this._loginStatus.emit("not_started");
        this._accesTokenPromiseArregator = new PromiseAggregator(() => this.runDeviceVerificationInternal());
    }

    public async tryLoginAsync() {
        this._loginStatus.emit("busy");
        let token = await this.runDeviceVerification();
        if (token.result == "NotCoupled") {
            this._loginStatus.emit("no_licence");
        } else if (token.result == "Error") {
            this._loginStatus.emit("error");
            this._loginError.emit(token.message);
        } else {
            this._loginStatus.emit("logged_in");
        }
    }

    public async runDeviceVerification(): Promise<GetDeviceTokenResult> {
        return this._accesTokenPromiseArregator.executeAsync();
    }

    private async runDeviceVerificationInternal(): Promise<GetDeviceTokenResult> {
        if (this._controllerTokens != null) {
            return {
                result: "Succes",
                token: this._controllerTokens.token,
                deviceNr: this._controllerTokens.deviceNr
            };
        } else {
            const tokenRes = await this._deviceVerificationService.tryGetTokenAsync();
            if (tokenRes.result === "Succes") {
                this._controllerTokens = tokenRes;
            }
            return tokenRes;
        }
    }


    private headerFactory(headers: Headers): void {
        headers.append("Content-Type", "application/json");
    }
}

export interface IControllerToken {
    token: string;
    deviceNr: number;
}