import { ViewPart, IParamViewPart } from "../ViewPart";
import { IObservable } from "../../../utils/eventbase/Observable";

export interface IParamInputBase extends IParamViewPart {
    enabled?: boolean;
    enabledSubscription?: IObservable<boolean>;
    disabled?: boolean;
    disabledSubscription?: IObservable<boolean>;
    focus?: boolean;
}

export class InputBase extends ViewPart {
    constructor(type: string, par: IParamInputBase) {
        super("INPUT", par);

        this.inputElement.type = type;
        this.inputElement.onblur = () => { this.onBlur(); };

        if (par.enabled === false) {
            this.disable();
        } else if (par.disabled) {
            this.disable();
        } else {
            this.enable();
        }

        if (par.enabledSubscription) {
            par.enabledSubscription.subscribeInitial(this.subscriptionContainer, (enabled) => {
                if (enabled) this.enable();
                else this.disable();
            });
        }

        if (par.disabledSubscription) {
            par.disabledSubscription.subscribeInitial(this.subscriptionContainer, (disabled) => {
                if (disabled) this.disable();
                else this.enable();
            });
        }

        if (par.focus) {
            this.focusAsync();
        }
    }

    protected get inputElement(): HTMLInputElement {
        return this.root as HTMLInputElement;
    }

    public disable(): void {
        this.onDisabled();
    }

    public enable(): void {
        this.onEnabled();
    }

    protected onDisabled(): void {
        this.inputElement.disabled = true;
    }

    protected onEnabled(): void {
        this.inputElement.disabled = false;
    }

    public async focusAsync(): Promise<void> {
        await this.delay(1);
        this.inputElement.focus();
    }

    public dispose(): void {
        this.inputElement.onblur = null;
        super.dispose();        
    }

    protected onBlur(): void {
    }


}