import { GridContainer, IParamGridContainer } from "../../../lib/ui/parts/containers/GridContainer";
import { SmallAblumCover } from "../../../common/ui/parts/image/SmallAlbumCover";
import { NormalText } from "../../../common/ui/parts/text/NormalText";
import texts from "../../../common/ui/localization/Language";
import { TrackNameText } from "../../../common/ui/parts/text/TrackNameText";
import { PlayerIconButton } from "../../../common/ui/parts/buttons/PlayerIconButton";
import { ProgressBar } from "../../../common/ui/parts/progressbar/ProgressBar";
import { PlayerBarActionButton } from "../../../common/ui/parts/player/PlayerBarActionButton";
import { ImagePart } from "../../../lib/ui/parts/image/ImagePart";
import { StyleConstants } from "../../../common/ui/parts/style/StyleConstants";
import { PlayerControlBarViewModel } from "./PlayerControlBarViewModel";
import theme from "../../../app/style/Theme";
import { IconButtonSize } from "../../../common/ui/parts/buttons/IconButton";
import { Part, StyleBinding } from "../../../lib/index";
import screenPropertiesService from "../../../lib/services/ScreenPropertiesService";
import { Colors } from "../../../common/ui/parts/colors/Colors";

export interface IParamPlayerControlBar extends IParamGridContainer {

}

export class PlayerControlBarView extends GridContainer {
    constructor(par: IParamPlayerControlBar, viewmodel: PlayerControlBarViewModel) {
        PlayerControlBarView.setDefaultStyle(par, {
            padding: theme.getSpacePx(5),
            backgroundColor: Colors.seconderyBackgroundColor,
        });

        par.children = [
            new GridContainer({
                style: {
                    alignItems: "center",
                    gridTemplateAreas: `"cover header controls buttons logo" "cover header controls buttons logo" "cover title  title buttons logo"`,
                    gridTemplateColumns: `${theme.scalePx(75)} ${theme.scalePx(145)} 1fr ${theme.scalePx(135)} ${theme.scalePx(90)}`,
                    gridTemplateRows: `33% 33% 33%`,
                },
                invisibleSubscription: screenPropertiesService.obsIsSmall,
                children:[
                    PlayerControlBarView.createCover(viewmodel),
                    PlayerControlBarView.createHeader(viewmodel),
                    PlayerControlBarView.createTitle(viewmodel),
                    PlayerControlBarView.createControls(viewmodel),
                    PlayerControlBarView.createButtons(viewmodel),
                    PlayerControlBarView.createLogo(viewmodel),
                ]
            }),
            new GridContainer({
                style: {
                    alignItems: "center",
                    gridTemplateAreas: `"cover controls buttons" "cover controls buttons" "cover title buttons"`,
                    gridTemplateColumns: `${theme.scalePx(75)} 1fr ${theme.scalePx(105)}`,
                    gridTemplateRows: `33% 33% 33%`,
                },
                visibleSubscription: screenPropertiesService.obsIsSmall,
                children: [
                    PlayerControlBarView.createCover(viewmodel),
                    PlayerControlBarView.createTitle(viewmodel),
                    PlayerControlBarView.createControls(viewmodel),
                    PlayerControlBarView.createButtons(viewmodel),
                ]
            }),
        ]
        super(par);
    }

    private static createCover(viewmodel: PlayerControlBarViewModel):Part  {
        return new SmallAblumCover({
            style: {
                marginRight: theme.getSpacePx(5),
                gridArea: "cover"
            }
        });
    }

    private static createHeader(viewmodel: PlayerControlBarViewModel): Part {
        return new NormalText({
            text: texts.nowPlaying,
            style: {
                gridArea: "header",
                alignSelf: "end"
            }
        });
    }

    private static createTitle(viewmodel: PlayerControlBarViewModel): Part {
        return new TrackNameText({
            style: {
                gridArea: "title",
            },
            textSubscription: viewmodel.obsTrackName
        });
    }

    private static createControls(viewmodel: PlayerControlBarViewModel): Part {
        return new GridContainer({
            style: {
                gridArea: "controls",
                gridTemplateAreas:
                    `"space_left buttons space_right" "start_time bar end_time"`,
                gridTemplateRows: "1fr min-content",
                gridTemplateColumns: `${theme.scalePx(30)} 1fr ${theme.scalePx(30)}`,
                justifyItems: "center",
            },
            children: [
                new GridContainer({
                    style: {
                        gridArea: "buttons",
                        width: theme.scalePx(160),
                        justifyContent: "space-between",
                        alignContent: "center",
                        gridTemplateColumns: "auto auto auto",
                    },
                    children: [
                        new PlayerIconButton({
                            icon: "icon-grey-previous",
                            onclick: () => viewmodel.buttonBack(),
                            iconButtonSize: IconButtonSize.Big
                        }),
                        new PlayerIconButton({
                            icon: "icon-grey-play",
                            invisibleSubscription: viewmodel.obsTrackPlaying,
                            onclick: () => viewmodel.buttonPlay(),
                            iconButtonSize: IconButtonSize.Big
                        }),
                        new PlayerIconButton({
                            icon: "icon-grey-pause",
                            visibleSubscription: viewmodel.obsTrackPlaying,
                            onclick: () => viewmodel.buttonPause(),
                            iconButtonSize: IconButtonSize.Big
                        }),
                        new PlayerIconButton({
                            icon: "icon-grey-next",
                            onclick: () => viewmodel.buttonNext(),
                            iconButtonSize: IconButtonSize.Big
                        }),
                    ]
                }),
                new NormalText({
                    style: {
                        fontSize: theme.fontSizeSmall(),
                        gridArea: "start_time",
                        alignSelf: "center"
                    },
                    textSubscription: viewmodel.obsTrackProgressText
                }),
                new ProgressBar({
                    style: {
                        gridArea: "bar"
                    },
                    progressSubscription: viewmodel.obsTrackProgress
                }),
                new NormalText({
                    style: {
                        fontSize: theme.fontSizeSmall(),
                        gridArea: "end_time",
                        alignSelf: "center"
                    },
                    textSubscription: viewmodel.obsTrackTotalText
                }),
            ]
        });
    }

    private static createButtons(viewmodel: PlayerControlBarViewModel): Part {
        return new GridContainer({
            style: {
                gridArea: "buttons",
                gridTemplateColumns: "auto auto",
                alignItems: "center",
                justifyContent: "end",
                paddingRight: theme.getSpacePx(10),
                gridColumnGap: theme.getSpacePx(10)
            },
            children: [
                new PlayerBarActionButton({
                    style: {
                        margin: theme.getSpacePx(5),
                    },
                    iconName: "icon-search",
                    onclickAsync: () => viewmodel.playerNavigationManager.searchButtonClickAsync(true),
                    activatedSubscription: viewmodel.playerNavigationManager.searchMenuButtonActive
                }),
                new PlayerBarActionButton({
                    style: {
                        margin: theme.getSpacePx(5),
                        marginRight: theme.getSpacePx(10)
                    },
                    iconName: "icon-mixer",
                    onclickAsync: () => viewmodel.playerNavigationManager.automatButtonClickAsync(),
                    activatedSubscription: viewmodel.playerNavigationManager.automatMenuButtonActive
                })
            ]
        });
    }

    private static createLogo(viewmodel: PlayerControlBarViewModel): Part {
        return new ImagePart({
            style: {
                gridArea: "logo",
                height: theme.scalePx(45),
            },
            source: StyleConstants.logoImageURL
        });
    }

}