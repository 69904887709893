import { ContainerPart, IParamContainerPart } from "../containers/ContainerPart";
import { ComponentViewModel } from "../../viewmodels/components/ComponentViewModel";
import { IObservable } from "../../../utils/eventbase/Observable";
import { H2TextPart } from "../text/H2TextPart";
import { IconPart } from "../icon/IconPart";
import { LinkPart } from "../button/LinkPart";
import { ViewPart } from "../ViewPart";
import theme from "../../../../app/style/Theme";

export interface IParamComponentBreadCrumPart extends IParamContainerPart {
    viewModelsSubscription: IObservable<ComponentViewModel[]>,
}

export class ComponentBreadCrumPart extends ContainerPart {

    constructor(par: IParamComponentBreadCrumPart) {

        ViewPart.setDefaultStyle(par, {
            display: "grid",
            gridAutoFlow: "column",
            gridAutoColumns: "auto",
            justifyContent: "start",
            alignItems: "baseline"
        });

        super(par);

        par.viewModelsSubscription.subscribeInitial(this.subscriptionContainer, (vms) => this.viewModelsChanged(vms));
    }

    private viewModelsChanged(viewModels: ComponentViewModel[]): void {
        this.removeChildren();
        if (viewModels.length > 0) {
            for (let index = 0; index < viewModels.length - 1; index++) {
                let vm = viewModels[index];
                this.addChild(this.createParentComponentView(vm));
                this.addChild(this.createSpaceView());
            }
            this.addChild(this.createActiveComponentView(viewModels[viewModels.length - 1]));
        }
    }

    protected createParentComponentView(componentViewModel: ComponentViewModel): ViewPart {
        return new LinkPart({
            textSubscription: componentViewModel.obsComponentTitle,
            onclickAsync: async () => { await componentViewModel.closeChildComponentsAsync() },
        });
    };

    protected createActiveComponentView(componentViewModel: ComponentViewModel): ViewPart {
        return new H2TextPart({
            style: {
                display: "inline",
                paddingBottom: theme.getSpacePx(4),
                margin: "0"
            },
            textSubscription: componentViewModel.obsComponentTitle,
        });

    };

    protected createSpaceView(): ViewPart {
        return new IconPart({
            icon: "arrow_right",
            style: {
                alignSelf: "flex-end",
                paddingBottom: theme.getSpacePx(3)
            }
        });
    };

}
