import { GridContainer } from "../../../../lib/index";
import { SettingsViewModel } from "./SettingsViewModel";
import { HeaderText } from "../../../../common/ui/parts/text/HeaderText";
import theme from "../../../../app/style/Theme";
import texts from "../../../../common/ui/localization/Language";
import { LabelText } from "../../../../common/ui/parts/text/LabelText";
import { TextInput } from "../../../../common/ui/parts/input/TextInput";
import { Button } from "../../../../common/ui/parts/buttons/Button";


export class SettingsView extends GridContainer {
    constructor(viewmodel: SettingsViewModel) {
        super({
            style: {
                gridAutoFlow: "row",
                gridAutoRows: "min-content",
                padding: theme.getSpacePx(10),
                gridRowGap: theme.getSpacePx(10)
            },
            children: [
                new HeaderText({
                    text: texts.settings,
                }),
                new GridContainer({
                    style: {
                        gridTemplateColumns: "auto 1fr",
                        gridColumnGap: theme.getSpacePx(10),
                        gridTemplateRows: "auto",
                        gridAutoFlow: "row"
                    },
                    children: [
                        new LabelText({
                            style: {
                                alignSelf: "center"
                            },
                            attachId: "settings_name_input",
                            text: texts.settingsControllerName
                        }),
                        new TextInput({
                            id: "settings_name_input",
                            textSubscriptionTwoWay: viewmodel.obsTwSettingControllername
                        })
                    ]
                }),
                new Button({
                    style: {
                        width: "fit-content"
                    },
                    text: texts.settingsSave
                })
            ]
        });
    }
}