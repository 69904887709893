import { GridContainer, ItemsFactoryPart, ScrollContainerPart } from "../../lib/index";
import { EngineSelectComponentViewModel } from "./EngineSelectComponentViewModel";
import { HeaderText } from "../../common/ui/parts/text/HeaderText";
import texts from "../../common/ui/localization/Language";
import { EngineListItemView } from "./engine_list/EngineListItemView";
import theme from "../../app/style/Theme";
import { NormalText } from "../../common/ui/parts/text/NormalText";
import { TitleText } from "../../common/ui/parts/text/TitleText";
import { Button } from "../../common/ui/parts/buttons/Button";

export class EngineSelectComponentView extends GridContainer {
    constructor(viewmodel: EngineSelectComponentViewModel) {
        super({
            style: {
                gridTemplateRows: "auto min-content min-content minmax(0, 1fr) min-content",
                padding: theme.getSpacePx(10)
            },
            children: [
                new HeaderText({
                    text: texts.selectEngine
                }),
                new NormalText({
                    textSubscription: viewmodel.connectHelperText
                }),
                new TitleText({
                    textSubscription: viewmodel.deviceNr
                }),
                new ScrollContainerPart({
                    style: {
                        display: "grid",
                        gridTemplateColumns: "1fr",
                        gridAutoFlow: "row",
                        gridAutoRows: "min-content",
                    },
                    children: [
                        new ItemsFactoryPart({
                            itemViewFactory: (vm) => new EngineListItemView(vm),
                            itemsSourceSubscription: viewmodel.engineList.obsViewmodels
                        })
                    ]
                }),
                new Button({
                    style: {
                        marginBottom: "10px"
                    },
                    text: "Speler toevoegen",
                    onclickAsync: () => viewmodel.showConnectCodeDialogAsync()
                }),
                new NormalText({
                    style: {
                        fontStyle: "italic",
                        alignSelf: "flex-start",
                    },
                    textSubscription: viewmodel.versionText
                })
            ]
        });
    }
}