import { ContainerPart, IParamContainerPart } from "../../../../lib/ui/parts/containers/ContainerPart";
import { ImagePart } from "../../../../lib/ui/parts/image/ImagePart";

export interface IParamAlbumCoverBase extends IParamContainerPart {

}

export class AlbumCoverBase extends ContainerPart {

    private _image: ImagePart;

    constructor(par: IParamAlbumCoverBase) {
        super(par);
        let randNR = Math.round(Math.random() * 2);
        this._image = new ImagePart({
            source: `./res/img/albumcover_${randNR}.png`,
            style: {
                width: "100%",
                height: "100%"
            }
        });
        this.addChild(this._image);


    }
}