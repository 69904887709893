import { SubscriptionContainer } from "../../utils/eventbase/SubscriptionContainer";
import { NavigationPath } from "./NavigationPath";
import { MainViewModelBase } from "../viewmodels/MainViewModelBase";


export class NavigationManager {
    private static singleTon: NavigationManager;
    private _currentHash = "";
    private _subscriptionContainer: SubscriptionContainer;
    private _mainViewModelBase: MainViewModelBase | undefined;

    public static getInstance(): NavigationManager {
        return this.singleTon || (this.singleTon = new NavigationManager());
    }

    private constructor() {
        this._subscriptionContainer = new SubscriptionContainer(this);
        //Disabled because the hash is not used
        //window.addEventListener("hashchange", () => (this.hashChanged()));
    }

    public setMainViewModel(value: MainViewModelBase) {
        this._mainViewModelBase = value;
        this.hashChanged();
        this._mainViewModelBase.onUpdateHash.subscribe(this._subscriptionContainer, () => (this.updateLocationPath()));
    }

    public get mainViewModel(): MainViewModelBase | undefined {
        return this._mainViewModelBase;
    }

    private hashChanged() {
        //if (location.hash !== this._currentHash) {
        //    this._currentHash = location.hash;
        //    this.setNavigationPathFromHash(location.hash);
        //}
    }

    private setNavigationPathFromHash(hash: string) {
        if (this._mainViewModelBase) {
            try {
                let cmd: NavigationPath;
                cmd = NavigationPath.fromUrl(hash);
                console.log(cmd);
                this._mainViewModelBase.navigateTo(cmd);
            } catch (err) {
                console.warn("Invalid hash");
                //ignore
            }

            this.updateLocationPath();
        }
    }

    public updateLocationPath() {
        //if (this.mainViewModel) {
        //    const n = new NavigationPath();
        //    this.mainViewModel.fillNavigationPath(n);
        //    this._currentHash = n.getHash();
        //    if (location.hash !== this._currentHash) {
        //        location.hash = this._currentHash;
        //    }
        //}
    }
}