import {ViewPart, IParamViewPart} from "../ViewPart";

export interface IParamTableRowPart extends IParamViewPart {
}

export class TableRowPart extends ViewPart {
    constructor(par: IParamTableRowPart) {
        super("tr", par);
    }
}
