import { ContentSwitchViewModel } from "./ContentSwitchViewModel";
import { ViewModel } from "./ViewModel";
import { NavigationPath } from "../../navigation/NavigationPath";

export abstract class StateContentSwitchViewModel extends ContentSwitchViewModel {

    private _defaultViewModel: ViewModel | undefined;

    public setDefaultViewModel(viewModel: ViewModel) {
        this._defaultViewModel = viewModel;
    }

    public navigateTo(navPath: NavigationPath) {
        if (this._defaultViewModel != null) {
            this._defaultViewModel.navigateTo(navPath);
        }
    }

    public fillNavigationPath(navPath: NavigationPath) {
        if (this._defaultViewModel != null) {
            this._defaultViewModel.fillNavigationPath(navPath);
        }
    }
}