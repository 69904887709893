import { TextViewBase, IParamTextViewBase } from "../bases/TextViewBase";
import { ViewPart } from "../ViewPart";
import { IObservable } from "../../../utils/eventbase/Observable";


//Implementation of the google material iconset

export interface IParamIconPart extends IParamTextViewBase {
    icon?: string,
    iconSubscription?: IObservable<string>
    iconSize?: string,
}

export class IconPart extends TextViewBase {
    public constructor(par: IParamIconPart) {

        ViewPart.setDefaultStyle(par, {
            textAlign: "center",
            verticalAlign: "middle",
            userSelect: "none",
            textDecoration: "none"
        });
        super("i", par);
        this.root.className = "material-icons";

        if (par.icon) {
            this.setText(par.icon);
        }
        if (par.iconSubscription) {
            this.setTextSubscription(par.iconSubscription);
        }

        if (par.iconSize) {
            this.setIconSize(par.iconSize);
        }

    }

    public setIconSize(size: string) {
        this.applyStyle({ fontSize: size });
    }
}