import { MessageC2E } from "../../messages/MessageC2E";
import { WebsocketState } from "../websocket/WebsocketState";
import { HubConnectionService } from "../hub/HubConnectionService";
import { ConnectionState } from "../hub/ConnectionState";
import { ChannelType } from "../../messages/ChannelType";
import { IFromEngineMessageH2C } from "../../messages/H2C/IFromEngineMessageH2C";
import { IEngineDescription } from "../login/EngineSelectionService";
import { SubscriptionContainer } from "../../../lib/utils/eventbase/SubscriptionContainer";
import { ObservableDef, IObservable, IObservableDef } from "../../../lib/utils/eventbase/Observable";
import { EventEmitter, IEventEmitter } from "../../../lib/utils/eventbase/EventEmitter";
import { IConnectionService } from "../IConnectionService";
import { IConnectedEngines } from "../../models/engine/IConnectedEngines";
import { DirectEngineConnectionService } from "./DirectEngineConnectionService";
import { IMessageToControllerE2DC } from "../../messages/E2DC/IMessageToControllerE2DC";
import { FetchManager } from "../../../lib/utils/fetch/FetchManager";

export class EngineConnectionService {
    private static singleton: EngineConnectionService;

    private readonly _subscriptionContainer: SubscriptionContainer;
    private readonly _eningeConnectionSubscriptionContainer: SubscriptionContainer;
    private readonly _connectionsState = new ObservableDef<EngineConnectionState>(EngineConnectionState.Disconnected);
    private readonly _receivedFromEngineEvent = new EventEmitter<IFromEngineMessageH2C | IMessageToControllerE2DC>();
    private readonly _receivedChannelOpenMessage = new EventEmitter<ChannelType>();
    private readonly _onError = new EventEmitter<string>();

    private _connectionService: IConnectionService;
    private _connectionMode: ConnectionType;
    private _fetchManager: FetchManager;
    private _requestedEngine: IEngineDescription | null = null;
    private _currentEngine: IEngineDescription | null = null;
    private _requestedPlayerId: number | null = null;
    private _currentPlayerId: number | null = null;
    private switchAfterDisconnect: boolean = false;


    public static getInstance(): EngineConnectionService {
        return this.singleton || (this.singleton = new EngineConnectionService());
    }

    private constructor() {
        this._fetchManager = FetchManager.getInstance();
        this._subscriptionContainer = new SubscriptionContainer(this);
        this._eningeConnectionSubscriptionContainer = new SubscriptionContainer(this);

        this._connectionMode = ConnectionType.Hub;
        this._connectionService = HubConnectionService.getInstance();
        this.attachToConnectionService();
    }


    public get obsEngineConnectionsState(): IObservableDef<EngineConnectionState> { return this._connectionsState; }
    public get receivedFromEngineEvent(): IEventEmitter<IFromEngineMessageH2C | IMessageToControllerE2DC> { return this._receivedFromEngineEvent }
    public get receivedChannelOpenMessage() { return this._receivedChannelOpenMessage; }
    public get onError(): IEventEmitter<string> { return this._onError }

    public async connectToEngine(engine: IEngineDescription, playerNbr: number) {
        this._currentEngine = engine;
        this._currentPlayerId = playerNbr;
        
        //TODO remove this when the api send the local url to the controller
        //engine.localUrl = "127.0.0.1:8181";
        if (this._connectionsState.value !== EngineConnectionState.Disconnected && this._connectionsState.value !== EngineConnectionState.NoLogin) {
            return;
        }
        this._connectionsState.emit(EngineConnectionState.PollingLocalConnection);
        //let connectLocal = await this.checkLocalAvailability(engine);
        if (false) {
            //this.setConnectionMode(ConnectionType.Local);
        } else {
            //todo hub forced out
            this.setConnectionMode(ConnectionType.Hub);
        }
        this._connectionService.connectToEngine(engine, playerNbr);
    }

    public connectTo(engine: IEngineDescription, playerNbr: number) {
        this._requestedEngine = engine;
        this._requestedPlayerId = playerNbr;
        if (this._connectionsState.value == EngineConnectionState.Disconnected ||
            this._connectionsState.value == EngineConnectionState.NoLogin) {
            this.connectToEngine(engine, playerNbr);
        } else if (this._requestedEngine != this._currentEngine) {
            this.disconnectFromEngine();
            this.switchAfterDisconnect = true;
        }
    }

    private async checkLocalAvailability(engine: IEngineDescription): Promise<boolean> {
        let p = new Promise<boolean>((resolve, reject) => {
            let fetchPromise = this._fetchManager.fetch("http://" + engine.localUrl + "/Info");
            fetchPromise.then(() => (resolve(true)));
            fetchPromise.catch(() => (resolve(false)));
            setTimeout(() => (resolve(false)), 2000);
        });
        return p;
    }

    public disconnectFromEngine(): void {
        this._connectionService.disconnectFromEngine();
    }

    public openChannel(channelId: ChannelType): any {
        if (this._connectionsState.value == EngineConnectionState.Connected) {
            this._connectionService.openChannel(channelId);
        }
    }

    public closeChannel(channelId: ChannelType): any {
        if (this._connectionsState.value == EngineConnectionState.Connected) {
            this._connectionService.closeChannel(channelId);
        }
    }

    public sendToEngine(message: MessageC2E, channelId: ChannelType) {
        this._connectionService.sendToEngine(message, channelId);
    }

    private setConnectionMode(mode: ConnectionType) {
        if (this._connectionMode == mode) {
            return;
        }
        this._connectionMode = mode;
        this.detachConnectionService();
        switch (mode) {
            case ConnectionType.Local:
                this._connectionService = DirectEngineConnectionService.getInstance();
                break;
            case ConnectionType.Hub:
                this._connectionService = HubConnectionService.getInstance();
                break;
            default:
                throw "Whats this? Invalid connection type!";
        }
        this.attachToConnectionService();
    }

    private detachConnectionService() {
        if (this._connectionService) {
            this._eningeConnectionSubscriptionContainer.unsubscribeAll();
        }
    }

    private attachToConnectionService() {
        this._connectionService.channelOpened.subscribe(this._eningeConnectionSubscriptionContainer, (channelType) => (this._receivedChannelOpenMessage.emit(channelType)));
        this._connectionService.obsConnectionsState.subscribeInitial(this._eningeConnectionSubscriptionContainer, (state) => (this.connectionStateChanged(state)));
        this._connectionService.receivedFromEngineEvent.subscribe(this._eningeConnectionSubscriptionContainer, (message) => (this.onReceivedFromEngine(message)));
        this._connectionService.connectedEnginesChangedEvent.subscribe(this._eningeConnectionSubscriptionContainer, (message) => (this.onEngineStatusChange(message)));
        this._connectionService.onError.subscribe(this._eningeConnectionSubscriptionContainer,
            err => {
                this._onError.emit(err != null ? err.message : "");
            });
    }


    private onReceivedFromEngine(message: IFromEngineMessageH2C | IMessageToControllerE2DC): void {
        this._receivedFromEngineEvent.emit(message);
    };

    private onEngineStatusChange(message: IConnectedEngines) {
        if (message.controllingEngineNbr != null && this._connectionsState.value != EngineConnectionState.Connected) {
            this._connectionsState.emit(EngineConnectionState.Connected);
        } else if (message.controllingEngineNbr == null) {
            this._connectionsState.emit(EngineConnectionState.NoEngine);
        }
    }

    private connectionStateChanged(state: ConnectionState) {
        switch (state) {
            case ConnectionState.Open:
                this._connectionsState.emit(EngineConnectionState.WaitingForEngine);
                break;
            case ConnectionState.Disconnected:
                this._connectionsState.emit(EngineConnectionState.Disconnected);
                
                if (this.switchAfterDisconnect == true && this._requestedEngine != null && this._requestedPlayerId != null) {
                    this.switchAfterDisconnect = false;
                    this.connectToEngine(this._requestedEngine, this._requestedPlayerId);
                }                
                break;
            case ConnectionState.Connecting:
                this._connectionsState.emit(EngineConnectionState.Connecting);
                break;
            case ConnectionState.NoLogin:
                this._connectionsState.emit(EngineConnectionState.NoLogin);
                break;
            default:
                this.assertUnreachable(state);
        }
    }

    private assertUnreachable(x: never): never {
        throw new Error("Didn't expect to get here");
    }


}

export enum EngineConnectionState {
    Disconnected,
    PollingLocalConnection,
    WaitingForEngine,
    NoEngine,
    Connecting,
    Connected,
    NoLogin
}

export enum ConnectionType {
    Local,
    Hub
}

