import { ViewPart, IParamViewPart } from "../ViewPart";
import { PartStyleTextAlign, IPartStyle } from "../../../ui/parts/IPartStyle";


export interface IParamTextViewBase extends IParamViewPart {

}

export abstract class TextViewBase extends ViewPart {

    constructor(tag: string, par: IParamTextViewBase) {
        super(tag, par);
    }

    public static styleTextOverflowEllipsis(style?: IPartStyle): IPartStyle {
        return TextViewBase.styleMerge({
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis" 
        }, style);
    }

    //public setColor(color: string) {
    //    this.applyStyle({
    //        color: color
    //    });
    //}

    //public setAlign(direction: PartStyleTextAlign) {
    //    this.applyStyle({
    //        textAlign: direction
    //    });
    //}

    //public setFontSize(sizePt: number) {
    //    this.applyStyle({
    //        fontSize: sizePt + "pt"
    //    });
    //}

    //public setFontWeigth(weigth: "normal" | "bold") {
    //    this.applyStyle({
    //        fontWeight: weigth.toString()
    //    });
    //}

}