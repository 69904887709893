import * as Collection from "./IKeyedCollection";

export class KeyedCollection<T> implements Collection.IKeyedCollection<T> {
    private _items: { [index: string]: T } = {};

    private _count: number = 0;

    public containsKey(key: string): boolean {
        return this._items.hasOwnProperty(key);
    }

    public count(): number {
        return this._count;
    }

    public add(key: string, value: T) {
        if (!this._items.hasOwnProperty(key))
            this._count++;

        this._items[key] = value;
    }

    public remove(key: string): T {
        var val = this._items[key];
        delete this._items[key];
        this._count--;
        return val;
    }

    public item(key: string): T | undefined {
        return this._items[key];
    }

    public clear(): void {
        this._items = {};
        this._count = 0;
    }

    public get keys(): string[] {
        var keySet: string[] = [];

        for (var prop in this._items) {
            if (this._items.hasOwnProperty(prop)) {
                keySet.push(prop);
            }
        }

        return keySet;
    }

    public get values(): T[] {
        var values: T[] = [];

        for (var prop in this._items) {
            if (this._items.hasOwnProperty(prop)) {
                values.push(this._items[prop]);
            }
        }

        return values;
    }
}