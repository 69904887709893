import { ApplicationConstants } from '../../../../app/ApplicationConstants';
import {
    IObservable,
    IObservableDef,
    IObservableOpt,
    ObservableDef,
    ObservableOpt
    } from '../../../../lib/utils/eventbase/Observable';
import { LoginService, LoginStatus } from '../../../../server_com/services/login/LoginService';
import { ViewModel } from '../../../../lib/ui/viewmodels/common/ViewModel';

export class LoginViewModel extends ViewModel{

    private _loginStatusText: ObservableOpt<string> = new ObservableOpt();
    public get loginStatusText(): IObservableOpt<string> { return this._loginStatusText; }

    private _versionText = new ObservableDef<string>(ApplicationConstants.versionString);
    public get versionText(): IObservableDef<string> { return this._versionText; };

    public get verificatieStep(): IObservable<string> { return this._loginService.obsLoginStep; };
    public get loginError(): IObservable<string> { return this._loginService.obsLoginError; };

    private _loginService: LoginService;

    constructor(parent: ViewModel) {
        super(parent);
        this._loginService = LoginService.getInstance();
        this._loginService.obsLoginStatus.subscribeInitial(this.subscriptionContainer, (s) => this.setLoginStatus(s));
        //setTimeout(() => this.login(), 100);
    }

    private login() {
        this._loginService.tryLoginAsync();

    }

    private setLoginStatus(status: LoginStatus): void {

        switch (status) {
            case "busy":
                this._loginStatusText.emit("Logging in");
                break;
            case "logged_in":
                this._loginStatusText.emit("Login succes");
                break;
            case "not_started":
                this._loginStatusText.emit("Application offline");
                break;
            case "no_licence":
                this._loginStatusText.emit(`Apparaat nummber: ${this._loginService.deviceNr}`);
                break;
            case "error":
                this._loginStatusText.emit("Failed to login");
                break;
            default:
                this.assertUnreachable(status);
        }
    }

    private assertUnreachable(x: never): never {
        throw new Error("Unreachable status: " + x);
    }
}