import { CardContainer, CardSizeMode, CardBackgroundColor } from "../../../../../../common/ui/parts/containers/CardContainer";
import { TextPart } from "../../../../../../lib/ui/parts/text/TextPart";
import { WindowEvents } from "../../../../../../lib/app/WindowEvents";
import { ContainerPart } from "../../../../../../lib/ui/parts/containers/ContainerPart";
import { IconButton } from "../../../../../../common/ui/parts/buttons/IconButton";
import { ViewPart } from "../../../../../../lib/ui/parts/ViewPart";
import { NormalText } from "../../../../../../common/ui/parts/text/NormalText";
import { ProgramStatusViewModel } from "./ProgramStatusViewModel";
import { AutomatTitleText } from "../../../../../../common/ui/parts/text/AutomatTitleText";
import { AutomatGenreText } from "../../../../../../common/ui/parts/text/AutomatGenreText";
import theme from "../../../../../../app/style/Theme";
import { GridContainer } from "../../../../../../lib/index";
import { Colors } from "../../../../../../common/ui/parts/colors/Colors";

export class ProgramStatusView extends ContainerPart {
    private _progressContainer: ContainerPart;

    constructor(viewModel: ProgramStatusViewModel) {

        let progressContainer = new ContainerPart({
            style: {
                position: "absolute",
                height: "auto",
                top: "0",
                bottom: "0",
                left: "0",
                right: "0",
                backgroundColor: Colors.listItemBackground,
                zIndex: "0",
            },
            children: [

            ]
        });

        super({
            children: [
                new ContainerPart({
                    style: {
                        margin: theme.getSpacePx(2.5, 5),
                        position: "relative",
                        background: "black",
                        //padding: theme.getSpacePx(5),
                        height: "auto",
                        width: "auto",
                        borderRadius: "100px",
                        overflow: "hidden"
                    },
                    children: [
                        progressContainer,
                        new GridContainer({
                            style: {
                                position: "relative",
                                zIndex: "1",
                                padding: theme.getSpacePx(5, 20, 5, 20),
                                gridTemplateAreas: 
                                    `"title_text button_decrease button_increase button_remove" "genre_text button_decrease button_increase button_remove"`,
                                gridTemplateRows: "0.5fr 0.5fr",
                                gridTemplateColumns: "1fr min-content min-content min-content",
                                alignItems: "center",
                                gridRowGap: theme.getSpacePx(2)
                            },
                            children: [
                                // programName
                                new AutomatTitleText({
                                    style: {
                                        gridArea: "title_text",
                                    },
                                    textSubscription: viewModel.programName,
                                }),
                                //programGenre
                                new AutomatGenreText({
                                    style: {
                                        gridArea: "genre_text",
                                    },
                                    textSubscription: viewModel.genreName,
                                }),
                                //removeButton
                                new IconButton({
                                    icon: "delete",
                                    onclick: () => viewModel.removeProgramClicked(),
                                    style: {
                                        gridArea: "button_remove",
                                    }
                                }),
                                //increaseButton
                                new IconButton({
                                    icon: "add",
                                    onclick: () => viewModel.increaseProgramClicked(),
                                    style: {
                                        gridArea: "button_increase",
                                    }
                                }),
                                //decreaseButton 
                                new IconButton({
                                    icon: "remove",
                                    onclick: () => viewModel.decreaseProgramClicked(),
                                    style: {
                                        gridArea: "button_decrease",
                                    }
                                })
                            ]
                        })
                    ]
                })
            ]
        });
        this._progressContainer = progressContainer;
        viewModel.useFactor.subscribeInitial(this.subscriptionContainer, (value) => (this.setUseFactor(value)));
    }

    private setUseFactor(value: number) {

        this._progressContainer.applyStyle({
            width: "auto",
            right: (100 - value * 100) + "%",
        });
    }

    dispose(): void {
        super.dispose();
    }
}

