import { EngineMenuView } from "./EngineMenuView";
import { EngineConnectionMenuView } from "./connection/EngineConnectionMenuView";
import { ContainerPart } from "../../lib/ui/parts/containers/ContainerPart";
import { ContentSwitchPart } from "../../lib/ui/parts/contentmanagers/ContentSwitchPart";
import { ViewFactory } from "../../lib/ui/parts/contentmanagers/ViewFactory";
import { EngineConnectionViewModel } from "../engine/EngineConnectionViewModel";
import { EngineConnectionMenuViewModel } from "../engine/connection/EngineConnectionMenuViewModel";
import { EngineMenuViewModel } from "../engine/EngineMenuViewModel";
import { PlayerViewModel } from "./player/PlayerViewModel";
import { PlayerView } from "./player/PlayerView";

export class EngineConnectionView extends ContainerPart {

    constructor(viewModel: EngineConnectionViewModel) {
        super({
            children: [
                new ContentSwitchPart({
                    viewModelSubscription: viewModel.activeChildViewModel,
                    viewFactories: [
                        new ViewFactory(EngineConnectionMenuViewModel, (vm) => new EngineConnectionMenuView(vm)),
                        new ViewFactory(PlayerViewModel, (vm) => new PlayerView(vm)),
                    ]
                })
            ]
        });
    }


}