import { GridContainer } from "../../../../lib/ui/parts/containers/GridContainer";
import { TracklistComponentViewModel } from "./TracklistComponentViewModel";
import { CardContainer, CardBackgroundColor, CardSizeMode } from "../../../../common/ui/parts/containers/CardContainer";
import { IconButton } from "../../../../common/ui/parts/buttons/IconButton";
import { TitleText } from "../../../../common/ui/parts/text/TitleText";
import { Button } from "../../../../common/ui/parts/buttons/Button";
import { ItemsFactoryPart } from "../../../../lib/ui/parts/nonvisible/ItemsFactoryPart";
import { ScrollContainerPart } from "../../../../lib/ui/parts/containers/ScrollContainerPart";
import { TracklistItemView } from "./TracklistItemView";
import { NormalText } from "../../../../common/ui/parts/text/NormalText";
import { BigTrackArtistText } from "../../../../common/ui/parts/text/BigTrackArtistText";
import { BigTrackTitleText } from "../../../../common/ui/parts/text/BigTrackTitleText";
import { AlbumCover } from "../../../../common/ui/parts/image/AlbumCover";
import texts from "../../../../common/ui/localization/Language";
import theme from "../../../../app/style/Theme";
import { Colors } from "../../../../common/ui/parts/colors/Colors";

export class TracklistComponentView extends GridContainer {

    constructor(viewmodel: TracklistComponentViewModel) {
        super({
            style: {
                overflowY: "auto",
                gridTemplateRows: "min-content minmax(0px, 1fr)",
                gridTemplateColumns: "auto",
                gridRowGap: theme.getSpacePx(60),
                padding: `0 5.5% ${theme.getSpacePx(10)} 5.5%`
            },

            children: [
                new GridContainer({
                    style: {
                        margin: theme.getSpacePx(10, 0),
                        gridTemplateColumns: "auto 1fr",
                        height: "auto"
                    },
                    children: [
                        new AlbumCover({

                        }),
                        new GridContainer({
                            style: {
                                gridTemplateRows: "min-content min-content min-content",
                                gridTemplateColumns: "1fr",
                                alignContent: "space-evenly",
                                paddingTop: theme.getSpacePx(40),
                                paddingLeft: theme.getSpacePx(30)
                            },
                            children: [
                                //Track title & artist
                                new NormalText({
                                    style: {
                                        color: Colors.primaryTextColor
                                    },
                                    text: texts.playlists
                                }),
                                new BigTrackArtistText({
                                    style: {
                                        color: Colors.primaryTextColor
                                    },
                                    text: texts.variousArtists
                                }),
                                new BigTrackTitleText({
                                    style: {

                                    },
                                    textSubscription: viewmodel.obsTitle
                                }),

                                //controls
                                new GridContainer({
                                    style: {
                                        width: theme.getSpacePx(150),
                                        gridTemplateColumns: "auto auto",
                                        gridColumnGap: theme.getSpacePx(10),
                                    },
                                    visibleSubscription: viewmodel.obsShowButtons,
                                    children: [
                                        new Button({
                                            text: "Play",
                                            onclick: () => viewmodel.playListNow()
                                        }),
                                        new Button({
                                            text: "Toevoegen",
                                            onclick: () => viewmodel.addList()
                                        }),
                                    ]
                                }),
                            ]
                        })
                    ]
                }),

                new GridContainer({
                    style: {
                        gridAutoFlow: "row",
                        gridAutoRows: "min-content",
                        gridRowGap: theme.getSpacePx(10),
                    },
                    children: [
                        new ItemsFactoryPart({
                            itemsSourceSubscription: viewmodel.obsTracks,
                            itemViewFactory: (vm, pos) => new TracklistItemView(vm)
                        })
                    ]
                })
            ]
        });
    }
}