import { ViewModel, IViewModel } from "../../../../lib/ui/viewmodels/common/ViewModel";
import { ITracklistDetailsTrack } from "../../../../server_com/models/player/tracklist/ITracklistDetailsTrack";
import { ObservableOpt, IObservable } from "../../../../lib/utils/eventbase/Observable";
import { WaitlistService } from "../../../../server_com/services/player/waitlist/WaitlistService";
import { TracklistsService } from "../../../../server_com/services/player/playlist/TracklistsService";
import { StyleConstants } from "../../../../common/ui/parts/style/StyleConstants";

export class TracklistItemViewModel extends ViewModel {

    private _obsTrackName: ObservableOpt<string> = new ObservableOpt();
    private _obsButtonIcon: ObservableOpt<string> = new ObservableOpt();

    public get obsTrackName(): IObservable<string> { return this._obsTrackName }
    public get obsButtonIcon(): IObservable<string> { return this._obsButtonIcon }

    private readonly _obsIcon: ObservableOpt<string> = new ObservableOpt();
    public get obsIcon(): IObservable<string> { return this._obsIcon; }

    private _tracklistsService: TracklistsService;

    constructor(parent: IViewModel, private tracklistItem: ITracklistDetailsTrack, private parentTracklistId:string) {
        super(parent);
        this._tracklistsService = TracklistsService.getInstance();
        this._obsTrackName.emit(`${tracklistItem.artist} - ${tracklistItem.title}`);
        this._obsButtonIcon.emit(tracklistItem.inWaitlist ? "playlist_add_check" : "playlist_add");
        this._obsIcon.emit(this.getTrackIcon(tracklistItem));
    }

    private getTrackIcon(track: ITracklistDetailsTrack): string {
        if (track.isKaraoke) {
            return "mic";
        } else if (track.isVideo) {
            return "movie";
        }
        //For now just show a play button
        return StyleConstants.playButtonIcon;
    }

    public onAddButtonClick(): void {
        this._tracklistsService.addTrackToWaitlist(this.tracklistItem.trackId, this.parentTracklistId);
        this._obsButtonIcon.emit("playlist_add_check");
    }
}