import {MessageC2H} from "../MessageC2H";

export class LoginMessageC2H extends MessageC2H {
    constructor(token: string/*, license: string*/, playerNbr: number) {
        super();
        this.token = token;
        this.playerNbr = playerNbr;
    }

    public readonly token: string;
    //public readonly license: string;
    public readonly playerNbr: number;
}