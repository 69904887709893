import { LocalizationService } from './common/ui/localization/LocalizationService';
import { LocalStorageManager } from './lib/app/local_storeage/LocalStorageManager';
import { MainView } from './ui/views/main/MainView';
import { MainViewModel } from './ui/viewmodels/main/MainViewModel';
import { NavigationManager } from './lib/ui/navigation/NavigationManager';
import { RootPart } from './lib/ui/parts/RootPart';
import { WorkerCom } from './app/worker/WorkerCom';

export class Index {

    constructor(root: HTMLElement) {
        LocalStorageManager.getInstance().checkReset();
        LocalizationService.getInstance().checkForUpdateAsync();
        const w = new WorkerCom();
        w.start();
        const navManager = NavigationManager.getInstance();
        navManager.setMainViewModel(new MainViewModel());

        let rootPart = new RootPart(root);

        // ReSharper disable once WrongExpressionStatement
        rootPart.addChild(new MainView(navManager.mainViewModel!));

    }

    public boot() {

    }

}


(Object as any).prototype["getType"] = function () { return this.name || this.constructor.name };

const boot = () => {
    let el = document.getElementById("content");
    if (el !== null) {
        el.innerHTML = "";
        (window as any).app = new Index(el);
    } else {
        console.log("Cannot find element 'content'");
    }
}

boot();
