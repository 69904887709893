import { IDisposable } from "../disposable/IDisposable";
import { IUnsubscribable } from "./IUnsubscribable";

export class SubscriptionContainer implements IDisposable {

    private _subscriptions: IUnsubscribable[] | undefined;

    constructor(private _parent: any) {
        this._subscriptions = new Array<IUnsubscribable>();
    }

    public addUnsubscribable(sub: IUnsubscribable) {
        this._subscriptions?.push(sub);
    }

    public unsubscribeAll() {
        if (this._subscriptions) {
            for (let sub of this._subscriptions) {
                if (sub != null) {
                    sub.unsubscribe(this);
                }
            }
        }
    }

    public dispose(): void {
        this.unsubscribeAll();
        this._subscriptions = undefined;
        delete this._parent;
    }
}