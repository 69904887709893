import { GridContainer, IParamGridContainer } from "../../../lib/ui/parts/containers/GridContainer";
import { PlayerSideBarViewModel } from "./PlayerSideBarViewModel";
import { TextWithIconButton } from "../../../common/ui/parts/buttons/TextWithIconButton";
import theme from "../../../app/style/Theme";
import texts from "../../../common/ui/localization/Language";
import { Colors } from "../../../common/ui/parts/colors/Colors";

export interface IParamPlayerSideBarView extends IParamGridContainer {

}

export class PlayerSideBarView extends GridContainer{

    constructor(par: IParamPlayerSideBarView, viewmodel: PlayerSideBarViewModel) {

        PlayerSideBarView.setDefaultStyle(par, {
            gridArea: "menu",
            backgroundColor: Colors.sideMenuBackgorundColor,
            gridTemplateRows: "1fr min-content",
            padding: theme.getSpacePx(30, 40),
            
        });
        par.children = [
            new GridContainer({
                style: {
                    gridAutoFlow: "row",
                    gridAutoRows: "min-content",
                },
                children: [
                    new TextWithIconButton({
                        style: {
                            gridColumn: "2"
                        },
                        icon: "icon-home",
                        text: texts.home,
                        onclick: () => viewmodel.homeButtonClick()
                    }),
                    new TextWithIconButton({
                        style: {
                            gridColumn: "2"
                        },
                        icon: "icon-play",
                        text: texts.playlists,
                        onclick: () => viewmodel.playlistButtonClick()
                    }),
                    new TextWithIconButton({
                        style: {
                            gridColumn: "2"
                        },
                        icon: "icon-search",
                        text: texts.search,
                        onclick: () => viewmodel.searchButtonClick()
                    }),
                ]
            }),
            new GridContainer({
                style: {
                    gridAutoFlow: "row",
                    gridAutoRows: "min-content",
                    justifyContent: "center"
                },
                children: [
                    new TextWithIconButton({
                        style: {
                            gridColumn: "2"
                        },
                        icon: "speaker_group",
                        text: "Zones",
                        onclick: () => viewmodel.gotoZones()
                    }),
                    new TextWithIconButton({
                        style: {
                            gridColumn: "2"
                        },
                        icon: "icon-settings",
                        text: "Instellingen",
                        onclick: () => viewmodel.gotoSettings()
                    }),
                    new TextWithIconButton({
                        style: {
                            gridColumn: "2"
                        },
                        icon: "icon-information",
                        text: "Informatie",
                        onclick: () => viewmodel.gotoInformation()
                    }),
                ]
            }),
            
        ];
        super(par);

    }

}