import { GridContainer } from "../../../../lib/ui/parts/containers/GridContainer";
import { ScrollContainerPart } from "../../../../lib/ui/parts/containers/ScrollContainerPart";
import { ItemsFactoryPart } from "../../../../lib/ui/parts/nonvisible/ItemsFactoryPart";
import { PlaylistsOverviewComponentViewModel } from "./PlaylistsOverviewComponentViewModel";
import { PlaylistsItemView } from "./playlist_list_item/PlaylistsItemView";
import { PartSize } from "../../../../lib/ui/parts/ViewPart";
import theme from "../../../../app/style/Theme";

export class PlaylistsOverviewComponentView extends GridContainer{

    constructor(viewmodel: PlaylistsOverviewComponentViewModel) {
        super({
            style: {
                height: "100%",
                gridTemplateRows: "100%",
                marginLeft: "6%"
            },
            children: [
                
                new ScrollContainerPart({
                    style: {
                        margin: theme.getSpacePx(10, 5),
                        boxSizing: "border-box",
                        display: "grid",
                        gridAutoFlow: "row",
                        gridRowGap: theme.getSpacePx(8),
                        gridTemplateRows: "auto",
                        gridTemplateColumns: "1fr"
                    },
                    partSize: PartSize.AutoSize,
                    children: [
                        new ItemsFactoryPart({
                            itemsSourceSubscription: viewmodel.playlistsViewModels,
                            itemViewFactory: (vm, p) => new PlaylistsItemView(vm, p)
                        })
                    ]
                }),
            ]
        });

    }
}