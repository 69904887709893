import { IObservable } from "../../../utils/eventbase/Observable";
import { Part } from "../Part";

export interface IParamItemFactoryPart<TViewModel> {
    itemSource?: TViewModel | null;
    itemSourceSubscription?: IObservable<TViewModel | null>;
    itemViewFactory?: (vm: TViewModel) => Part;
    nullViewFactory?: () => Part;
}

export class ItemFactoryPart<TViewModel> extends Part {

    constructor(par: IParamItemFactoryPart<TViewModel>) {

        super({});

        if (par.itemSource) {
            if (par.itemSource) {

                if (par.itemViewFactory) {
                    let part = par.itemViewFactory(par.itemSource);
                    this.addChild(part);
                }

            } else {

                if (par.nullViewFactory) {
                    this.addChild(par.nullViewFactory());
                }
            }
        }
        else if (par.itemSourceSubscription) {
            par.itemSourceSubscription.subscribeInitial(this.subscriptionContainer, (viewModel: TViewModel | null) => {

                this.removeChildren();
                if (viewModel) {

                    if (par.itemViewFactory) {
                        let part = par.itemViewFactory(viewModel);
                        this.addChild(part);
                    }

                } else {

                    if (par.nullViewFactory) {
                        this.addChild(par.nullViewFactory());
                    }
                }
            });
        }
    }
}