import { TextViewBase, IParamTextViewBase } from "../bases/TextViewBase";

export interface IParamH1TextPart extends IParamTextViewBase {
}

export class H1TextPart extends TextViewBase {

    constructor(par: IParamH1TextPart) {
        super("H1", par);
    }
}