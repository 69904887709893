export class PlayerTrack {

    constructor(title: string, artist: string, album: string) {
        this.title = title;
        this.artist = artist;
        this.album = album;
    }

    public title: string;
    public artist: string;
    public album: string;
}