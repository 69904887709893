import { StyleConstants } from "../style/StyleConstants";
import { ContainerBase, IParamContainerBase } from "../../../../lib/ui/parts/bases/ContainerBase";
import { ContainerPart } from "../../../../lib/ui/parts/containers/ContainerPart";
import { ViewPart } from "../../../../lib/ui/parts/ViewPart";
import { IObservable } from "../../../../lib/utils/eventbase/Observable";
import theme from "../../../../app/style/Theme";
import { Colors } from "../colors/Colors";






export interface IParamProgressBar extends IParamContainerBase {
    progress?: number,
    progressSubscription?: IObservable<number>,
}

export class ProgressBar extends ContainerBase {

    private readonly _barbackgroundView: ContainerPart;
    private readonly _barView: ContainerPart;
    private readonly _barEndDotView: ContainerPart;

    constructor(par: IParamProgressBar) {

        ViewPart.setDefaultStyle(par, {
            width: "100%",
            height: theme.scalePx(30),
            position: "relative",
        });

        super(par);

        this._barbackgroundView = new ContainerPart({
            style: {
                height: theme.scalePx(10),
                marginTop: theme.getSpacePx(10),
                position: "absolute",
                backgroundColor: Colors.progressbarColor,
                boxShadow: StyleConstants.defaultBoxShadow,
                borderRadius: theme.getSpacePx(5),
                pointerEvents: "none"
            }

        });
        this.addChild(this._barbackgroundView);

        this._barView = new ContainerPart({
            style: {
                width: "100%",
                height: theme.scalePx(10),
                marginTop: theme.getSpacePx(10),
                position: "absolute",
                boxShadow: StyleConstants.defaultBoxShadow,
                borderRadius: theme.getSpacePx(5),
                pointerEvents: "none",
                backgroundColor: Colors.progressbarPassedColor,
            }
        });
        this.addChild(this._barView);

        this._barEndDotView = new ContainerPart({
            style: {
                width: theme.scalePx(20),
                height: theme.scalePx(20),
                borderRadius: theme.getSpacePx(10),
                backgroundColor: Colors.progressbarThumpColor,
                boxShadow: StyleConstants.defaultBoxShadow,
                position: "absolute",
                top: theme.getSpacePx(5),
                left: "0px",
                pointerEvents: "none"
            }
        });
        this.addChild(this._barEndDotView);
        //this.barEndDotView.root.setAttribute("draggable","");
        //this.root.onclick = (ev: MouseEvent) => (this.handleMouseEvent(ev));
        //this.root.onmousemove = (ev: MouseEvent) => (this.handleMouseEvent(ev));
        if (typeof par.progress === "number") {
            this.setProgress(par.progress);
        }
        if (par.progressSubscription) {
            par.progressSubscription.subscribeInitial(this.subscriptionContainer, (v) => this.setProgress(v));
        }
    }

    private handleMouseEvent(ev: MouseEvent) {
        if ((ev.type === "click" && ev.button === 0) || ev.buttons === 1) {
            this.setProgress(ev.offsetX, this.absoluteWidth);
        }
    }

    public setProgress(progress: number, max: number = 100) {
        progress = Math.min(progress, max);
        progress = Math.max(progress, 0);
        max = Math.max(max, 1);
        const value = (progress / max) * 100;
        let leftMargin = theme.scalePx(10 * value / 100);
        this._barView.applyStyle({
            width: `calc(3px + ${value}%`,
        });
        this._barEndDotView.applyStyle({
            left: `calc(${value}% - ${leftMargin})`
        });
    }

}
