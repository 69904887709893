import { TransitionBase } from "../../../lib/ui/transitions/TransitionBase";
import { ViewPart } from "../../../lib/ui/parts/ViewPart";
import { StyleConstants } from "../parts/style/StyleConstants";

export class TransitionFadeInFromLeft extends TransitionBase {
    constructor() {
        super(StyleConstants.defaultTransitionSpeed, 1);
    }

    public beforeAnimate(view: ViewPart): void {
        if (view.root.style.display === "none") {
            view.applyStyle({
                display: "block"
            });
        }

        view.show();
        view.applyStyle({
            pointerEvents: "none",
            position: "relative",
            right: window.innerWidth + "px",
            left: (-window.innerWidth) + "px",
        });
    }

    public animateStep(view: ViewPart, step: number): void {
        view.applyStyle({
            right: "0px",
            left: "0px",
        });
    }

    public afterAnimate(view: ViewPart): void {
        view.applyStyle({
            pointerEvents: "auto",
            position: "unset",
        });
    }
}