import { ViewPart, IParamViewPart } from "../ViewPart";

export interface IParamImageBase extends IParamViewPart {
    source: string;
}

export abstract class ImageBase extends ViewPart {
    protected get image(): HTMLImageElement {
        return this.root as HTMLImageElement;
    }

    constructor(par: IParamImageBase) {
        ImageBase.setDefaultStyle(par, { userSelect: "none" });
        super("IMG", par);
        if (par.source) {
            this.changeSource(par.source);
        }
        
    }

    public changeSource(src: string) {
        this.image.setAttribute("src", src);
    }

    public dispose(): void {
        super.dispose();
    }
}