import { ComponentBreadCrumPart, IParamComponentBreadCrumPart } from "../../../../lib/ui/parts/components/ComponentBreadCrumPart";
import { TitleText } from "../text/TitleText";
import { ComponentViewModel } from "../../../../lib/ui/viewmodels/components/ComponentViewModel";
import { ViewPart } from "../../../../lib/ui/parts/ViewPart";
import { Link } from "../link/Link";
import { NormalText } from "../text/NormalText";
import theme from "../../../../app/style/Theme";

export interface IParamPlayerBreadCrumText extends IParamComponentBreadCrumPart {

}

export class PlayerBreadCrumText extends ComponentBreadCrumPart {

    constructor(par: IParamPlayerBreadCrumText) {
        PlayerBreadCrumText.setDefaultStyle(par, {
            alignItems: "center"
        });
        super(par);
    }

    protected createParentComponentView(componentViewModel: ComponentViewModel): ViewPart {
        return new NormalText({
            textSubscription: componentViewModel.obsComponentTitle,
            onclickAsync: async () => { await componentViewModel.closeChildComponentsAsync() },
        });
    };

    protected createActiveComponentView(componentViewModel: ComponentViewModel): ViewPart {
        return new NormalText({
            style: {
                display: "inline",
                margin: "0"
            },
            textSubscription: componentViewModel.obsComponentTitle,
        });

    };

    protected createSpaceView(): ViewPart {
        return new NormalText({
            style: {
                margin: theme.getSpacePx(0,5)
            },
            text: " | "
        });
    };
}