import { PartSize } from "../ViewPart";
import { IOverlayViewModel } from "../components/PopupComponentContainerPart";
import { ContainerPart } from "../containers/ContainerPart";
import { LinkPart } from "../button/LinkPart";
import { H3TextPart } from "../text/H3TextPart";
import theme from "../../../../app/style/Theme";

export class DefaultPopupOverlay extends ContainerPart {

    constructor(vm: IOverlayViewModel) {
        super({
            partSize: PartSize.Undefined,
            style: {
                backgroundColor: "#0008",
                top: "0px",
                left: "0px",
                height: "100vh",
                width: "100vw",
                position: "absolute",
                zIndex: "100",
                display: "grid",
                justifyContent: "center",
                alignContent: "center",
            },
            onclickAsync: async () => { await vm.contentViewModel.tryCloseComponentAsync() },
            clickOnlyOnThisElement: true,
            children: [
                new ContainerPart({
                    partSize: PartSize.AutoSize,
                    style: {
                        backgroundColor: "black",
                        padding: theme.getSpacePx(5),
                        margin: theme.getSpacePx(5)
                    },
                    children: [
                        new H3TextPart({
                            textSubscription: vm.contentViewModel.obsComponentTitle
                        }),
                        vm.contentViewPart,
                        new LinkPart({
                            text: "Close",
                            onclick: () => vm.contentViewModel.closeComponent(),
                        }),
                    ]
                })]
        });
    }
}