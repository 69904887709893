import { TracklistItemViewModel } from "./TracklistItemViewModel";
import { ItemPosition } from "../../../../lib/ui/parts/nonvisible/ItemsFactoryPart";
import { GridContainer } from "../../../../lib/ui/parts/containers/GridContainer";
import { IconButton } from "../../../../common/ui/parts/buttons/IconButton";
import { TrackNameText } from "../../../../common/ui/parts/text/TrackNameText";
import { StyleConstants } from "../../../../common/ui/parts/style/StyleConstants";
import { HybridIcon } from "../../../../common/ui/parts/icon/HybridIcon";
import theme from "../../../../app/style/Theme";
import { Colors } from "../../../../common/ui/parts/colors/Colors";

export class TracklistItemView extends GridContainer {
    constructor(viewModel: TracklistItemViewModel) {
        super({
            style: {
                marginBottom: theme.getSpacePx(2),
                borderRadius: theme.getSpacePx(100),
                backgroundColor: Colors.listItemBackground,
                gridTemplateColumns: `${theme.scalePx(50)} 1fr auto`,
                gridTemplateRows: theme.scalePx(46),
                overflow: "hidden",
                alignItems: "center",
                height: "auto",
            },
            children: [
                new HybridIcon({
                    style: {
                        gridRow: "1",
                        gridColumn: "1",
                        color: Colors.primaryTextColor,
                    },
                    iconSubscription: viewModel.obsIcon,
                    iconSize: theme.scalePx(30)
                }),
                new TrackNameText({
                    style: {
                        gridRow: "1",
                        gridColumn: "2 / span 3",
                    },
                    textSubscription: viewModel.obsTrackName
                }),
                new GridContainer({
                    style: {
                        gridRow: "1",
                        gridColumn: "3",
                        gridTemplateRows: "auto",
                        gridTemplateColumns: "0.5fr auto 0.5fr",
                        paddingRight: theme.getSpacePx(10),
                        backgroundColor: Colors.listItemBackgroundHighlight,
                        boxShadow: `${Colors.listItemBackgroundHighlight} ${theme.getSpacePx(0,0,10,10)}`,
                        alignItems: "center",
                    },
                    children: [
                        new IconButton({
                            style: {
                                gridColumn: "2 / 2",
                            },
                            iconSubscription: viewModel.obsButtonIcon,
                            onclick: () => (viewModel.onAddButtonClick())
                        }),

                    ]
                })
            ]
        });
    }
}