import { ViewPart, PartSize } from "../ViewPart";
import { ContainerPart, IParamContainerPart } from "./ContainerPart";
import { PartStyleJustifyItems, PartGridAutoFlow } from "../IPartStyle";

export interface IParamGridPart extends IParamContainerPart {
    gridTemplateColumns?: string,
    gridTemplateRows?: string,
    gridColumnGap?: string,
    gridRowGap?: string,
    gridJustifyItems?: PartStyleJustifyItems,
    gridAutoFlow?: PartGridAutoFlow,
    gridAutoColumns?: string,
    gridAutoRows?: string,
}


export class GridPart extends ContainerPart {

    constructor(par: IParamGridPart) {

        if (!par.partSize) {
            par.partSize = PartSize.FullSize;
        }

        ViewPart.setDefaultStyle(par, {
            overflow: "hidden",
            display: "grid",
        });

        super(par);

        if (par.gridTemplateColumns) {
            this.setGridTemplateColumns(par.gridTemplateColumns);
        }

        if (par.gridTemplateRows) {
            this.setGridTemplateRows(par.gridTemplateRows);
        }

        if (par.gridColumnGap) {
            this.setGridColumnGap(par.gridColumnGap);
        }

        if (par.gridRowGap) {
            this.setGridRowGap(par.gridRowGap);
        }

        if (par.gridJustifyItems) {
            this.setGridJustifyItems(par.gridJustifyItems);
        }

        if (par.gridAutoFlow) {
            this.setGridAutoFlow(par.gridAutoFlow);
        }

        if (par.gridAutoColumns) {
            this.applyStyle({
                gridAutoColumns: par.gridAutoColumns,
            });
        }

        if (par.gridAutoRows) {
            this.applyStyle({
                gridAutoRows: par.gridAutoRows,
            });
        }


    }

    protected onAddChildToViewPart(child: ViewPart): void {
        child.applyStyle({
            width: "auto",
            height: "auto"
        });
        super.onAddChildToViewPart(child);
    }


    public setGridTemplateColumns(def: string) {
        this.applyStyle({
            gridTemplateColumns: def,
        });
    }

    public setGridTemplateRows(def: string) {
        this.applyStyle({
            gridTemplateRows: def,
        });
    }

    public setGridColumnGap(def: string) {
        this.applyStyle({
            gridColumnGap: def,
        });
    }

    public setGridRowGap(def: string) {
        this.applyStyle({
            gridRowGap: def,
        });
    }

    public setGridJustifyItems(justify: PartStyleJustifyItems) {
        this.applyStyle({
            justifyItems: justify
        });
    }

    public setGridAutoFlow(gridAutoFlow: PartGridAutoFlow) {
        this.applyStyle({
            gridAutoFlow: gridAutoFlow
        });
    }

}