export class ApplicationConstants {

    // Version. Also in manifest.json!
    public static get version(): number[] { return [1, 3, 4]; }
    public static get versionString(): string { return "v"+this.version.join("."); }

    public static get debug(): boolean { return false; }

    public static get logApplicationsState(): boolean { return false; }

    public static get logWebsocketInformation(): boolean { return true }
}