import { TransitionBase } from "../../../lib/ui/transitions/TransitionBase";
import { ViewPart } from "../../../lib/ui/parts/ViewPart";
import { StyleConstants } from "../parts/style/StyleConstants";

export class TransitionFadeIn extends TransitionBase {
    constructor() {
        super(StyleConstants.defaultTransitionSpeed, 1);
    }

    protected beforeAnimate(view: ViewPart): void {
        if (view.root.style.display === "none") {
            view.applyStyle({
                display: "block"
            });
        }

        view.applyStyle({
            pointerEvents: "none"
        });
        view.setOpacity(0);
    }

    protected animateStep(view: ViewPart, step: number): void {
        view.setOpacity(1);
    }

    protected afterAnimate(view: ViewPart): void {
        view.setOpacity(1);
        view.applyStyle({
            pointerEvents: "auto"
        });
    }
}