import { CurrentSongControlsViewModel } from "./CurrentSongControlsViewModel";
import { GridContainer } from "../../../../../lib/ui/parts/containers/GridContainer";
import { IconButtonSize } from "../../../../../common/ui/parts/buttons/IconButton";
import { PlayerIconButton } from "../../../../../common/ui/parts/buttons/PlayerIconButton";
import { AlbumCover } from "../../../../../common/ui/parts/image/AlbumCover";
import { BigTrackArtistText } from "../../../../../common/ui/parts/text/BigTrackArtistText";
import { BigTrackTitleText } from "../../../../../common/ui/parts/text/BigTrackTitleText";
import { NormalText } from "../../../../../common/ui/parts/text/NormalText";
import texts from "../../../../../common/ui/localization/Language";
import theme from "../../../../../app/style/Theme";
import { BooleanItemFactoryPart } from "../../../../../lib/ui/parts/nonvisible/BooleanItemFactoryPart";
import screenPropertiesService from "../../../../../lib/services/ScreenPropertiesService";
import { CurrentSongControlsSmallView } from "./CurrentSongControlsSmallView";
import { CurrentSongControlsNormalView } from "./CurrentSongControlsNormalView";

export class CurrentSongControlsView extends GridContainer {

    public constructor(viewmodel: CurrentSongControlsViewModel) {
        super({
            style: {
                height: "auto"
            },
            children: [
                new BooleanItemFactoryPart({
                    valueSourceSubscription: screenPropertiesService.obsIsSmall,
                    itemViewFactoryTrue: () => new CurrentSongControlsSmallView(viewmodel),
                    itemViewFactoryFalse: () => new CurrentSongControlsNormalView(viewmodel)
                })
            ]
        });
    }
}