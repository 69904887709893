import { ViewModel, ObservableOpt, IObservableOpt, ComponentViewModel } from "../../../../lib/index";
import { ApplicationConstants } from "../../../../app/ApplicationConstants";
import screenPropertiesService from "../../../../lib/services/ScreenPropertiesService";
import texts from "../../../../common/ui/localization/Language";
import { EngineSelectionService } from "../../../../server_com/services/login/EngineSelectionService";

export class AboutComponentViewModel extends ComponentViewModel {

    private _aboutText: ObservableOpt<string> = new ObservableOpt();
    public get obsAboutText(): IObservableOpt<string> { return this._aboutText; }

    private _roleText: ObservableOpt<string> = new ObservableOpt();
    public get obsRoleText(): IObservableOpt<string> { return this._roleText; }


    public get obsScreenSizes() { return screenPropertiesService.obsScreenProperties }

    constructor(parent: ViewModel) {
        super(parent, texts.about);
        this._aboutText.emit(`PB-SOUND player controller (${ApplicationConstants.versionString})`);

        const engineService = EngineSelectionService.instance;
        engineService.obsCurrentEngine.subscribeInitial(this.subscriptionContainer, (engine => {
            this._roleText.emit(`Role:${engine?.hasRoleJukebox ? " JukeBox":""}${engine?.hasRoleController ? " Controller":""}`);
        }))
    }

    public async showAsync() {
        return this.executeComponentAsync();
    }


}