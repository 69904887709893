import { UrlRepository } from "../../utils/UrlRepository";
import { EngineListStore } from "../../utils/stores/EngineListStore";
import { SubscriptionContainer } from "../../../lib/utils/eventbase/SubscriptionContainer";
import { FetchManager } from "../../../lib/utils/fetch/FetchManager";
import { ReasonableError } from "../../../lib/utils/ReasonableError";
import { TypedPromise } from "../../../lib/utils/TypedPromise";
import { LoginService } from "./LoginService";
import { FetchService } from "../../apilib/index";
import { PromiseAggregator } from "../../../common/utils/index";
import { ObservableDef, IObservable } from "../../../lib/index";

//TODO: ENGINE SELECT

export class EngineSelectionService {

    private static _singleton: EngineSelectionService;
    static get instance(): EngineSelectionService {
        return this._singleton || (this._singleton = new EngineSelectionService());
    }

    private _enginesList: EngineList | null = null;
    private _getEnginesPromiseArregator: PromiseAggregator<EngineList | null>;

    private readonly _engineListStore: EngineListStore;
    private readonly _loginService: LoginService;
    private readonly _fetchService: FetchService;

    private _obsCurrentEngine: ObservableDef<IEngineDescription | null> = new ObservableDef<IEngineDescription | null>(null);
    public get obsCurrentEngine(): IObservable<IEngineDescription | null> { return this._obsCurrentEngine; }

    private _obsEngines: ObservableDef<EngineList | null> = new ObservableDef<EngineList | null>(null);
    public get obsEngines(): IObservable<EngineList | null> { return this._obsEngines; }

    constructor() {
        this._engineListStore = EngineListStore.getInstance();
        this._fetchService = new FetchService("omit");
        this._loginService = LoginService.getInstance();
        this._getEnginesPromiseArregator = new PromiseAggregator<EngineList | null>(() => this.getEnginesInternal());
        this.tryLoadLastEngine();
    }

    private async tryLoadLastEngine() {
        let lastEngine = await this.getLastUsedEngineAsync();
        this._obsCurrentEngine.emit(lastEngine);
    }

    public invalidateList() {
        this._enginesList = null;
    }

    public async getEnginesAsync(): Promise<EngineList | null> {
        return this._getEnginesPromiseArregator.executeAsync();
    }

    private async getEnginesInternal(): Promise<EngineList | null> {
        if (this._enginesList != null) {
            return this._enginesList;
        }
        let accessToken = await this._loginService.runDeviceVerification();
        if (accessToken.result === "Succes") {
            let engines = await this.fetchEngineList(accessToken.token);
            if (engines) {
                const engineList = new EngineList(engines);
                this._enginesList = engineList;
                this._engineListStore.setEngines(engineList)
                this._obsEngines.emit(engineList);
                return engineList;
            }
        }
        return null;
    }

    private async fetchEngineList(deviceToken: string): Promise<IEngineListResult | null> {
        let url = await UrlRepository.getInstance().getEnginesApiUrlAsync();
        if (url == null) {
            return null;
        }
        let enginesResult = await this._fetchService.fetchGetAsync<IEngineListResult>(url, (headers) => {
            headers.append("recordcasetoken", deviceToken);
        });
        if (enginesResult.resultType == "data") {
            return enginesResult.data;
        } else {
            return null;
        }

    }

    public setCurrentEngine(engine: IEngineDescription) {
        if (this._obsCurrentEngine.value != null && this._obsCurrentEngine.value.token == engine.token) {
            return;
        }
        this._obsCurrentEngine.emitOnChanged(engine);
        this.setLastusedEngine(engine);
    }

    public setLastusedEngine(engine: IEngineDescription) {
        this._engineListStore.setLastUsed(engine);
    }

    public async getLastUsedEngineAsync(): Promise<IEngineDescription | null> {
        const lastUsed = this._engineListStore.getLastUsed();
        const engineList = await this.getEnginesAsync();

        if (engineList && lastUsed) {
            const engine = engineList.engines.find(
                (e) => (e.companyName === lastUsed.companyName && e.licenseDescription === lastUsed.licenseDescription));

            if (engine) {
                return engine;
            }
        }

        return null;
    }
}

export class EngineList implements IEngineListResult {
    validUntilDateTime: string;
    engines: IEngineDescription[];

    constructor(data: IEngineListResult) {
        this.engines = data.engines.map((engine, index) => ({
            companyName: engine.companyName,
            licenseDescription: engine.licenseDescription,
            token: engine.token,
            hubApiUrl: engine.hubApiUrl,
            localUrl: engine.localUrl,
            controllerVersion: engine.controllerVersion,
            hasRoleController: engine.hasRoleController,
            hasRoleJukebox: engine.hasRoleJukebox,
            index: index,
        }));
        this.validUntilDateTime = data.validUntilDateTime;
    }
}

export interface IEngineDescription {
    companyName: string;
    licenseDescription: string;
    token: string;
    hubApiUrl: string;
    localUrl: string;
    controllerVersion: string;
    hasRoleController: boolean;
    hasRoleJukebox: boolean;
    index: number;
}

export interface IEngineListResult {
    validUntilDateTime: string;
    engines: IEngineDescriptionResult[];
}
interface IEngineDescriptionResult {
    companyName: string;
    licenseDescription: string;
    token: string;
    hubApiUrl: string;
    localUrl: string; //TODO slopen
    controllerVersion: string;
    hasRoleController: boolean;
    hasRoleJukebox: boolean;
}