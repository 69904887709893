import { WebsocketState, WebsocketStateClosed, WebsocketStateConnected, WebsocketStateConnecting } from "./WebsocketState";
import { ObservableDef, IObservable, IObservableDef } from "../../../lib/utils/eventbase/Observable";
import {EventEmitter, IEventEmitter } from "../../../lib/utils/eventbase/EventEmitter";
import {ApplicationConstants} from "../../../app/ApplicationConstants";
import texts from "../../../common/ui/localization/Language";

export class WebsocketService {

    private readonly _obsConnectionState = new ObservableDef<WebsocketState>(new WebsocketStateClosed());
    private readonly _receivedFromWebsocketEvent = new EventEmitter<any>();

    private _connection: WebSocket | null = null;
    private _lastUrl = "";
    private _closedByUser = false;
    private _retryIsScheduled = false;
    private _numOfRetrys = 0;

    public get obsWebsocketState(): IObservableDef<WebsocketState> { return this._obsConnectionState }

    public get receivedFromWebsocketEvent(): IEventEmitter<any> { return this._receivedFromWebsocketEvent }

    public connect(url: string): boolean {
        if (!this._obsConnectionState.value) return false;
        switch (this._obsConnectionState.value.state) {
            case "Closed":
                this._closedByUser = false;
                this._lastUrl = url;
                this.setupWebsocket(url);
                return true;
            case "Connecting":
                console.warn("Cant open connection while connecting");
                return false;
            case "Connected":
                console.warn("Connection already open");
                return false;
            default:
                return false;
        }
    }

    public disconnect() {
        if (this._obsConnectionState.value && this._obsConnectionState.value.state === "Connected" && this._connection) {
            this._closedByUser = true;
            this._connection.close();
        } else {
            console.warn("The connection is not closed because it is already closed");
        }
    }

    public send(message: any) {

        if (this._obsConnectionState.value && this._obsConnectionState.value.state === "Connected" && this._connection) {
            this._connection.send(JSON.stringify(message));
        } else {
            console.error("The connection is not open while trying to send message:",message);
            throw Error("The connection is not open");
        }
    }

    private setupWebsocket(apiUrl: string): void {

        //let url = "ws://" + apiUrl + "/ws/hub/controller/v1";
        //let url = "ws://localhost:8181";
        let url = apiUrl;

        this.setWebsocketState(new WebsocketStateConnecting(this._lastUrl));

        this._connection = new WebSocket(url);

        this._connection.addEventListener("error", (ev) => { this.onError(ev as ErrorEvent) });

        // ReSharper disable once Html.EventNotResolved
        this._connection.addEventListener("open", (ev: Event) => { this.onOpen(ev) });

        // ReSharper disable once Html.EventNotResolved
        this._connection.addEventListener("close", (ev: CloseEvent) => { this.onClose(ev) });

        this._connection.addEventListener("message", (ev: MessageEvent) => { this.onMessage(ev) });

    }

    private onError(evt: ErrorEvent) {
        if (evt.error == "0") {
            return;
        }
        if (ApplicationConstants.debug) {
            console.error("Websocket connection error event:", evt);
        }
        this.handleError(evt.error || new Error(texts.unknowConnectionErrorFull));
    }

    private handleError(err: Error) {
        if (ApplicationConstants.debug) {
            console.error("Websocket connection error:", err);
        }

        const closedState = new WebsocketStateClosed();
        closedState.errorMessage = err ? err.message : null;
        closedState.closedByUser = this._closedByUser;
        this.setWebsocketState(closedState);
    }

    private onOpen(evt: any) {
        this._numOfRetrys = 1;
        this.setWebsocketState(new WebsocketStateConnected(this._lastUrl));
    }

    private onClose(evt: CloseEvent) {
        if (ApplicationConstants.debug) {
            console.log("Connection closed. Reason:", evt.reason || evt.code);
        }

        if (!this._closedByUser) {
            this.handleError(new Error(evt.reason || evt.code.toString()));
            return;
        }

        let closedState = new WebsocketStateClosed();
        closedState.errorMessage = null;
        closedState.closedByUser = this._closedByUser;
        this.setWebsocketState(closedState);
    }

    //private scheduleRetry() {

    //    if (this._numOfRetrys < 10 && !this._retryIsScheduled) {
    //        setTimeout(() => {
    //            console.log("Retry for connection....");
    //            this.connect(this._lastUrl);
    //            this._retryIsScheduled = false;
    //        },
    //            (1000 * this._numOfRetrys) + 1);
    //        this._numOfRetrys++;
    //        this._retryIsScheduled = true;
    //        console.log("Retry for connection scheduled");
    //        this.setWebsocketState(WebsocketState.WaitForRetry);
    //    } else if (!this._retryIsScheduled) {
    //        console.error("No connection after 10 retry's");
    //        this.setConnectionError(new Error(Strings.noMoreConnectionsAttempts));
    //        this.setWebsocketState(WebsocketState.Error);
    //    }
    //}

    private onMessage(evt: any) {
        let res: any = {};
        try {
            res = JSON.parse(evt.data);
        } catch (err) {
            console.warn("Can not parse message received from a websocket", evt.data);
        }
        if (!res.hasOwnProperty("type")) {
            console.warn("Unknow message received on a websocket", res);
        }

        this._receivedFromWebsocketEvent.emit(res);
    }

    private setWebsocketState(event: WebsocketState): void {
        if (ApplicationConstants.logApplicationsState && this._obsConnectionState.value) {
            console.log(
                `Websocket state change from '${this._obsConnectionState.value.state}' to '${event.state
                }' `);
        }
        this._obsConnectionState.emit(event);
    }

}